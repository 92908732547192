"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFetchProgressSupported = isFetchProgressSupported;
exports.default = default_1;
var Progress_1 = require("./Progress");
function isFetchProgressSupported() {
  return typeof Response !== 'undefined' && typeof ReadableStream !== 'undefined';
}
function default_1(_ref) {
  var _ref$defaultSize = _ref.defaultSize,
    defaultSize = _ref$defaultSize === void 0 ? 0 : _ref$defaultSize,
    _ref$emitDelay = _ref.emitDelay,
    emitDelay = _ref$emitDelay === void 0 ? 10 : _ref$emitDelay,
    _ref$onProgress = _ref.onProgress,
    onProgress = _ref$onProgress === void 0 ? function (_progress) {
      return null;
    } : _ref$onProgress,
    _ref$onComplete = _ref.onComplete,
    onComplete = _ref$onComplete === void 0 ? function () {
      return null;
    } : _ref$onComplete,
    _ref$onError = _ref.onError,
    onError = _ref$onError === void 0 ? function (_e) {
      return null;
    } : _ref$onError;
  return function FetchProgress(response) {
    if (!isFetchProgressSupported()) {
      return response;
    }
    var body = response.body,
      headers = response.headers,
      status = response.status;
    var contentLength = headers.get('content-length') || defaultSize;
    if (contentLength === 0) return response;
    var progress = new Progress_1.default(contentLength, emitDelay);
    var reader = body.getReader();
    var stream = new ReadableStream({
      start: function start(controller) {
        function push() {
          reader.read().then(function (_ref2) {
            var done = _ref2.done,
              value = _ref2.value;
            if (done) {
              onComplete();
              controller.close();
              return;
            }
            if (value) {
              progress.flow(value, onProgress);
            }
            controller.enqueue(value);
            push();
          }).catch(function (err) {
            onError(err);
          });
        }
        push();
      }
    });
    return new Response(stream, {
      headers: headers,
      status: status
    });
  };
}