import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { RemoveProjectTag } from 'upmesh-core/src/client/commands/project/tags/RemoveProjectTag';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { AddOrChangeTagDialog } from '../../companies/AddOrChangeTagDialog';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
const noDataImage = require('../../../assets/img/no_types_or_crafts_or_tags.png');
export class ProjectTagSettings extends PureComponent {
    constructor(props) {
        super(props);
        this.renderTicketCell = (item) => (<View key={`tickets_${item.id}`} style={{
                flexDirection: 'row',
                flex: 1,
                minWidth: 150,
                justifyContent: 'flex-end',
                paddingHorizontal: 16,
                alignContent: 'flex-end',
                alignItems: 'flex-end',
            }}>
      <MaterialText fixedWidth="100%" textAlign="right" centeredBox>{`${item.tickets} ${I18n.m.getMessage('tickets')}`}</MaterialText>
    </View>);
        this.renderGroupCell = (item) => (<View key={`group_${item.id}`} style={{
                flexDirection: 'row',
                flex: 1,
                minWidth: 150,
                justifyContent: 'flex-start',
                paddingHorizontal: 16,
                alignItems: 'center',
            }}>
      <View style={{
                height: 16,
                width: 16,
                alignSelf: 'center',
                borderRadius: 8,
                backgroundColor: item.color,
                marginRight: 8,
            }}/>
      <MaterialText centeredBox>{`${item.groupName}`}</MaterialText>
    </View>);
        this.renderPlanCell = (item) => (<View key={`plans_${item.id}`} style={{
                flexDirection: 'row',
                flex: 1,
                minWidth: 150,
                justifyContent: 'flex-end',
                paddingHorizontal: 16,
                alignContent: 'flex-end',
                alignItems: 'flex-end',
            }}>
      <MaterialText centeredBox>{`${item.plans} ${I18n.m.getMessage('plans')}`}</MaterialText>
    </View>);
        this.init = async () => {
            const { project } = this.props;
            const data = [];
            const promises = [];
            project.tags?.forEach((tag) => {
                promises.push(new Promise((resolve) => {
                    let tickets = 0;
                    UpmeshClient.instance.modals.ticket
                        .count(`projectId eq '${project.id}' and tags/tagName eq '${tag.tagName.replace(/'/gi, "''")}'`)
                        .then((t) => {
                        tickets = t;
                        return UpmeshClient.instance.modals.plan.count(`projectId eq '${project.id}' and tags/tagName eq '${tag.tagName.replace(/'/gi, "''")}' and deleted ne true`);
                    })
                        .then((plans) => {
                        const id = `${tag.groupName}_${tag.tagName}`;
                        if (data.findIndex((t) => t.id === id) === -1)
                            data.push({ tag, tickets, plans, ...tag, id });
                        resolve();
                    })
                        .catch((e) => {
                        console.debug('cant count tickets', e);
                    });
                }));
            });
            await Promise.all(promises);
            this.setState({ data });
        };
        this.openAddDialog = (_e) => {
            const { project } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddOrChangeTagDialog settings={project}/>,
                contentPadding: false,
            });
        };
        this.openEditDialog = (tag) => (_e) => {
            const { project } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddOrChangeTagDialog tag={tag.tag} settings={project}/>,
                contentPadding: false,
            });
        };
        this.openFab = () => {
            if (Fab.instance != null) {
                const { size } = this.props;
                Fab.instance.open({
                    fabIcon: 'plus',
                    extraPaddingBottom: size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0,
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: this.openAddDialog,
                });
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.removeTag = (tag) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('projectRemoveTagQuestion', { tag: tag.tag }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeTypeNow(tag.tag)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeTypeNow = (tag) => (_e) => {
            const { project } = this.props;
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new RemoveProjectTag({ tag }, project.id, CurrentUser.token);
                remove
                    .execute(ClientStore.commandStore)
                    .catch((e) => {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.renderTable = () => {
            const { data } = this.state;
            const { size } = this.props;
            const height = size.contentHeight;
            const columns = [
                {
                    title: I18n.m.getMessage('projectSettingsName'),
                    keyInData: 'tagName',
                    dataType: 'string',
                    style: { width: 200 },
                },
                {
                    title: I18n.m.getMessage('tagGroup'),
                    keyInData: 'groupName',
                    dataType: 'string',
                    style: { width: 150 },
                    cellRenderer: this.renderGroupCell,
                },
                {
                    title: I18n.m.getMessage('projectSettingsContainedIn'),
                    keyInData: 'tickets',
                    dataType: 'number',
                    style: { width: 150 },
                    cellRenderer: this.renderTicketCell,
                },
                {
                    title: I18n.m.getMessage('projectSettingsContainedIn'),
                    keyInData: 'plans',
                    dataType: 'number',
                    style: { width: 150 },
                    cellRenderer: this.renderPlanCell,
                },
            ];
            return (<Table tableName="ProjectTags" actionItemsLength={2} actions={(_item) => [
                    {
                        toolTip: I18n.m.getMessage('edit'),
                        onAction: this.openEditDialog,
                        icon: 'pencil-outline',
                    },
                    {
                        toolTip: I18n.m.getMessage('delete'),
                        onAction: this.removeTag,
                        icon: 'delete-outline',
                    },
                ]} columns={columns} maxHeight={height - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(108)} emptyTableHint={I18n.m.getMessage('projectTypeNoDataHint')} emptyTableText={I18n.m.getMessage('projectTypeNoDataText')} emptyTableImage={noDataImage} data={data}/>);
        };
        this.state = {};
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init ProjectTagSettings', err));
        this.openFab();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.project !== this.props.project) {
            this.init().catch((err) => console.debug('cant init ProjectTagSettings', err));
        }
    }
    componentWillUnmount() {
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('projectSettingsTags')}</MaterialText>
        </View>
        <Card style={{ width: '100%', height: 'auto' }}>{this.renderTable()}</Card>
      </View>);
    }
}
