import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { SwitchListItem } from 'materialTheme/src/theme/components/SwitchListItem';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { BackHandler, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { I18n } from '../../i18n/I18n';
const styles = {
    headerContainer: {
        position: 'relative',
        width: '100%',
        overflow: 'visible',
    },
    headerCloseIcon: {
        position: 'absolute',
        right: -4,
        top: 8,
    },
    spacer: { height: 6 },
    chipContainer: { flexDirection: 'row' },
    checkboxListItemContainer: { marginLeft: -4 },
};
export class ProjectsFilter extends PureComponent {
    constructor(props) {
        super(props);
        this.showArchived = (showArchived) => {
            this.setState({ showArchived }, this.calculateFiltered);
        };
        this.filterTemplates = (val) => {
            const showTemplate = val.index === 0 ? undefined : val.index.toString();
            this.setState({ showTemplate }, this.calculateFiltered);
        };
        this.calculateFiltered = () => {
            const { onChanged } = this.props;
            if (onChanged != null) {
                const filter = ProjectsFilter.createCurrentFilter(this.state, this.props.currentFilter.words);
                onChanged(filter);
            }
        };
        this.setProjectTypes = (e) => {
            this.setState({ selectedProjectTypes: e }, this.calculateFiltered);
        };
        this.setProjectStates = (e) => {
            this.setState({ selectedProjectStates: e }, this.calculateFiltered);
        };
        this.setProjectMembers = (e) => {
            this.setState({ selectedProjectMembers: e }, this.calculateFiltered);
        };
        this.setProjectCreators = (e) => {
            this.setState({ selectedProjectCreators: e }, this.calculateFiltered);
        };
        this.templateSelectList = [
            I18n.m.getMessage('projectFilterTemplateJustProjects'),
            I18n.m.getMessage('projectFilterTemplateJustTemplate'),
            I18n.m.getMessage('projectFilterTemplateAll'),
        ];
        this.changeBuildTime = (newDate) => {
            const currentBuildTimeFilter = newDate == null ? undefined : typeof newDate === 'number' ? newDate : newDate.getTime();
            this.setState({ currentBuildTimeFilter }, this.calculateFiltered);
        };
        this.changeProjectTime = (newDate) => {
            const currentProjectTimeFilter = newDate == null ? undefined : typeof newDate === 'number' ? newDate : newDate.getTime();
            this.setState({ currentProjectTimeFilter }, this.calculateFiltered);
        };
        this.filterProjectOwner = () => {
            const { showOnlyMyProjects } = this.state;
            this.setState({ showOnlyMyProjects: !showOnlyMyProjects }, this.calculateFiltered);
        };
        const projectMember = ProjectsFilter.getProjectMembers(props.allProjects);
        this.state = {
            projectTypes: ProjectsFilter.getProjectTypes(props.allProjects),
            selectedProjectTypes: ProjectsFilter.getSelectedProjectTypes(props.currentFilter),
            projectStates: ProjectsFilter.getProjectStates(props.allProjects),
            selectedProjectStates: ProjectsFilter.getSelectedProjectStates(props.currentFilter),
            projectMember,
            projectCreators: ProjectsFilter.getProjectCreators(props.allProjects),
            selectedProjectMembers: ProjectsFilter.getSelectedProjectMembers(props.currentFilter, projectMember),
            currentProjectTimeFilter: props.currentFilter.projectTime,
            currentBuildTimeFilter: props.currentFilter.buildTime,
            showArchived: props.currentFilter.archived,
            showOnlyMyProjects: props.currentFilter.myProjects != null && props.currentFilter.myProjects.length > 0,
            selectedProjectCreators: ProjectsFilter.getSelectedProjectCreators(props.currentFilter, projectMember),
            showTemplate: props.currentFilter.showTemplate,
        };
    }
    componentDidMount() {
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
            Dialog.instance?.close();
            return true;
        });
    }
    static getProjectTypes(allProjects) {
        const available = [];
        const values = [];
        allProjects.forEach((t) => {
            if (t.projectType != null && t.projectType.length > 0) {
                if (available.indexOf(t.projectType) === -1)
                    available.push(t.projectType);
            }
        });
        for (let i = 0; i < available.length; i += 1) {
            values.push({
                id: available[i],
                title: available[i],
            });
        }
        return values;
    }
    static getSelectedProjectTypes(currentFilter) {
        const types = currentFilter.projectTypes;
        if (types == null || types.length === 0)
            return [];
        const available = [];
        const values = [];
        types.forEach((t) => {
            if (t != null && t.length > 0) {
                if (available.indexOf(t) === -1)
                    available.push(t);
            }
        });
        for (let i = 0; i < available.length; i += 1) {
            values.push({
                id: available[i],
                title: available[i],
            });
        }
        return values;
    }
    static getProjectStates(allProjects) {
        const available = [];
        const values = [];
        allProjects.forEach((t) => {
            if (t.state != null && t.state.name != null && t.state.name.length > 0) {
                if (available.indexOf(t.state.name) === -1)
                    available.push(t.state.name);
            }
        });
        for (let i = 0; i < available.length; i += 1) {
            values.push({
                id: available[i],
                title: available[i],
            });
        }
        return values;
    }
    static getSelectedProjectStates(currentFilter) {
        const types = currentFilter.projectStates;
        if (types == null || types.length === 0)
            return [];
        const available = [];
        const values = [];
        types.forEach((t) => {
            if (t != null && t.length > 0) {
                if (available.indexOf(t) === -1)
                    available.push(t);
            }
        });
        for (let i = 0; i < available.length; i += 1) {
            values.push({
                id: available[i],
                title: available[i],
            });
        }
        return values;
    }
    static getProjectMembers(allProjects) {
        const available = [];
        const values = [];
        allProjects.forEach((t) => {
            if (t.team != null && t.team.length > 0) {
                t.team.forEach((m) => {
                    if (available.find((a) => a.id === m.user.id) == null)
                        available.push({ userName: m.username, user: m.user, id: m.user.id });
                });
            }
        });
        for (let i = 0; i < available.length; i += 1) {
            values.push({
                id: available[i].id,
                title: available[i].userName,
                thumbnail: <UserImage user={available[i].user} size={24}/>,
            });
        }
        return values;
    }
    static getProjectCreators(allProjects) {
        const available = [];
        const values = [];
        allProjects.forEach((t) => {
            if (t.team != null && t.team.length > 0) {
                t.team.forEach((m) => {
                    if (available.find((a) => a.id === m.user.id) == null)
                        if (t.createdBy === m.user.id) {
                            available.push({ userName: m.username, user: m.user, id: m.user.id });
                        }
                });
            }
        });
        for (let i = 0; i < available.length; i += 1) {
            values.push({
                id: available[i].id,
                title: available[i].userName,
                thumbnail: <UserImage user={available[i].user} size={24}/>,
            });
        }
        return values;
    }
    static getSelectedProjectMembers(currentFilter, allMembers) {
        const types = currentFilter.projectMembers;
        if (types == null || types.length === 0)
            return [];
        const values = [];
        types.forEach((t) => {
            if (t != null && t.length > 0) {
                const a = allMembers.find((a) => a.id === t);
                if (a)
                    values.push(a);
            }
        });
        return values;
    }
    static getSelectedProjectCreators(currentFilter, allMembers) {
        const types = currentFilter.projectCreators;
        if (types == null || types.length === 0)
            return [];
        const values = [];
        types.forEach((t) => {
            if (t != null && t.length > 0) {
                const a = allMembers.find((a) => a.id === t);
                if (a)
                    values.push(a);
            }
        });
        return values;
    }
    componentWillUnmount() {
        if (this.backHandler)
            this.backHandler.remove();
    }
    static createCurrentFilter(state, words) {
        const projectTypes = [];
        state.selectedProjectTypes.forEach((s) => {
            projectTypes.push(s.title);
        });
        const projectStates = [];
        state.selectedProjectStates.forEach((s) => {
            projectStates.push(s.title);
        });
        const projectMembers = [];
        state.selectedProjectMembers.forEach((s) => {
            projectMembers.push(s.id ? s.id : s.title);
        });
        const projectCreators = [];
        state.selectedProjectCreators.forEach((s) => {
            projectCreators.push(s.id ? s.id : s.title);
        });
        return {
            myProjects: state.showOnlyMyProjects ? CurrentUser.userId : undefined,
            buildTime: state.currentBuildTimeFilter,
            projectTime: state.currentProjectTimeFilter,
            archived: state.showArchived,
            words,
            projectStates,
            projectMembers,
            projectTypes,
            projectCreators,
            showTemplate: state.showTemplate,
        };
    }
    getTemplateIndex() {
        const { showTemplate } = this.state;
        return showTemplate == null ? 0 : Number.parseInt(showTemplate, 10);
    }
    render() {
        const { showArchived, currentProjectTimeFilter, currentBuildTimeFilter, projectTypes, selectedProjectTypes, projectStates, selectedProjectStates, projectMember, selectedProjectMembers, projectCreators, selectedProjectCreators, } = this.state;
        const { hideArchived } = this.props;
        return (<>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectOwner')}</MaterialText>
        <Chip title={I18n.m.getMessage('myProjects')} onPressChip={this.filterProjectOwner} backgroundColor={this.state.showOnlyMyProjects ? ThemeManager.style.brandPrimary : undefined} textColor={this.state.showOnlyMyProjects ? '#FFFFFF' : undefined} thumbnail={<UserImage size={24} user={CurrentUser.entity}/>}/>
        {projectCreators.length > 0 ? (<View style={{ width: '100%' }}>
            <View style={styles.spacer}/>
            <ChipGroup key={`selectedProjectStates_${selectedProjectCreators.toString()}`} dialogTitle={I18n.m.getMessage('projectCreatorNotOwner')} label={I18n.m.getMessage('projectCreatorNotOwner')} bordered={false} availableChips={projectCreators} chips={selectedProjectCreators} onChanged={this.setProjectCreators}/>
          </View>) : null}
        {projectTypes.length > 0 ? (<View style={{ width: '100%' }}>
            <View style={styles.spacer}/>
            <ChipGroup key={`selectedProjectTypes_${selectedProjectTypes.toString()}`} dialogTitle={I18n.m.getMessage('projectType')} label={I18n.m.getMessage('projectType')} bordered={false} availableChips={projectTypes} chips={selectedProjectTypes} onChanged={this.setProjectTypes}/>
          </View>) : null}
        {projectStates.length > 0 ? (<View style={{ width: '100%' }}>
            <View style={styles.spacer}/>
            <ChipGroup key={`selectedProjectStates_${selectedProjectStates.toString()}`} dialogTitle={I18n.m.getMessage('projectState')} label={I18n.m.getMessage('projectState')} bordered={false} availableChips={projectStates} chips={selectedProjectStates} onChanged={this.setProjectStates}/>
          </View>) : null}
        {projectMember.length > 0 ? (<View style={{ width: '100%' }}>
            <View style={styles.spacer}/>
            <ChipGroup key={`selectedProjectStates_${selectedProjectMembers.toString()}`} dialogTitle={I18n.m.getMessage('team')} label={I18n.m.getMessage('team')} bordered={false} availableChips={projectMember} chips={selectedProjectMembers} onChanged={this.setProjectMembers}/>
          </View>) : null}
        <View style={styles.spacer}/>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectTime')}</MaterialText>
        <DateInput placeholder={I18n.m.getMessage('date')} key={`projectTime${currentProjectTimeFilter}`} onChange={this.changeProjectTime} selectedDate={currentProjectTimeFilter == null ? undefined : new Date(currentProjectTimeFilter)}/>
        <View style={styles.spacer}/>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('buildTime')}</MaterialText>
        <DateInput placeholder={I18n.m.getMessage('date')} key={`buildTime${currentBuildTimeFilter}`} onChange={this.changeBuildTime} selectedDate={currentBuildTimeFilter == null ? undefined : new Date(currentBuildTimeFilter)}/>
        {!hideArchived && (<View style={styles.checkboxListItemContainer}>
            <SwitchListItem title={I18n.m.getMessage('projectFilterArchived')} key="archivedTickets" value={showArchived === true} onChange={this.showArchived}/>
          </View>)}
        <View>
          <FormInputPicker labelText={I18n.m.getMessage('projectFilterTemplatePicker')} selectedIndex={this.getTemplateIndex()} list={this.templateSelectList} onChange={this.filterTemplates}/>
        </View>
      </>);
    }
}
