"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var UserEntity = function (_ReadModelEntity_1$Re) {
  function UserEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, UserEntity);
    _this = _callSuper(this, UserEntity, [obj]);
    _this.entityName = 'User';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(UserEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(UserEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new UserEntity(obj);
    }
  }, {
    key: "getFullName",
    value: function getFullName() {
      if (this.lastname != null && this.firstname != null) return `${this.firstname} ${this.lastname}`;
      if (this.lastname != null) return `${this.lastname}`;
      if (this.firstname != null) return `${this.firstname}`;
      return 'no name';
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('userId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('lastname', new OdataEntity_1.OdataIndex());
      baseMap.set('firstname', new OdataEntity_1.OdataIndex());
      baseMap.set('locale', new OdataEntity_1.OdataIndex());
      baseMap.set('colorCode', new OdataEntity_1.OdataIndex());
      baseMap.set('userImageFileId', new OdataEntity_1.OdataIndex());
      baseMap.set('company', new OdataEntity_1.OdataIndex());
      baseMap.set('phone', new OdataEntity_1.OdataIndex());
      baseMap.set('functionInCompany', new OdataEntity_1.OdataIndex());
      baseMap.set('emails', new OdataEntity_1.OdataIndex(true));
      baseMap.set('lastLogin', new OdataEntity_1.OdataIndex(true));
      baseMap.set('origin', new OdataEntity_1.OdataIndex(true));
      baseMap.set('status', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.UserEntity = UserEntity;