import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { RemoveCompanyTicketLayout } from 'upmesh-core/src/client/commands/companies/ticketlayouts/RemoveCompanyTicketLayout';
import { CachedEntities } from '../../config/CachedEntities';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { openAddOrChangeProjectTicketLayoutDialog } from '../project/settings/ticketlayouts/AddOrChangeProjectTicketLayoutDialog';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
const noDataImage = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class CompanyTicketLayoutsView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.init = async () => {
            const { companySettings } = this.props;
            const data = [];
            if (companySettings.ticketLayouts != null && companySettings.ticketLayouts.length > 0) {
                for (const tl of companySettings.ticketLayouts) {
                    const t = tl;
                    t.creatorName = CachedEntities.knownUsers.get(t.createdBy)?.getFullName() || '-';
                    data.push(t);
                }
            }
            this.setState({ data });
        };
        this.openEditDialog = (layout) => (_e) => {
            const { companySettings } = this.props;
            openAddOrChangeProjectTicketLayoutDialog({ companySettings, layout })(_e);
        };
        this.openFab = () => {
            if (Fab.instance != null) {
                const { companySettings } = this.props;
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    title: I18n.m.getMessage('ticketLayoutTitleCreateNew'),
                    onPressFab: openAddOrChangeProjectTicketLayoutDialog({ companySettings }),
                });
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.removeLayout = (layout) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('projectRemoveTicketLayoutQuestion', { title: layout.title }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeLayoutNow(layout.id)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeLayoutNow = (id) => (_e) => {
            const { companySettings } = this.props;
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new RemoveCompanyTicketLayout({ companyId: companySettings.id }, id, CurrentUser.token);
                remove
                    .execute(ClientStore.commandStore)
                    .catch((e) => {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.renderTable = () => {
            const { data } = this.state;
            const { size } = this.props;
            const height = size.contentHeight;
            const columns = [
                {
                    title: I18n.m.getMessage('projectSettingsName'),
                    keyInData: 'title',
                    dataType: 'string',
                    style: { width: 150 },
                },
                {
                    title: I18n.m.getMessage('creator'),
                    keyInData: 'creatorName',
                    dataType: 'string',
                    style: { width: 150 },
                },
                {
                    title: I18n.m.getMessage('createdAt'),
                    keyInData: 'createdAt',
                    dataType: 'Date',
                    style: { width: 150 },
                },
                {
                    title: I18n.m.getMessage('projectSettingsTicketLayoutFields'),
                    keyInData: 'fields',
                    sortable: false,
                    dataType: 'string',
                    style: { width: 150 },
                    cellRenderer: (item, column, _index) => {
                        return (<View key={`fields${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
              <MaterialText numberOfLines={1}>
                {item.fields != null && item.fields.length > 0
                                ? item.fields.map((v, i) => {
                                    let r = '';
                                    if (i > 0)
                                        r += ', ';
                                    r += v.label;
                                    return r;
                                })
                                : '-'}
              </MaterialText>
            </View>);
                    },
                },
            ];
            return (<Table actionItemsLength={2} tableName="ProjectTicketLayouts" actions={(_item) => [
                    {
                        toolTip: I18n.m.getMessage('edit'),
                        onAction: this.openEditDialog,
                        icon: 'pencil-outline',
                    },
                    {
                        toolTip: I18n.m.getMessage('delete'),
                        onAction: this.removeLayout,
                        icon: 'delete-outline',
                    },
                ]} columns={columns} maxHeight={height - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(108)} emptyTableHint={I18n.m.getMessage('projectTicketLayoutNoDataHint')} emptyTableText={I18n.m.getMessage('projectTicketLayoutNoDataText')} emptyTableImage={noDataImage} data={data}/>);
        };
        this.state = {
            myRole: this.myRole(),
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init ProjectTicketLayoutSettings', err));
        this.openFab();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.companySettings !== this.props.companySettings) {
            this.init().catch((err) => console.debug('cant init ProjectTicketLayoutSettings', err));
        }
    }
    componentWillUnmount() {
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('ticketLayouts')}</MaterialText>
        </View>
        <Card style={{ width: '100%', height: 'auto' }}>{this.renderTable()}</Card>
      </View>);
    }
}
