import { PromisePool } from 'cqrs-shared/src/PromisePool';
import { Url } from 'cqrs-shared/src/uri/Url';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import querystring from 'querystring';
import React, { PureComponent } from 'react';
import { UIManager, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { CreateTicket } from 'upmesh-core/src/client/commands/tickets/CreateTicket';
import { TicketFilter } from 'upmesh-core/src/client/query/filter/TicketFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { openProjectSelectorDialog } from '../selectors/ProjectSelector';
import { ReportIcon } from '../tickets/ReportIcon';
import { TicketDialogFilter } from '../tickets/TicketDialogFilter';
import { TicketEntitySynced } from '../tickets/TicketEntitySynced';
import { TicketNavigation } from '../tickets/TicketNavigation';
import { TicketsOverView } from '../tickets/TicketsOverView';
import { TicketTagFilterIcon } from '../tickets/TicketTagFilterIcon';
import { GlobalBar } from './GlobalBar';
import { PageView } from './PageView';
export class AllTicketsView extends PureComponent {
    constructor(props) {
        super(props);
        this.counterTO = 0;
        this.mounted = false;
        this.filteredTickets = [];
        this.tickets = [];
        this.ticketChanged = () => {
            this.init().catch((err) => console.debug(err));
        };
        this.plansChanged = () => {
            this.init().catch((err) => console.debug(err));
        };
        this.getTicketData = async (t) => {
            return TicketEntitySynced.convertFromTicket(t, false);
        };
        this.onChangeTicketOrder = (tickets) => {
            TicketNavigation.setTickets(tickets);
        };
        this.gotoPlanDetails = (id) => (_e) => {
            const { searchWords, ticketFilter } = this.state;
            const f = ticketFilter != null ? querystring.stringify({ fat: JSON.stringify(ticketFilter) }) : '';
            const url = Url.getURLfromString(`/allTickets/${id}/`);
            url.search = `q=${searchWords}&${f}`;
            Routing.instance.goTo(url);
        };
        this.getViewIcon = () => {
            const { selectedView } = this.state;
            let icon = {
                icon: 'format-list-bulleted-square',
                rotation: 0,
                iconIconMoon: false,
                toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToListView'),
            };
            if (selectedView === 'table') {
                icon = {
                    icon: 'table-large',
                    rotation: 0,
                    iconIconMoon: false,
                    toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToTableView'),
                };
            }
            else if (selectedView === 'calendar') {
                icon = {
                    icon: 'calendar',
                    rotation: 0,
                    toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToCalendarView'),
                };
            }
            else if (selectedView === 'canban') {
                icon = {
                    icon: 'poll',
                    rotation: 180,
                    toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToCanban'),
                };
            }
            icon.onPress = this.openViewMenu;
            icon.toolTip = I18n.m.getMessage('changeView');
            return icon;
        };
        this.openViewMenu = (e) => {
            const { target } = e.nativeEvent;
            UIManager.measureInWindow(target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 320,
                };
                Menu.instance?.open({
                    client,
                    items: [
                        {
                            text: I18n.m.getMessage('ticketsDetailsViewChangeToCanban'),
                            onPress: this.changeSelectedView('canban'),
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="poll" rotation={180}/>,
                                width: 40,
                            },
                        },
                        {
                            text: I18n.m.getMessage('ticketsDetailsViewChangeToListView'),
                            onPress: this.changeSelectedView('list'),
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="format-list-bulleted-square"/>,
                                width: 40,
                            },
                        },
                        {
                            text: I18n.m.getMessage('ticketsDetailsViewChangeToTableView'),
                            onPress: this.changeSelectedView('table'),
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="table-large"/>,
                                width: 40,
                            },
                        },
                        {
                            text: I18n.m.getMessage('ticketsDetailsViewChangeToCalendarView'),
                            onPress: this.changeSelectedView('calendar'),
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="calendar"/>,
                                width: 40,
                            },
                        },
                    ],
                });
            });
        };
        this.changeSelectedView = (newSelected) => () => {
            Menu.instance?.close();
            SimpleStorage.set('ticketsSelectedView', newSelected);
            this.setState({ selectedView: newSelected });
        };
        this.changeView = (_index, button) => {
            SimpleStorage.set('ticketsSelectedView', button.key);
            this.setState({ selectedView: button.key });
        };
        this.selectProject = (project) => {
            Dialog.instance?.close(() => Routing.instance.openDialog('ticket', { projectId: project.id, id: 'new' })(null));
        };
        this.openFab = () => {
            if (Fab.instance == null) {
                window.setTimeout(this.openFab, 100);
                return;
            }
            Fab.instance.open({
                fabIcon: 'plus',
                fabIconOpen: 'close',
                small: false,
                fabColor: ThemeManager.style.brandPrimary,
                fabColorOpen: ThemeManager.style.brandSecondary,
                title: I18n.m.getMessage('commandCreateTicket'),
                extraPaddingBottom: ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0,
                onPressFab: openProjectSelectorDialog({
                    placeHolderText: I18n.m.getMessage('selectProject'),
                    onSelect: this.selectProject,
                    projectFilter: async (p) => {
                        const c = new CreateTicket({ projectId: p.id, title: '' });
                        let canCreateTicket = true;
                        await c.canI().catch(() => {
                            canCreateTicket = false;
                        });
                        return canCreateTicket;
                    },
                }),
            });
        };
        this.getItemKey = (item, _index) => `plan_${item.id}`;
        this.onPressFilter = (_e) => {
            TicketDialogFilter.open(this.tickets, this.setFilter, this.state.ticketFilter);
        };
        this.onSearchTickets = (text) => {
            if (this.searchTimer != null) {
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = window.setTimeout(this.searchNowTickets(text), 250);
        };
        this.pressCreateTicket = (planId, planX, planY) => async (e) => {
            const projectId = null;
            if (projectId != null) {
                const pid = planId == null || planId.length === 0 || planId === 'all' ? undefined : planId;
                const catched = await DefaultErrorHandler.getProjectErrors(projectId);
                if (catched) {
                    return;
                }
                Routing.instance.openDialog('ticket', { projectId, planId: pid, planX, planY, id: 'new' })(e);
            }
        };
        this.searchNowTickets = (text) => () => {
            if (this.searching) {
                this.searchTimer = window.setTimeout(this.searchNowTickets(text), 100);
                return;
            }
            this.searching = true;
            this.searching = false;
            Routing.instance.changeQueryParameter({ q: text });
            this.setState({ searchWords: text }, () => {
                this.showSearchedTickets().catch((err) => console.debug(err));
            });
        };
        this.setFilter = (ticketFilter) => {
            this.setState({ ticketFilter }, () => {
                this.updateVisibleTickets()
                    .then(() => {
                    const f = JSON.stringify(ticketFilter);
                    requestAnimationFrame(() => {
                        SimpleStorage.set(`${CurrentUser.userId}_saved_allticketsfilter`, f);
                        Routing.instance.changeQueryParameter({ fat: f }, true);
                    });
                })
                    .catch((err) => console.error(err));
            });
        };
        let ticketFilter = {};
        try {
            if (props.fat != null)
                ticketFilter = JSON.parse(props.fat);
        }
        catch (e) {
            console.debug('Invalid filter', e);
        }
        const ticketsSelectedView = SimpleStorage.get('ticketsSelectedView');
        const selectedView = ticketsSelectedView != null ? ticketsSelectedView : 'list';
        this.state = {
            ticketFilter,
            searchWords: props.q != null ? props.q : '',
            ...this.createNumColumns(ResizeEvent.current.contentWidth),
            visibleTickets: null,
            selectedView,
        };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'Ticket',
            attachKey: 'allTicketsTickets',
            callback: this.ticketChanged,
        });
        this.mounted = true;
        this.init().catch((err) => console.debug(err));
        this.openFab();
    }
    async init() {
        const projects = await UpmeshClient.instance.modals.project.get({ filter: 'deleted ne true and archived ne true' });
        const projectIds = [];
        projects.forEach((p) => projectIds.push(p.id));
        const tickets = await UpmeshClient.instance.modals.ticket.get({
            filter: `(projectId in ${JSON.stringify(projectIds)})`,
        });
        this.tickets = await PromisePool.run({
            collection: tickets,
            task: this.getTicketData,
            maxConcurrency: 4,
        });
        this.updateVisibleTickets()
            .then(() => {
            this.showSearchedTickets().catch((err) => console.debug(err));
        })
            .catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        TicketNavigation.setTickets([]);
        UpmeshClient.eventDispatcher.detach('Ticket', 'allTicketsTickets');
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { visibleTickets, searchWords, ticketFilter, selectedView } = this.state;
        const { height, size } = this.props;
        const filterActive = this.isFilter(ticketFilter);
        let viewNumber = 0;
        if (selectedView === 'list')
            viewNumber = 1;
        else if (selectedView === 'table')
            viewNumber = 2;
        else if (selectedView === 'calendar')
            viewNumber = 3;
        let filterIcon = (<Icon icon={filterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('ticketFilter')} onPress={this.onPressFilter} color={filterActive ? ThemeManager.style.brandWarning : 'white'} key={`filterButton${filterActive}`}/>);
        if (filterActive && ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM) {
            filterIcon = (<View key={`filterButtonBigOuter${filterActive}`}>
          <ContainedButton title={I18n.m.getMessage('filterRemove')} icon={{ icon: 'filter-remove', color: '#ffffff' }} key={`filterButton${filterActive}`} onPress={this.onPressFilter} backgroundColor={ThemeManager.style.brandWarning}/>
        </View>);
        }
        const useSegmentedViewSelect = ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM;
        const tagIcon = (<TicketTagFilterIcon currentFilter={ticketFilter != null ? ticketFilter : new TicketFilter()} setFilter={(f) => {
                Routing.instance.changeQueryParameter({
                    ft: JSON.stringify(f),
                });
                this.setFilter(f);
            }} tickets={this.tickets} color="#FFFFFF"/>);
        const headerButtons = [
            filterIcon,
            tagIcon,
            <ReportIcon key="reportIconButton" allTickets filterActive={filterActive} selectedTickets={visibleTickets || []} color="#FFFFFF"/>,
        ];
        if (useSegmentedViewSelect) {
            headerButtons.push(<View style={{ width: 216 }} key="viewSegmentButton">
          <SegmentedButton buttons={[
                    { icon: { icon: 'poll', rotation: 180 }, key: 'canban' },
                    { icon: { icon: 'format-list-bulleted-square' }, key: 'list' },
                    { icon: { icon: 'table-large' }, key: 'table' },
                    { icon: { icon: 'calendar' }, key: 'calendar' },
                ]} onPress={this.changeView} singleSelectSelected={viewNumber} backgroundColor="transparent" borderColor="#ffffff" textColor="#ffffff" selectedColor="rgba(196, 196, 196, 0.2)" density={2}/>
        </View>);
        }
        else {
            headerButtons.push(<Icon {...this.getViewIcon()} key="viewIcon" color="#ffffff"/>);
        }
        const sDisplay = !(ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM);
        return (<View style={{
                width: '100%',
                backgroundColor: 'transparent',
                overflow: 'hidden',
                position: 'absolute',
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
            }}>
        {!sDisplay ? <GlobalBar user={CurrentUser.entity} size={size} site="allTickets"/> : undefined}
        <PageView showAccountIcon={sDisplay} showMenu={false} style={{
                backgroundColor: 'transparent',
                width: '100%',
                overflow: 'hidden',
                position: 'absolute',
                right: 0,
                left: 0,
                top: sDisplay ? 0 : 48,
                bottom: ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0,
            }} headerProps={{
                backgroundColor: 'transparent',
                withBorder: false,
                textColor: '#FFFFFF',
                title: '',
                searchBarProps: {
                    backgroundColor: 'transparent',
                    textColor: '#ffffff',
                    iconColor: '#ffffff',
                    hoverColor: ThemeManager.style.getDefaultHoverColor('#ffffff'),
                    searchBarValue: searchWords,
                    searchBarPlaceholder: I18n.m.getMessage('allTickets'),
                    searchOnChange: this.onSearchTickets,
                    tooltip: I18n.m.getMessage('searchTickets'),
                },
                rightButtons: headerButtons,
            }} scrollable={false}>
          {visibleTickets == null ? (<Spinner />) : (<TicketsOverView showProjects size={size} pressCreateTicket={this.pressCreateTicket} currentTicketFilter={ticketFilter} onPressFilter={this.onPressFilter} filterActive={filterActive} width={ResizeEvent.current.contentWidth} height={sDisplay ? height : height - 48} style={{
                    height: sDisplay ? height : height - 48,
                    width: ResizeEvent.current.contentWidth,
                }} tickets={visibleTickets} planId="all" selectedView={selectedView} multiselectEnabled={false} updateTicketFilter={(f) => {
                    Routing.instance.changeQueryParameter({
                        ft: JSON.stringify(f),
                    });
                    this.setFilter(f);
                }} onChangeTicketOrder={this.onChangeTicketOrder} hideTicketHierarchy/>)}
        </PageView>
      </View>);
    }
    createNumColumns(forWidth) {
        let targetWidth = ThemeManager.style.getScreenRelativePixelSize(212);
        const width = forWidth - 2 * ThemeManager.style.contentPaddingValue;
        const numColumns = Math.max(1, Math.round(width / targetWidth));
        targetWidth = width / numColumns;
        return { targetWidth, numColumns };
    }
    async filterTickets(tickets) {
        const filter = this.state.ticketFilter;
        this.filteredTickets = (await TicketFilter.filterTickets(tickets, filter));
        return this.filteredTickets;
    }
    isFilter(filter) {
        return filter != null && TicketFilter.isSet(filter);
    }
    async showSearchedTickets() {
        let visibleTickets = this.tickets;
        const { searchWords } = this.state;
        visibleTickets = await this.filterTickets(visibleTickets);
        if (searchWords != null && searchWords.length > 0) {
            visibleTickets = (await TicketFilter.filterTicketsByText(searchWords, visibleTickets, true));
            this.filteredTickets = visibleTickets;
        }
        this.setState({ visibleTickets });
    }
    async updateVisibleTickets() {
        let visibleTickets = this.tickets;
        visibleTickets = await this.filterTickets(visibleTickets);
        TicketNavigation.setTickets(visibleTickets);
        this.setState({ visibleTickets });
    }
}
AllTicketsView.defaultProps = {
    title: 'upmesh',
};
