import _ from 'lodash';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { AddProjectTag } from 'upmesh-core/src/client/commands/project/tags/AddProjectTag';
import { AddTicketTag } from 'upmesh-core/src/client/commands/tickets/AddTicketTag';
import { RemoveTicketTag } from 'upmesh-core/src/client/commands/tickets/RemoveTicketTag';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
export class TagChips extends PureComponent {
    constructor(props) {
        super(props);
        this.onProjectChanged = (e) => {
            const { ticket } = this.props;
            if (e.entities && e.entities.has(ticket.projectId)) {
                const p = e.entities.get(ticket.projectId);
                if (p && p.entity) {
                    const project = p.entity;
                    if (project && project.tags && JSON.stringify(project.tags) !== this.lastTags) {
                        this.init().catch((err) => console.debug(err));
                    }
                }
            }
        };
        this.init = async () => {
            const { ticket, project } = this.props;
            const a = new AddTicketTag({}, ticket.id);
            let disabledChips = true;
            const ticketTags = [];
            const projectTags = await this.getStdTags(ticket.projectId);
            ticket.tags?.forEach((t) => {
                ticketTags.push({
                    title: t.tagName,
                    id: t.tagName,
                    groupId: t.groupName,
                    backgroundColor: t.color,
                });
            });
            try {
                const canI = await a.canI();
                if (typeof canI === 'boolean' && canI) {
                    disabledChips = false;
                }
            }
            catch (e) {
                disabledChips = true;
            }
            const canAddNewProjectTag = await RightsManager.hasWriteRight(project.id, CurrentUser.userId, 'commandChangeProject');
            this.setState({
                disabledChips,
                canAddNewProjectTag,
                ticketTags,
                projectTags: projectTags.tags,
                projectTagGroups: projectTags.groups,
            });
        };
        this.saveTags = (e) => {
            const { ticket, project } = this.props;
            const { projectTags, projectTagGroups } = this.state;
            if (ticket.tags == null)
                ticket.tags = [];
            const asyncNow = async () => {
                for (let i = 0; i < e.length; i += 1) {
                    if (ticket.tags.findIndex((j) => j.tagName === e[i].id) === -1) {
                        if (projectTags.findIndex((t) => t.title === e[i].title) === -1) {
                            const group = projectTagGroups.find((g) => g.groupName === e[i].groupId);
                            if (group != null) {
                                const newTag = { tagName: e[i].title, groupName: group.groupName, color: group.backgroundColor };
                                const c = new AddProjectTag({ tag: newTag }, project.id);
                                try {
                                    await c.execute().then(async () => {
                                        const add = new AddTicketTag({ tag: { tagName: e[i].title, color: group.backgroundColor, groupName: group.groupName } }, ticket.id);
                                        await add.execute();
                                        const p = projectTags.slice();
                                        p.push({ title: e[i].title, groupId: e[i].groupId, backgroundColor: group.backgroundColor });
                                    });
                                }
                                catch (e) {
                                    if (e.messageCode != null) {
                                        Routing.instance.alert.post({ text: I18n.m.getMessage(e.messageCode) });
                                    }
                                    else {
                                        ErrorReporter.sendReport({ subject: 'error', data: e, type: 'warn' });
                                    }
                                }
                            }
                        }
                        else {
                            try {
                                const c = new AddTicketTag({ tag: { tagName: e[i].id, color: e[i].backgroundColor, groupName: e[i].groupId } }, ticket.id);
                                await c.execute();
                            }
                            catch (err) {
                                this.props.errorResult(err);
                            }
                        }
                    }
                }
                for (const t of ticket.tags) {
                    if (e.findIndex((j) => j.id === t.tagName) === -1) {
                        try {
                            const c = new RemoveTicketTag({ tag: { ...t } }, ticket.id);
                            await c.execute();
                        }
                        catch (err) {
                            this.props.errorResult(err);
                        }
                    }
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.getAddButtons = (text) => {
            const list = [];
            const { projectTagGroups } = this.state;
            for (let i = 0; i < projectTagGroups.length; i += 1) {
                const group = projectTagGroups[i];
                list.push({
                    title: group.groupName,
                    secondTextLine: I18n.m.getMessage('tagsAddNewToGroup', { tag: text }),
                    titleColor: ThemeManager.style.black87,
                    textColor: ThemeManager.style.brandDanger,
                    groupId: group.groupName,
                    thumbnail: {
                        width: 24,
                        thumbnail: (<View style={{
                                height: 24,
                                width: 24,
                                borderStyle: 'dashed',
                                borderWidth: ThemeManager.style.borderWidth,
                                borderColor: group.backgroundColor,
                                borderRadius: 12,
                            }}>
              <Icon toolTip="" icon="plus" color={group.backgroundColor} outerSize={23}/>
            </View>),
                    },
                });
            }
            return list;
        };
        this.state = {
            disabledChips: true,
            canAddNewProjectTag: false,
            ticketTags: [],
            projectTags: [],
            projectTagGroups: [],
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'Project',
            attachKey: 'tagChips',
            callback: this.onProjectChanged,
        });
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('Project', 'tagChips');
    }
    async getStdTags(projectId) {
        const projectTags = [];
        const projectTagGroups = [];
        const project = await UpmeshClient.instance.modals.project.getById(projectId);
        this.lastTags = project.tags ? JSON.stringify(project.tags) : '';
        project.tags?.forEach((t) => {
            projectTags.push({
                id: t.tagName,
                groupId: t.groupName,
                title: t.tagName,
                backgroundColor: t.color,
            });
            if (!projectTagGroups.find((g) => g.id === t.groupName))
                projectTagGroups.push({
                    backgroundColor: t.color,
                    groupName: t.groupName,
                    id: t.groupName,
                });
        });
        projectTags.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        return { groups: projectTagGroups, tags: projectTags };
    }
    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        if (!_.isEqual(this.props.ticket, _prevProps.ticket)) {
            this.init().catch((err) => console.debug(err));
        }
    }
    render() {
        const { labelStyle } = this.props;
        const { disabledChips, canAddNewProjectTag, projectTags, ticketTags, projectTagGroups } = this.state;
        return [
            <View style={labelStyle} key="tagsLabel">
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('ticketsDetailsTags')}
        </MaterialText>
      </View>,
            <ChipGroup key={`tagChips_${ticketTags.toString()}`} dialogTitle={I18n.m.getMessage('ticketsDetailsTags')} textColor="#FFFFFF" label="" bordered={false} ref={(chipGroup) => this.setState({ chipGroup })} availableChips={projectTags} chips={ticketTags} sortFormByGroup onChanged={this.saveTags} buttonDisabled={disabledChips} editable={!disabledChips} tooltipAddChip={I18n.m.getMessage('addTags')} chipGroups={projectTagGroups} canAddNewChips={canAddNewProjectTag} addNewButtons={this.getAddButtons} canAddNewChipsViaSearchbar={false}/>,
        ];
    }
}
