import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { FilterChips } from 'materialTheme/src/theme/components/chips/FilterChips';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { AbsenceFilter } from 'upmesh-core/src/client/query/filter/AbsenceFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
const getStatuusList = (selectedStatuus) => {
    const chips = [];
    chips.push({
        title: I18n.m.getMessage('absenceStateOpen'),
        data: 'open',
        backgroundColor: ThemeManager.style.brandPrimary,
        textColor: '#FFFFFF',
        checked: selectedStatuus.indexOf('open') >= 0,
    }, {
        title: I18n.m.getMessage('absenceStateRejected'),
        data: 'rejected',
        backgroundColor: ThemeManager.style.brandDanger,
        textColor: '#FFFFFF',
        checked: selectedStatuus.indexOf('rejected') >= 0,
    }, {
        title: I18n.m.getMessage('absenceStateApproved'),
        data: 'approved',
        backgroundColor: ThemeManager.style.brandSuccess,
        textColor: '#FFFFFF',
        checked: selectedStatuus.indexOf('approved') >= 0,
    });
    return chips;
};
const getTasksFromFilter = (filter, entries) => {
    const selectedTasks = [];
    const taskList = [];
    entries.forEach((e) => {
        if (e.taskName != null && e.taskId != null && taskList.findIndex((elem) => elem.id === e.taskId) === -1) {
            taskList.push({ title: e.taskName, id: e.taskId });
            if (filter.taskIds != null && filter.taskIds.includes(e.taskId)) {
                selectedTasks.push({ title: e.taskName, id: e.taskId });
            }
        }
    });
    return { selectedTasks, taskList };
};
const getCollaboratorsFromFilter = (filter, entries) => {
    const collaboratorList = [];
    const selectedCollaborators = [];
    entries.forEach((e) => {
        if (collaboratorList.findIndex((u) => u.id === e.userId) === -1) {
            const m = CompanyUserInfo.companyMember.find((a) => a.userId != null ? a.userId === e.userId : a.id === e.memberId);
            const thumbnail = <UserImage user={e.user} size={24}/>;
            collaboratorList.push({ title: e.memberName, id: e.userId, thumbnail, groupId: m?.groupId });
            if (filter.userIds != null && e.userId != null && filter.userIds.includes(e.userId)) {
                selectedCollaborators.push({ title: e.memberName, thumbnail, id: e.userId, groupId: m?.groupId });
            }
        }
    });
    return { selectedCollaborators, collaboratorList };
};
const getProjectsFromFilter = (filter, entries) => {
    const projectList = [];
    const selectedProjects = [];
    entries.forEach((e) => {
        if (e.projectId != null && e.projectTitle != null) {
            if (projectList.findIndex((u) => u.id === e.projectId) === -1) {
                projectList.push({ title: e.projectTitle, id: e.projectId });
                if (filter.projectIds != null && filter.projectIds.includes(e.projectId)) {
                    selectedProjects.push({ title: e.projectTitle, id: e.projectId });
                }
            }
        }
    });
    return { selectedProjects, projectList };
};
const getCostCentersFromFilter = (filter, entries) => {
    const costCenterList = [];
    const selectedCostCenters = [];
    entries.forEach((e) => {
        if (e.costCenterId != null && e.costCenterName != null) {
            if (costCenterList.findIndex((u) => u.id === e.costCenterId) === -1) {
                costCenterList.push({ title: e.costCenterName, id: e.costCenterId });
                if (filter.costCenterIds != null && filter.costCenterIds.includes(e.costCenterId)) {
                    selectedCostCenters.push({ title: e.costCenterName, id: e.costCenterId });
                }
            }
        }
    });
    return { selectedCostCenters, costCenterList };
};
const selectedCollaboratorsToUserIds = (chips) => {
    const userIds = [];
    if (chips.length > 0) {
        chips.forEach((c) => {
            if (c.id != null)
                userIds.push(c.id);
        });
    }
    return userIds;
};
export function AbsenceFilterDialog(props) {
    const [currentFilter, setCurrentFilter] = useState(props.initialFilter);
    const [currentForm, setCurrentForm] = useState();
    const [amount, setAmount] = useState({ selected: 0, calculating: true });
    const [collaboratorGroups, setCollaboratorGroups] = useState([]);
    const showMyTimes = currentForm == null ? false : currentForm.selectedCollaborators.findIndex((r) => r.id === CurrentUser.userId) > -1;
    const onSelect = () => {
        DialogBetween.instance?.close(() => {
            Routing.instance.changeQueryParameter({
                fab: JSON.stringify(currentFilter),
            });
        });
    };
    useEffect(() => {
        let isMounted = true;
        if (CompanyUserInfo.company) {
            UpmeshClient.instance.modals.group
                .get({
                filter: `deleted ne true and companyId eq '${CompanyUserInfo.company.id}'`,
            })
                .then((groupEntities) => {
                const collaboratorGroups = [];
                groupEntities.forEach((group) => {
                    collaboratorGroups.push({
                        id: group.id,
                        groupName: group.groupName !== 'default_employees'
                            ? group.groupName
                            : I18n.m.getMessage('companyGroupDefaultName'),
                        backgroundColor: 'transparent',
                    });
                });
                if (isMounted)
                    setCollaboratorGroups(collaboratorGroups);
            })
                .catch((err) => console.debug('cant get groups', err));
        }
        return () => {
            isMounted = false;
        };
    }, []);
    useEffect(() => {
        const formData = {
            date: currentFilter.date,
            dateTo: currentFilter.dateTo,
            ...getTasksFromFilter(currentFilter, props.all),
            ...getCollaboratorsFromFilter(currentFilter, props.all),
            ...getProjectsFromFilter(currentFilter, props.all),
            ...getCostCentersFromFilter(currentFilter, props.all),
        };
        setCurrentForm(formData);
    }, []);
    useEffect(() => {
        const { all } = props;
        setAmount({ calculating: true, selected: 0 });
        try {
            const filtered = AbsenceFilter.filter(all, currentFilter);
            setAmount({ calculating: false, selected: filtered.length });
        }
        catch (e) {
            setAmount({ calculating: false, selected: 0 });
        }
    }, [currentFilter]);
    const onRemoveMyTimesClick = () => {
        if (currentForm != null) {
            const { selectedCollaborators } = currentForm;
            const { userId } = CurrentUser;
            if (selectedCollaborators && userId != null) {
                const i = selectedCollaborators.findIndex((r) => r.id === userId);
                if (userId != null && i > -1)
                    selectedCollaborators.splice(i, 1);
                const userIds = selectedCollaboratorsToUserIds(selectedCollaborators);
                setCurrentForm({ ...currentForm, selectedCollaborators });
                setCurrentFilter({ ...currentFilter, userIds });
            }
        }
    };
    const onAddMyTimesClick = () => {
        if (currentForm != null) {
            const { collaboratorList, selectedCollaborators } = currentForm;
            const me = collaboratorList.find((a) => a.id === CurrentUser.userId);
            if (me != null) {
                selectedCollaborators.push(me);
                const userIds = selectedCollaboratorsToUserIds(selectedCollaborators);
                setCurrentForm({ ...currentForm, selectedCollaborators });
                setCurrentFilter({ ...currentFilter, userIds });
            }
        }
    };
    return (<>
      <DialogTitle key="AbsenceFilterTitle">{I18n.m.getMessage('timeTrackingFilter')}</DialogTitle>
      <DialogContent key="AbsenceFilterContent">
        {currentForm == null ? (<Spinner />) : (<View>
            {currentForm.projectList.length <= 1 ? null : (<ChipGroup chips={currentForm.selectedProjects} availableChips={currentForm.projectList} label={I18n.m.getMessage('projects')} onChanged={(chips) => {
                    const formData = { ...currentForm };
                    formData.selectedProjects = chips;
                    setCurrentForm(formData);
                    const projectIds = [];
                    if (chips.length > 0) {
                        chips.forEach((c) => {
                            if (c.id != null)
                                projectIds.push(c.id);
                        });
                    }
                    const f = { ...currentFilter };
                    f.projectIds = projectIds;
                    setCurrentFilter(f);
                }}/>)}
            {currentForm.costCenterList.length <= 1 ? null : (<ChipGroup chips={currentForm.selectedCostCenters} availableChips={currentForm.costCenterList} label={I18n.m.getMessage('costCenters')} onChanged={(chips) => {
                    const formData = { ...currentForm };
                    formData.selectedCostCenters = chips;
                    setCurrentForm(formData);
                    const costCenterIds = [];
                    if (chips.length > 0) {
                        chips.forEach((c) => {
                            if (c.id != null)
                                costCenterIds.push(c.id);
                        });
                    }
                    const f = { ...currentFilter };
                    f.costCenterIds = costCenterIds;
                    setCurrentFilter(f);
                }}/>)}
            {currentForm.collaboratorList.length <= 1 ? null : (<View>
                <View style={{ height: 8 }}/>
                <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('collaborator')}</MaterialText>
                <Chip thumbnail={<UserImage user={CurrentUser.entity} size={24}/>} title={I18n.m.getMessage('timeTrackingMyTimes')} backgroundColor={showMyTimes ? ThemeManager.style.brandPrimary : undefined} textColor={showMyTimes ? '#FFFFFF' : undefined} onPressChip={showMyTimes ? onRemoveMyTimesClick : onAddMyTimesClick} key={`myTimesChip_${showMyTimes}`}/>
                <ChipGroup key={`${JSON.stringify(currentForm.selectedCollaborators)}_${JSON.stringify(currentForm.collaboratorList)}_${collaboratorGroups.length}`} dialogTitle={I18n.m.getMessage('collaborator')} chips={currentForm.selectedCollaborators} chipGroups={collaboratorGroups} sortFormByGroup availableChips={currentForm.collaboratorList} onChanged={(chips) => {
                    const userIds = selectedCollaboratorsToUserIds(chips);
                    setCurrentForm({ ...currentForm, selectedCollaborators: chips });
                    setCurrentFilter({ ...currentFilter, userIds });
                }}/>
              </View>)}
            {currentForm.taskList.length <= 1 ? null : (<>
                <View style={{ height: 8 }}/>
                <ChipGroup chips={currentForm.selectedTasks} availableChips={currentForm.taskList} label={I18n.m.getMessage('absenceTask')} onChanged={(chips) => {
                    const formData = { ...currentForm };
                    formData.selectedTasks = chips;
                    setCurrentForm(formData);
                    const taskIds = [];
                    if (chips.length > 0) {
                        chips.forEach((c) => {
                            if (c.id != null)
                                taskIds.push(c.id);
                        });
                    }
                    const f = { ...currentFilter };
                    f.taskIds = taskIds;
                    setCurrentFilter(f);
                }}/>
              </>)}
            <View style={{ height: 8 }}/>
            <MaterialText type={MaterialTextTypes.Caption}>
              {I18n.m.getMessage('timeTrackingFilterDateRange')}
            </MaterialText>
            <DateInput labelText={I18n.m.getMessage('timeTrackingFilterDay')} placeholder={I18n.m.getMessage('timeTrackingFilterDay')} key={`projectTime${currentForm.date}`} onChange={(newDate) => {
                const date = newDate == null ? undefined : typeof newDate === 'number' ? newDate : newDate.getTime();
                const formData = { ...currentForm };
                formData.date = date;
                setCurrentForm(formData);
                const f = { ...currentFilter };
                f.date = date;
                setCurrentFilter(f);
            }} selectedDate={currentForm.date == null ? undefined : new Date(currentForm.date)}/>
            <DateInput labelText={I18n.m.getMessage('timeTrackingFilterDayTo')} placeholder={I18n.m.getMessage('timeTrackingFilterDayTo')} key={`projectTimeTo${currentForm.dateTo}`} onChange={(newDate) => {
                const date = newDate == null ? undefined : typeof newDate === 'number' ? newDate : newDate.getTime();
                const formData = { ...currentForm };
                formData.dateTo = date;
                setCurrentForm(formData);
                const f = { ...currentFilter };
                f.dateTo = date;
                setCurrentFilter(f);
            }} selectedDate={currentForm.dateTo == null ? undefined : new Date(currentForm.dateTo)}/>
            <View style={{ height: 8 }}/>
            <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('absenceState')}</MaterialText>
            <FilterChips textColor="#FFFFFF" chips={getStatuusList(currentFilter.state ? currentFilter.state : [])} onPressChip={(_1, chip) => {
                const current = currentFilter.state ? currentFilter.state : [];
                const index = current.findIndex((a) => a === chip.data);
                if (chip.checked === true && index === -1) {
                    current.push(`${chip.data}`);
                }
                else if (chip.checked !== true && index >= 0) {
                    current.splice(index, 1);
                }
                const f = { ...currentFilter };
                f.state = current;
                setCurrentFilter(f);
            }}/>
          </View>)}
      </DialogContent>
      <DialogActions key="AbsenceFilterActions">
        <ContainedButton title={I18n.m.getMessage('reset')} onPress={() => {
            if (currentForm != null)
                setCurrentForm({
                    ...currentForm,
                    date: undefined,
                    dateTo: undefined,
                    selectedProjects: [],
                    selectedCostCenters: [],
                    selectedTasks: [],
                });
            setCurrentFilter(new AbsenceFilter());
        }} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton loading={amount.calculating} disabled={amount.calculating} title={`${I18n.m.getMessage('apply')} ${amount.selected}/${props.all.length}`} onPress={onSelect}/>
      </DialogActions>
    </>);
}
export const openAbsenceFilterDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    DialogBetween.instance?.open({
        content: <AbsenceFilterDialog {...props}/>,
        contentPadding: false,
        closeOnTouchOutside: false,
        scrollable: false,
        openPosition,
        fullscreenResponsive: true,
        showCloseIcon: true,
    });
};
