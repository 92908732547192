import { AsyncEvent } from 'ts-events';
export class SharedWorkerStorage {
    async initStorage() {
        try {
            const allData = await new Promise((resolve) => {
                const key = 'allData';
                const onM = (resolve) => (msg) => {
                    if (msg.startsWith(`storageGotµ${key}µ`)) {
                        const v = msg.substr(`storageGotµ${key}µ`.length);
                        this.message.detach(onM);
                        resolve(v.length > 0 ? v : null);
                    }
                };
                this.message.attach(onM(resolve));
                this.port.postToClient(`storageGetµ${key}`);
            });
            if (allData != null)
                this.inMemoryData = JSON.parse(allData);
            return this;
        }
        catch (e) {
            console.error(e);
            return this;
        }
        return this;
    }
    constructor(w) {
        this.inMemoryData = {};
        this.message = new AsyncEvent();
        this.onMessage = (e) => {
            if (e.message.startsWith('storage')) {
                this.message.post(e.message);
            }
        };
        this.port = w;
        this.port.onMessage.attach(this.onMessage);
    }
    get(key) {
        return this.inMemoryData[key];
    }
    async getAllKeys() {
        try {
            return new Promise((resolve) => {
                const onM = (resolve) => (msg) => {
                    if (msg.startsWith(`storageAK`)) {
                        const v = msg.substr(`storageAK`.length);
                        this.message.detach(onM);
                        resolve(v.length > 0 ? JSON.parse(v) : []);
                    }
                };
                this.message.attach(onM(resolve));
                this.port.postToClient(`storageAllKeys`);
            });
        }
        catch (e) {
            return [];
        }
    }
    async remove(key) {
        this.port.postToClient(`storageRemoveµ${key}`);
    }
    set(key, value) {
        this.inMemoryData[key] = value;
        this.setAsync(key, value).catch((err) => console.error(err));
    }
    async setAsync(key, value) {
        try {
            await new Promise((resolve) => {
                const onM = (resolve) => (msg) => {
                    if (msg.startsWith(`storageSotµ${key}`)) {
                        resolve();
                    }
                };
                this.message.attach(onM(resolve));
                this.port.postToClient(`storageSetµ${key}µ${value}`);
            });
        }
        catch (e) {
            console.debug('cant save kea in Worker', e);
        }
    }
    clear() {
        this.port.postToClient(`storageClear`);
        this.inMemoryData = {};
    }
}
