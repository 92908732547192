"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
var Speedometer_1 = require("./Speedometer");
var Progress = function () {
  function Progress(length) {
    var emitDelay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
    (0, _classCallCheck2.default)(this, Progress);
    this.length = parseInt(length, 10) || 0;
    this.transferred = 0;
    this.speed = 0;
    this.streamSpeed = (0, Speedometer_1.default)(this.speed || 5000);
    this.initial = false;
    this.emitDelay = emitDelay;
    this.eventStart = 0;
    this.percentage = 0;
  }
  return (0, _createClass2.default)(Progress, [{
    key: "getRemainingBytes",
    value: function getRemainingBytes() {
      return this.length - this.transferred;
    }
  }, {
    key: "getEta",
    value: function getEta() {
      return this.length >= this.transferred ? this.getRemainingBytes() / this.speed * 1000000000 : 0;
    }
  }, {
    key: "flow",
    value: function flow(chunk, onProgress) {
      var chunkLength = chunk.length;
      this.transferred += chunkLength;
      this.speed = this.streamSpeed(chunkLength);
      this.percentage = Math.round(this.transferred / this.length * 100);
      if (!this.initial) {
        this.eventStart = Date.now();
        this.initial = true;
      }
      if (this.length >= this.transferred || Date.now() - this.eventStart > this.emitDelay) {
        this.eventStart = Date.now();
        var progress = {
          total: this.length,
          transferred: this.transferred,
          speed: this.speed,
          eta: this.getEta(),
          remaining: 0,
          percentage: 0
        };
        if (this.length) {
          progress.remaining = this.getRemainingBytes();
          progress.percentage = this.percentage;
        }
        onProgress(progress);
      }
    }
  }]);
}();
exports.default = Progress;