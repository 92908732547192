var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Page = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ResizeEvent = require("../ResizeEvent");
var _Routing = require("../routing/Routing");
var _ThemeManager = require("../ThemeManager");
var _FadeInAnimation = require("./animations/FadeInAnimation");
var _ContainedButton = require("./button/ContainedButton");
var _Header = require("./header/Header");
var _ScrollContainer = require("./scroll/ScrollContainer");
var _StoredStartPages = require("../../utils/StoredStartPages");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var containerStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: 24
};
var Page = exports.Page = function (_PureComponent) {
  function Page(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Page);
    _this = _callSuper(this, Page, [props]);
    _this.goBack = function () {
      _Routing.Routing.instance.goBack();
    };
    _this.goHome = function () {
      _Routing.Routing.instance.goTo(`/${_StoredStartPages.StoredStartPages.getStoredStartPage()}`);
    };
    _this.onResize = function (e) {
      if (_this.props.onLayout != null) {
        _this.props.onLayout(e);
      }
      _this.forceUpdate();
    };
    _this.onScroll = function (e) {
      if (_this.props.onScroll != null) {
        _this.props.onScroll(e);
      }
    };
    _this._animations = new _FadeInAnimation.FadeInAnimation();
    _this.state = {
      height: _this.contentSize.height,
      width: _this.contentSize.width,
      maxWidth: _this.props.maxWidth == null ? 1 : _this.props.maxWidth,
      maxHeight: _this.props.maxHeight == null ? 1 : _this.props.maxHeight,
      viewHeight: 1,
      viewWidth: 1,
      layoutKnown: false,
      hasError: false
    };
    return _this;
  }
  (0, _inherits2.default)(Page, _PureComponent);
  return (0, _createClass2.default)(Page, [{
    key: "contentSize",
    get: function get() {
      var height = this.props.headerTitle != null && this.props.headerTitle.length > 0 ? _ResizeEvent.ResizeEvent.current.contentHeight - _ThemeManager.ThemeManager.style.headerHeight : _ResizeEvent.ResizeEvent.current.contentHeight;
      return {
        height: height,
        width: _ResizeEvent.ResizeEvent.current.contentWidth
      };
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      this._animations.toValue(1, function () {});
      _ResizeEvent.ResizeEvent.onResize.attach(this.onResize);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this._animations.toValue(0, function () {});
      _ResizeEvent.ResizeEvent.onResize.detach(this.onResize);
    }
  }, {
    key: "render",
    value: function render() {
      var scrollable = this.props.scrollable;
      var hasError = this.state.hasError;
      var contentSize = this.contentSize;
      var textInputRefs = [];
      if (this.props.textInputRefs != null && this.props.textInputRefs.length > 0) {
        for (var i = 0; i < this.props.textInputRefs.length; i += 1) {
          textInputRefs.push(this.props.textInputRefs[i]);
        }
      }
      var leftButtons = this.props.headerLeftButtons != null ? this.props.headerLeftButtons : [];
      if (this.props.headerHomeButton === true) {
        leftButtons.push((0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          onPress: this.goHome,
          icon: {
            icon: 'home-outline'
          },
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.headerButtonColor
        }, "home"));
      }
      if (this.props.headerBackButton === true) {
        leftButtons.push((0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          onPress: this.goBack,
          icon: {
            icon: 'keyboard-backspace'
          },
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.headerButtonColor
        }, "back"));
      }
      var keyboardShouldPersistTaps = _reactNativeWeb.Platform.OS === 'android' ? {
        keyboardShouldPersistTaps: 'always'
      } : undefined;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.Animated.View, {
        style: _reactNativeWeb.StyleSheet.flatten([{
          position: 'absolute',
          top: _ResizeEvent.ResizeEvent.current.contentTop,
          left: _ResizeEvent.ResizeEvent.current.contentLeft,
          right: _ResizeEvent.ResizeEvent.current.contentRight,
          bottom: _ResizeEvent.ResizeEvent.current.contentBottom
        }, this._animations.animations, {
          backgroundColor: this.props.backgroundColor
        }, this.props.style]),
        children: [this.props.headerTitle != null && this.props.headerTitle.length > 0 ? (0, _jsxRuntime.jsx)(_Header.Header, {
          title: this.props.headerTitle,
          leftButtons: leftButtons,
          rightButtons: this.props.headerRightButtons,
          textColor: this.props.headerTextColor
        }) : null, scrollable ? (0, _jsxRuntime.jsx)(_ScrollContainer.ScrollContainer, Object.assign({
          onScroll: this.onScroll,
          scrollEnabled: scrollable,
          keyboardShouldPersistTaps: "always",
          style: [this.contentSize, {
            width: '100%'
          }]
        }, keyboardShouldPersistTaps, {
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: containerStyle,
            children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                width: '100%',
                height: 'auto'
              },
              children: this.props.children
            })
          })
        })) : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: [contentSize, {
            width: '100%'
          }],
          children: hasError ? null : this.props.children
        })]
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        children: null,
        width: 0,
        height: 1,
        maxWidth: 1,
        maxHeight: 1,
        scrollable: true,
        backgroundColor: '#FFFFFF',
        headerTitle: '',
        headerHomeButton: true,
        headerBackButton: false
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, _prevState) {
      return {
        width: nextProps.width,
        height: nextProps.height,
        maxWidth: nextProps.maxWidth,
        maxHeight: nextProps.maxHeight
      };
    }
  }]);
}(_react.PureComponent);