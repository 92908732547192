import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { I18n } from './i18n/I18n';
export function SyncProgressFooter(props) {
    const [status, setStatus] = useState();
    const sStatus = (s) => {
        setStatus(s);
    };
    useEffect(() => {
        AuthClient.instance.syncDispatcher.syncStatus.attach(sStatus);
        return () => {
            AuthClient.instance.syncDispatcher.syncStatus.detach(sStatus);
        };
    }, []);
    return (<View style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
            height: 40,
            backgroundColor: ThemeManager.style.appBgColorDarken,
            borderColor: ThemeManager.style.borderColor,
            borderWidth: 0,
            borderBottomWidth: 1,
            borderTopWidth: 1,
            paddingHorizontal: 16,
        }}>
      <View style={{ flexDirection: 'row' }}>
        <Spinner small/>
        <View style={{ width: 8, height: 1 }}/>
        <MaterialText centeredBox>
          {I18n.m.getMessage('syncStatusSyncing')} {status != null ? `${status.percent}%` : ''}
        </MaterialText>
      </View>
      <Icon icon="close" toolTip="close" onPress={props.hideFooter}/>
    </View>);
}
