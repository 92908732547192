var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormOptions = exports.Form = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _lodash = _interopRequireDefault(require("lodash"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var uuid = _interopRequireWildcard(require("uuid"));
var _I18n = require("../../i18n/I18n");
var _Routing = require("../../routing/Routing");
var _ThemeManager = require("../../ThemeManager");
var _ContainedButton = require("../button/ContainedButton");
var _CheckboxListItem = require("../CheckboxListItem");
var _NumberPicker = _interopRequireDefault(require("../NumberPicker"));
var _ScrollContainer = require("../scroll/ScrollContainer");
var _SwitchListItem = require("../SwitchListItem");
var _MaterialText = require("../text/MaterialText");
var _DateInputFormFilled = require("./DateInputFormFilled");
var _FormActions = require("./FormActions");
var _FormHeader = require("./FormHeader");
var _FormInputFilled = require("./FormInputFilled");
var _FormInputPicker = require("./FormInputPicker");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var FormOptions = exports.FormOptions = (0, _createClass2.default)(function FormOptions() {
  (0, _classCallCheck2.default)(this, FormOptions);
  this.cols = 1;
  this.optional = false;
});
var Form = exports.Form = function (_Component) {
  function Form(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, Form);
    _this = _callSuper(this, Form, [props, context]);
    _this.formInputs = new Map();
    _this.scrollContainerId = uuid.v1();
    _this.onConnectionChange = function () {
      var _this$state = _this.state,
        offlineError = _this$state.offlineError,
        serverConnection = _this$state.serverConnection;
      if (offlineError !== _this.getOfflineError(_this.props.command, serverConnection)) {
        _this.setState({
          offlineError: _this.getOfflineError(_this.props.command, serverConnection)
        });
      }
    };
    _this.hasChanged = function (key) {
      return function (value1) {
        var asyncNow = function () {
          var _ref = (0, _asyncToGenerator2.default)(function* () {
            var fi = _this.formInputs.get(key);
            var _this$state2 = _this.state,
              resetData = _this$state2.resetData,
              currentFormData = _this$state2.currentFormData;
            var globalOnChangeError = '';
            var errors = new Map();
            var _this$props = _this.props,
              command = _this$props.command,
              dataOptions = _this$props.dataOptions,
              onChangeData = _this$props.onChangeData;
            if (fi != null) {
              var _dataOptions$get;
              var map = (_dataOptions$get = dataOptions.get(key)) == null ? void 0 : _dataOptions$get.mapValue;
              var value = map != null ? map(value1, true) : value1;
              var newData = Object.assign({}, currentFormData);
              if (value == null) {
                try {
                  delete newData[key];
                } catch (e) {}
              } else {
                newData[key] = value;
              }
              var newCommand = command.clone(Object.assign({}, command.data, newData), command.entityId, command.token);
              try {
                yield newCommand.canI();
                yield newCommand.validate();
              } catch (e) {
                if (Array.isArray(e)) {
                  for (var error of e) {
                    if (error != null && error.key != null) {
                      errors.set(error.key, error);
                    }
                  }
                } else if (e.key != null) {
                  errors.set(e.key, e);
                } else {
                  var message = e['messageCode'] != null && e['messageCode'].length > 0 ? _I18n.I18n.m.getMessage(e.messageCode, e['messageData']) : e.message;
                  globalOnChangeError = message || e.toString();
                }
              }
              var hasChanged = !_lodash.default.isEqual(newData, resetData);
              _this.setState({
                errors: errors,
                globalOnChangeError: globalOnChangeError,
                hasChanged: hasChanged,
                currentFormData: newData
              }, function () {
                if (onChangeData != null) onChangeData(newData);
              });
              return;
            }
            _this.setState({
              errors: errors,
              globalOnChangeError: globalOnChangeError
            });
          });
          return function asyncNow() {
            return _ref.apply(this, arguments);
          };
        }();
        asyncNow().catch(function (error) {
          return void 0;
        });
      };
    };
    _this.nextFields = function (key) {
      return function () {
        var errors = _this.state.errors;
        var index = _this.keys.indexOf(key);
        if (_this.keys.length > index + 1) {
          var fi = _this.formInputs.get(_this.keys[index + 1]);
          if (fi != null) {
            fi.focus();
          }
        } else if (errors.size > 0) {
          var error = errors.values().next().value;
          var _fi = _this.formInputs.get(error.key);
          if (_fi != null) {
            _fi.focus();
          }
        } else {
          _this.onSave();
        }
        return undefined;
      };
    };
    _this.onReset = function () {
      var fis = _this.formInputs;
      var dataOptions = _this.props.dataOptions;
      for (var key of fis.keys()) {
        var fi = fis.get(key);
        if (fi != null) {
          var _dataOptions$get2;
          var resetData = _this.state.resetData;
          var value = void 0;
          if (resetData != null && resetData[key] != null) {
            value = resetData[key];
          }
          var map = (_dataOptions$get2 = dataOptions.get(key)) == null ? void 0 : _dataOptions$get2.mapValue;
          var valueMapped = map != null ? map(value, false) : value;
          fi.setValue(valueMapped);
        }
      }
      _this.setState({
        hasChanged: false,
        errors: new Map(),
        globalOnChangeError: ''
      }, _this.forceUpdate);
    };
    _this.onSave = function () {
      var fis = _this.formInputs;
      var _this$props2 = _this.props,
        beforeSave = _this$props2.beforeSave,
        command = _this$props2.command,
        store = _this$props2.store,
        onSaved = _this$props2.onSaved,
        catchError = _this$props2.catchError,
        dataOptions = _this$props2.dataOptions;
      var _this$state3 = _this.state,
        resetData = _this$state3.resetData,
        errors = _this$state3.errors;
      var formSubmitError = '';
      var hasChanged = false;
      _this.setState({
        isLoading: true
      }, function () {
        var asyncNow = function () {
          var _ref2 = (0, _asyncToGenerator2.default)(function* () {
            var newData = Object.assign({}, resetData);
            for (var key of fis.keys()) {
              var fi = fis.get(key);
              if (fi != null) {
                var _dataOptions$get3;
                var getValue1 = fi.getValue();
                var value1 = getValue1 != null && typeof getValue1 === 'string' ? getValue1.trim() : getValue1;
                var map = (_dataOptions$get3 = dataOptions.get(key)) == null ? void 0 : _dataOptions$get3.mapValue;
                newData[key] = map != null ? map(value1, true) : value1;
              }
            }
            var newCommand = command.clone(Object.assign({}, command.data, newData), command.entityId, command.token);
            try {
              yield newCommand.canI();
              yield newCommand.validate();
            } catch (e) {
              if (Array.isArray(e)) {
                for (var error of e) {
                  if (error != null && error.key != null) {
                    errors.set(error.key, error);
                  }
                }
              }
            }
            if (beforeSave != null) {
              var canSave = yield beforeSave(newCommand.data);
              if (!canSave.canSave) {
                _this.setState({
                  errors: errors,
                  isLoading: false
                });
                return null;
              }
            }
            try {
              yield newCommand.execute(store);
              if (onSaved != null && newCommand.executed) {
                var result = newCommand['result'] ? newCommand['result'] : newCommand.data;
                onSaved(result);
              }
            } catch (e) {
              hasChanged = true;
              var errorData = Object.assign({}, newData);
              newData = resetData;
              if (catchError != null && catchError(e, errorData)) {
                formSubmitError = '';
              } else {
                formSubmitError = _this.getErrorMessage(e);
              }
              if (formSubmitError !== '') {
                _Routing.Routing.instance.alert.post({
                  text: formSubmitError
                });
              }
            }
            _this.setState({
              errors: errors,
              hasChanged: hasChanged,
              isLoading: false,
              resetData: newData
            });
            return null;
          });
          return function asyncNow() {
            return _ref2.apply(this, arguments);
          };
        }();
        asyncNow().catch(function (err) {
          return void 0;
        });
      });
    };
    _this.setRef = function (key) {
      return function (_e) {
        _this.formInputs.set(key, _e);
      };
    };
    var serverConnection = props.serverConnection != null ? props.serverConnection : _AuthClient.AuthClient.instance != null && _AuthClient.AuthClient.instance.serverConnection != null ? _AuthClient.AuthClient.instance.serverConnection : null;
    _this.state = {
      serverConnection: serverConnection,
      currentFormData: props.command.data,
      offlineError: _this.getOfflineError(props.command, serverConnection),
      isLoading: false,
      hasChanged: false,
      resetData: props.command.data,
      globalOnChangeError: '',
      errors: new Map(),
      cols: props.baseCols != null ? props.baseCols : 1
    };
    return _this;
  }
  (0, _inherits2.default)(Form, _Component);
  return (0, _createClass2.default)(Form, [{
    key: "getOfflineError",
    value: function getOfflineError(command) {
      var serverConnection = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      if (command['offlineUsage'] === true) return false;
      return serverConnection == null || !serverConnection.isConnected;
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.state.serverConnection != null) {
        this.state.serverConnection.onConnectionChanged.attach(this.onConnectionChange);
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.state.serverConnection != null) {
        this.state.serverConnection.onConnectionChanged.detach(this.onConnectionChange);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var globalOnChangeError = this.state.globalOnChangeError;
      var _this$props3 = this.props,
        stickyActions = _this$props3.stickyActions,
        stickyHeader = _this$props3.stickyHeader,
        postText = _this$props3.postText,
        children = _this$props3.children;
      var error = globalOnChangeError != null && globalOnChangeError.length > 0 ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        color: _ThemeManager.ThemeManager.style.brandDanger,
        children: globalOnChangeError
      }) : null;
      var formTitle = this.renderFormHeader('title', stickyHeader);
      var formDescription = this.renderFormHeader('description');
      var form = this.renderForm();
      var buttons = this.renderButtons();
      var post = postText != null ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        children: postText
      }) : null;
      if (stickyHeader && stickyActions) {
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            height: '100%'
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: '100%',
              padding: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
              paddingBottom: 0
            },
            children: formTitle
          }), (0, _jsxRuntime.jsxs)(_ScrollContainer.ScrollContainer, {
            containerId: this.scrollContainerId,
            style: {
              width: '100%',
              height: '100%'
            },
            contentContainerStyle: {
              padding: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
              paddingTop: 0
            },
            children: [formDescription, form, children, post]
          }), (0, _jsxRuntime.jsx)(_FormActions.FormActions, {
            onBottom: true,
            children: buttons
          })]
        });
      }
      if (stickyActions) {
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            height: '100%'
          },
          children: [(0, _jsxRuntime.jsxs)(_ScrollContainer.ScrollContainer, {
            containerId: this.scrollContainerId,
            style: {
              width: '100%',
              height: '100%'
            },
            contentContainerStyle: {
              padding: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16)
            },
            children: [formTitle, formDescription, form, post]
          }), (0, _jsxRuntime.jsx)(_FormActions.FormActions, {
            onBottom: true,
            children: buttons
          })]
        });
      }
      if (stickyHeader && !stickyActions) {
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            height: '100%'
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: '100%',
              padding: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
              paddingBottom: 0
            },
            children: formTitle
          }), (0, _jsxRuntime.jsxs)(_ScrollContainer.ScrollContainer, {
            containerId: this.scrollContainerId,
            style: {
              width: '100%',
              height: '100%'
            },
            contentContainerStyle: {
              paddingTop: 0
            },
            children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
              style: {
                padding: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16)
              },
              children: [formDescription, form, post, children, error]
            }), (0, _jsxRuntime.jsx)(_FormActions.FormActions, {
              onBottom: false,
              children: buttons
            })]
          })]
        });
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            padding: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
            paddingBottom: 28
          },
          children: [formTitle, formDescription, form, post, children, error]
        }), (0, _jsxRuntime.jsx)(_FormActions.FormActions, {
          onBottom: false,
          children: buttons
        })]
      });
    }
  }, {
    key: "getErrorMessage",
    value: function getErrorMessage(e) {
      var _this2 = this;
      var formSubmitError = '';
      if (e['message'] != null && e['message'].length > 0 || e['errorMessage'] != null && e['errorMessage'].length > 0) {
        var m = e['message'] != null && e['message'].length > 0 ? e['message'] : e['errorMessage'];
        var message = e['messageCode'] != null && e['messageCode'].length > 0 ? _I18n.I18n.m.getMessage(e.messageCode, e['messageData']) : m;
        formSubmitError = message != null && message.length > 0 && message !== e.messageCode ? message : m;
      } else if (Array.isArray(e)) {
        e.forEach(function (l) {
          formSubmitError += _this2.getErrorMessage(l);
        });
      } else if (typeof e === 'string') {
        formSubmitError = e;
      } else {
        formSubmitError = e.toString();
      }
      return formSubmitError;
    }
  }, {
    key: "renderButtons",
    value: function renderButtons() {
      var buttons = [];
      var _this$props4 = this.props,
        saveButtonLabel = _this$props4.saveButtonLabel,
        resetButtonLabel = _this$props4.resetButtonLabel,
        resetButton = _this$props4.resetButton,
        additionalButtons = _this$props4.additionalButtons,
        disableSaveOnNothingChanged = _this$props4.disableSaveOnNothingChanged,
        saveButtonColor = _this$props4.saveButtonColor,
        saveButtonTextColor = _this$props4.saveButtonTextColor,
        showButtonsIfOffline = _this$props4.showButtonsIfOffline;
      var _this$state4 = this.state,
        isLoading = _this$state4.isLoading,
        hasChanged = _this$state4.hasChanged,
        errors = _this$state4.errors,
        offlineError = _this$state4.offlineError;
      if (offlineError && !showButtonsIfOffline) return [];
      var saveLabel = saveButtonLabel != null ? saveButtonLabel : _I18n.I18n.m.getMessage('save');
      var resetLabel = resetButtonLabel != null ? resetButtonLabel : _I18n.I18n.m.getMessage('reset');
      var disabledSave = isLoading || !hasChanged && disableSaveOnNothingChanged || errors.size > 0 || offlineError;
      if (additionalButtons != null) {
        buttons.push.apply(buttons, (0, _toConsumableArray2.default)(additionalButtons));
      }
      if (resetButton) {
        var disabledReset = isLoading || !hasChanged;
        buttons.push((0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          title: resetLabel,
          onPress: this.onReset,
          disabled: disabledReset,
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary
        }, "resetButton"));
      }
      buttons.push((0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
        loading: isLoading,
        title: saveLabel,
        disabled: disabledSave,
        onPress: this.onSave,
        backgroundColor: saveButtonColor != null ? saveButtonColor : undefined,
        textColor: saveButtonTextColor != null ? saveButtonTextColor : undefined
      }, "saveButton"));
      return buttons;
    }
  }, {
    key: "renderForm",
    value: function renderForm() {
      var _this3 = this;
      var formElements = [];
      var _this$props5 = this.props,
        command = _this$props5.command,
        dataOptions = _this$props5.dataOptions,
        tabIndexStart = _this$props5.tabIndexStart,
        stickyActions = _this$props5.stickyActions;
      var _this$state5 = this.state,
        errors = _this$state5.errors,
        cols = _this$state5.cols,
        offlineError = _this$state5.offlineError,
        currentFormData = _this$state5.currentFormData;
      if (offlineError) {
        return (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: _I18n.I18n.m.getMessage('commandOfflineNotPossible')
        });
      }
      var i = tabIndexStart != null ? tabIndexStart : 10;
      this.keys = [];
      for (var key of dataOptions.keys()) {
        this.keys.push(key);
      }
      var _loop = function _loop() {
        var options = dataOptions.get(_key);
        var type = options.formType;
        var props = Object.assign({}, options.props);
        props.key = `form_${i}_${_key.toString()}`;
        props.tabIndex = i;
        props.ref = _this3.setRef(_key);
        props.nextField = _this3.nextFields(_key);
        props.onChange = _this3.hasChanged(_key);
        if (errors.has(_key)) {
          var e = errors.get(_key);
          if (e != null) {
            props.error = true;
            var message = e.messageCode != null && e.messageCode.length > 0 ? _I18n.I18n.m.getMessage(e.messageCode, e['messageData']) : e.message != null ? e.message : _I18n.I18n.m.getMessage('formSubmitError');
            props.helperText = message != null && message.length > 0 && message !== e.messageCode ? message : e.message;
          }
        } else {
          props.error = false;
          props.helperText = props.helperText ? props.helperText : '';
        }
        if (props.value == null && command.data[_key] != null) {
          var _dataOptions$get4;
          var map = (_dataOptions$get4 = dataOptions.get(_key)) == null ? void 0 : _dataOptions$get4.mapValue;
          props.initValue = map != null ? map(command.data[_key], false) : command.data[_key];
        }
        var formElement;
        if (type === 'string' || type === 'email' || type === 'number' || type === 'password') {
          var typeProps = {};
          if (type === 'email') {
            typeProps.keyboardType = 'email-address';
            typeProps.autoCompleteWeb = 'email';
          } else if (type === 'number') {
            typeProps.keyboardType = 'numeric';
          } else if (type === 'password') {
            typeProps.keyboardType = 'default';
            typeProps.secureTextEntry = true;
          } else {
            typeProps.keyboardType = 'default';
          }
          formElement = (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, Object.assign({
            testId: props.testId != null ? props.testId : props.labelText,
            scrollContainerId: stickyActions ? _this3.scrollContainerId : undefined
          }, props, typeProps), `element_${props.labelText}`);
        } else if (type === 'Date') {
          formElement = (0, _jsxRuntime.jsx)(_DateInputFormFilled.DateInputFormFilled, Object.assign({
            testId: props.labelText,
            scrollContainerId: stickyActions ? _this3.scrollContainerId : undefined
          }, props), `element_${props.labelText}`);
        } else if (type === 'Picker') {
          var _dataOptions$get5;
          var _map = (_dataOptions$get5 = dataOptions.get(_key)) == null ? void 0 : _dataOptions$get5.mapValue;
          var initValue = _map != null ? _map(currentFormData[_key], false) : currentFormData[_key];
          var selectedIndex;
          if (initValue['index'] != null) selectedIndex = initValue['index'];else {
            var _props$list;
            selectedIndex = (_props$list = props.list) == null ? void 0 : _props$list.findIndex(function (u) {
              if (typeof u === 'string') return u === initValue;
              return u['title'] === initValue;
            });
          }
          if (selectedIndex == null || selectedIndex === -1) selectedIndex = 0;
          formElement = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              paddingBottom: 0,
              width: '100%'
            },
            children: (0, _jsxRuntime.jsx)(_FormInputPicker.FormInputPicker, Object.assign({
              labelText: props.labelText == null ? '' : props.labelText,
              testId: props.labelText,
              list: props.list,
              selectedIndex: selectedIndex
            }, props))
          }, `element_${props.labelText}`);
        } else if (type === 'switch') {
          formElement = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              paddingVertical: 8,
              width: '100%'
            },
            children: (0, _jsxRuntime.jsx)(_SwitchListItem.SwitchListItem, Object.assign({
              listItemProps: {
                paddingLeft: 0
              },
              title: props.labelText == null ? '' : props.labelText,
              testId: props.labelText
            }, props))
          }, `element_${props.labelText}`);
        } else if (type === 'text') {
          formElement = (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, Object.assign({}, props, {
            children: props.labelText
          }));
        } else if (type === 'boolean') {
          formElement = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              paddingBottom: 8,
              width: '100%'
            },
            children: (0, _jsxRuntime.jsx)(_CheckboxListItem.CheckboxListItem, Object.assign({
              listItemProps: {
                paddingLeft: 0
              },
              title: props.labelText == null ? '' : props.labelText,
              testId: props.labelText
            }, props))
          }, `element_${props.labelText}`);
        } else if (type === 'numberPicker') {
          formElement = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              paddingVertical: 8,
              width: '100%'
            },
            children: (0, _jsxRuntime.jsx)(_NumberPicker.default, Object.assign({
              testId: props.labelText,
              title: props.labelText == null ? '' : props.labelText
            }, props))
          }, `element_${props.labelText}`);
        } else if (type === 'individiual') {
          var component = options.component;
          if (component == null) {} else {
            props['currentData'] = _this3.state.currentFormData;
            formElement = (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                paddingBottom: 0,
                width: '100%'
              },
              children: component(props)
            }, `element_${props.labelText}`);
          }
        }
        if (formElement != null) {
          if (cols > 1) {
            var elementCols = options.cols != null ? Math.min(cols, options.cols) : 1;
            var percentWidth = 100 / (1 + cols - elementCols);
            var elementWidth = `${percentWidth < 100 ? percentWidth - 1 : 100}%`;
            formElements.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: _ThemeManager.ThemeManager.style.formElement(elementWidth),
              children: formElement
            }, `cols_${i / cols}`));
          } else {
            formElements.push(formElement);
          }
        }
        i += 1;
      };
      for (var _key of dataOptions.keys()) {
        _loop();
      }
      if (cols > 1) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          },
          children: formElements
        });
      }
      return formElements;
    }
  }, {
    key: "renderFormHeader",
    value: function renderFormHeader(type) {
      var noPaddingBottom = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var formHeaderProps = this.props.formHeaderProps;
      if (formHeaderProps != null) {
        if (formHeaderProps.formTitle != null && type === 'title') return (0, _jsxRuntime.jsx)(_FormHeader.FormHeader, {
          titlePaddingBottom: noPaddingBottom ? 0 : undefined,
          formTitle: formHeaderProps.formTitle
        });
        if (formHeaderProps.formText != null && type === 'description') return (0, _jsxRuntime.jsx)(_FormHeader.FormHeader, {
          formText: formHeaderProps.formText
        });
      }
      return null;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        resetButton: false,
        stickyActions: false,
        stickyHeader: false,
        disableSaveOnNothingChanged: true,
        showButtonsIfOffline: false
      };
    }
  }]);
}(_react.Component);