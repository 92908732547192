"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateTask = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var Config_1 = require("../../../../Config");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var TaskCreated_1 = require("../../../events/company/tasks/TaskCreated");
var CompanySettingsEntity_1 = require("../../../query/entities/CompanySettingsEntity");
var TaskEntity_1 = require("../../../query/entities/TaskEntity");
var CreateTask = function (_CommandRoot_1$Comman) {
  function CreateTask(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateTask);
    _this = _callSuper(this, CreateTask, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'CreateTask';
    return _this;
  }
  (0, _inherits2.default)(CreateTask, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(CreateTask, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var company = yield CommandReadModels_1.CommandReadModels.company.getById(this.data.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new TaskCreated_1.TaskCreated(this.entityId, {
          active: this.data.active,
          companyId: this.data.companyId,
          productive: this.taskType.productive,
          wageType: this.data.wageType,
          taskTypeId: this.data.taskTypeId,
          followUpWageType1: this.data.followUpWageType1,
          followUpWageType2: this.data.followUpWageType2,
          mark: this.data.mark,
          description: this.data.description
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validateTask",
    value: function () {
      var _validateTask = (0, _asyncToGenerator2.default)(function* (data, key) {
        var tasks = yield CommandReadModels_1.CommandReadModels.task.get({
          filter: `companyId eq '${data.companyId}' and deleted ne true`
        });
        var errors = [];
        if (key == null || key === 'mark') {
          if (data.mark == null || data.mark.length === 0) {
            errors.push({
              message: 'Das Kennzeichen wird benötigt',
              messageCode: 'required',
              key: 'mark'
            });
          }
          if ((tasks == null ? void 0 : tasks.findIndex(function (t) {
            return data.mark === t.mark && data.id !== t.id;
          })) > -1) {
            errors.push({
              message: 'Das Kennzeichen existiert bereits',
              messageCode: 'companyTaskAlreadyExists',
              key: 'mark'
            });
          }
        }
        if (key == null || key === 'description') {
          if (data.description == null || data.description.length === 0) {
            errors.push({
              message: 'Die Bezeichnung wird benötigt',
              messageCode: 'required',
              key: 'description'
            });
          }
        }
        if (key == null || key === 'taskTypeId') {
          if (data.taskTypeId == null || data.taskTypeId.length === 0) {
            errors.push({
              message: 'Die Tätigkeitsart wird benötigt',
              messageCode: 'required',
              key: 'taskTypeId'
            });
          } else {
            try {
              this.taskType = yield CompanySettingsEntity_1.CompanySettingsEntity.getTaskTypeById(data.taskTypeId);
            } catch (e) {
              errors.push({
                message: 'Die Tätigkeitsart existiert nicht',
                messageCode: 'required',
                key: 'taskTypeId'
              });
            }
          }
        }
        if (key == null || key === 'followUpWageType1') {
          if (data.followUpWageType1 != null && data.followUpWageType1.length > 0 && (data.wageType == null || data.wageType.length === 0)) {
            errors.push({
              message: 'Die Lohnart wird benötigt',
              messageCode: 'taskWageTypeRequiredIfFollowUp',
              key: 'wageType'
            });
          }
        }
        if (data.followUpWageType2 != null && data.followUpWageType2.length > 0 && (key == null || key === 'followUpWageType2')) {
          if (data.followUpWageType1 == null || data.followUpWageType1.length === 0) {
            errors.push({
              message: 'Die Folgelohnart 1 wird benötigt',
              messageCode: 'taskFollowUpRequiredIfFollowUp2',
              key: 'followUpWageType1'
            });
          }
          if (data.wageType == null || data.wageType.length === 0) {
            errors.push({
              message: 'Die Lohnart wird benötigt',
              messageCode: 'taskWageTypeRequiredIfFollowUp',
              key: 'wageType'
            });
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validateTask(_x, _x2) {
        return _validateTask.apply(this, arguments);
      }
      return validateTask;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'mark') {
          if (this.data.mark != null && this.data.mark.length > Config_1.ConfigHandler.config.string_Large) {
            throw {
              key: 'mark',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
        yield this.validateTask(new TaskEntity_1.TaskEntity(Object.assign({
          id: this.entityId
        }, this.data)), key);
      });
      function validate(_x3) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.CreateTask = CreateTask;