"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TicketComments = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var TicketCommentsEntity_1 = require("./entities/TicketCommentsEntity");
var TicketComments = function (_ReadModelClientOffli) {
  function TicketComments() {
    var _this;
    (0, _classCallCheck2.default)(this, TicketComments);
    _this = _callSuper(this, TicketComments, arguments);
    _this.exampleEntity = new TicketCommentsEntity_1.TicketCommentsEntity();
    _this.syncPriority = 2;
    _this.onEvents = [{
      eventName: 'TicketCommentAdded',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketCommentChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketCommentFileAdded',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(TicketComments, _ReadModelClientOffli);
  return (0, _createClass2.default)(TicketComments);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.TicketComments = TicketComments;