import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MenuRaw } from 'materialTheme/src/theme/components/MenuRaw';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { ScrollView, UIManager, View } from 'react-native';
import { ChangeFolder } from 'upmesh-core/src/client/commands/folder/ChangeFolder';
import { CreateFolder } from 'upmesh-core/src/client/commands/folder/CreateFolder';
import { I18n } from '../../i18n/I18n';
import { ChangeFolderDialog } from './ChangeFolderDialog';
import { CreateFolderDialog } from './CreateFolderDialog';
import { MoveFolderDialog } from './MoveFolderDialog';
export class FilesViewFolders extends PureComponent {
    constructor(props) {
        super(props);
        this.changeFolder = (f) => (_e) => {
            const { projectId } = this.props;
            Routing.instance.goTo(`/projects/${projectId}/files/${f}`);
        };
        this.openFolderMenu = (folder) => (e) => {
            UIManager.measure(e.nativeEvent.target, (_originX, _originY, _width, height, pageX, pageY) => {
                const client = { height, width: 300, x: pageX, y: pageY };
                const items = [
                    {
                        title: I18n.m.getMessage('createSubfolder'),
                        onPress: this.createSubFolder(folder == null ? undefined : folder.id),
                        thumbnail: { thumbnail: <Icon toolTip="" icon="folder-outline"/>, width: 24 },
                    },
                ];
                if (folder != null) {
                    items.push({
                        title: I18n.m.getMessage('edit'),
                        onPress: this.editFolder(folder),
                        thumbnail: { thumbnail: <Icon toolTip="" icon="pencil-outline"/>, width: 24 },
                    }, {
                        title: I18n.m.getMessage('moveFolder'),
                        onPress: this.moveFolder(folder),
                        thumbnail: { thumbnail: <Icon toolTip="" icon="folder-move-outline"/>, width: 24 },
                    }, {
                        title: I18n.m.getMessage('delete'),
                        onPress: () => this.props.deleteFolder(folder.id),
                        thumbnail: { thumbnail: <Icon toolTip="" icon="delete-outline"/>, width: 24 },
                    });
                }
                MenuRaw.instance?.open({
                    client,
                    filterFormInput: false,
                    items,
                });
            });
        };
        this.moveFolder = (item) => (_e) => {
            MenuRaw.instance?.close();
            MoveFolderDialog.open(item);
        };
        this.createSubFolder = (folderId) => () => {
            MenuRaw.instance?.close();
            const { projectId } = this.props;
            const c = new CreateFolder({
                subFolderFromId: folderId != null ? folderId : null,
                title: '',
                access: [],
                description: '',
                tags: [],
                projectId,
            });
            c.canI()
                .then(() => CreateFolderDialog.openDialog({ projectId, folderId }))
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.editFolder = (folder) => () => {
            MenuRaw.instance?.close();
            const c = new ChangeFolder({
                title: folder.title,
                access: folder.access,
                tags: folder.tags,
                description: folder.description,
            }, folder.id);
            c.canI()
                .then(() => ChangeFolderDialog.openDialog({ folder }))
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.toggleFolder = (id) => (_e) => {
            const { openedFolder } = this.state;
            const index = openedFolder.findIndex((e) => e === id);
            if (index > -1)
                openedFolder.splice(index, 1);
            else
                openedFolder.push(id);
            const { projectId } = this.props;
            SimpleStorage.set(`openFolders_${projectId}`, JSON.stringify(openedFolder));
            this.setState({ openedFolder: [...openedFolder] });
        };
        this.state = { openedFolder: [], init: false };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    async init() {
        const { projectId, folder } = this.props;
        let { openedFolder } = this.state;
        try {
            const s = SimpleStorage.get(`openFolders_${projectId}`);
            if (s != null)
                openedFolder = JSON.parse(s);
        }
        catch (e) {
            console.debug('cant get open folders', e);
        }
        if (folder != null && folder !== 'private' && folder !== 'public' && folder !== 'all' && folder.length > 0) {
            const toOpen = this.getToOpenFolder(folder);
            toOpen.forEach((f) => {
                if (!openedFolder.includes(f))
                    openedFolder.push(f);
            });
        }
        this.setState({ init: true, openedFolder: [...openedFolder] });
    }
    getToOpenFolder(id) {
        const { folders } = this.props;
        const toOpen = [];
        const ind = folders.findIndex((e) => e.id === id);
        if (ind > 0) {
            if (!toOpen.includes('public'))
                toOpen.push('public');
            const f = folders[ind];
            if (f != null && f.subFolderFromId != null && f.subFolderFromId.length > 0) {
                if (!toOpen.includes(f.subFolderFromId))
                    toOpen.push(f.subFolderFromId);
                toOpen.push(...this.getToOpenFolder(f.subFolderFromId));
            }
        }
        return toOpen;
    }
    renderFolderItem(folderName, folderId, icon, openable = false, folderEntity, ebene = 0) {
        const { folder } = this.props;
        const { openedFolder } = this.state;
        const isOpen = openedFolder.includes(folderId);
        return (<View key={`folder_${folderId}`} style={{
                position: 'relative',
                width: '100%',
                height: 40,
                flexDirection: 'row',
                backgroundColor: folder === folderId ? ThemeManager.style.brandPrimary : ThemeManager.style.appBgColor,
                justifyContent: 'space-between',
                borderRadius: 0,
                borderBottomRightRadius: 6,
                borderTopRightRadius: 6,
            }}>
        <Ripple style={{
                position: 'absolute',
                flexDirection: 'row',
                width: '100%',
                flex: 1,
                height: 40,
            }} onPress={this.changeFolder(folderId)} hoverColor={folder === folderId ? ThemeManager.style.brandPrimary : '#dddddf'}/>
        <View collapsable={false} pointerEvents="box-none" style={{
                position: 'absolute',
                flexDirection: 'row',
                width: '100%',
                flex: 1,
                height: 40,
                paddingLeft: ebene * 8,
            }}>
          {openable ? (<View style={{ width: 40 }}>
              <Icon icon={isOpen ? 'menu-down' : 'menu-right'} toolTip="" onPress={this.toggleFolder(folderId)} outerSize={40} iconSize={24} color={folder === folderId ? '#FFFFFF' : ThemeManager.style.defaultIconColor}/>
            </View>) : (<View style={{ width: 40 }}/>)}
          <View pointerEvents="none" style={{ flexDirection: 'row', flex: 1, height: 40 }}>
            <Icon toolTip={folderName} icon={icon} color={folder === folderId ? '#FFFFFF' : ThemeManager.style.defaultIconColor}/>
            <View pointerEvents="none" style={{ flexDirection: 'row', flex: 1, height: 40 }}>
              <MaterialText fixedWidth="100%" numberOfLines={1} centeredBox color={folder === folderId ? '#FFFFFF' : ThemeManager.style.defaultIconColor}>
                {folderName}
              </MaterialText>
            </View>
          </View>
          {folderEntity != null || folderId === 'public' ? (<View style={{ height: 40, justifyContent: 'center', marginRight: 2 }}>
              <Icon icon="dots-vertical" toolTip="" outerSize={36} onPress={this.openFolderMenu(folderEntity)} color={folder === folderId ? '#FFFFFF' : ThemeManager.style.defaultIconColor}/>
            </View>) : (<View style={{ width: 36 }}/>)}
        </View>
      </View>);
    }
    render() {
        const { init } = this.state;
        if (!init)
            return <Spinner />;
        const { width } = this.props;
        return (<ScrollView style={{ width, height: '100%' }} contentContainerStyle={{ paddingBottom: 72 }}>
        {this.renderFolderItem(I18n.m.getMessage('filesFolderPublic'), 'public', 'folder-home', true, undefined, 0)}
        {this.renderUserFolders()}
        {this.renderFolderItem(I18n.m.getMessage('filesFolderPrivate'), 'private', 'folder-lock', false)}
        {this.renderFolderItem(I18n.m.getMessage('tickets'), 'tickets', 'folder-marker', false)}
        {this.renderFolderItem(I18n.m.getMessage('menuProjectDailyReports'), 'reports', 'calendar', false)}
      </ScrollView>);
    }
    renderUserFolders() {
        const { folders } = this.props;
        const { openedFolder } = this.state;
        const publicIsOpen = openedFolder.includes('public');
        if (!publicIsOpen || folders == null || folders.length === 0)
            return null;
        return this.renderFolder();
    }
    renderFolder(mainId = 'public', ebene = 1) {
        const { folders } = this.props;
        const { openedFolder } = this.state;
        const items = [];
        folders.forEach((e) => {
            const subFolderFromId = e.subFolderFromId != null ? e.subFolderFromId : 'public';
            if ((mainId == null && subFolderFromId == null) || (mainId != null && subFolderFromId === mainId)) {
                const isOpen = openedFolder.includes(e.id);
                items.push(this.renderFolderItem(e.title, e.id, 'folder', true, e, ebene));
                if (isOpen)
                    items.push(...this.renderFolder(e.id, ebene + 1));
            }
        });
        return items;
    }
}
