import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Divider } from 'materialTheme/src/theme/components/utils/Divider';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
const findFirstIndex = (mapping, item, excelKeys) => {
    const values = [...mapping.values()];
    for (let i = 0; i < values.length; i += 1) {
        if (values[i].excelKey === item.title) {
            const index = excelKeys.findIndex((elem) => elem === item.title);
            if (index >= 0) {
                return index + 1;
            }
            return 0;
        }
    }
    return 0;
};
export function AssignmentPicker(props) {
    const [formIndex, setFormIndex] = useState(0);
    useEffect(() => {
        setFormIndex(findFirstIndex(props.mapping, props.item, props.excelKeys));
    }, [props.selectedType]);
    const pickList = [{ title: I18n.m.getMessage('importExcelNoAssignment'), data: props.item.key }];
    for (const cItem of props.excelKeys) {
        pickList.push({ title: cItem, data: props.item.key });
    }
    let renderText = I18n.m.getMessage('importExcelNoValue');
    if (props.excelElement != null) {
        const mapType = props.mapping.get(props.item.key);
        if (mapType != null) {
            const value = props.excelElement[mapType.excelKey];
            if (value != null && value.length > 0) {
                renderText = value;
            }
        }
    }
    if (renderText.length > 24) {
        renderText = `${renderText.substring(0, 20)}...`;
    }
    return (<>
      <View style={{ width: '100%', flex: 1, flexDirection: 'row' }}>
        <View style={{ flex: 2, justifyContent: 'center' }}>
          <MaterialText>{props.item.title}</MaterialText>
        </View>
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Icon icon="arrow-right" toolTip=""/>
        </View>
        <View style={{ height: 56, flex: 2 }}>
          <View style={{ position: 'relative', top: -8 }}>
            <FormInputPicker list={pickList} selectedIndex={formIndex} onChange={props.changeFunction} onChangePicker={setFormIndex}/>
          </View>
        </View>
        <View style={{ flex: 2, justifyContent: 'center' }}>
          <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <MaterialText>{renderText}</MaterialText>
          </View>
        </View>
      </View>
      <Divider />
    </>);
}
