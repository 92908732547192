var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserImageSyncButton = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _TempFile = require("cqrs-core/build/src/client/commands/TempFile");
var _Url = require("cqrs-shared/build/src/uri/Url");
var _WaitFor = require("cqrs-shared/build/src/WaitFor");
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _Uploads = require("../../../file/upload/Uploads");
var _ContainedButton = require("../../../theme/components/button/ContainedButton");
var _Dialog = require("../../../theme/components/Dialog");
var _Icon = require("../../../theme/components/Icon");
var _Menu = require("../../../theme/components/Menu");
var _Ripple = require("../../../theme/components/utils/Ripple");
var _I18n = require("../../../theme/i18n/I18n");
var _LoadingEvents = require("../../../theme/routing/LoadingEvents");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _userContext = _interopRequireDefault(require("../../../userContext"));
var _Imprint = require("../../Imprint");
var _UserImage = require("./UserImage");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var UserImageSyncButton = exports.UserImageSyncButton = function (_PureComponent) {
  function UserImageSyncButton(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, UserImageSyncButton);
    _this = _callSuper(this, UserImageSyncButton, [props, context]);
    _this.openImprint = function () {
      var _Menu$instance, _Dialog$instance;
      (_Menu$instance = _Menu.Menu.instance) == null || _Menu$instance.close();
      (_Dialog$instance = _Dialog.Dialog.instance) == null || _Dialog$instance.open({
        content: (0, _jsxRuntime.jsx)(_Imprint.Imprint, {}),
        scrollable: true,
        showCloseIcon: true
      });
    };
    _this.openUrl = function (url) {
      var _Menu$instance2;
      _reactNativeWeb.Linking.openURL(url).catch(function (err) {
        return void 0;
      });
      (_Menu$instance2 = _Menu.Menu.instance) == null || _Menu$instance2.close();
    };
    _this.openMenu = function (e) {
      _reactNativeWeb.UIManager.measureInWindow(e.nativeEvent.target, function (x, y, _width, height) {
        var client = {
          x: x,
          y: y,
          height: height,
          width: 256
        };
        var asyncNow = function () {
          var _ref = (0, _asyncToGenerator2.default)(function* () {
            var _Menu$instance3;
            var _this$props = _this.props,
              additionalMenuElements = _this$props.additionalMenuElements,
              additionalMenuElementsAfterMyAccount = _this$props.additionalMenuElementsAfterMyAccount;
            var syncStatus = _this.state.syncStatus;
            var user = _this.context.user;
            var thumbnail = user != null ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                width: 24,
                height: 24,
                borderRadius: 24 / 2
              },
              children: (0, _jsxRuntime.jsx)(_UserImage.UserImage, {
                size: 24,
                user: user
              }, `MenuUserImage_${user.id}`)
            }, `userImageRound_${user.id}`) : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
            var items = [{
              text: _I18n.I18n.m.getMessage('account'),
              onPress: _this.onMenuPressAccount,
              thumbnail: {
                thumbnail: thumbnail,
                width: 24
              }
            }];
            if (additionalMenuElementsAfterMyAccount != null) {
              var additionalMenuElementsAA = yield additionalMenuElementsAfterMyAccount();
              if (additionalMenuElementsAA && additionalMenuElementsAA.length > 0) {
                additionalMenuElementsAA.forEach(function (element) {
                  return items.push(element);
                });
              }
            }
            items.push({
              text: _I18n.I18n.m.getMessage('accountCompany'),
              onPress: _this.onMenuPressCompany,
              thumbnail: {
                thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                  toolTip: "",
                  icon: "domain",
                  color: _ThemeManager.ThemeManager.style.black38
                }),
                width: 24
              }
            }, {
              text: _I18n.I18n.m.getMessage('accountSettings'),
              onPress: _this.onMenuPressSettings,
              cutLine: true,
              thumbnail: {
                thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                  toolTip: "",
                  icon: "cog-outline",
                  color: _ThemeManager.ThemeManager.style.black38
                }),
                width: 24
              }
            });
            if (additionalMenuElements != null) {
              var additionalElements = yield additionalMenuElements();
              if (additionalElements && additionalElements.length > 0) {
                additionalElements.forEach(function (element) {
                  return items.push(element);
                });
              }
            }
            items.push({
              text: _I18n.I18n.m.getMessage(`syncStatus${syncStatus.status}`, syncStatus.textValues),
              onPress: _this.onMenuPressSyncStatus,
              cutLine: true,
              thumbnail: {
                thumbnail: _this.renderSyncIcon(),
                width: 24
              }
            });
            items.push({
              text: _I18n.I18n.m.getMessage('accountLogout'),
              onPress: _this.onMenuPressLogOut,
              cutLine: true,
              thumbnail: {
                thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                  toolTip: "",
                  icon: "power",
                  color: "#FA2A1C"
                }),
                width: 24
              }
            });
            items.push({
              text: _I18n.I18n.m.getMessage('helpArea'),
              onPress: function onPress() {
                return _this.openUrl('https://upmesh.freshdesk.com/support/solutions');
              },
              thumbnail: {
                thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                  toolTip: _I18n.I18n.m.getMessage('helpArea'),
                  icon: "help-circle-outline"
                }),
                width: 24
              }
            });
            items.push({
              text: _I18n.I18n.m.getMessage('statusPage'),
              onPress: function onPress() {
                return _this.openUrl('https://status.upmesh.de');
              },
              thumbnail: {
                thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                  toolTip: _I18n.I18n.m.getMessage('statusPage'),
                  icon: "web-check"
                }),
                width: 24
              }
            });
            items.push({
              text: _I18n.I18n.m.getMessage('impressum'),
              onPress: _this.openImprint,
              thumbnail: {
                thumbnail: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {}),
                width: 24
              }
            });
            (_Menu$instance3 = _Menu.Menu.instance) == null || _Menu$instance3.open({
              client: client,
              items: items
            });
          });
          return function asyncNow() {
            return _ref.apply(this, arguments);
          };
        }();
        asyncNow().catch(function (err) {
          return void 0;
        });
      });
    };
    _this.onMenuPressSettings = function () {
      var _Menu$instance4;
      _Routing.Routing.instance.goTo('/settings');
      (_Menu$instance4 = _Menu.Menu.instance) == null || _Menu$instance4.close();
    };
    _this.onMenuPressSyncStatus = function () {
      var _Menu$instance5;
      _Routing.Routing.instance.goTo('/sync');
      (_Menu$instance5 = _Menu.Menu.instance) == null || _Menu$instance5.close();
    };
    _this.onMenuPressAccount = function () {
      var _Menu$instance6;
      _Routing.Routing.instance.goTo('/account');
      (_Menu$instance6 = _Menu.Menu.instance) == null || _Menu$instance6.close();
    };
    _this.onMenuPressCompany = function () {
      var _Menu$instance7;
      _Routing.Routing.instance.goTo('/company');
      (_Menu$instance7 = _Menu.Menu.instance) == null || _Menu$instance7.close();
    };
    _this.onMenuPressLogOut = function () {
      var _Menu$instance8, _Dialog$instance2;
      (_Menu$instance8 = _Menu.Menu.instance) == null || _Menu$instance8.close();
      var title = _I18n.I18n.m.getMessage('accountLogoutQuestionTitle');
      var content = _I18n.I18n.m.getMessage('accountLogoutQuestion');
      var buttons = [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
        title: _I18n.I18n.m.getMessage('abort'),
        onPress: (_Dialog$instance2 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance2.close,
        backgroundColor: "transparent",
        textColor: _ThemeManager.ThemeManager.style.brandPrimary
      }, "noButton"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
        title: _I18n.I18n.m.getMessage('accountLogout'),
        onPress: _this.onLogOutNow(true),
        backgroundColor: "transparent",
        textColor: _ThemeManager.ThemeManager.style.brandPrimary
      }, "yesButton")];
      var asyncNow = function () {
        var _ref2 = (0, _asyncToGenerator2.default)(function* () {
          var _Dialog$instance4;
          var getUploads = yield _Uploads.Uploads.getUploads();
          try {
            if (getUploads.length > 0) {
              var _Dialog$instance3;
              title = _I18n.I18n.m.getMessage('accountLogoutTitleUploads');
              content = `${_I18n.I18n.m.getMessage('accountLogoutQuestionUploads')} ${content}`;
              buttons = [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                title: _I18n.I18n.m.getMessage('accountLogoutQuestionUploadsDiscard'),
                onPress: function onPress() {
                  try {
                    _Uploads.Uploads.getUploads().then(function (getUploads) {
                      getUploads.forEach(function (u) {
                        return _Uploads.Uploads.cancelUpload(u.id);
                      });
                    }).catch(function (err) {});
                  } catch (e) {}
                  _this.onLogOutNow(true)();
                },
                backgroundColor: _ThemeManager.ThemeManager.style.brandDanger,
                textColor: "#FFFFFF"
              }, "noButton"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                title: _I18n.I18n.m.getMessage('accountLogoutQuestionUploadsWait'),
                onPress: (_Dialog$instance3 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance3.close,
                backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary,
                textColor: "#FFFFFF"
              }, "yesButton")];
            }
          } catch (e) {}
          (_Dialog$instance4 = _Dialog.Dialog.instance) == null || _Dialog$instance4.open({
            content: content,
            title: title,
            buttons: buttons
          });
        });
        return function asyncNow() {
          return _ref2.apply(this, arguments);
        };
      }();
      asyncNow().catch(function (err) {
        return void 0;
      });
    };
    _this.onLogOutNow = function (deleteDB) {
      return function (_e) {
        var _Dialog$instance5;
        (_Dialog$instance5 = _Dialog.Dialog.instance) == null || _Dialog$instance5.close(function () {
          _LoadingEvents.LoadingEvents.instance.startLoading();
          _AuthClient.AuthClient.instance.logOut(deleteDB, _Url.Url.getURLfromString('/login')).catch(function (err) {
            return void 0;
          });
        });
      };
    };
    _this.onChanged = function (e) {
      if (e.event === 'onPatched' || e.event === 'onPosted' || e.event === 'onDeleted') {
        _this.getSyncErrors().catch(function (err) {
          return void 0;
        });
      }
    };
    _this.renderSyncIcon = function () {
      var syncStatus = _this.state.syncStatus;
      switch (syncStatus.status) {
        case 'Online':
          return (0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: "",
            icon: "cloud-check-outline",
            color: _ThemeManager.ThemeManager.style.brandSecondary
          });
        case 'Error':
          return (0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: "",
            icon: "weather-cloudy-alert",
            color: "#FA2A1C"
          });
        case 'Syncing':
          return (0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: "",
            icon: "cloud-sync-outline",
            color: _ThemeManager.ThemeManager.style.brandPrimary
          });
        default:
          return (0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: "",
            icon: "cloud-off-outline",
            color: _ThemeManager.ThemeManager.style.black38
          });
      }
    };
    _this.updateSyncStatus = function () {
      _this.getSyncErrors().catch(function (err) {
        return void 0;
      });
    };
    _this.state = {
      syncStatus: UserImageSyncButton.getSyncStatus(0, 0, []),
      syncErrors: 0,
      syncPending: 0,
      tempfiles: []
    };
    return _this;
  }
  (0, _inherits2.default)(UserImageSyncButton, _PureComponent);
  return (0, _createClass2.default)(UserImageSyncButton, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.getSyncErrors().catch(function (err) {
        return void 0;
      });
      _AuthClient.AuthClient.instance.syncDispatcher.syncStatus.attach(this.updateSyncStatus);
      _AuthClient.AuthClient.instance.serverConnectionsChanged.attach(this.updateSyncStatus);
      if (_AuthClient.AuthClient.instance.offlineCommandRepo != null) {
        _AuthClient.AuthClient.instance.offlineCommandRepo.changeEvent.attach(this.onChanged);
      }
    }
  }, {
    key: "getSyncErrors",
    value: function () {
      var _getSyncErrors = (0, _asyncToGenerator2.default)(function* () {
        yield _WaitFor.WaitFor.instance.waitFor(function () {
          return _AuthClient.AuthClient.instance.ready;
        });
        yield _WaitFor.WaitFor.instance.waitFor(function () {
          return _CurrentUser.CurrentUser.userId != null && _CurrentUser.CurrentUser.userId.length > 0;
        });
        var tempfiles = [];
        var commands = [];
        var syncErrors = 0;
        var syncPending = 0;
        if (_Uploads.Uploads.localDb != null) {
          if (this.tempfileRepo == null) {
            this.tempfileRepo = _Uploads.Uploads.localDb.getRepos(new _TempFile.TempFile());
            this.tempfileRepo.changeEvent.attach(this.onChanged);
          }
          tempfiles = yield this.tempfileRepo.get({
            filter: 'targetUrl ne null'
          });
          if (_AuthClient.AuthClient.instance.offlineCommandRepo != null) commands = yield _AuthClient.AuthClient.instance.offlineCommandRepo.get({
            filter: 'synced ne true',
            orderby: 'createdAt ASC'
          });
          for (var c of commands) {
            if (c.error) {
              syncErrors += 1;
            } else {
              syncPending += 1;
            }
          }
        }
        this.setState({
          tempfiles: tempfiles,
          syncPending: syncPending,
          syncErrors: syncErrors,
          syncStatus: UserImageSyncButton.getSyncStatus(syncErrors, syncPending, tempfiles)
        });
      });
      function getSyncErrors() {
        return _getSyncErrors.apply(this, arguments);
      }
      return getSyncErrors;
    }()
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _AuthClient.AuthClient.instance.syncDispatcher.syncStatus.detach(this.updateSyncStatus);
      _AuthClient.AuthClient.instance.serverConnectionsChanged.detach(this.updateSyncStatus);
      if (_AuthClient.AuthClient.instance.offlineCommandRepo != null) {
        _AuthClient.AuthClient.instance.offlineCommandRepo.changeEvent.detach(this.onChanged);
      }
    }
  }, {
    key: "renderUserImage",
    value: function renderUserImage(size) {
      var user = this.context.user;
      if (user == null) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: size,
            height: size,
            borderRadius: size / 2,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 6,
            left: 6
          }
        }, "userImageRound");
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: size,
          height: size,
          borderRadius: size / 2,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 6,
          left: 6
        },
        children: (0, _jsxRuntime.jsx)(_UserImage.UserImage, {
          size: this.props.size,
          user: user
        }, `userImage_${user.id}`)
      }, `userImageRound_${user.id}`);
    }
  }, {
    key: "render",
    value: function render() {
      var size = this.props.size;
      var syncStatus = this.state.syncStatus;
      return (0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
        onPress: this.openMenu,
        backgroundColor: "rgba(255,255,255,0)",
        hoverColor: _ThemeManager.ThemeManager.style.getDefaultHoverColor(),
        accessibilityLabel: "Profile Image",
        style: {
          width: size + 12,
          height: size + 12,
          borderRadius: (size + 12) / 2,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: size + 4,
            height: size + 4,
            backgroundColor: syncStatus.color,
            borderRadius: (size + 4) / 2,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 4,
            left: 4
          }
        }, `syncstatus_${syncStatus.color}`), this.renderUserImage(size)]
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        size: 24
      };
    }
  }, {
    key: "getSyncStatus",
    value: function getSyncStatus(syncErrors, syncPending, tempfiles) {
      if (syncErrors > 0) {
        return {
          color: _ThemeManager.ThemeManager.style.brandDanger,
          status: 'Error',
          isSyncing: false,
          percent: 0
        };
      }
      var serverConnection = _AuthClient.AuthClient.instance.serverConnected();
      if (!serverConnection) {
        return {
          color: _ThemeManager.ThemeManager.style.brandWarning,
          status: 'Offline',
          textValues: {
            pending: syncPending
          },
          isSyncing: false,
          percent: 0
        };
      }
      if (_AuthClient.AuthClient.instance.syncDispatcher.syncRunning || tempfiles.length > 0) {
        return {
          color: _ThemeManager.ThemeManager.style.brandPrimary,
          status: 'Syncing',
          isSyncing: true,
          percent: _AuthClient.AuthClient.instance.syncDispatcher.currentSyncStatus.percent
        };
      }
      return {
        color: _ThemeManager.ThemeManager.style.brandSecondary,
        status: 'Online',
        isSyncing: false,
        percent: 0
      };
    }
  }]);
}(_react.PureComponent);
UserImageSyncButton.contextType = _userContext.default;