var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImageEditor = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _crossFetch = _interopRequireDefault(require("cross-fetch"));
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _CacheableImage = require("../images/CacheableImage");
var _TuiPhotoEditor = require("./TuiPhotoEditor");
var _a;
var ImageEditor = exports.ImageEditor = function () {
  function ImageEditor() {
    (0, _classCallCheck2.default)(this, ImageEditor);
  }
  return (0, _createClass2.default)(ImageEditor, null, [{
    key: "openImageEditor",
    value: function () {
      var _openImageEditor = (0, _asyncToGenerator2.default)(function* (image, originalfilename, saveFunction, auth) {
        var img = image;
        if (image.startsWith('http')) {
          if (_CacheableImage.CacheableImage.webcache[image] != null) {
            img = _CacheableImage.CacheableImage[image];
          } else if (auth) {
            var b = yield _a.loadBas64ImageWithHeader(image, auth);
            img = b;
          }
        }
        _TuiPhotoEditor.TuiPhotoEditor.openImageEditor(img, originalfilename, saveFunction);
      });
      function openImageEditor(_x, _x2, _x3, _x4) {
        return _openImageEditor.apply(this, arguments);
      }
      return openImageEditor;
    }()
  }]);
}();
_a = ImageEditor;
ImageEditor.loadBas64ImageWithHeader = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (source, auth) {
    var headers = {};
    if ((auth == null || auth) && _CurrentUser.CurrentUser.token != null && _CurrentUser.CurrentUser.token.length > 0) headers['authorization'] = `Bearer ${_CurrentUser.CurrentUser.token}`;
    var res = yield (0, _crossFetch.default)(source, {
      method: 'GET',
      headers: headers
    });
    var content = yield res.blob();
    var reader = new FileReader();
    return new Promise(function (resolve, reject) {
      reader.onload = function (r) {
        var t = r.currentTarget;
        if (t != null && t.result != null && (t.result.toString().substr(0, 10) === 'data:image' || t.result.toString().substr(0, 29) === 'data:application/octet-stream')) {
          resolve(t.result.toString());
        } else {
          reject(new Error('no result'));
        }
      };
      reader.onerror = function (e) {
        reject(e);
      };
      reader.readAsDataURL(content);
    });
  });
  return function (_x5, _x6) {
    return _ref.apply(this, arguments);
  };
}();