import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AssignTicketTo } from 'upmesh-core/src/client/commands/tickets/AssignTicketTo';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../../project/CurrentProject';
import { ProjectMemberDetailsPU } from '../../team/ProjectMemberDetailsPU';
export class AssigneeChip extends PureComponent {
    constructor(props) {
        super(props);
        this.checkDisabled = async () => {
            const { ticket } = this.props;
            const c = new AssignTicketTo({}, ticket.id);
            try {
                const canI = await c.canI();
                if (typeof canI === 'boolean' && canI) {
                    this.setState({ disabledChip: false });
                }
                else {
                    this.setState({ disabledChip: true });
                }
            }
            catch (e) {
                this.setState({ disabledChip: true });
            }
        };
        this.onChangeAssignedTeamMember = (_index) => {
            const { ticket } = this.props;
            if (ticket != null) {
                ticket.assignedToUserId = _index;
                const c = new AssignTicketTo({ assignedToUserId: ticket.assignedToUserId }, ticket.id);
                c.execute().catch((err) => {
                    this.props.errorResult(err);
                });
            }
        };
        this.openMemberDetails = async (_e) => {
            const { ticket } = this.props;
            if (ticket.assignedToUserId != null) {
                const projectUser = await UpmeshClient.instance.modals.projectUser.get({
                    filter: `projectId eq '${ticket.projectId}' and userId eq '${ticket.assignedToUserId}' and status eq 'accepted'`,
                });
                const projectUserId = projectUser.length === 0 ? `creator_${ticket.assignedToUserId}` : projectUser[0].id;
                DialogUp.instance?.open({
                    content: (<ProjectMemberDetailsPU projectId={ticket.projectId} data={{ projectUserId, userId: ticket.assignedToUserId }} closeFunction={DialogUp.instance?.close}/>),
                });
            }
        };
        this.state = { disabledChip: true };
    }
    componentDidMount() {
        this.checkDisabled().catch((err) => console.debug(err));
    }
    static get assignedTeamMemberList() {
        const chips = [];
        const team = CurrentProject.instance.getCurrentProjectTeam();
        for (let i = 0; i < team.length; i += 1) {
            chips.push({
                title: `${team[i].user.firstname} ${team[i].user.lastname}`,
                subtitle: team[i].user.company,
                onPressChipData: team[i].user.id,
                thumbnail: <UserImage size={24} user={team[i].user}/>,
            });
        }
        return chips;
    }
    render() {
        const { labelStyle, ticket } = this.props;
        const chipList = AssigneeChip.assignedTeamMemberList;
        let chipListSelected = -1;
        if (ticket.assignedToUserId != null && ticket.assignedToUserId.length > 0) {
            for (let i = 0; i < chipList.length; i += 1) {
                if (chipList[i].onPressChipData === ticket.assignedToUserId) {
                    chipListSelected = i;
                    break;
                }
            }
        }
        return [
            <View style={labelStyle} key="AssigneLabel">
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('ticketsDetailsTitleAssignedToUser')}
        </MaterialText>
      </View>,
            <OpenableChip key="AssigneChip" showDeleteIcon emptyStateChip={{
                    thumbnail: (<View style={{
                            borderStyle: 'dashed',
                            borderWidth: ThemeManager.style.borderWidth,
                            borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                            width: ThemeManager.style.getScreenRelativePixelSize(24),
                            height: ThemeManager.style.getScreenRelativePixelSize(24),
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                        }}>
              <Icon toolTip="" icon="help" iconSize={20} outerSize={ThemeManager.style.getScreenRelativePixelSize(24)} radius={0}/>
            </View>),
                    title: I18n.m.getMessage('ticketsDetailsTitleNotAssignedToUser'),
                    onPressChipData: '0',
                }} dialogTitle={I18n.m.getMessage('ticketsDetailsTitleAssignedToUser')} accessibilityLabel="assignee" elevation={40} selected={chipListSelected} onPressChip={this.onChangeAssignedTeamMember} widthOpen={256} textColor={ThemeManager.style.black87} chipsList={chipList} chipDisabled={this.state.disabledChip} onPressMainChip={ticket.assignedToUserId != null ? this.openMemberDetails : undefined}/>,
        ];
    }
}
