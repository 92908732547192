var _a;
import { AsyncEvent } from 'ts-events';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
export class CompanyUserInfo {
    static get companyMember() {
        return _a._companyMember;
    }
    static get me() {
        if (_a._me != null)
            return _a._me;
        if (CurrentUser.userId != null && CurrentUser.userId.length > 4) {
            const me = _a._companyMember.find((c) => c.userId === CurrentUser.userId);
            if (me != null) {
                _a._me = me;
                return me;
            }
        }
        return null;
    }
    static get companyMemberNames() {
        return _a._companyMemberNames;
    }
    static get company() {
        return _a._company;
    }
    static set company(c) {
        if ((c == null || c.id !== _a._lastId) && _a.companyListenerKey != null) {
            UpmeshClient.eventDispatcher.detach('Company', _a.companyListenerKey);
            if (_a.companyMemberListenerKey != null)
                UpmeshClient.eventDispatcher.detach('CompanyMember', _a.companyMemberListenerKey);
            if (_a.companySettingsListenerKey != null)
                UpmeshClient.eventDispatcher.detach('CompanySettings', _a.companySettingsListenerKey);
        }
        if (c != null && _a._lastId !== c.id) {
            _a._lastId = c.id;
            _a.companyListenerKey = UpmeshClient.eventDispatcher.attach({
                callback: (en) => {
                    const e = en.entities.get(c.id);
                    if (e != null) {
                        _a._company = e.entity;
                        _a.companyChanged.post(e.entity);
                    }
                },
                readModelName: 'Company',
                entityId: c.id,
            });
            _a.companySettingsListenerKey = UpmeshClient.eventDispatcher.attach({
                callback: (en) => {
                    const e = en.entities.get(c.id);
                    if (e != null) {
                        _a._companySettings = e.entity;
                        I18n.m.defaultMessagesOverride = e.entity.messagesOverride;
                        _a.companySettingsChanged.post(e.entity);
                    }
                },
                readModelName: 'CompanySettings',
                entityId: c.id,
            });
            _a.companyMemberListenerKey = UpmeshClient.eventDispatcher.attach({
                callback: (_e) => {
                    _a.updateCompanyMember().catch((err) => console.debug('cant update company member', err));
                },
                readModelName: 'CompanyMember',
            });
        }
        if (c == null) {
            _a._companySettings = null;
            I18n.m.defaultMessagesOverride = {};
            _a._companyMember = [];
            _a._companyMemberNames = [];
        }
        else {
            _a.updateCompanyMember().catch((err) => console.debug('cant update company member', err));
            _a.setCompanySettings().catch((err) => console.debug('cant update company settings', err));
        }
        _a._company = c;
    }
    static async getUserNameFromCompanyMember(c) {
        try {
            if (c.userId != null) {
                const user = await AuthClient.instance.modals.user.getById(c.userId);
                return user.getFullName();
            }
            return `${c.firstName} ${c.lastName}`;
        }
        catch (err) {
            console.debug('cant update company member', err);
        }
        return null;
    }
    static async updateCompanyMember() {
        try {
            const promises = [];
            const member = await UpmeshClient.instance.modals.companyMember.get({
                filter: `companyId eq '${_a._lastId}' and deleted ne true`,
            });
            if (member.length > 0 && this._company == null)
                await _a.setCompanyUserInfo();
            _a._companyMember = member;
            member.forEach((cm) => promises.push(_a.getUserNameFromCompanyMember(cm)));
            const usernames = await Promise.all(promises);
            _a._companyMemberNames = [];
            usernames.forEach((u) => {
                if (u != null)
                    _a._companyMemberNames.push(u);
            });
            _a._me = null;
        }
        catch (err) {
            console.debug('cant update company member', err);
        }
    }
    static async setCompanySettings() {
        try {
            _a._companySettings = await UpmeshClient.instance.modals.companySettings.getById(_a._lastId);
            I18n.m.defaultMessagesOverride = _a._companySettings?.messagesOverride;
        }
        catch (err) {
            console.debug('cant update company member', err);
        }
    }
    static get companySettings() {
        return _a._companySettings;
    }
}
_a = CompanyUserInfo;
CompanyUserInfo._company = null;
CompanyUserInfo._companySettings = null;
CompanyUserInfo._companyMember = [];
CompanyUserInfo._companyMemberNames = [];
CompanyUserInfo._lastId = '';
CompanyUserInfo.companyListenerKey = null;
CompanyUserInfo.companyMemberListenerKey = null;
CompanyUserInfo.companySettingsListenerKey = null;
CompanyUserInfo.companyChanged = new AsyncEvent();
CompanyUserInfo.companySettingsChanged = new AsyncEvent();
CompanyUserInfo.setCompanyUserInfo = async () => {
    try {
        const companyMember = await UpmeshClient.instance.modals.companyMember.get({
            filter: `userId eq '${CurrentUser.userId}' and deleted ne true`,
            top: 1,
        });
        if (companyMember.length > 0 && companyMember[0].role !== 'inactive') {
            _a.company = await UpmeshClient.instance.modals.company.getById(companyMember[0].companyId);
        }
        else {
            _a.company = null;
        }
    }
    catch (err) {
        console.debug('cant get company informations', err);
        _a.company = null;
    }
};
