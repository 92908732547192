import { VoiceInputIcon } from 'materialTheme/src/theme/components/audio/VoiceInputIcon';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialTextEditable } from 'materialTheme/src/theme/components/text/MaterialTextEditable';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { KiConnectorV2 } from 'upmesh-core/src/server/webserver/ki/KiConnectorV2';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { TicketReadView } from '../tickets/TicketReadView';
import { ChatView } from './ChatView';
import { CurrentProject } from './CurrentProject';
export function SpeechCommandDialog(props) {
    const defaultInputText = I18n.m.getMessage('kiActionHelper');
    const [inputText, setInputText] = useState(defaultInputText);
    const [chatHistory, setChatHistory] = useState([]);
    const addOrReplace = (changed) => {
        let found = false;
        for (let i = 0; i < chatHistory.length; i += 1) {
            const current = chatHistory[i];
            if (current.getId() === changed.getId()) {
                chatHistory[i] = changed;
                found = true;
                break;
            }
        }
        if (!found)
            chatHistory.push(changed);
        setChatHistory([...chatHistory]);
    };
    const sendVoiceCommand = async () => {
        if (inputText.length < 1)
            return;
        const headers = {};
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';
        headers['authorization'] = `Bearer ${CurrentUser.token}`;
        const url = `${UpmeshClient.instance.url}/textcommand/query`;
        const query = {
            timestamp: new Date().getTime(),
            input: inputText,
            userId: CurrentUser.userId,
            additionalData: {
                projectId: props.projectId,
            },
        };
        setChatHistory([...chatHistory, KiConnectorV2.fromQuery(query, '')]);
        setInputText(defaultInputText);
        try {
            const result = await fetch(url, { headers, method: 'POST', body: JSON.stringify(query) });
            if (result.status === 200) {
                const jsonResponse = await result.json();
                const resultConnector = KiConnectorV2.fromResponse(jsonResponse);
                addOrReplace(resultConnector);
            }
            else {
                const jsonResponse = await result.json();
                console.error('error on ki request', jsonResponse);
                DefaultErrorHandler.showDefaultErrorAlert(jsonResponse);
            }
        }
        catch (err) {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        }
    };
    const acceptButtonPressed = (kiConnector) => {
        kiConnector
            .executeResponse(CurrentUser.token)
            .then(() => {
            const path = `/projects/${CurrentProject.instance.getCurrentProjectId()}/tickets`;
            Toast.instance?.open({
                title: `${I18n.m.getMessage('kiActionCreateTicketSuccess')}`,
                onPressButton: () => {
                    Dialog.instance?.close();
                    Toast.instance?.close();
                    Routing.instance.goTo(path, true);
                },
                buttonTitle: I18n.m.getMessage('open'),
            });
        })
            .catch((err) => {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    };
    const chatMessages = () => {
        const result = [];
        for (let i = 0; i < chatHistory.length; i += 1) {
            const current = chatHistory[i];
            result.push({
                type: 'GenericMessageProp',
                timestamp: current.getTimestamp(),
                text: current.getQueryText(),
                icon: CurrentUser.entity,
                right: true,
            });
            if (current.getResponse() == null) {
                result.push({
                    type: 'GenericMessageProp',
                    timestamp: current.getTimestamp() + 1,
                    text: I18n.m.getMessage('kiActionLoading'),
                    icon: { icon: 'robot', toolTip: 'meshy' },
                    right: false,
                });
            }
            else {
                if (current.getAction() === 'CreateTicket') {
                    result.push({
                        type: 'CustomMessageProp',
                        timestamp: current.getTimestamp() + 1,
                        content: <TicketReadView ticket={current.getTicketDataFromResponse()}/>,
                        right: false,
                        icon: { icon: 'robot', toolTip: 'meshy' },
                    });
                    result.push({
                        type: 'CustomItemProp',
                        timestamp: current.getTimestamp() + 2,
                        content: (<ContainedButton title={I18n.m.getMessage('kiActionCreateTicket')} onPress={() => {
                                acceptButtonPressed(current);
                            }}/>),
                    });
                }
                if (current.getAction() === 'Nothing') {
                    result.push({
                        type: 'GenericMessageProp',
                        timestamp: current.getTimestamp() + 1,
                        text: I18n.m.getMessage('kiActionNotFound'),
                        icon: { icon: 'robot', toolTip: 'meshy' },
                        right: false,
                    });
                }
            }
        }
        return result;
    };
    const getChatHeight = () => {
        let whole = ResizeEvent.current.contentHeight;
        whole -= 36;
        whole -= 72;
        return whole;
    };
    const getWidth = () => {
        const { contentWidth } = ResizeEvent.current;
        if (contentWidth <= ThemeManager.style.breakpointM) {
            return contentWidth;
        }
        return 512;
    };
    useEffect(() => {
        if (Platform.OS === 'web') {
            const keyboardListener = (event) => {
                if (event.enterKey) {
                    sendVoiceCommand().catch((err) => console.error('cant send message', err));
                }
            };
            document.addEventListener('keydown', keyboardListener);
            return () => {
                document.removeEventListener('keydown', keyboardListener);
            };
        }
        return () => {
        };
    });
    return (<>
      <DialogTitle>{I18n.m.getMessage('kiDialogTitle')}</DialogTitle>
      <View style={{
            height: Math.min(getChatHeight(), 600),
            backgroundColor: 'rgba(230,230,230,0.5)',
            width: getWidth(),
        }}>
        <ChatView contentHeight={getChatHeight()} messages={chatMessages()}/>
      </View>
      <View style={{
            width: '100%',
            overflow: 'visible',
            maxHeight: '100%',
            paddingBottom: 0,
            minHeight: 24,
        }}>
        <View style={{ minHeight: 75, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <ScrollView style={{
            height: 'auto',
            maxHeight: Math.min(ResizeEvent.current.contentHeight / 2, 72),
        }}>
            <MaterialTextEditable materailTextProps={{ color: ThemeManager.style.black87 }} onFocus={() => {
            if (inputText === defaultInputText) {
                setInputText('');
            }
        }} onChange={setInputText}>
              {inputText}
            </MaterialTextEditable>
          </ScrollView>
          <Icon key="speech_command_send_button" icon="send" toolTip="sendTooltip" onPress={() => {
            sendVoiceCommand().catch((err) => console.error('cant send message', err));
        }}/>
          <VoiceInputIcon key="speech_command_voice_button" locale="de-DE" onRecognizedText={setInputText}/>
        </View>
      </View>
    </>);
}
