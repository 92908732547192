"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeGroupName = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var Config_1 = require("../../../Config");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var GroupNameChanged_1 = require("../../events/company/group/GroupNameChanged");
var ChangeGroupName = function (_CommandRoot_1$Comman) {
  function ChangeGroupName(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeGroupName);
    _this = _callSuper(this, ChangeGroupName, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeGroupName';
    return _this;
  }
  (0, _inherits2.default)(ChangeGroupName, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeGroupName, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        this.group = yield CommandReadModels_1.CommandReadModels.instance.group.getById(this.entityId);
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.group.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new GroupNameChanged_1.GroupNameChanged(this.entityId, {
          groupName: this.data.groupName
        }, this.group.companyId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var _this2 = this;
        var groups = yield CommandReadModels_1.CommandReadModels.group.get({
          filter: `deleted ne true and companyId eq '${this.group.companyId}'`
        });
        if (key == null || key === 'groupName') {
          if (this.data.groupName == null || this.data.groupName.length === 0) {
            throw {
              message: 'Gruppenname wird benötigt',
              messageCode: 'companyGroupRequired'
            };
          }
          if (groups.find(function (element) {
            return _this2.data.groupName === element.groupName;
          })) {
            throw {
              message: 'Gruppenname existiert bereits',
              messageCode: 'companyGroupAlreadyExists'
            };
          }
          if (this.data.groupName != null && this.data.groupName.length > Config_1.ConfigHandler.config.string_Medium) {
            throw {
              key: 'groupName',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeGroupName = ChangeGroupName;