"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateShareToken = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var ApplicationError_1 = require("odatarepos/build/src/entities/ApplicationError");
var Config_1 = require("../../../Config");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var ProjectEntity_1 = require("../../query/entities/ProjectEntity");
var CreateShareToken = function (_CommandRoot_1$Comman) {
  function CreateShareToken(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateShareToken);
    _this = _callSuper(this, CreateShareToken, [data, entityId, token]);
    _this.aggregate = 'upmeshUser';
    _this.waitForResult = true;
    _this.commandName = 'CreateShareToken';
    return _this;
  }
  (0, _inherits2.default)(CreateShareToken, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(CreateShareToken, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.data.projectId);
        yield ProjectEntity_1.ProjectEntity.checkProjectStatusAndGeneralAccess(project.id, userId);
        if (this.data.entityType === 'Folder') {
          yield CommandReadModels_1.CommandReadModels.instance.folder.getById(this.data.forEntityId);
          return true;
        }
        if (this.data.entityType === 'StoredFile') {
          yield CommandReadModels_1.CommandReadModels.instance.storedFile.getById(this.data.forEntityId);
          return true;
        }
        if (this.data.entityType === 'JournalProtocol') {
          yield CommandReadModels_1.CommandReadModels.instance.journalProtocol.getById(this.data.forEntityId);
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return undefined;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'expireDate') {
          if (new Date(this.data.expireDate).getTime() < new Date().getTime()) {
            throw new ApplicationError_1.ApplicationError('Ablaufdatum liegt vor dem aktuellem Datum', 'expireDateBeforeCurrentDate', ApplicationError_1.ApplicationErrorStatusCode.BadRequestError);
          }
        }
        if (key == null || key === 'name') {
          if (this.data.name.length > Config_1.ConfigHandler.config.string_Short) {
            throw {
              key: 'value',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.CreateShareToken = CreateShareToken;