import ConnectionContext from 'materialTheme/src/connectionContext';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import BackButton from 'materialTheme/src/views/components/BackButton';
import React, { PureComponent } from 'react';
import { FlatList, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { GlobalBar } from '../root/GlobalBar';
import { PageView } from '../root/PageView';
import { LogBookItem } from './LogBookItem';
export class LogBook extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.deep = 'ticket';
        this.loadStep = 15;
        this.isLoading = false;
        this.currentLogBook = [];
        this.onChanged = (en) => {
            const { ticketId, projectId, projectLogBook, isPreview } = this.props;
            const { logbook, skip } = this.state;
            let logBookOld = [...logbook];
            let logBookNew = [];
            en.entities.forEach((e) => {
                if (e.entity != null &&
                    ((ticketId != null && e.entity.entityType === 'Ticket' && e.entity.entityId === ticketId) ||
                        (projectId != null && projectId === e.entity.projectId) ||
                        (ticketId == null &&
                            projectId == null &&
                            e.entity.userIds.findIndex((obj) => obj.userId === CurrentUser.userId) > -1))) {
                    logBookNew = [e.entity];
                    if (projectLogBook || isPreview) {
                        if (logBookOld.length > 1)
                            logBookNew.push(logBookOld[0], logBookOld[1]);
                        else if (logBookOld.length > 0)
                            logBookNew.push(logBookOld[0]);
                    }
                    else {
                        logBookNew.push(...logBookOld);
                    }
                    logBookOld = [...logBookNew];
                }
            });
            if (logBookNew.length > 0)
                this.setState({ logbook: logBookNew, skip: skip + 1 });
        };
        this.getItemKey = (item) => {
            const { projectLogBook } = this.props;
            return `logbookEntry1_${item.id}_${projectLogBook}`;
        };
        this.renderRow = ({ item }) => {
            const { projectLogBook } = this.props;
            return (<LogBookItem key={`logbookEntry_${item.id}_${projectLogBook}`} logbookItem={item} textColor={this.props.textColor} deep={this.deep} withUserImage={this.deep !== 'ticket'} flat={this.props.flat}/>);
        };
        this.loadMore = () => {
            const { isPreview, projectLogBook } = this.props;
            if (!projectLogBook && !isPreview) {
                if (!this.isLoading) {
                    this.isLoading = true;
                    const asyncNow = async () => {
                        const { ticketId, projectId } = this.props;
                        const { skip, logbook } = this.state;
                        const top = this.loadStep;
                        const filter = ticketId != null
                            ? `entityType eq 'Ticket' and entityId eq '${ticketId}'`
                            : projectId != null
                                ? `projectId eq '${projectId}'`
                                : `userIds/userId eq '${CurrentUser.userId}'`;
                        const logbookNew = await UpmeshClient.instance.modals.logBook.get({
                            filter,
                            top,
                            skip: skip + this.loadStep,
                            orderby: 'createdAt DESC',
                        });
                        logbook.push(...logbookNew);
                        this.setState({ logbook, skip: skip + this.loadStep }, () => {
                            requestAnimationFrame(() => {
                                this.isLoading = false;
                            });
                        });
                    };
                    asyncNow().catch((err) => console.error(err));
                }
                else {
                    if (this.loadMoreTimeOut != null)
                        clearTimeout(this.loadMoreTimeOut);
                    this.loadMoreTimeOut = setTimeout(this.loadMore, 200);
                }
            }
        };
        this.onSearch = (text) => {
            this.setState({ searchWords: text });
        };
        if (props.projectId != null) {
            this.deep = 'project';
        }
        else if (props.ticketId == null) {
            this.deep = 'multiproject';
        }
        this.state = { logbook: [], skip: 0, searchWords: props.searchWord != null ? props.searchWord : '' };
    }
    componentDidMount() {
        this.loadEntries().catch((err) => console.debug(err));
        this.attachKey = UpmeshClient.eventDispatcher.attach({
            readModelName: 'LogBook',
            callback: this.onChanged,
        });
    }
    componentWillUnmount() {
        if (this.attachKey != null) {
            UpmeshClient.eventDispatcher.detach('LogBook', this.attachKey);
        }
    }
    async loadEntries() {
        if (!this.isLoading) {
            this.isLoading = true;
            const { ticketId, projectId, projectLogBook, isPreview, setTitle, hideWidgetProjectName } = this.props;
            const { skip } = this.state;
            const top = projectLogBook || isPreview ? 3 : this.loadStep;
            const filter = ticketId != null
                ? `entityType eq 'Ticket' and entityId eq '${ticketId}'`
                : projectId != null
                    ? `projectId eq '${projectId}'`
                    : `userIds/userId eq '${CurrentUser.userId}'`;
            const logbook = await UpmeshClient.instance.modals.logBook.get({
                filter,
                top,
                skip,
                orderby: 'createdAt DESC',
            });
            this.setState({ logbook }, () => {
                requestAnimationFrame(() => {
                    this.isLoading = false;
                });
            });
            if (setTitle && !hideWidgetProjectName) {
                const projectName = projectId
                    ? (await UpmeshClient.instance.modals.project.getById(projectId)).title
                    : undefined;
                setTitle(I18n.m.getMessage('activities') + (projectName ? ` (${projectName})` : ''));
            }
        }
    }
    renderList() {
        const { logbook } = this.state;
        if (!this.context.connectedToServer || logbook.length === 0)
            return (<MaterialText>
          {I18n.m.getMessage(this.context.connectedToServer ? 'noActivities' : 'noActivitiesOffline')}
        </MaterialText>);
        return (<FlatList key="logbook_all" keyExtractor={this.getItemKey} style={ThemeManager.style.absoluteStyle} data={logbook} renderItem={this.renderRow} onEndReached={this.loadMore} onEndReachedThreshold={0.1}/>);
    }
    render() {
        const { logbook } = this.state;
        const { height, isPreview, projectLogBook, moreActivitiesRoute, projectId } = this.props;
        if (logbook == null) {
            return <Spinner />;
        }
        if (projectLogBook || isPreview) {
            return (<View style={{ width: '100%', height, position: 'relative' }}>
          {logbook.length === 0 ? (<View key="noLogBookEntries" style={{ padding: 16, width: '100%' }}>
              <MaterialText>
                {I18n.m.getMessage(this.context.connectedToServer ? 'noActivities' : 'noActivitiesOffline')}
              </MaterialText>
            </View>) : (logbook.map((item) => this.renderRow({ item })))}
          {logbook.length < 3 ? null : (<View style={{
                        width: '100%',
                        borderTopColor: ThemeManager.style.borderColor,
                        borderTopWidth: ThemeManager.style.borderWidth,
                        position: 'absolute',
                        bottom: 0,
                        backgroundColor: '#FFFFFF',
                    }}>
              <ContainedButton full title={I18n.m.getMessage('logBookShowMoreActivities')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} onPress={Routing.instance.goToButton(projectLogBook ? `/projects/${this.props.projectId}/logbook` : moreActivitiesRoute)}/>
            </View>)}
        </View>);
        }
        if (projectId != null) {
            return (<View style={{
                    height: height != null ? height : '100%',
                    width: '100%',
                    position: 'relative',
                    backgroundColor: 'transparent',
                    overflow: 'hidden',
                }}>
          {this.renderList()}
        </View>);
        }
        const sDisplay = !(ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM);
        return (<View style={{
                width: '100%',
                backgroundColor: 'transparent',
                overflow: 'hidden',
                position: 'absolute',
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
            }}>
        {!sDisplay ? <GlobalBar user={CurrentUser.entity} size={ResizeEvent.current} site="activities"/> : undefined}
        <PageView style={{
                backgroundColor: 'transparent',
                position: 'absolute',
                top: sDisplay ? 0 : ThemeManager.style.headerHeight,
                bottom: 0,
                right: 0,
                left: 0,
            }} headerProps={{
                withElevation: false,
                withBorder: false,
                textColor: '#FFFFFF',
                title: I18n.m.getMessage('logBook'),
                backgroundColor: 'transparent',
                leftButtons: sDisplay
                    ? [<BackButton color="#FFFFFF" onAllPlatforms key="backButtonActivities"/>]
                    : undefined,
            }} showAccountIcon={sDisplay} scrollable={false}>
          <View style={{
                height: height != null ? height : '100%',
                width: '100%',
                position: 'relative',
            }}>
            {this.renderList()}
          </View>
        </PageView>
      </View>);
    }
}
LogBook.contextType = ConnectionContext;
