var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BrightSkyMapper = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _crossFetch = _interopRequireDefault(require("cross-fetch"));
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
var _WeatherDaily = require("../WeatherDaily");
var _WeatherForLocation = require("../WeatherForLocation");
var _WeatherHourly = require("../WeatherHourly");
var BrightSkyMapper = exports.BrightSkyMapper = function () {
  function BrightSkyMapper() {
    (0, _classCallCheck2.default)(this, BrightSkyMapper);
    this.isLoading = false;
    this.nextDays = 7;
    this.loadFromCache = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (latitude, longitude) {
        try {
          var g = _SimpleStorage.SimpleStorage.get(`brightsky_${latitude.toString()}_${longitude.toString()}`);
          if (g != null) {
            var data = JSON.parse(g);
            var c = new Date(data.saved);
            c.setHours(c.getHours() + 4);
            return {
              data: data,
              old: !(c.getTime() > Date.now())
            };
          }
        } catch (e) {}
        return null;
      });
      return function (_x, _x2) {
        return _ref.apply(this, arguments);
      };
    }();
    this.saveCache = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (latitude, longitude, w) {
        try {
          var d = JSON.stringify(Object.assign({}, w, {
            saved: new Date()
          }));
          _SimpleStorage.SimpleStorage.set(`brightsky_${latitude.toString()}_${longitude.toString()}`, d);
        } catch (e) {}
      });
      return function (_x3, _x4, _x5) {
        return _ref2.apply(this, arguments);
      };
    }();
    if (BrightSkyMapper._instance != null) {}
  }
  return (0, _createClass2.default)(BrightSkyMapper, [{
    key: "getDataForLocationAndDay",
    value: function () {
      var _getDataForLocationAndDay = (0, _asyncToGenerator2.default)(function* (latitude, longitude, day) {
        try {
          var url = `https://api.brightsky.dev/weather?lat=${latitude.toString()}&lon=${longitude.toString()}&date=${day}&tz=Europe/Berlin`;
          var r = yield (0, _crossFetch.default)(url);
          var text = yield r.text();
          this.isLoading = false;
          if (r.status === 200) {
            var json = JSON.parse(text);
            try {
              var data = this.mapDayLocationData(json['0'] != null && json['0']['data'] != null ? json['0']['data'] : json);
              return data;
            } catch (e) {
              return null;
            }
          }
        } catch (e) {}
        return null;
      });
      function getDataForLocationAndDay(_x6, _x7, _x8) {
        return _getDataForLocationAndDay.apply(this, arguments);
      }
      return getDataForLocationAndDay;
    }()
  }, {
    key: "mapDayLocationData",
    value: function mapDayLocationData(data) {
      var w = new _WeatherDaily.WeatherDay();
      w.fill(data);
      return w;
    }
  }, {
    key: "arrayAvg",
    value: function arrayAvg(myArray) {
      var i = 0;
      var summ = 0;
      var ArrayLen = myArray.length;
      while (i < ArrayLen) {
        summ += myArray[i++];
      }
      return summ / ArrayLen;
    }
  }, {
    key: "getDataForLocation",
    value: function () {
      var _getDataForLocation = (0, _asyncToGenerator2.default)(function* (latitude, longitude) {
        var cached = yield this.loadFromCache(latitude, longitude);
        if (cached != null && !cached.old) return cached.data;
        if (!this.isLoading) {
          this.isLoading = true;
          try {
            var d = new Date();
            var day = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0).toISOString();
            var lastDay = new Date(d.getFullYear(), d.getMonth(), d.getDate() + this.nextDays, 0, 0, 0, 0).toISOString();
            var url = `https://api.brightsky.dev/weather?lat=${latitude.toString()}&lon=${longitude.toString()}&date=${day}&last_date=${lastDay}`;
            var r = yield (0, _crossFetch.default)(url);
            var text = yield r.text();
            this.isLoading = false;
            if (r.status === 200) {
              var json = JSON.parse(text);
              var w = new _WeatherForLocation.WeatherForLocation();
              w.latitude = latitude;
              w.longitude = longitude;
              w.timezone = 'Europe/Berlin';
              w.hourly = new _WeatherHourly.WeatherHourly();
              w.hourly.data = [];
              w.daily = new _WeatherDaily.WeatherDaily();
              w.daily.data = [];
              var _lastDay = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
              var wDay = null;
              var dTemperatures = [];
              var dHumidity = [];
              var icons = [];
              var totalRain = 0;
              if (json['weather'] != null && json['weather'].length > 0) {
                for (var h of json['weather']) {
                  var _d = new Date(h.timestamp);
                  var currentDay = `${_d.getFullYear()}-${_d.getMonth() + 1}-${_d.getDate()}`;
                  var data = new _WeatherHourly.WeatherHourlyData();
                  data.fill(h);
                  if (h.icon != null) data.icon = this.mapIcon(h.icon);
                  data.time = new Date(h.timestamp).getTime();
                  if (h.temperature != null) dTemperatures.push(h.temperature);
                  if (h.relative_humidity) dHumidity.push(h.relative_humidity);
                  if (h.icon != null) {
                    if (_d.getHours() >= 7 && _d.getHours() <= 16) {
                      icons.push(h.icon);
                      icons.push(h.icon);
                    }
                    icons.push(h.icon);
                  }
                  data.pressure = w.hourly.data.push(data);
                  data.windSpeed = h.wind_speed;
                  data.precipProbability = h.precipitation;
                  data.humidity = h.relative_humidity ? h.relative_humidity / 100 : dHumidity.length > 0 ? dHumidity[dHumidity.length - 1] / 100 : null;
                  if (h.precipitation != null) totalRain += h.precipitation;
                  if (currentDay !== _lastDay) {
                    wDay = new _WeatherDaily.WeatherDay();
                    var lDay = _lastDay.split('-');
                    wDay.time = new Date(Number.parseInt(lDay[0], 10), Number.parseInt(lDay[1], 10) - 1, Number.parseInt(lDay[2], 10)).getTime();
                    wDay.fill(h);
                    wDay.cloudCover = h.cloud_cover;
                    wDay.temperatureLow = Math.min.apply(Math, (0, _toConsumableArray2.default)(dTemperatures));
                    wDay.temperatureHigh = Math.max.apply(Math, (0, _toConsumableArray2.default)(dTemperatures));
                    wDay.pressure = h.pressure_msl;
                    wDay.windSpeed = h.wind_speed;
                    wDay.humidity = dHumidity.length > 0 ? this.arrayAvg(dHumidity) / 100 : null;
                    wDay.icon = this.mapIcons(icons);
                    wDay.precipProbability = totalRain;
                    w.daily.data.push(wDay);
                    totalRain = 0;
                    _lastDay = currentDay;
                    dHumidity = [];
                    dTemperatures = [];
                    icons = [];
                  }
                }
              }
              yield this.saveCache(latitude, longitude, w);
              return w;
            }
          } catch (e) {
            this.isLoading = false;
          }
        }
        return cached != null ? cached.data : null;
      });
      function getDataForLocation(_x9, _x10) {
        return _getDataForLocation.apply(this, arguments);
      }
      return getDataForLocation;
    }()
  }, {
    key: "mapIcons",
    value: function mapIcons(icons) {
      var dayAndNightRemoved = [];
      icons.forEach(function (p) {
        dayAndNightRemoved.push(p.replace('-day', '').replace('-night', '').replace('-', ''));
      });
      if (dayAndNightRemoved.includes('rain')) return 'rain';
      if (dayAndNightRemoved.includes('snow')) return 'snow';
      if (dayAndNightRemoved.includes('sleet')) return 'sleet';
      if (dayAndNightRemoved.includes('fog')) return 'fog';
      if (dayAndNightRemoved.includes('wind')) return 'wind';
      if (dayAndNightRemoved.includes('wind')) return 'wind';
      var hits = [];
      var _loop = function _loop(d) {
        var found = hits.findIndex(function (p) {
          return p.id === d;
        });
        var count = d.indexOf('partly') > -1 ? 2 : 1;
        if (found >= 0) {
          hits[found] = Object.assign({}, hits[found], {
            counted: hits[found].counted + count
          });
        } else {
          hits.push({
            id: d,
            counted: count
          });
        }
      };
      for (var d of dayAndNightRemoved) {
        _loop(d);
      }
      hits.sort(function (a, b) {
        return a.counted > b.counted ? -1 : 1;
      });
      if (hits.length >= 2) {
        if (hits[0].id === 'clear' || hits[0].id === 'cloudy') {
          return 'partlycloudyday';
        }
      }
      var r = hits[0].id;
      return r.indexOf('partly') > -1 ? `${r}day` : r;
    }
  }, {
    key: "mapIcon",
    value: function mapIcon(icon) {
      if (icon === 'clear-day') return 'clearday';
      if (icon === 'clear-night') return 'clearnight';
      if (icon === 'fog') return 'fog';
      if (icon === 'hail') return 'hail';
      if (icon === 'rain') return 'rain';
      if (icon === 'partly-cloudy-day') return 'partlycloudyday';
      if (icon === 'snow') return 'snow';
      if (icon === 'sleet') return 'sleet';
      if (icon === 'wind') return 'wind';
      if (icon === 'partly-cloudy-night') return 'partlycloudynight';
      if (icon === 'thunderstorm') return 'thunderstorm';
      return 'cloudy';
    }
  }], [{
    key: "instance",
    get: function get() {
      if (BrightSkyMapper._instance == null) {
        BrightSkyMapper._instance = new BrightSkyMapper();
      }
      return BrightSkyMapper._instance;
    }
  }]);
}();