import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { BackHandler, View } from 'react-native';
import { ProjectFilter } from 'upmesh-core/src/client/query/filter/ProjectFilter';
import { I18n } from '../../i18n/I18n';
import { ProjectsFilter } from './ProjectsFilter';
const styles = {
    headerContainer: {
        position: 'relative',
        width: '100%',
        overflow: 'visible',
    },
    headerCloseIcon: {
        position: 'absolute',
        right: -4,
        top: 8,
    },
    spacer: { height: 6 },
    chipContainer: { flexDirection: 'row' },
    checkboxListItemContainer: { marginLeft: -4 },
};
const ProjectsDialogFilter = React.memo((props) => {
    const [amountSelected, setAmountSelected] = useState(0);
    const [calculateAmount, setCalculateAmount] = useState(true);
    const [currentFilter, setCurrentFilter] = useState(props.currentFilter);
    const [resetCounter, setResetCounter] = useState(0);
    const backHandler = useRef();
    useEffect(() => {
        try {
            const filtered = ProjectFilter.filterProjects(props.allProjects, currentFilter);
            setCalculateAmount(false);
            setAmountSelected(filtered.length);
        }
        catch (err) {
            setCalculateAmount(false);
            setAmountSelected(0);
        }
    }, [props.allProjects, currentFilter]);
    useEffect(() => {
        backHandler.current = BackHandler.addEventListener('hardwareBackPress', () => {
            Dialog.instance?.close();
            return true;
        });
    }, []);
    const onSelect = () => {
        Dialog.instance?.close(() => {
            Routing.instance.changeQueryParameter({
                f: JSON.stringify(currentFilter),
            });
        });
    };
    return (<>
        <DialogContent key="projectFilterContent">
          <View style={styles.headerContainer} key="projectsFilterHeader">
            <DialogTitle inContent>{I18n.m.getMessage('projectsFilter')}</DialogTitle>
            <View style={styles.headerCloseIcon} key="projectsFilterHeaderClose">
              <Icon toolTip={I18n.m.getMessage('close')} icon="close" onPress={Dialog.instance?.close}/>
            </View>
          </View>
          <ProjectsFilter key={`projectFilter_${resetCounter}`} allProjects={props.allProjects} currentFilter={currentFilter} onChanged={setCurrentFilter}/>
        </DialogContent>
        <View key="errorText" style={{ alignSelf: 'flex-end', padding: 0, paddingRight: 16 }}>
          <MaterialText textPadding={0} type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
            {amountSelected === 0 ? I18n.m.getMessage('noProjectsFound') : ''}
          </MaterialText>
        </View>
        <DialogActions key="projectFilterActions">
          <ContainedButton title={I18n.m.getMessage('reset')} onPress={() => {
            setCurrentFilter(new ProjectFilter());
            setResetCounter(resetCounter + 1);
        }} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          <ContainedButton disabled={calculateAmount || (amountSelected === 0 && props.allProjects.length > 0)} title={`${I18n.m.getMessage('apply')} ${amountSelected}/${props.allProjects.length}`} onPress={onSelect}/>
        </DialogActions>
      </>);
});
export const openProjectsFilterDialog = (allProjects, currentFilter) => {
    if (Dialog.instance != null) {
        const content = <ProjectsDialogFilter allProjects={allProjects} currentFilter={currentFilter}/>;
        Dialog.instance?.open({
            content,
            contentPadding: false,
            closeOnTouchOutside: false,
        });
    }
};
