import * as _ from 'lodash';
import { AddressCard } from 'materialTheme/src/theme/components/addressCard/AddressCard';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { ScrollView } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { AddressEntity } from 'upmesh-auth-core/src/client/query/entities/AddressEntity';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ChangeProjectAddress } from 'upmesh-core/src/client/commands/projectaddress/ChangeProjectAddress';
import { CreateProjectAddress } from 'upmesh-core/src/client/commands/projectaddress/CreateProjectAddress';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { ConfigAll } from '../../../config/ConfigAll';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CurrentProject } from '../CurrentProject';
export class ProjectAddressItem extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.saveConstructionAddress = (newAddress) => {
            const { type } = this.props;
            const { project } = this.state;
            if (project == null)
                return;
            const asyncNow = async () => {
                try {
                    const { address } = this.state;
                    if (address != null && !_.isEqual(newAddress, address.address)) {
                        const c = new ChangeProjectAddress({ address: newAddress }, address.id);
                        await c.execute();
                    }
                    else {
                        const c = new CreateProjectAddress({
                            type,
                            address: newAddress,
                            projectId: project.id,
                        });
                        await c.execute();
                    }
                }
                catch (e) {
                    ErrorReporter.sendReport({ subject: 'Cant update construction address', data: e, type: 'warn' });
                    Routing.instance.alert.post({ text: e.message });
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.state = { isLoading: true, project: CurrentProject.instance.getCurrentProject(), canEdit: false };
    }
    componentDidMount() {
        this.mounted = true;
        this.attachKey = UpmeshClient.eventDispatcher.attach({
            readModelName: 'ProjectAddress',
            callback: (_e) => {
                this.init().catch((err) => console.debug(err));
            },
        });
        this.init().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        this.mounted = false;
        if (this.attachKey != null) {
            UpmeshClient.eventDispatcher.detach('ProjectAddress', this.attachKey);
        }
    }
    async init() {
        const { type, addressId, addAcs } = this.props;
        const { project } = this.state;
        if (project == null) {
            return;
        }
        const nameAC = [];
        const phoneAC = [];
        const emailAC = [];
        if (addAcs) {
            const projects = await UpmeshClient.instance.modals.projectAddress.get({
                filter: 'deleted ne true and archived ne true',
            });
            projects.forEach((project) => {
                if (project.address) {
                    const { address } = project;
                    if (address.email && !emailAC.includes(address.email))
                        emailAC.push(address.email);
                    if (address.contactName && !nameAC.includes(address.contactName))
                        nameAC.push(address.contactName);
                    if (address.phone && !phoneAC.includes(address.phone))
                        phoneAC.push(address.phone);
                }
            });
        }
        const id = addressId ? ` and id eq '${addressId}'` : '';
        const address = await UpmeshClient.instance.modals.projectAddress.get({
            filter: `projectId eq '${project.id}' and deleted ne true  and type eq '${type}'${id}`,
        });
        let canEdit = await RightsManager.hasWriteRight(project.id, CurrentUser.userId, 'commandChangeProject');
        const catched = await DefaultErrorHandler.getProjectErrors(project.id);
        if (catched)
            canEdit = false;
        if (address.length > 0) {
            this.setState({ isLoading: false, address: address[0], emailAC, nameAC, phoneAC, canEdit });
        }
        else {
            this.setState({ isLoading: false, emailAC, nameAC, phoneAC, canEdit });
        }
    }
    render() {
        const { address, project, canEdit } = this.state;
        if (project == null) {
            return null;
        }
        const { isLoading, nameAC, phoneAC, emailAC } = this.state;
        const { type, hideEdit } = this.props;
        return (<ScrollView style={{ width: '100%', height: '100%' }} nestedScrollEnabled>
        {isLoading ? (<Spinner />) : (<AddressCard editable={canEdit} hideEdit={hideEdit} key={`${address != null ? address.id : 'new'}_${nameAC}_${phoneAC}_${emailAC}`} type={type === 'contact'
                    ? I18n.m.getMessage('projectContactLabelWidget')
                    : I18n.m.getMessage('projectContactLabel')} address={address != null && address.address != null ? new AddressEntity(address.address) : undefined} onChangeAddress={this.saveConstructionAddress} backendURL={`${ConfigAll.b2cURL}/backend/googleapis`} nameAC={nameAC} phoneAC={phoneAC} emailAC={emailAC}/>)}
      </ScrollView>);
    }
}
