"use strict";
(function (global, undefined) {
    if (global.setImmediate) {
        return;
    }
    let nextHandle = 1;
    const tasksByHandle = {};
    let currentlyRunningATask = false;
    const doc = global.document;
    let registerImmediate;
    function setImmediate(callback) {
        if (typeof callback !== 'function') {
            callback = new Function(`${callback}`);
        }
        const args = new Array(arguments.length - 1);
        for (let i = 0; i < args.length; i++) {
            args[i] = arguments[i + 1];
        }
        const task = { callback, args };
        tasksByHandle[nextHandle] = task;
        registerImmediate(nextHandle);
        return nextHandle++;
    }
    function clearImmediate(handle) {
        delete tasksByHandle[handle];
    }
    function run(task) {
        const { callback } = task;
        const { args } = task;
        switch (args.length) {
            case 0:
                callback();
                break;
            case 1:
                callback(args[0]);
                break;
            case 2:
                callback(args[0], args[1]);
                break;
            case 3:
                callback(args[0], args[1], args[2]);
                break;
            default:
                callback.apply(undefined, args);
                break;
        }
    }
    function runIfPresent(handle) {
        if (currentlyRunningATask) {
            setTimeout(runIfPresent, 0, handle);
        }
        else {
            const task = tasksByHandle[handle];
            if (task) {
                currentlyRunningATask = true;
                try {
                    run(task);
                }
                finally {
                    clearImmediate(handle);
                    currentlyRunningATask = false;
                }
            }
        }
    }
    function installNextTickImplementation() {
        registerImmediate = function (handle) {
            process.nextTick(function () {
                runIfPresent(handle);
            });
        };
    }
    function canUsePostMessage() {
        if (global.postMessage && !global.importScripts) {
            let postMessageIsAsynchronous = true;
            const oldOnMessage = global.onmessage;
            global.onmessage = function () {
                postMessageIsAsynchronous = false;
            };
            global.postMessage('', '*');
            global.onmessage = oldOnMessage;
            return postMessageIsAsynchronous;
        }
    }
    function installPostMessageImplementation() {
        const messagePrefix = `setImmediate$${Math.random()}$`;
        const onGlobalMessage = function (event) {
            if (event.source === global && typeof event.data === 'string' && event.data.indexOf(messagePrefix) === 0) {
                runIfPresent(+event.data.slice(messagePrefix.length));
            }
        };
        if (global.addEventListener) {
            global.addEventListener('message', onGlobalMessage, false);
        }
        else {
            global.attachEvent('onmessage', onGlobalMessage);
        }
        registerImmediate = function (handle) {
            global.postMessage(messagePrefix + handle, '*');
        };
    }
    function installMessageChannelImplementation() {
        const channel = new MessageChannel();
        channel.port1.onmessage = function (event) {
            const handle = event.data;
            runIfPresent(handle);
        };
        registerImmediate = function (handle) {
            channel.port2.postMessage(handle);
        };
    }
    function installReadyStateChangeImplementation() {
        const html = doc.documentElement;
        registerImmediate = function (handle) {
            let script = doc.createElement('script');
            script.onreadystatechange = function () {
                runIfPresent(handle);
                script.onreadystatechange = null;
                html.removeChild(script);
                script = null;
            };
            html.appendChild(script);
        };
    }
    function installSetTimeoutImplementation() {
        registerImmediate = function (handle) {
            setTimeout(runIfPresent, 0, handle);
        };
    }
    let attachTo = Object.getPrototypeOf && Object.getPrototypeOf(global);
    attachTo = attachTo && attachTo.setTimeout ? attachTo : global;
    if ({}.toString.call(global.process) === '[object process]') {
        installNextTickImplementation();
    }
    else if (canUsePostMessage()) {
        installPostMessageImplementation();
    }
    else if (global.MessageChannel) {
        installMessageChannelImplementation();
    }
    else if (doc && 'onreadystatechange' in doc.createElement('script')) {
        installReadyStateChangeImplementation();
    }
    else {
        installSetTimeoutImplementation();
    }
    attachTo.setImmediate = setImmediate;
    attachTo.clearImmediate = clearImmediate;
})(typeof self === 'undefined' ? (typeof global === 'undefined' ? this : global) : self);
