var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OpenableChip = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _DialogUp = require("../DialogUp");
var _FormInputFilled = require("../forminput/FormInputFilled");
var _Icon = require("../Icon");
var _ListItem = require("../ListItem");
var _MenuRaw = require("../MenuRaw");
var _Measurement = require("../utils/Measurement");
var _Chip = require("./Chip");
var _ChipDialogForm = require("./ChipDialogForm");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var OpenableChip = exports.OpenableChip = function (_PureComponent) {
  function OpenableChip(props) {
    var _this;
    (0, _classCallCheck2.default)(this, OpenableChip);
    _this = _callSuper(this, OpenableChip, [props]);
    _this.maxMenuLength = 5;
    _this.openedDialog = false;
    _this.chipsListToMenuElements = function () {
      var _this$props = _this.props,
        chipsList = _this$props.chipsList,
        selected = _this$props.selected,
        segmentedChipsList = _this$props.segmentedChipsList;
      var menuElements = [];
      var segmentedMenuElements;
      if (chipsList == null && segmentedChipsList == null) {
        return;
      }
      var selectedHeight = 0;
      if (segmentedChipsList != null) {
        segmentedMenuElements = [];
        var _loop = function _loop() {
          var items = [];
          segmentedChipsList[i].chips.forEach(function (chip, index) {
            return items.push(_this.chipToMenuElement(chip, index));
          });
          segmentedMenuElements.push(Object.assign({}, segmentedChipsList[i], {
            items: items
          }));
        };
        for (var i = 0; i < segmentedChipsList.length; i += 1) {
          _loop();
        }
      } else if (chipsList != null) {
        for (var _i = 0; _i < chipsList.length; _i += 1) {
          menuElements.push(_this.chipToMenuElement(chipsList[_i], _i));
          if (selected != null && _i <= selected) {
            selectedHeight += _ListItem.ListItem.getHeight(menuElements[_i]);
          }
        }
      }
      _this.setState({
        selectedHeight: selectedHeight,
        menuElements: menuElements,
        segmentedMenuElements: segmentedMenuElements
      });
    };
    _this.closeChip = function () {
      var _this$state = _this.state,
        menuElements = _this$state.menuElements,
        segmentedMenuElements = _this$state.segmentedMenuElements;
      if (menuElements.length > _this.maxMenuLength || segmentedMenuElements && segmentedMenuElements.length > 0) {
        var _DialogUp$instance;
        _this.openedDialog = false;
        (_DialogUp$instance = _DialogUp.DialogUp.instance) == null || _DialogUp$instance.close();
      } else {
        var _MenuRaw$instance;
        (_MenuRaw$instance = _MenuRaw.MenuRaw.instance) == null || _MenuRaw$instance.close();
      }
    };
    _this.onPressButton = function (index) {
      return function (_e) {
        var _this$props2 = _this.props,
          chipsList = _this$props2.chipsList,
          onPressButton = _this$props2.onPressButton,
          button = _this$props2.button,
          buttonDisabled = _this$props2.buttonDisabled;
        var chip = chipsList != null && index > chipsList.length ? chipsList[index] : undefined;
        _this.closeChip();
        if (chip == null) {
          return;
        }
        var iconRight = chip.button != null || button != null && chip.button != null ? 'close-circle' : undefined;
        var onPressThisButton = function onPressThisButton(_data) {};
        if (iconRight === 'close-circle' && (chip.buttonDisabled !== true || chip.buttonDisabled && buttonDisabled)) {
          if (chip.onPressButton != null && typeof chip.onPressButton === 'function') {
            onPressThisButton = chip.onPressButton;
          } else if (onPressButton != null) {
            onPressThisButton = onPressButton;
          }
        }
        onPressThisButton(_e);
      };
    };
    _this.onPressChip = function (index, oldSelected) {
      return function (_e) {
        var _this$props3 = _this.props,
          chipsList = _this$props3.chipsList,
          onPressChip = _this$props3.onPressChip,
          segmentedChipsList = _this$props3.segmentedChipsList,
          emptyStateChip = _this$props3.emptyStateChip;
        var _this$state2 = _this.state,
          menuElements = _this$state2.menuElements,
          segmentedMenuElements = _this$state2.segmentedMenuElements,
          activeSegment = _this$state2.activeSegment,
          selectedSegment = _this$state2.selectedSegment;
        var chip = chipsList != null && index >= 0 ? chipsList[index] : emptyStateChip || {
          title: _I18n.I18n.m.getMessage('noSelection'),
          onPressChipData: ''
        };
        if (index >= 0) {
          if (segmentedChipsList != null && segmentedChipsList[activeSegment].chips != null && segmentedMenuElements != null) {
            chip = segmentedChipsList[activeSegment].chips[index];
            segmentedMenuElements[activeSegment].items[index].selected = true;
            if (oldSelected != null && segmentedMenuElements[selectedSegment].items[oldSelected] != null) segmentedMenuElements[selectedSegment].items[oldSelected].selected = false;
          } else {
            menuElements[index].selected = true;
            if (oldSelected && oldSelected >= 0) {
              menuElements[oldSelected].selected = false;
            }
          }
        }
        _this.closeChip();
        if (chip != null) {
          var onPressThisChip = function onPressThisChip(_data) {};
          if (chip.chipDisabled !== true) {
            if (chip.onPressChip !== undefined) {
              onPressThisChip = chip.onPressChip;
            } else if (onPressChip != null) {
              onPressThisChip = onPressChip;
            }
          }
          _this.setState({
            menuElements: menuElements,
            segmentedMenuElements: segmentedMenuElements,
            selectedSegment: activeSegment
          }, function () {});
          var e = chip.onPressChipData != null ? chip.onPressChipData : index;
          onPressThisChip(e);
        }
      };
    };
    _this.openChip = function (_e) {
      if (!_this.chip) {
        setTimeout(function () {
          return _this.openChip;
        }, 12);
      }
      var _this$state3 = _this.state,
        menuElements = _this$state3.menuElements,
        segmentedMenuElements = _this$state3.segmentedMenuElements;
      if (segmentedMenuElements && segmentedMenuElements.length > 0 || menuElements.length > _this.maxMenuLength) {
        _this.openDialog();
      } else {
        _this.openMenu().catch(function (err) {
          return void 0;
        });
      }
    };
    _this.openDialog = function () {
      var _DialogUp$instance2;
      var _this$props4 = _this.props,
        dialogIcon = _this$props4.dialogIcon,
        dialogTitle = _this$props4.dialogTitle,
        selected = _this$props4.selected,
        disableAutoSort = _this$props4.disableAutoSort;
      var _this$state4 = _this.state,
        menuElements = _this$state4.menuElements,
        segmentedMenuElements = _this$state4.segmentedMenuElements,
        activeSegment = _this$state4.activeSegment;
      _this.openedDialog = true;
      (_DialogUp$instance2 = _DialogUp.DialogUp.instance) == null || _DialogUp$instance2.open({
        content: (0, _jsxRuntime.jsx)(_ChipDialogForm.ChipDialogForm, {
          disableAutoSort: disableAutoSort,
          items: menuElements,
          icon: dialogIcon,
          title: dialogTitle,
          onCancel: _this.closeDialog,
          onSave: _this.closeDialog,
          singleSelectSelected: selected,
          segmentedLists: segmentedMenuElements,
          onChangedSegment: _this.onChangedSegment,
          activeSegment: activeSegment
        }),
        contentPadding: false,
        fullscreenResponsive: true
      });
    };
    _this.onChangedSegment = function (index) {
      _this.setState({
        activeSegment: index
      });
    };
    _this.closeDialog = function () {
      var _DialogUp$instance3;
      _this.openedDialog = false;
      (_DialogUp$instance3 = _DialogUp.DialogUp.instance) == null || _DialogUp$instance3.close();
    };
    _this.openMenu = (0, _asyncToGenerator2.default)(function* () {
      var _MenuRaw$instance2;
      var s = yield _Measurement.Measurement.measure(_this.chip);
      var y = s.pageY > _ThemeManager.ThemeManager.style.statusBarHeight ? s.pageY : s.y;
      if (_this.props.looksLikeFI) y -= 16;
      var client = {
        y: y,
        x: s.pageX,
        height: s.height,
        width: _this.props.widthOpen != null ? _this.props.widthOpen : 256
      };
      var elevation = _this.props.elevation;
      var menuElements = _this.state.menuElements;
      (_MenuRaw$instance2 = _MenuRaw.MenuRaw.instance) == null || _MenuRaw$instance2.open({
        client: client,
        elevation: elevation,
        items: menuElements
      });
    });
    _this.setRef = function (r) {
      _this.chip = r;
    };
    _this.clear = function (e) {
      var selected = _this.props.selected;
      _this.onPressChip(-1, selected)(e);
    };
    _this.state = {
      chipsSearchList: props.chipsList,
      segmentedMenuElements: [],
      selectedHeight: 0,
      menuElements: [],
      activeSegment: props.selectedSegment != null && props.selectedSegment > 0 ? props.selectedSegment : 0,
      selectedSegment: props.selectedSegment != null && props.selectedSegment > 0 ? props.selectedSegment : 0
    };
    return _this;
  }
  (0, _inherits2.default)(OpenableChip, _PureComponent);
  return (0, _createClass2.default)(OpenableChip, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.chipsListToMenuElements();
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      var _MenuRaw$instance3, _DialogUp$instance4;
      (_MenuRaw$instance3 = _MenuRaw.MenuRaw.instance) == null || _MenuRaw$instance3.close();
      if (this.openedDialog) (_DialogUp$instance4 = _DialogUp.DialogUp.instance) == null || _DialogUp$instance4.close();
    }
  }, {
    key: "chipToMenuElement",
    value: function chipToMenuElement(chip, index) {
      var _this$props5 = this.props,
        buttonColor = _this$props5.buttonColor,
        textColor = _this$props5.textColor,
        button = _this$props5.button,
        selected = _this$props5.selected;
      var iconRight = chip.button || button && chip.button ? {
        icon: 'close-circle',
        onPress: this.onPressButton(index),
        color: chip.removeIconColor != null ? chip.removeIconColor : buttonColor
      } : undefined;
      return {
        iconRight: iconRight,
        title: chip.title != null ? chip.title : '',
        secondTextLine: chip.subtitle != null ? chip.subtitle : '',
        backgroundColor: '#FFFFFF',
        textColor: chip.textColor != null ? chip.textColor : textColor,
        onPress: chip.chipDisabled ? undefined : this.onPressChip(index, selected),
        thumbnail: chip.thumbnail != null ? {
          rounded: true,
          thumbnail: chip.thumbnail,
          width: 24
        } : undefined,
        paddingMidToLeft: 12,
        selected: index === selected
      };
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, _prevState, _snapshot) {
      var chipsList = this.props.chipsList;
      if (chipsList && prevProps.chipsList) {
        var hasChanges = false;
        var i = 0;
        do {
          if (chipsList[i] !== prevProps.chipsList[i]) hasChanges = true;
          i += 1;
        } while (i < chipsList.length - 1 && !hasChanges);
        if (hasChanges) this.chipsListToMenuElements();
      }
    }
  }, {
    key: "onLayout",
    value: function onLayout() {}
  }, {
    key: "renderChip",
    value: function renderChip() {
      var _this$props6 = this.props,
        chipsList = _this$props6.chipsList,
        selected = _this$props6.selected,
        textColor = _this$props6.textColor,
        buttonColor = _this$props6.buttonColor,
        backgroundColor = _this$props6.backgroundColor,
        hoverColor = _this$props6.hoverColor,
        chipDisabled = _this$props6.chipDisabled,
        accessibilityLabel = _this$props6.accessibilityLabel,
        onPressMainChip = _this$props6.onPressMainChip,
        segmentedChipsList = _this$props6.segmentedChipsList,
        thumbOnlyOnList = _this$props6.thumbOnlyOnList,
        emptyStateChip = _this$props6.emptyStateChip,
        showDeleteIcon = _this$props6.showDeleteIcon;
      var selectedSegment = this.state.selectedSegment;
      var chip = chipsList != null && selected != null ? chipsList[selected] : undefined;
      if (segmentedChipsList != null && selected != null) chip = segmentedChipsList[selectedSegment].chips[selected];
      if (chip === undefined) {
        chip = emptyStateChip || {
          title: _I18n.I18n.m.getMessage('noSelection'),
          onPressChipData: ''
        };
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          flexDirection: 'row',
          alignItems: 'center'
        },
        children: [(0, _jsxRuntime.jsx)(_Chip.Chip, {
          accessibilityLabel: accessibilityLabel,
          title: chip.title,
          backgroundColor: chip.backgroundColor != null ? chip.backgroundColor : backgroundColor,
          textColor: chip.textColor != null ? chip.textColor : textColor,
          onPressChip: onPressMainChip != null ? onPressMainChip : this.openChip,
          onPressButton: this.openChip,
          hoverColor: chip.hoverColor !== undefined ? chip.hoverColor : hoverColor,
          removeIcon: "menu-down",
          button: !chipDisabled,
          removeIconColor: buttonColor != null ? buttonColor : textColor,
          chipDisabled: chipDisabled && !onPressMainChip,
          thumbnail: thumbOnlyOnList ? undefined : chip.thumbnail,
          removeIconTooltip: _I18n.I18n.m.getMessage('open')
        }), !chipDisabled && showDeleteIcon === true && selected != null && selected >= 0 ? (0, _jsxRuntime.jsx)(_Icon.Icon, {
          toolTip: _I18n.I18n.m.getMessage('clear'),
          icon: "close-circle",
          onPress: this.clear
        }) : null]
      });
    }
  }, {
    key: "renderFI",
    value: function renderFI() {
      var _this$props7 = this.props,
        chipsList = _this$props7.chipsList,
        selected = _this$props7.selected,
        formInputHelperText = _this$props7.formInputHelperText,
        formInputLabel = _this$props7.formInputLabel,
        segmentedChipsList = _this$props7.segmentedChipsList,
        formInputError = _this$props7.formInputError;
      var selectedSegment = this.state.selectedSegment;
      var chip = chipsList != null && selected != null ? chipsList[selected] : undefined;
      if (segmentedChipsList != null && selected != null) chip = segmentedChipsList[selectedSegment].chips[selected];
      return (0, _jsxRuntime.jsx)(_FormInputFilled.FormInputFilled, {
        value: chip ? chip.title : '',
        clearButton: false,
        overrideOnPressFocus: this.openChip,
        error: formInputError === true,
        labelText: formInputLabel,
        iconRight: {
          icon: 'menu-down',
          toolTip: '',
          onPress: this.openChip
        },
        helperText: formInputHelperText
      });
    }
  }, {
    key: "render",
    value: function render() {
      var looksLikeFI = this.props.looksLikeFI;
      if (looksLikeFI) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          ref: this.setRef,
          onLayout: this.onLayout,
          children: this.renderFI()
        });
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        ref: this.setRef,
        onLayout: this.onLayout,
        style: {
          alignSelf: 'flex-start'
        },
        children: this.renderChip()
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        button: false,
        buttonDisabled: false,
        chipDisabled: false,
        widthOpen: 400,
        backgroundColor: _Chip.Chip.defaultProps.backgroundColor,
        textColor: _ThemeManager.ThemeManager.style.black87,
        buttonColor: _ThemeManager.ThemeManager.style.black42,
        onPressButton: undefined,
        onPressChip: undefined,
        selected: 0,
        elevation: 8,
        looksLikeFI: false
      };
    }
  }]);
}(_react.PureComponent);