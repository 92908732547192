import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { DateRangeInput } from 'materialTheme/src/theme/components/forminput/DateRangeInput';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import NumberPicker from 'materialTheme/src/theme/components/NumberPicker';
import { ProgressBar } from 'materialTheme/src/theme/components/ProgressBar';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { FlatList, Platform, View } from 'react-native';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { AddTicketWatcher } from 'upmesh-core/src/client/commands/tickets/AddTicketWatcher';
import { ArchiveTicket } from 'upmesh-core/src/client/commands/tickets/ArchiveTicket';
import { AssignTicketTo } from 'upmesh-core/src/client/commands/tickets/AssignTicketTo';
import { ChangeTicketApprover } from 'upmesh-core/src/client/commands/tickets/ChangeTicketApprover';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { DeleteTicket } from 'upmesh-core/src/client/commands/tickets/DeleteTicket';
import { RestoreTicket } from 'upmesh-core/src/client/commands/tickets/RestoreTicket';
import { UnArchiveTicket } from 'upmesh-core/src/client/commands/tickets/UnArchiveTicket';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../../project/CurrentProject';
export class BulkChangeDialogContent extends PureComponent {
    constructor(props) {
        super(props);
        this.renderConfirmationQuestion = () => {
            const { selectedIDs } = this.props;
            return (<View>
        <View style={{ padding: ThemeManager.style.contentPaddingValue, paddingBottom: 32 }}>
          <MaterialText type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('bulkChangesConfirmationDialogHeader')}
          </MaterialText>
          <MaterialText>
            {I18n.m.getMessage('bulkChangesConfirmationDialogText', { count: selectedIDs.size })}
          </MaterialText>
        </View>
        <DialogActions>
          <ContainedButton title={I18n.m.getMessage('back')} onPress={() => this.setState({ page: 'selection' })} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          <ContainedButton title={I18n.m.getMessage('apply')} onPress={this.select()}/>
        </DialogActions>
      </View>);
        };
        this.abort = false;
        this.cancelMulti = () => {
            this.abort = true;
            this.closeDialog();
        };
        this.renderProgressBar = () => {
            const { selectedIDs } = this.props;
            const { ticketsToBeChanged } = this.state;
            const maxTickets = ticketsToBeChanged != null ? ticketsToBeChanged : selectedIDs.size;
            return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
        <MaterialText>
          {I18n.m.getMessage('bulkChangesChangingTickets', {
                    count: this.state.changeProgress,
                    sum: maxTickets,
                })}
        </MaterialText>
        <View style={{ height: ThemeManager.style.getScreenRelativePixelSize(16) }}/>
        <ProgressBar progressInPercent={(this.state.changeProgress / maxTickets) * 100}/>
        <View style={{ height: ThemeManager.style.getScreenRelativePixelSize(16) }}/>
        <DialogActions>
          <ContainedButton title={I18n.m.getMessage('cancel')} onPress={() => this.cancelMulti()} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        </DialogActions>
      </View>);
        };
        this.archiveTickets = () => {
            const { onChange, errorDialog } = this.props;
            const selectedTickets = this.getSelectedTickets();
            let errorCount = 0;
            const asyncNow = async () => {
                if (selectedTickets.length != null) {
                    for (let i = 0; i < selectedTickets.length; i += 1) {
                        if (this.abort) {
                            Dialog.instance?.close();
                        }
                        else {
                            this.setState({ page: 'progress', changeProgress: i + 1 });
                            const t = selectedTickets[i];
                            try {
                                if (t.archived === true) {
                                    const c = new UnArchiveTicket({}, t.id);
                                    await c.execute();
                                }
                                else {
                                    const c = new ArchiveTicket({}, t.id);
                                    await c.execute();
                                }
                            }
                            catch (e) {
                                errorCount += 1;
                            }
                        }
                    }
                }
                Dialog.instance?.close();
                if (errorCount > 0)
                    errorDialog(errorCount);
                onChange();
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.deleteTickets = () => {
            const { onChange, errorDialog } = this.props;
            const selectedTickets = this.getSelectedTickets();
            let errorCount = 0;
            const asyncNow = async () => {
                if (selectedTickets.length != null) {
                    for (let i = 0; i < selectedTickets.length; i += 1) {
                        if (this.abort) {
                            Dialog.instance?.close();
                        }
                        else {
                            this.setState({ page: 'progress', changeProgress: i + 1 });
                            const t = selectedTickets[i];
                            try {
                                if (t.deleted === true) {
                                    const c = new RestoreTicket({}, t.id);
                                    await c.execute();
                                }
                                else {
                                    const c = new DeleteTicket({}, t.id);
                                    await c.execute();
                                }
                            }
                            catch (e) {
                                errorCount += 1;
                            }
                        }
                    }
                }
                Dialog.instance?.close();
                if (errorCount > 0)
                    errorDialog(errorCount);
                onChange();
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.closeDialog = () => {
            setTimeout(() => {
                Dialog.instance?.close();
            }, 1000);
        };
        this.getSelectedTickets = () => {
            const { tickets, selectedIDs } = this.props;
            const selectedTickets = [];
            tickets.forEach((t) => {
                if (selectedIDs.has(t.id))
                    selectedTickets.push(t);
            });
            return selectedTickets;
        };
        this.renderArchiveDialog = () => (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
      <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('archiveTicketsDialogHeadline')}</MaterialText>
      <MaterialText>{I18n.m.getMessage('archiveTicketsQuestion')}</MaterialText>
      <View style={{ alignSelf: 'flex-end', flexDirection: 'row', paddingTop: ThemeManager.style.contentPaddingValue }}>
        <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton title={I18n.m.getMessage('apply')} onPress={this.archiveTickets}/>
      </View>
    </View>);
        this.renderDeleteDialog = () => (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
      <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('deleteTicketsDialogHeadline')}</MaterialText>
      <MaterialText>{I18n.m.getMessage('deleteTicketsQuestion')}</MaterialText>
      <View style={{ alignSelf: 'flex-end', flexDirection: 'row', paddingTop: ThemeManager.style.contentPaddingValue }}>
        <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton title={I18n.m.getMessage('apply')} onPress={this.deleteTickets}/>
      </View>
    </View>);
        this.renderMember = (member) => {
            const { user } = member.item;
            const { selectedMember } = this.state;
            if (user.id === '0') {
                return (<ListItem key={`memebr0${selectedMember === '0'}`} backgroundColor={selectedMember === '0' ? ThemeManager.style.chipDefaultBg : '#FFFFFF'} title={I18n.m.getMessage('bulkChangesNotAssignedToUser')} thumbnail={{
                        thumbnail: (<View style={{
                                borderStyle: 'dashed',
                                borderWidth: ThemeManager.style.borderWidth,
                                borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                                width: ThemeManager.style.getScreenRelativePixelSize(24),
                                height: ThemeManager.style.getScreenRelativePixelSize(24),
                                alignItems: 'center',
                                alignContent: 'center',
                                justifyContent: 'center',
                            }}>
                <Icon toolTip="" icon="help" iconSize={20} outerSize={ThemeManager.style.getScreenRelativePixelSize(24)} radius={0}/>
              </View>),
                        width: 24,
                        rounded: true,
                    }} onPress={() => this.setState({ selectedMember: '0' })}/>);
            }
            return (<ListItem key={`member${user.id}_${selectedMember === user.id}`} backgroundColor={selectedMember === user.id ? ThemeManager.style.chipDefaultBg : '#FFFFFF'} title={user.getFullName()} secondTextLine={user.company} thumbnail={{ thumbnail: <UserImage size={24} user={user}/>, width: 24, rounded: true }} onPress={() => this.setState({ selectedMember: user.id })}/>);
        };
        this.select = () => (_e) => {
            const { changedTopic } = this.props;
            const { selectedMember, selected, selectedList } = this.state;
            this.setState({ page: 'progress', changeProgress: 0 });
            if (selectedMember != null) {
                if (changedTopic === 'assignee') {
                    this.setNewAssignee(selectedMember).catch((err) => console.debug(err));
                }
                else if (changedTopic === 'approver') {
                    this.setNewApprover(selectedMember).catch((err) => console.debug(err));
                }
                else if (changedTopic === 'watcher') {
                    this.setNewWatcher(selectedMember).catch((err) => console.debug(err));
                }
                else if (typeof changedTopic !== 'string') {
                    this.setCustomField(selectedMember, changedTopic).catch((err) => console.debug(err));
                }
            }
            else if (typeof changedTopic !== 'string') {
                const listTypes = ['CheckboxList', 'multiperson', 'MultiselectList'];
                const listSelected = changedTopic != null &&
                    changedTopic.customField != null &&
                    listTypes.indexOf(changedTopic.customField.type) > -1;
                if (listSelected)
                    this.setCustomList(selectedList, changedTopic).catch((err) => console.debug(err));
                else
                    this.setCustomField(selected, changedTopic).catch((err) => console.debug(err));
            }
        };
        this.setCustomList = async (selectedList, layout) => {
            const { onChange, errorDialog } = this.props;
            const selectedTickets = this.getSelectedTickets();
            let errorCount = 0;
            if (selectedTickets != null && selectedList != null) {
                const types = layout.onTypes;
                let filteredTickets = [];
                if (types != null && types.length > 0)
                    filteredTickets = selectedTickets.filter((t) => t.type != null && types.indexOf(t.type) > -1);
                if (filteredTickets != null && filteredTickets.length > 0) {
                    this.setState({ ticketsToBeChanged: filteredTickets.length });
                    const newList = [];
                    if (selectedList.length > 0) {
                        const idNotTitle = layout.customField != null && layout.customField.type === 'multiperson';
                        for (const s of selectedList) {
                            const item = idNotTitle ? s.id : s.title;
                            if (item != null && s.selected === true)
                                newList.push(item);
                        }
                        for (let i = 0; i < filteredTickets.length; i += 1) {
                            const ticket = filteredTickets[i];
                            if (this.abort) {
                                Dialog.instance?.close();
                            }
                            else {
                                this.setState({ changeProgress: i + 1 });
                                try {
                                    const c = new ChangeTicketCustomField({ fieldId: layout.id, value: newList }, ticket.id);
                                    await c.execute();
                                }
                                catch (e) {
                                    errorCount += 1;
                                }
                            }
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
            this.setState({ ticketsToBeChanged: undefined });
        };
        this.setCustomField = async (selected, layout) => {
            const { onChange, errorDialog } = this.props;
            const selectedTickets = this.getSelectedTickets();
            let errorCount = 0;
            const types = layout.onTypes;
            let filteredTickets = [];
            if (types != null)
                filteredTickets = selectedTickets.filter((t) => t.type != null && types.indexOf(t.type) > -1);
            if (filteredTickets.length > 0) {
                this.setState({ ticketsToBeChanged: filteredTickets.length });
                if (types != null && types.length > 0) {
                    for (let i = 0; i < filteredTickets.length; i += 1) {
                        const ticket = filteredTickets[i];
                        if (this.abort) {
                            Dialog.instance?.close();
                        }
                        else {
                            this.setState({ changeProgress: i + 1 });
                            try {
                                const c = new ChangeTicketCustomField({ fieldId: layout.id, value: selected }, ticket.id);
                                await c.execute();
                            }
                            catch (e) {
                                errorCount += 1;
                            }
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
            this.setState({ ticketsToBeChanged: undefined });
        };
        this.setNewApprover = async (userID) => {
            const { onChange, errorDialog } = this.props;
            let errorCount = 0;
            const selectedTickets = this.getSelectedTickets();
            if (selectedTickets != null) {
                for (let i = 0; i < selectedTickets.length; i += 1) {
                    if (this.abort) {
                        Dialog.instance?.close();
                    }
                    else {
                        this.setState({ changeProgress: i + 1 });
                        const ticket = selectedTickets[i];
                        if (!(userID === '0' && ticket.approverUserId == null) && ticket.approverUserId !== userID) {
                            try {
                                const c = new ChangeTicketApprover({ approverUserId: userID }, ticket.id);
                                await c.execute();
                            }
                            catch (e) {
                                errorCount += 1;
                            }
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        this.setNewAssignee = async (userID) => {
            const { onChange, errorDialog } = this.props;
            let errorCount = 0;
            const selectedTickets = this.getSelectedTickets();
            if (selectedTickets != null) {
                for (let i = 0; i < selectedTickets.length; i += 1) {
                    if (this.abort) {
                        Dialog.instance?.close();
                    }
                    else {
                        this.setState({ changeProgress: i + 1 });
                        const ticket = selectedTickets[i];
                        if (!(userID === '0' && ticket.assignedToUserId == null) && ticket.assignedToUserId !== userID) {
                            try {
                                const c = new AssignTicketTo({ assignedToUserId: userID }, ticket.id);
                                await c.execute();
                            }
                            catch (e) {
                                errorCount += 1;
                            }
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        this.setNewWatcher = async (userID) => {
            const { onChange, errorDialog } = this.props;
            let errorCount = 0;
            const selectedTickets = this.getSelectedTickets();
            for (let i = 0; i < selectedTickets.length; i += 1) {
                if (this.abort) {
                    Dialog.instance?.close();
                }
                else {
                    this.setState({ changeProgress: i + 1 });
                    const ticket = selectedTickets[i];
                    if (ticket.watchers == null)
                        ticket.watchers = [];
                    if (ticket.watchers.findIndex((j) => j.userId === userID) === -1) {
                        try {
                            const c = new AddTicketWatcher({ userId: userID }, ticket.id);
                            await c.execute();
                        }
                        catch (err) {
                            errorCount += 1;
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        const team = CurrentProject.instance.getCurrentProjectTeam();
        const no = { user: new UserEntity({ id: '0' }), role: 'no' };
        const memberList = props.changedTopic !== 'watcher' ? [no] : [];
        team.forEach((member) => {
            memberList.push(member);
        });
        this.state = {
            memberList,
            selectedMember: undefined,
            changeProgress: 0,
            page: 'selection',
        };
    }
    render() {
        const { changedTopic } = this.props;
        const { page } = this.state;
        if (page === 'selection') {
            if (changedTopic === 'assignee' || changedTopic === 'approver' || changedTopic === 'watcher') {
                return this.renderUserList();
            }
            if (changedTopic === 'archive')
                return this.renderArchiveDialog();
            if (changedTopic === 'delete')
                return this.renderDeleteDialog();
            if (typeof changedTopic !== 'string' && changedTopic.customField !== undefined) {
                switch (changedTopic.customField.type) {
                    case 'person':
                        return this.renderUserList();
                    case 'string':
                        return this.renderSingleFields();
                    case 'number':
                        return this.renderSingleFields();
                    case 'Date':
                        return this.renderSingleFields();
                    case 'DateRange':
                        return this.renderSingleFields();
                    case 'multiperson':
                        return this.renderList();
                    case 'CheckboxList':
                        return this.renderList();
                    case 'MultiselectList':
                        return this.renderList();
                    case 'List':
                        return this.renderList();
                    default:
                        return <View />;
                }
            }
        }
        else if (page === 'confirmation')
            return this.renderConfirmationQuestion();
        else if (page === 'progress')
            return this.renderProgressBar();
        return <View />;
    }
    componentDidMount() {
        if (Platform.OS === 'web') {
            window.onbeforeunload = () => I18n.m.getMessage('webBeforeUnloadHint');
        }
    }
    componentWillUnmount() {
        if (Platform.OS === 'web') {
            window.onbeforeunload = null;
        }
    }
    renderSingleFields() {
        const { changedTopic } = this.props;
        if (typeof changedTopic === 'string' || changedTopic.customField == null)
            return <View />;
        let field = <View />;
        let selected;
        if (changedTopic.customField.type === 'string') {
            const options = changedTopic.customField.options;
            field = (<FormInputFilled labelText={I18n.m.getMessage('bulkChangesNewValue')} numberOfLines={options == null || options.multiline == null || options.multiline === false ? 1 : 5} onChange={(t) => {
                    selected = t;
                }}/>);
        }
        else if (changedTopic.customField.type === 'number') {
            const options = changedTopic.customField.options;
            field = (<NumberPicker formatTime={false} title={I18n.m.getMessage('bulkChangesNewValue')} min={options.min} max={options.max} initValue={options.default} onChange={(v) => {
                    selected = v;
                }}/>);
        }
        else if (changedTopic.customField.type === 'Date') {
            const options = changedTopic.customField.options;
            field = (<DateInput labelText={changedTopic.label} selectTime={options.withTime === true} onChange={(date) => {
                    selected = { date };
                }}/>);
        }
        else if (changedTopic.customField.type === 'DateRange') {
            const options = changedTopic.customField.options;
            field = (<DateRangeInput labelText={changedTopic.label} selectTime={options.withTime === true} onChange={(d) => {
                    selected = d;
                }}/>);
        }
        return (<View>
        <DialogTitle>{changedTopic.label}</DialogTitle>
        <DialogContent dialogHasTitle>
          <MaterialText>{changedTopic.description}</MaterialText>
          {field}
          <DialogActions onBottom>
            <ContainedButton title={I18n.m.getMessage('abort')} onPress={() => {
                this.setState({ selected: undefined });
                Dialog.instance?.close();
            }} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
            <ContainedButton title={I18n.m.getMessage('apply')} onPress={() => this.setState({ page: 'confirmation', selected })}/>
          </DialogActions>
        </DialogContent>
      </View>);
    }
    renderList() {
        const { changedTopic } = this.props;
        if (typeof changedTopic === 'string' ||
            changedTopic.customField == null ||
            (changedTopic.customField.type !== 'List' &&
                changedTopic.customField.type !== 'CheckboxList' &&
                changedTopic.customField.type !== 'multiperson' &&
                changedTopic.customField.type !== 'MultiselectList')) {
            return <View />;
        }
        const items = [];
        const multiselect = changedTopic.customField.type !== 'List';
        if (changedTopic.customField.type === 'multiperson') {
            const { memberList } = this.state;
            const member = memberList.filter((m) => m.user.id !== '0');
            for (const m of member) {
                items.push({ title: m.user.getFullName(), id: m.user.id });
            }
        }
        else if (changedTopic.customField.type === 'List') {
            const { list } = changedTopic.customField.options;
            if (list && list.length > 0) {
                list.forEach((i) => {
                    items.push({
                        title: i,
                        onPress: () => {
                            this.setState({ selected: i, page: 'confirmation' });
                        },
                    });
                });
            }
        }
        else {
            const { list } = changedTopic.customField.options;
            if (list && list.length > 0) {
                list.forEach((i) => {
                    items.push({ title: i });
                });
            }
        }
        return (<ChipDialogForm title={changedTopic.label} items={items} multiselect={multiselect} onSave={(items) => {
                this.setState({ page: 'confirmation', selectedList: items });
            }} onCancel={() => {
                this.setState({ selectedList: undefined });
                Dialog.instance?.close();
            }}/>);
    }
    renderUserList() {
        const { changedTopic } = this.props;
        const { memberList, selectedMember } = this.state;
        let header = 'Test';
        if (changedTopic === 'assignee')
            header = I18n.m.getMessage('bulkChangesChangeAssignee');
        if (changedTopic === 'approver')
            header = I18n.m.getMessage('bulkChangesChangeApprover');
        if (changedTopic === 'watcher')
            header = I18n.m.getMessage('bulkChangesAddWatcher');
        if (typeof changedTopic !== 'string') {
            header = changedTopic.label;
        }
        return (<>
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>
          {memberList != null && memberList.length > 0 ? (<FlatList key={`memberList_${selectedMember}`} data={memberList} renderItem={this.renderMember}/>) : null}
        </DialogContent>
        <DialogActions>
          <ContainedButton title={I18n.m.getMessage('abort')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          <ContainedButton disabled={selectedMember == null} title={I18n.m.getMessage('apply')} onPress={selectedMember != null ? () => this.setState({ page: 'confirmation', changeProgress: 0 }) : undefined}/>
        </DialogActions>
      </>);
    }
}
