import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { ServerConnection } from 'cqrs-core/src/core/ServerConnection';
import { Url } from 'cqrs-shared/src/uri/Url';
import { WaitFor } from 'cqrs-shared/src/WaitFor';
import { DBleadermanager } from 'materialTheme/src/odataDB/loki/DBleadermanager';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { AsyncEvent } from 'ts-events';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import * as uuid from 'uuid';
import { Config } from './Config';
import { ConfigAll } from './ConfigAll';
import { SharedWorkerWeb } from './SharedWorkerWeb';
export class Init {
    static get connectionStatusChanged() {
        if (Init._connectionStatusChanged == null)
            Init._connectionStatusChanged = new AsyncEvent();
        return Init._connectionStatusChanged;
    }
    static get connectionStatus() {
        return true;
    }
    static getServerConnection(token) {
        if (Init._serverConnection != null)
            return Init._serverConnection;
        const clientversion = Config.getVersion().split('@')[1];
        Init._serverConnection = new ServerConnection({
            remoteOptions: { apiUrl: Config.b2cURL, authToken: token },
            autoConnect: false,
            query: { clientversion },
        });
        return Init._serverConnection;
    }
    static async requestPermissions() {
        return true;
    }
    static get clientId() {
        if (Init._clientId == null)
            Init._clientId = uuid.v1();
        return Init._clientId;
    }
    static get hasWorker() {
        return Init.myWorker != null;
    }
    static async initChannelHandling() {
        console.log('init shared worker');
        try {
            throw new Error('Not supported test');
            Init.myWorker = new SharedWorker(new URL('./SharedWorkerWeb.js', import.meta.url), { name: `upmeshDB_${ConfigAll.getVersionCode()}` });
            Init.myWorker.onerror = (err) => {
                console.error('SharedWorker Error', err);
            };
            SharedWorkerWeb.port = Init.myWorker.port;
            await new Promise((resolve) => {
                Init.myWorker.port.onmessage = (e) => {
                    console.log('MessageReceived', e.data);
                    if (e.data.startsWith('storageGetµallData')) {
                        SimpleStorage.getAllKeys()
                            .then((keys) => {
                            const value = {};
                            for (const key of keys) {
                                try {
                                    value[key] = SimpleStorage.get(key);
                                }
                                catch (error) {
                                    console.debug('simple storage error', { key, error });
                                }
                            }
                            console.log('MessageReceived SEND');
                            if (Config.getEnvironment() === 'development')
                                Init.myWorker.port.postMessage(`logsOnClient`);
                            Init.myWorker.port.postMessage(`storageGotµallDataµ${JSON.stringify(value)}`);
                        })
                            .catch((err) => console.error(err));
                    }
                    else if (e.data.startsWith('startedWorker')) {
                        resolve();
                    }
                };
                Init.myWorker.port.start();
            });
            Init.myWorker.port.onmessage = (e) => {
                SharedWorkerWeb.instance.toClientMessage(e.data);
            };
            console.log('inited shared worker');
        }
        catch (e) {
            console.debug('cant init SharedWorker, goBack to default', e);
            Init.myWorker = null;
            return Init.initDefaultChannelHandling();
        }
        return true;
    }
    static async onLeaderMessage(msg) {
        console.log('onLeaderMessage', msg);
    }
    static async onClientMessage(msg) {
        console.log('onClientMessage', msg);
    }
    static async initDefaultChannelHandling() {
        if (Init.channel == null) {
            console.log('initDefaultChannelHandling');
            Init.channel = new BroadcastChannel('upmeshRunner');
            Init.elector = createLeaderElection(Init.channel);
            Init.channel.onmessage = Init.onMessage;
            if (window.location.hash.length > 0 && window.location.hash.includes('stolen')) {
                await new Promise((resolve) => {
                    setTimeout(() => {
                        WaitFor.instance
                            .waitFor(() => {
                            return Init.elector.hasLeader;
                        }, 500, 5000)
                            .then(() => {
                            resolve();
                        })
                            .catch(() => {
                            resolve();
                        });
                    }, 2500);
                });
            }
            await Init.checkLeadership();
            Init.ensureLeadership();
        }
        return true;
    }
    static postMessage(msg) {
        if (Init.myWorker != null) {
            Init.myWorker.port.postMessage(`toLeaderµ${msg}`);
        }
        else if (Init.channel != null) {
            Init.channel.postMessage(msg).catch((err) => console.debug(err));
        }
    }
    static ensureLeadership() {
        if (Init.leaderIntervall)
            clearInterval(Init.leaderIntervall);
        setInterval(() => {
            if (!Init.elector.hasLeader) {
                Init.checkLeadership().catch((err) => console.error(err));
            }
        }, 5000);
    }
    static async checkLeadership() {
        if (Init.elector.hasLeader === false) {
            try {
                await new Promise((resolve) => {
                    const to = setInterval(() => {
                        if (Init.elector.hasLeader) {
                            clearInterval(to);
                            resolve();
                        }
                    }, 300);
                    Init.elector
                        .awaitLeadership()
                        .then(() => {
                        clearInterval(to);
                        resolve();
                    })
                        .catch((err) => {
                        console.warn('elector: cant get leadership', err);
                    });
                });
                Init.isLeader = Init.elector.isLeader;
                DBleadermanager.isLeader = Init.elector.isLeader;
            }
            catch (err) {
                console.warn('elector: cant get leadership', err);
            }
        }
    }
}
Init.isLeader = false;
Init.onMessage = (msg) => {
    try {
        if (msg.startsWith('toLeader') && Init.isLeader) {
            Init.onLeaderMessage(msg).catch((err) => console.warn('error n Init.onLeaderMessage', err));
        }
        else if (msg.startsWith('client')) {
            if (!Init.isLeader) {
                Init.onClientMessage(msg).catch((err) => console.warn('error n Init.onClientMessage', err));
            }
        }
        else if (msg === 'logout') {
            AuthClient.instance.logOut(true).catch((err) => console.warn('logout error', err));
        }
        else if (msg.startsWith('login')) {
            const split = msg.split('µ');
            if (!AuthClient.instance.loggedIn || CurrentUser.userId !== split[1]) {
                LoadingEvents.instance.startLoading();
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }
        else if (msg.startsWith('steal')) {
            const s = msg.split('µ');
            const url = Url.getURLfromString(window.location.href);
            if (s[1] !== Init.clientId) {
                url.hash = 'stolen';
            }
            else {
                url.hash = '';
            }
            window.location.href = url.href;
            window.location.reload();
        }
    }
    catch (e) {
        console.debug('onMessage error on Tab', e);
    }
};
