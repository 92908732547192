import { Url } from 'cqrs-shared/src/uri/Url';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Page } from 'materialTheme/src/theme/components/Page';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { TabContent } from 'materialTheme/src/theme/components/tabs/TabBarContent';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { ScrollView, View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { ProjectUploadButton } from '../file/ProjectUploadButton';
import { NoRights } from '../NoRights';
import { CurrentProject } from '../project/CurrentProject';
import { AppBar } from '../root/AppBar';
import { PlanDetailsComponent } from './PlanDetailsComponent';
import { PlanVersionsList } from './PlanVersionsList';
import { PlanZoomTileMapper } from './PlanZoomTileMapper';
export class PlanSplitView extends PureComponent {
    constructor(props) {
        super(props);
        this.gotoPlanVersions = () => {
            const { projectId, planId } = this.props;
            Routing.instance.goTo(`/projects/${projectId}/plans/${planId}/versions`, true);
        };
        this.gotoPlanDetails = () => {
            const { projectId, planId } = this.props;
            Routing.instance.goTo(`/projects/${projectId}/plans/${planId}`, true);
        };
        this.initRunning = false;
        this.init = async () => {
            if (!this.initRunning) {
                this.initRunning = true;
                if (this.initTO != null)
                    clearTimeout(this.initTO);
                const { planId, versionId, projectId } = this.props;
                await DateLocale.waitFor(() => UpmeshClient.instance.modals.plan != null);
                try {
                    const getPlan = await UpmeshClient.instance.modals.plan.getById(planId);
                    if (getPlan.deleted) {
                        Routing.instance.goTo(`/projects/${getPlan.projectId}/plans`, true);
                        Routing.instance.alert.post({ text: I18n.m.getMessage('planNotFound') });
                        this.initRunning = false;
                        return;
                    }
                    if (getPlan.activePlanId !== 'MAP') {
                        const getPlanVersions = await UpmeshClient.instance.modals.planVersion.get({
                            filter: `planId eq '${planId}' and deleted ne true`,
                        });
                        const planVersionId = versionId != null && versionId.length > 3 ? versionId : getPlan.activePlanId;
                        const pv = await UpmeshClient.instance.modals.planVersion.getById(planVersionId);
                        if (pv.deleted) {
                            Routing.instance.goTo(`/projects/${getPlan.projectId}/plans/${getPlan.id}/versions`, true);
                            this.initRunning = false;
                            return;
                        }
                        const planVersions = getPlanVersions;
                        planVersions.sort((a, b) => (a.version > b.version ? -1 : a.version < b.version ? 1 : 0));
                        this.setState({
                            planVersions,
                            selectedPlanVersion: pv,
                            plan: getPlan,
                            init: true,
                            planVersionCount: planVersions.length,
                        }, () => {
                            this.initRunning = false;
                        });
                    }
                    else {
                        this.setState({
                            plan: getPlan,
                            planVersionCount: 0,
                            planVersions: [],
                            init: true,
                        }, () => {
                            this.initRunning = false;
                        });
                    }
                }
                catch (e) {
                    this.initRunning = false;
                    if (e.statusCode === 404) {
                        Routing.instance.goTo(`/projects/${projectId}/plans`, true);
                        Routing.instance.alert.post({ text: I18n.m.getMessage('planNotFound') });
                    }
                    else {
                        Routing.instance.alert.post({
                            text: `${I18n.m.getMessage('plansErrorNotFound')} ${DefaultErrorHandler.getDefaultErrorMessages(e)}`,
                        });
                        console.debug('plan error details', e);
                        ErrorReporter.sendReport({
                            subject: `plan error - ${e.name} ${e.message}`,
                            data: e,
                            type: 'warn',
                        });
                    }
                }
            }
            else if (this.initTO == null)
                this.initTO = setTimeout(() => {
                    this.init().catch((err) => console.error(err));
                }, 500);
        };
        this.counterTO = 0;
        this.mounted = false;
        this.handlePlanChange = (e) => {
            const { plan } = this.state;
            if (plan != null && plan.id === e.id) {
                this.init().catch((err) => console.debug(err));
            }
        };
        this.handleVersionsChange = (e) => {
            const { plan } = this.state;
            if (plan != null && plan.projectId === e.projectId && plan.activePlanId !== 'MAP') {
                this.init().catch((err) => console.debug(err));
            }
        };
        this.onLayout = (_e) => {
            const splitView = this.getSplit();
            this.setState({ splitView });
            this.forceUpdate();
        };
        this.pressEditPlan = async (e) => {
            const { plan } = this.state;
            if (plan != null) {
                Routing.instance.openDialog('editPlan', { type: 'edit', planId: plan.id })(e);
            }
        };
        this.goBack = () => {
            const { plan } = this.state;
            if (plan != null) {
                Routing.instance.goTo(`/projects/${plan.projectId}/plans`, true);
            }
            else {
                Routing.instance.goTo('/', true);
            }
        };
        this.goBackFromVersion = () => {
            const { plan } = this.state;
            const { tab } = this.props;
            if (plan != null) {
                Routing.instance.goTo(`/projects/${plan.projectId}/plans/${plan.id}/${tab}`, true);
            }
            else {
                Routing.instance.goTo('/', true);
            }
        };
        this.changeTabLink = (i) => {
            const { versionId } = this.props;
            const { splitView } = this.state;
            if (splitView || versionId == null) {
                if (i === 1) {
                    this.gotoPlanVersions();
                }
                else {
                    this.gotoPlanDetails();
                }
            }
        };
        const splitView = this.getSplit();
        this.state = {
            splitView,
            init: false,
            canRead: false,
            error: '',
            project: undefined,
            plan: undefined,
            planVersions: [],
            selectedPlanVersionId: props.versionId,
            selectedPlanVersion: undefined,
            planId: props.planId,
            isUploading: false,
            planVersionCount: 0,
        };
    }
    getSplit() {
        return ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM;
    }
    componentDidMount() {
        CurrentProject.planChanged.attach(this.handlePlanChange);
        CurrentProject.planVersionsChanged.attach(this.handleVersionsChange);
        this.init().catch((e) => console.debug('cant init PlanSplitView', e));
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.planId !== prevState.planId || nextProps.versionId !== prevState.selectedPlanVersionId) {
            return { init: false, planId: nextProps.planId, selectedPlanVersionId: nextProps.versionId };
        }
        return null;
    }
    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        if (!this.state.init) {
            this.init().catch((e) => console.debug('cant init PlanSplitView', e));
        }
    }
    componentWillUnmount() {
        CurrentProject.planChanged.detach(this.handlePlanChange);
        CurrentProject.planVersionsChanged.detach(this.handleVersionsChange);
    }
    renderPlan(planVersion) {
        const { plan } = this.state;
        if (plan == null)
            return null;
        if (planVersion == null) {
            return (<PlanZoomTileMapper plan={plan} type="map" planVersion={planVersion} zoomAble key={`plan_${plan.id}`} url={`${UpmeshClient.instance.url}`}/>);
        }
        return (<PlanZoomTileMapper type="plan" plan={plan} planVersion={planVersion} zoomAble offlineData={OfflineDataDownloader.isPlanVersionSynced(planVersion.projectId, planVersion.id)} key={`planVersion_${planVersion.id}`} url={`${UpmeshClient.instance.url}/plan/tiles/${planVersion.projectId}/planVersion/${planVersion.id}`}/>);
    }
    render() {
        const { error, init } = this.state;
        if (!init) {
            return (<Page>
          <Spinner />
        </Page>);
        }
        if (error != null && error.length > 0) {
            return <NoRights error={error}/>;
        }
        const { tab } = this.props;
        const { splitView, planVersions, selectedPlanVersionId, plan, selectedPlanVersion } = this.state;
        if (plan == null)
            return <View />;
        const secondView = [];
        let page1Style = { width: '100%' };
        if (splitView || (selectedPlanVersionId != null && selectedPlanVersionId.length > 0)) {
            if (selectedPlanVersion != null && plan.activePlanId !== 'MAP') {
                secondView.push(this.renderPlan(selectedPlanVersion));
            }
            else if (plan.activePlanId === 'MAP') {
                secondView.push(this.renderPlan());
            }
            else {
                if (!splitView) {
                    this.gotoPlanDetails();
                }
            }
            page1Style = {
                maxWidth: 256,
                borderWidth: 0,
                borderRightWidth: ThemeManager.style.borderWidth,
                borderStyle: 'solid',
                borderColor: ThemeManager.style.borderColor,
                backgroundColor: '#FFFFFF',
            };
        }
        const title = selectedPlanVersion != null ? `${plan.title} v${selectedPlanVersion.version}` : plan.title;
        let out = (<View onLayout={this.onLayout} style={{
                width: '100%',
                height: '100%',
            }}>
        <AppBar title={title} leftButtons={[
                <Icon toolTip={I18n.m.getMessage('back')} key="backIcon" icon="arrow-left" onPress={this.goBackFromVersion} outerSize={48}/>,
            ]}/>
        <View onLayout={this.onLayout} style={{
                width: '100%',
                height: ResizeEvent.current.contentHeight - ThemeManager.style.headerHeight,
            }}>
          {secondView}
        </View>
      </View>);
        const { projectId, planId } = this.props;
        const { planVersionCount } = this.state;
        if (splitView || selectedPlanVersionId == null || selectedPlanVersionId.length === 0) {
            const tabMenu = [{ title: I18n.m.getMessage('planDetails') }];
            const tabContent = [
                <ScrollView key={`PlanDetails${plan.id}`} style={{
                        flexDirection: 'column',
                        width: '100%',
                        height: ResizeEvent.current.contentHeight - ThemeManager.style.headerHeight - 48,
                    }}>
          <PlanDetailsComponent plan={plan} showThumb={!splitView}/>
        </ScrollView>,
            ];
            if (plan.activePlanId !== 'MAP') {
                tabMenu.push({ title: I18n.m.getMessage('planVersions') });
                tabContent.push(<View key={`PlanVersions${plan.id}`}>
            <View style={{
                        padding: ThemeManager.style.contentPaddingValue,
                        paddingBottom: 0,
                        width: 256,
                        alignSelf: 'center',
                    }}>
              <ProjectUploadButton uploadText={I18n.m.getMessage('planVersionsUploadDirect')} uploadIcon="cloud-upload-outline" uploadDropDialogText={I18n.m.getMessage('planVersionsUploadDirect')} multiple={false} endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/planfiles/version`)} data={{ projectId, planId }} id={`planVersion_${planId}`} key={`planVersion_${planVersionCount}`} filter={['pdf', 'images']} iconPosition="top"/>
            </View>
            <PlanVersionsList key="PlanVersionsList" width={splitView ? 256 : ResizeEvent.current.contentWidth} height={ResizeEvent.current.contentHeight -
                        ThemeManager.style.headerHeight -
                        ThemeManager.style.getScreenRelativePixelSize(194)} type="thumb" plan={plan} planVersions={planVersions} selectedPlanVersionId={selectedPlanVersionId}/>
          </View>);
            }
            const leftIcons = [
                <Icon toolTip={I18n.m.getMessage('back')} key="backIcon" icon="arrow-left" onPress={this.goBack} outerSize={48}/>,
            ];
            let selectedTab = tab === 'versions' ? 1 : 0;
            if (plan.activePlanId === 'MAP')
                selectedTab = 0;
            out = (<View onLayout={this.onLayout} style={{
                    width: '100%',
                    height: ResizeEvent.current.contentHeight,
                    flexDirection: 'row',
                }}>
          <View style={page1Style}>
            <View style={{
                    flexDirection: 'column',
                }}>
              <AppBar title={plan.title} additionalContentPaddingLeft={false} leftButtons={leftIcons} additionalContent={<View style={{
                        height: ThemeManager.style.getScreenRelativePixelSize(48),
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        width: '100%',
                        flexDirection: 'row',
                    }}>
                    <TabBar textColor={ThemeManager.style.black87} unfocusedTextColor={ThemeManager.style.disabledColor} underlineColor={ThemeManager.style.brandPrimary} contentHeight={0} width={splitView ? 255 : ResizeEvent.current.contentWidth} onChangeSelected={this.changeTabLink} selected={selectedTab} tabs={tabMenu}/>
                  </View>}/>

              <TabContent height={ResizeEvent.current.contentHeight - ThemeManager.style.headerHeight - 48} selected={selectedTab} width={splitView ? 256 : ResizeEvent.current.contentWidth} changeSelection={this.changeTabLink} contents={tabContent}/>
            </View>
          </View>
          <View style={{
                    width: splitView ? ResizeEvent.current.contentWidth - 312 : ResizeEvent.current.contentWidth,
                    flexDirection: 'row',
                }}>
            {secondView}
          </View>
        </View>);
        }
        return out;
    }
}
PlanSplitView.defaultProps = {
    title: 'upmesh',
};
