"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReadRemoteStore = void 0;
var tracing_1 = require("@sentry/tracing");
var ODataDB_1 = require("odatarepos/build/src/odata/root/ODataDB");
var ErrorReporter_1 = require("odatarepos/build/src/reporting/ErrorReporter");
var uuid = require("uuid");
var Config_1 = require("../../Config");
var GetDataOverSocket_1 = require("../commands/GetDataOverSocket");
var RemoteRepo_1 = require("./RemoteRepo");
var ReadRemoteStore = function (_ODataDB_1$ODataDB) {
  function ReadRemoteStore(commandStore) {
    var _this;
    (0, _classCallCheck2.default)(this, ReadRemoteStore);
    _this = _callSuper(this, ReadRemoteStore);
    _this.odataRepoClass = RemoteRepo_1.RemoteRepo;
    _this.commandStore = commandStore;
    return _this;
  }
  (0, _inherits2.default)(ReadRemoteStore, _ODataDB_1$ODataDB);
  return (0, _createClass2.default)(ReadRemoteStore, [{
    key: "closeDB",
    value: function () {
      var _closeDB = (0, _asyncToGenerator2.default)(function* () {
        return Promise.resolve(false);
      });
      function closeDB() {
        return _closeDB.apply(this, arguments);
      }
      return closeDB;
    }()
  }, {
    key: "fetchData",
    value: function () {
      var _fetchData = (0, _asyncToGenerator2.default)(function* (readmodel, method, options) {
        var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'socket';
        if (type === 'fetch' && this.commandStore != null && this.commandStore.serverConnection != null) {
          var url = `/${readmodel.toLowerCase()}`;
          if (method === 'count') url += `/$count`;else if (method === 'distinct') url += `/$distinct`;
          if (options != null) {
            if (options.id != null) url += `/${options.id}`;
            if (options.filter != null && options.filter.length > 0) {
              url += `?$filter=${escape(options.filter)}`;
            }
          }
          var r = this.commandStore.serverConnection.fetch(url, {
            method: 'GET'
          });
          return r;
        }
        var span;
        var transaction;
        if (ErrorReporter_1.ErrorReporter.Reporter != null && Config_1.ConfigHandler.config.environment === '123') {
          transaction = ErrorReporter_1.ErrorReporter.Reporter.startTransaction({
            name: `${readmodel}.${method}`,
            op: 'websocket'
          });
          if (transaction != null) span = transaction.startChild({
            op: 'websocket',
            data: options
          });
        }
        var c = new GetDataOverSocket_1.GetDataOverSocket({
          method: method,
          readmodel: readmodel,
          options: options
        }, uuid.v1(), this.commandStore.serverConnection != null ? this.commandStore.serverConnection.token : '');
        try {
          yield c.execute(this.commandStore);
          if (ErrorReporter_1.ErrorReporter.Reporter != null && span != null && transaction != null) {
            span.setStatus(tracing_1.SpanStatus.Ok);
            span.setData('result', c.result);
            transaction.setStatus(tracing_1.SpanStatus.Ok);
          }
        } catch (e) {
          if (ErrorReporter_1.ErrorReporter.Reporter != null && span != null && transaction != null) {
            if (e != null && e['statusCode'] != null) {
              span.setHttpStatus(e['statusCode']);
              transaction.setHttpStatus(e['statusCode']);
            } else {
              span.setStatus(tracing_1.SpanStatus.UnknownError);
              transaction.setStatus(tracing_1.SpanStatus.UnknownError);
            }
            span.setData('error', e);
          }
          throw e;
        }
        if (ErrorReporter_1.ErrorReporter.Reporter != null && span != null && transaction != null) {
          span.finish();
          transaction.finish();
        }
        return c.result;
      });
      function fetchData(_x, _x2, _x3) {
        return _fetchData.apply(this, arguments);
      }
      return fetchData;
    }()
  }, {
    key: "initDB",
    value: function () {
      var _initDB = (0, _asyncToGenerator2.default)(function* () {
        return true;
      });
      function initDB() {
        return _initDB.apply(this, arguments);
      }
      return initDB;
    }()
  }, {
    key: "deleteDB",
    value: function () {
      var _deleteDB = (0, _asyncToGenerator2.default)(function* () {
        return true;
      });
      function deleteDB() {
        return _deleteDB.apply(this, arguments);
      }
      return deleteDB;
    }()
  }, {
    key: "saveDB",
    value: function () {
      var _saveDB = (0, _asyncToGenerator2.default)(function* () {
        return true;
      });
      function saveDB() {
        return _saveDB.apply(this, arguments);
      }
      return saveDB;
    }()
  }]);
}(ODataDB_1.ODataDB);
exports.ReadRemoteStore = ReadRemoteStore;