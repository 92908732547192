import { Url } from 'cqrs-shared/src/uri/Url';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { CheckboxListItem } from 'materialTheme/src/theme/components/CheckboxListItem';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Linking, Platform, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { SaveReportSettings } from 'upmesh-core/src/client/commands/report/SaveReportSettings';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { ProjectUploadButton } from '../file/ProjectUploadButton';
import { StoredFileDownloader } from '../file/StoredFileDownloader';
import { StoredFileDownloaderOptions } from '../file/StoredFileDownloaderOptions';
import { CurrentProject } from '../project/CurrentProject';
export class ReportDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.fileTypesChangedWrapper = (en) => {
            const { allTickets } = this.props;
            en.entities.forEach((e) => {
                if (!allTickets && e.entity.id === CurrentProject.instance.getCurrentProjectId()) {
                    this.fileTypesChanged().catch((err) => console.debug(err));
                }
                else if (allTickets) {
                    this.fileTypesChanged().catch((err) => console.debug(err));
                }
            });
        };
        this.fileTypesChanged = async () => {
            const { allTickets } = this.props;
            if (!allTickets) {
                const current = CurrentProject.instance.getCurrentProject();
                if (current != null) {
                    const listItems = [];
                    current.fileTypes?.forEach((category) => {
                        listItems.push({
                            title: category,
                            backgroundColor: ThemeManager.style.brandPrimary,
                        });
                    });
                    this.setState({ projectCategories: listItems });
                }
            }
            else {
                const projects = await UpmeshClient.instance.modals.project.get({
                    filter: 'deleted ne true',
                });
                const mergedCategories = new Set();
                projects.forEach((project) => {
                    if (project.fileTypes != null) {
                        project.fileTypes.forEach((category) => {
                            mergedCategories.add(category);
                        });
                    }
                });
                const listItems = [];
                mergedCategories.forEach((category) => {
                    listItems.push({
                        title: category,
                        backgroundColor: ThemeManager.style.brandPrimary,
                    });
                });
                this.setState({ projectCategories: listItems });
            }
        };
        this.settingsChanged = (en) => {
            en.entities.forEach((e) => {
                if (e.entity.id === this.state.reportSettings.id)
                    this.setState({ reportSettings: e.entity });
            });
        };
        this.createReport = async (reportSettings, ticketIds) => {
            if (!UpmeshClient.instance.serverConnected()) {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('commandOfflineNotPossible'),
                });
                return;
            }
            this.setState({ isLoading: true });
            try {
                if (reportSettings.projectId) {
                    Toast.instance?.open({ title: I18n.m.getMessage('reportWillBeCreated') });
                    CurrentProject.instance
                        .createTicketReport(reportSettings, ticketIds, true)
                        .then(() => {
                        Toast.instance?.open({
                            title: I18n.m.getMessage('reportCreated'),
                            buttonTitle: I18n.m.getMessage('openFolder'),
                            onPressButton: () => {
                                Routing.instance.goTo(`/projects/${reportSettings.projectId}/files/private`);
                                Toast.instance?.close();
                            },
                        });
                    })
                        .catch((err) => {
                        DefaultErrorHandler.showDefaultErrorAlert(err);
                    });
                }
                else {
                    const link = await CurrentProject.instance.directDownloadTicketReport(reportSettings, ticketIds);
                    if (link != null) {
                        let orgFilename = `${reportSettings.frontPage.titleName}.pdf`;
                        if (ticketIds.length === 1)
                            orgFilename = `${ticketIds[0]}.pdf`;
                        StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                            link,
                            orgFilename,
                        }))
                            .catch((e) => {
                            console.error('cant download', link, e);
                            DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                            throw e;
                        })
                            .finally(() => {
                            this.setState({ isLoading: false });
                        });
                    }
                    else {
                        this.setState({ isLoading: false });
                    }
                }
            }
            catch (e) {
                console.debug('ReportIcon Error', e);
                let message = '';
                if (e != null && (e.message != null || e.errorMessage != null)) {
                    message = e.message != null ? e.message : e.errorMessage;
                }
                if (message != null && message.length > 0) {
                    console.debug('error', message);
                    Routing.instance.alert.post({
                        text: I18n.m.getMessage(message),
                    });
                }
                else {
                    Routing.instance.alert.post({
                        text: I18n.m.getMessage('commandOfflineNotPossible'),
                    });
                }
                this.closeFunction();
                this.setState({
                    isLoading: false,
                });
                this.props.errorResult(message);
            }
        };
        this.openURL = (link) => {
            Linking.canOpenURL(link)
                .then((supported) => {
                if (!supported) {
                    return;
                }
                Linking.openURL(link).catch((err) => console.debug(err));
            })
                .catch((err) => {
                console.error(`adHelper OPENING URL ERROR ${err.toString()}`);
            });
        };
        this.showDisabledInfo = () => {
            const { reportSettings } = this.props;
            const asyncNow = async () => {
                const project = await UpmeshClient.instance.modals.project.getById(reportSettings.projectId);
                if (project.creator !== CurrentUser.userId) {
                    return Routing.instance.alert.post({
                        text: I18n.m.getMessage('askOwnerForUpgradeProject'),
                    });
                }
                const acc = await RightsManager.hasVersion(CurrentUser.userId);
                if (acc !== 'basic') {
                    return Routing.instance.alert.post({
                        text: I18n.m.getMessage('upgradeProjectQuestion'),
                        buttons: [
                            <ContainedButton key="abort" title={I18n.m.getMessage('abort')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                            <ContainedButton key="dashboard" title={I18n.m.getMessage('menuProject')} onPress={this.goToDashboard} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                        ],
                    });
                }
                return this.openURL('https://www.upmesh.de/preise/');
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.goToDashboard = () => {
            const { reportSettings } = this.props;
            Alert.instance?.close(() => {
                this.closeFunction(() => {
                    requestAnimationFrame(() => {
                        Routing.instance.goTo(`projects/${reportSettings.projectId}/project`);
                    });
                });
            });
        };
        this.abort = (_e) => {
            this.closeFunction();
        };
        this.sortPickerList = [
            I18n.m.getMessage('reportTicketDetailsNumber'),
            I18n.m.getMessage('reportTicketDetailsStatus'),
            I18n.m.getMessage('reportTicketDetailsDue'),
            I18n.m.getMessage('reportTicketDetailsAssignee'),
            I18n.m.getMessage('reportTicketDetailsCreated'),
        ];
        this.qualityPickerList = [
            I18n.m.getMessage('qualityNormal'),
            I18n.m.getMessage('qualityGood'),
            I18n.m.getMessage('qualityOptimal'),
        ];
        this.planQualityPickerList = [
            I18n.m.getMessage('qualityPlanNormal'),
            I18n.m.getMessage('qualityPlanGood'),
            I18n.m.getMessage('qualityPlanOptimal'),
        ];
        this.picturePickerList = [
            I18n.m.getMessage('reportTicketPhotoPerLine'),
            `2 ${I18n.m.getMessage('reportTicketPhotosPerLine')}`,
            `3 ${I18n.m.getMessage('reportTicketPhotosPerLine')}`,
        ];
        this.isUploadingState = (e) => (count) => {
            const isUploading = count > 0;
            this.setState((prevstate) => {
                if (prevstate[e] !== isUploading) {
                    const state = {};
                    state[e] = isUploading;
                    return { ...prevstate, ...state };
                }
                return null;
            });
        };
        this.onChangeName = (reportName) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.frontPage.titleName = reportName;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeUsedFrontPage = (selected) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.frontPage.selectedMode = selected === 0 ? 'description' : 'titlepage';
                return { reportSettings: current.createOne(current), selectedModeButton: selected };
            });
        };
        this.onChangeDescription = (description) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.frontPage.description = description;
                return { reportSettings: current.createOne(current), frontPageDescription: description };
            });
        };
        this.onChangeToc = (showToc) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.frontPage.showTableOfContents = showToc;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangePageBreakTicket = (pageBreakTicket) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.layout.lineBreakBeforeTicket = pageBreakTicket;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeQrCode = (qrCodes) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.layout.qrCodes = qrCodes;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeIncludeLinks = (includeLinks) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.layout.includeLinks = includeLinks;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangePlanOverviewTicket = (planOverview) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.localizationPlan = planOverview;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangePlanSnippetTicket = (planSnippet) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.localizationSnippet = planSnippet;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeMapLocalization = (mapSnippet) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.localizationMap = mapSnippet;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeSortOrder = (selected) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                switch (selected.value) {
                    case I18n.m.getMessage('reportTicketDetailsNumber'):
                        current.layout.sort = 'ticketNumber';
                        break;
                    case I18n.m.getMessage('reportTicketDetailsStatus'):
                        current.layout.sort = 'ticketStatus';
                        break;
                    case I18n.m.getMessage('reportTicketDetailsDue'):
                        current.layout.sort = 'completionOn';
                        break;
                    case I18n.m.getMessage('reportTicketDetailsAssignee'):
                        current.layout.sort = 'assignedToUserId';
                        break;
                    default:
                        current.layout.sort = 'createdAt';
                }
                return {
                    reportSettings: current.createOne(current),
                    sortPickerListSelected: this.sortPickerListSelected(current),
                };
            });
        };
        this.onChangeGroupOrder = (selected) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                switch (selected.value) {
                    case I18n.m.getMessage('reportTicketGroupCompany'):
                        current.layout.groupBy = 'companyId';
                        break;
                    case I18n.m.getMessage('reportTicketDetailsAssignee'):
                        current.layout.groupBy = 'assignedToUserId';
                        break;
                    case I18n.m.getMessage('reportTicketDetailsCraft'):
                        current.layout.groupBy = 'craft';
                        break;
                    case I18n.m.getMessage('reportTicketDetailsTyp'):
                        current.layout.groupBy = 'type';
                        break;
                    case I18n.m.getMessage('reportTicketDetailsApprover'):
                        current.layout.groupBy = 'approverUserId';
                        break;
                    case I18n.m.getMessage('reportTicketGroupProject'):
                        current.layout.groupBy = 'projectId';
                        break;
                    default:
                        current.layout.groupBy = undefined;
                }
                return {
                    reportSettings: current.createOne(current),
                    groupPickerListSelected: this.groupPickerListSelected(current),
                };
            });
        };
        this.onChangePhotoQuality = (selected) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                switch (selected.value) {
                    case I18n.m.getMessage('qualityNormal'):
                        current.ticketDetails.photoQuality = 'normal';
                        break;
                    case I18n.m.getMessage('qualityGood'):
                        current.ticketDetails.photoQuality = 'good';
                        break;
                    case I18n.m.getMessage('qualityOptimal'):
                        current.ticketDetails.photoQuality = 'optimal';
                        break;
                    default:
                        current.ticketDetails.photoQuality = 'low';
                }
                return {
                    reportSettings: current.createOne(current),
                    photoqualityListSelected: this.qualityPickerListSeleced(current.ticketDetails.photoQuality),
                };
            });
        };
        this.onChangePlanQuality = (selected) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                switch (selected.value) {
                    case I18n.m.getMessage('qualityPlanNormal'):
                        current.ticketDetails.planQuality = 'normal';
                        break;
                    case I18n.m.getMessage('qualityPlanGood'):
                        current.ticketDetails.planQuality = 'good';
                        break;
                    case I18n.m.getMessage('qualityPlanOptimal'):
                        current.ticketDetails.planQuality = 'optimal';
                        break;
                    default:
                        current.ticketDetails.planQuality = 'normal';
                }
                return {
                    reportSettings: current.createOne(current),
                    planqualityListSelected: this.qualityPickerListSeleced(current.ticketDetails.planQuality),
                };
            });
        };
        this.onChangeType = (type) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.type = type;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeStatus = (status) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.status = status;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeCraft = (_craft) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.craft = _craft;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeDue = (due) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.dueDate = due;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeAssignee = (assignee) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.assignee = assignee;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeTags = (tags) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.tags = tags;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeApprover = (approver) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.approver = approver;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeCreated = (created) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.created = created;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeCustom = (label) => (v) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                const s = current.ticketDetails.customFields ? [...current.ticketDetails.customFields] : [];
                const index = s.findIndex((a) => a === label);
                if (index > -1 && !v)
                    s.splice(index, 1);
                else if (index === -1 && v)
                    s.push(label);
                current.ticketDetails.customFields = s;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeLastChanged = (lastChanged) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.lastChanged = lastChanged;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeTicketDescription = (description) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.description = description;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeComments = (comments) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.comments = comments;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangePhotos = (photos) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.photos = photos;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeMergeTicketPdfs = (mergeTicketPDFs) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.layout.mergeTicketPdfsToReport = mergeTicketPDFs;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeLogbook = (logbook) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.logbook = logbook;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.onChangeImagePerLine = (selected) => {
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.imagesPerLine = selected.index + 1;
                return { reportSettings: current.createOne(current), picturePickerListSelected: selected.index };
            });
        };
        this.onChangeReportCategories = (e) => {
            const fileTypes = [];
            e.forEach((element) => {
                fileTypes.push(element.title);
            });
            this.setState((prevState) => {
                const current = prevState.reportSettings;
                current.ticketDetails.photosCategories = fileTypes;
                return { reportSettings: current.createOne(current) };
            });
        };
        this.closeFunction = (callback) => {
            const { closeFunc } = this.props;
            if (closeFunc != null)
                closeFunc(callback);
            else
                Dialog.instance?.close(callback);
        };
        this.sendToJournal = () => {
            const { reportSettings } = this.state;
            const { saveFunc } = this.props;
            if (saveFunc != null)
                saveFunc(reportSettings);
            this.closeFunction();
        };
        this.saveReportSettings = () => {
            const { reportSettings } = this.state;
            const { ticketIds, editable } = this.props;
            const current = reportSettings;
            current.userId = CurrentUser.userId;
            const asyncNow = async () => {
                try {
                    if (editable) {
                        const save = new SaveReportSettings(reportSettings, current.id);
                        await save.execute();
                    }
                    await this.createReport(current, ticketIds);
                    this.closeFunction();
                }
                catch (e) {
                    if (e['messageCode'] === 'notInBasicProject') {
                        await this.createReport(current, ticketIds);
                        this.closeFunction();
                        return;
                    }
                    console.debug('error saving report settings', e);
                    this.props.errorResult(e);
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        const selected = this.groupPickerListSelected(props.reportSettings);
        this.state = {
            reportSettings: props.reportSettings,
            sortPickerListSelected: this.sortPickerListSelected(props.reportSettings),
            groupPickerListSelected: selected,
            picturePickerListSelected: this.picturePickerListSelected(props.reportSettings),
            logoIsUploading: false,
            titleIsUploading: false,
            attachmentIsUploaing: false,
            photoqualityListSelected: this.qualityPickerListSeleced(props.reportSettings.ticketDetails.photoQuality),
            planqualityListSelected: this.qualityPickerListSeleced(props.reportSettings.ticketDetails.planQuality),
            isLoading: false,
            projectCategories: [],
            selectedModeButton: props.reportSettings.frontPage.selectedMode === 'description' ? 0 : 1,
            frontPageDescription: props.reportSettings.frontPage.description != null ? props.reportSettings.frontPage.description : '',
        };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'ReportSettings',
            attachKey: 'ReportSettingsDialog',
            callback: this.settingsChanged,
        });
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'Project',
            attachKey: 'ReportSettingsDialogProject',
            callback: this.fileTypesChangedWrapper,
        });
        this.fileTypesChanged().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        Menu.instance?.close();
        UpmeshClient.eventDispatcher.detach('ReportSettings', 'ReportSettingsDialog');
        UpmeshClient.eventDispatcher.detach('Project', 'ReportSettingsDialogProject');
    }
    picturePickerListSelected(reportSettings) {
        return reportSettings.ticketDetails.imagesPerLine - 1;
    }
    sortPickerListSelected(reportSettings) {
        if (reportSettings.layout.sort === 'ticketNumber')
            return 0;
        if (reportSettings.layout.sort === 'ticketStatus')
            return 1;
        if (reportSettings.layout.sort === 'completionOn')
            return 2;
        if (reportSettings.layout.sort === 'assignedToUserId')
            return 3;
        if (reportSettings.layout.sort === 'createdAt')
            return 4;
        return 0;
    }
    groupPickerListSelected(reportSettings) {
        if (reportSettings.layout.groupBy === 'companyId')
            return 0;
        if (reportSettings.layout.groupBy === 'assignedToUserId')
            return 1;
        if (reportSettings.layout.groupBy === 'craft')
            return 2;
        if (reportSettings.layout.groupBy === 'type')
            return 3;
        if (reportSettings.layout.groupBy === 'approverUserId')
            return 4;
        if (reportSettings.layout.groupBy === 'projectId')
            return 5;
        if (reportSettings.layout.groupBy == null)
            return 5;
        return 0;
    }
    qualityPickerListSeleced(quality) {
        if (quality === 'low')
            return 0;
        if (quality === 'good')
            return 1;
        if (quality === 'optimal')
            return 2;
        return 0;
    }
    render() {
        const { connectedToServer } = this.context;
        const { editable, customFields, ticketIds, filterActive, onPressFilter, saveFunc, saveWithNoResult } = this.props;
        const reportForJournals = saveFunc != null;
        const { reportSettings, logoIsUploading, attachmentIsUploaing, titleIsUploading, sortPickerListSelected, groupPickerListSelected, picturePickerListSelected, planqualityListSelected, photoqualityListSelected, projectCategories, selectedModeButton, frontPageDescription, } = this.state;
        const helpCircle = Platform.OS !== 'web' ? null : (<Icon iconSize={18} toolTip="" icon="help-circle-outline" onPress={this.showDisabledInfo}/>);
        if (!connectedToServer) {
            return (<View>
          <DialogContent dialogHasTitle={false}>
            <MaterialText>{I18n.m.getMessage('commandOfflineNotPossible')}</MaterialText>
          </DialogContent>

          <DialogActions>
            <ContainedButton title={I18n.m.getMessage('ok')} onPress={this.abort} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          </DialogActions>
        </View>);
        }
        const groupPickerList = [
            I18n.m.getMessage('reportTicketGroupCompany'),
            I18n.m.getMessage('reportTicketDetailsAssignee'),
            I18n.m.getMessage('reportTicketDetailsCraft'),
            I18n.m.getMessage('reportTicketDetailsTyp'),
            I18n.m.getMessage('reportTicketDetailsApprover'),
        ];
        const selectedPhotoCategories = [];
        if (reportSettings.ticketDetails.photosCategories != null) {
            reportSettings.ticketDetails.photosCategories.forEach((element) => {
                selectedPhotoCategories.push({
                    title: element,
                    backgroundColor: ThemeManager.style.brandPrimary,
                });
            });
        }
        if (this.props.allTickets != null && this.props.allTickets) {
            groupPickerList.push(I18n.m.getMessage('reportTicketGroupProject'));
        }
        else {
            groupPickerList.push(I18n.m.getMessage('reportTicketGroupNoGroup'));
        }
        return [
            <DialogTitle key="DialogTitleReportDialog" iconRight={onPressFilter == null
                    ? undefined
                    : {
                        toolTip: I18n.m.getMessage('ticketFilter'),
                        icon: filterActive ? 'filter-remove' : 'filter-outline',
                        color: filterActive ? ThemeManager.style.brandPrimary : ThemeManager.style.defaultIconColor,
                        outerSize: 36,
                        onPress: onPressFilter,
                    }}>
        {I18n.m.getMessage('reportCreation', { x: ticketIds.length })}
      </DialogTitle>,
            <DialogContent key="DialogContentReportDialog">
        {reportForJournals === true ? undefined : (<View>
            <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
              <MaterialText type={MaterialTextTypes.Body1} strong>
                {I18n.m.getMessage('reportCoverPageText')}
              </MaterialText>
              {!editable ? helpCircle : null}
            </View>
            <FormInputFilled disabled={!editable} initValue={reportSettings.frontPage.titleName} labelText={I18n.m.getMessage('reportName')} onChange={this.onChangeName}/>
            <View style={{ height: 1, width: '100%' }}/>
            <SegmentedButton buttons={[
                        { title: I18n.m.getMessage('reportSelectDescription'), key: 'description' },
                        { title: I18n.m.getMessage('reportSelectTitlepage'), key: 'titlepage' },
                    ]} onPress={this.onChangeUsedFrontPage} singleSelectSelected={selectedModeButton}/>
            <View style={{ height: 8 }}/>
            {selectedModeButton === 1 ? (<>
                <ProjectUploadButton onUploading={this.isUploadingState('titleIsUploading')} disableUpload={!editable} uploadText={I18n.m.getMessage('reportCoverPageTextButton')} uploadIcon="cloud-upload-outline" uploadDropDialogText={I18n.m.getMessage('reportCoverPage')} multiple={false} deleteFunction="default" uploaded={reportSettings.customTitlePageFileId != null
                            ? [
                                {
                                    name: reportSettings.customTitlePageFilename,
                                    targetUrl: `${UpmeshClient.instance.url}/reportsettings/${reportSettings.id}/titlepage/${reportSettings.customTitlePageFileId}`,
                                },
                            ]
                            : []} id={`${reportSettings.id}_titlepage`} filter={['pdf']} endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/reportsettings/${reportSettings.id}/titlepage`)} selfManageUploads/>
                <View style={{ height: 8 }}/>
              </>) : (<FormInputFilled disabled={!editable} labelText={I18n.m.getMessage('reportDescription')} onChange={this.onChangeDescription} initValue={frontPageDescription} numberOfLines={3}/>)}
            <View style={{
                        height: 0,
                        width: '100%',
                        borderBottomWidth: ThemeManager.style.borderWidth,
                        borderBottomColor: ThemeManager.style.borderColor,
                        marginBottom: ThemeManager.style.contentPaddingValue,
                    }}/>
          </View>)}
        <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
          
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('reportLayout')}
          </MaterialText>
          {!editable ? helpCircle : null}
        </View>
        {reportForJournals === true ? undefined : (<CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportShowToc')} onChange={this.onChangeToc} value={reportSettings.frontPage.showTableOfContents}/>)}
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportPageBreakForTicket')} onChange={this.onChangePageBreakTicket} value={reportSettings.layout.lineBreakBeforeTicket}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportIncludeLinks')} onChange={this.onChangeIncludeLinks} value={reportSettings.layout.includeLinks}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportQrCodes')} onChange={this.onChangeQrCode} value={reportSettings.layout.qrCodes}/>
        <FormInputPicker disabled={!editable} labelText={I18n.m.getMessage('reportGroupByLabel')} list={groupPickerList} onChange={this.onChangeGroupOrder} selectedIndex={groupPickerListSelected}/>
        <FormInputPicker disabled={!editable} labelText={I18n.m.getMessage('reportSortByLabel')} list={this.sortPickerList} onChange={this.onChangeSortOrder} selectedIndex={sortPickerListSelected}/>
        <View style={{
                    height: 0,
                    width: '100%',
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderBottomColor: ThemeManager.style.borderColor,
                    marginBottom: ThemeManager.style.contentPaddingValue,
                }}/>
        <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('reportTicketDetails')}
          </MaterialText>
          {!editable ? helpCircle : null}
        </View>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketDetailsStatus')} onChange={this.onChangeStatus} value={reportSettings.ticketDetails.status}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketDetailsCraft')} onChange={this.onChangeCraft} value={reportSettings.ticketDetails.craft}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketDetailsTyp')} onChange={this.onChangeType} value={reportSettings.ticketDetails.type}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketDetailsCreated')} onChange={this.onChangeCreated} value={reportSettings.ticketDetails.created}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketDetailsLastChanged')} onChange={this.onChangeLastChanged} value={reportSettings.ticketDetails.lastChanged}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketDetailsDue')} onChange={this.onChangeDue} value={reportSettings.ticketDetails.dueDate}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketDetailsAssignee')} onChange={this.onChangeAssignee} value={reportSettings.ticketDetails.assignee}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketDetailsApprover')} onChange={this.onChangeApprover} value={reportSettings.ticketDetails.approver}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketDetailsTags')} onChange={this.onChangeTags} value={reportSettings.ticketDetails.tags}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportDescription')} onChange={this.onChangeTicketDescription} value={reportSettings.ticketDetails.description}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportLayoutPlan')} onChange={this.onChangePlanOverviewTicket} value={reportSettings.ticketDetails.localizationPlan}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportLayoutPlanSnippet')} onChange={this.onChangePlanSnippetTicket} value={reportSettings.ticketDetails.localizationSnippet}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportLayoutMapLocalization')} onChange={this.onChangeMapLocalization} value={reportSettings.ticketDetails.localizationMap}/>

        {customFields &&
                    customFields.map((c) => {
                        return (<CheckboxListItem disabled={!editable} title={c} onChange={this.onChangeCustom(c)} value={customFields == null || customFields.findIndex((a) => c === a) >= 0}/>);
                    })}

        <FormInputPicker disabled={!editable ||
                    (!reportSettings.ticketDetails.localizationSnippet && !reportSettings.ticketDetails.localizationPlan)} labelText={I18n.m.getMessage('reportTicketActivitiesPlansQuality')} list={this.planQualityPickerList} onChange={this.onChangePlanQuality} selectedIndex={planqualityListSelected}/>
        <View style={{
                    height: 0,
                    width: '100%',
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderBottomColor: ThemeManager.style.borderColor,
                    marginBottom: ThemeManager.style.contentPaddingValue,
                }}/>
        <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('reportTicketActivities')}
          </MaterialText>
          {!editable ? helpCircle : null}
        </View>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketActivitiesComments')} onChange={this.onChangeComments} value={reportSettings.ticketDetails.comments}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketActivitiesLogbook')} onChange={this.onChangeLogbook} value={reportSettings.ticketDetails.logbook}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketActivitiesPhotos')} onChange={this.onChangePhotos} value={reportSettings.ticketDetails.photos}/>
        <CheckboxListItem disabled={!editable} title={I18n.m.getMessage('reportTicketActivitiesPDFsEmbed')} onChange={this.onChangeMergeTicketPdfs} value={reportSettings.layout.mergeTicketPdfsToReport}/>
        {reportSettings.ticketDetails.photos || reportSettings.layout.mergeTicketPdfsToReport ? (<ChipGroup key="fileTypes" dialogTitle={I18n.m.getMessage('reportTicketActivitiesCategories')} textColor="#FFFFFF" label={I18n.m.getMessage('reportTicketActivitiesFilterCategories')} bordered={false} availableChips={projectCategories} onChanged={this.onChangeReportCategories} chips={selectedPhotoCategories} editable={editable} tooltipAddChip={I18n.m.getMessage('reportTicketActivitiesFilterForCatHint')}/>) : null}
        {reportSettings.ticketDetails.photos ? (<View>
            <FormInputPicker disabled={!editable || !reportSettings.ticketDetails.photos} labelText={I18n.m.getMessage('reportTicketActivitiesPhotosQuality')} list={this.qualityPickerList} onChange={this.onChangePhotoQuality} selectedIndex={photoqualityListSelected}/>
            <FormInputPicker disabled={!editable} labelText={I18n.m.getMessage('reportPicturesPerLineLabel')} list={this.picturePickerList} onChange={this.onChangeImagePerLine} selectedIndex={picturePickerListSelected}/>
          </View>) : null}
        {reportForJournals === true ? undefined : (<View>
            <View style={{
                        height: 0,
                        width: '100%',
                        borderBottomWidth: ThemeManager.style.borderWidth,
                        borderBottomColor: ThemeManager.style.borderColor,
                        marginBottom: ThemeManager.style.contentPaddingValue,
                    }}/>
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {I18n.m.getMessage('reportAttachment')}
            </MaterialText>
            <View style={{ height: 8 }}/>
            <ProjectUploadButton onUploading={this.isUploadingState('attachmentIsUploaing')} disableUpload={!editable} uploadText={I18n.m.getMessage('reportAttachmentButton')} uploadIcon="cloud-upload-outline" uploadDropDialogText={I18n.m.getMessage('reportAttachment')} multiple={false} deleteFunction="default" uploaded={reportSettings.customAttachmentPageFileId != null
                        ? [
                            {
                                name: reportSettings.customAttachmentPageFilename,
                                targetUrl: `${UpmeshClient.instance.url}/reportsettings/${reportSettings.id}/attachment/${reportSettings.customAttachmentPageFileId}`,
                            },
                        ]
                        : []} id={`${reportSettings.id}_attachment`} filter={['pdf']} endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/reportsettings/${reportSettings.id}/attachment`)} selfManageUploads/>
            <View style={{
                        height: 0,
                        width: '100%',
                        borderBottomWidth: ThemeManager.style.borderWidth,
                        borderBottomColor: ThemeManager.style.borderColor,
                        marginBottom: ThemeManager.style.contentPaddingValue,
                        marginTop: ThemeManager.style.contentPaddingValue,
                    }}/>
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {I18n.m.getMessage('reportReportLogo')}
            </MaterialText>
            <View style={{ height: 8 }}/>
            <ProjectUploadButton onUploading={this.isUploadingState('logoIsUploading')} disableUpload={!editable} uploadText={I18n.m.getMessage('reportReportLogoButton')} uploadIcon="cloud-upload-outline" multiple={false} deleteFunction="default" uploaded={reportSettings.customizedLogoDataFileId != null
                        ? [
                            {
                                name: reportSettings.customizedLogoDataFilename,
                                targetUrl: `${UpmeshClient.instance.url}/reportsettings/${reportSettings.id}/logo/${reportSettings.customizedLogoDataFileId}`,
                            },
                        ]
                        : []} id={`${reportSettings.id}_logo`} filter={['images']} endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/reportsettings/${reportSettings.id}/logo`)} selfManageUploads/>
          </View>)}
      </DialogContent>,
            <View style={{ width: '100%' }} key="DialogActionsReportDialog">
        <View key="errorText" style={{ alignSelf: 'flex-end', padding: 0, paddingRight: 16 }}>
          <MaterialText textPadding={0} type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
            {ticketIds.length === 0 ? I18n.m.getMessage('noTicketsAvailable') : ''}
          </MaterialText>
        </View>
        <DialogActions>
          <ContainedButton title={I18n.m.getMessage('abort')} onPress={this.abort} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
          <ContainedButton disabled={(!saveWithNoResult && ticketIds.length === 0) ||
                    logoIsUploading ||
                    attachmentIsUploaing ||
                    titleIsUploading} title={reportForJournals ? I18n.m.getMessage('save') : I18n.m.getMessage('reportCreateButton')} loading={this.state.isLoading} onPress={reportForJournals ? this.sendToJournal : this.saveReportSettings}/>
        </DialogActions>
      </View>,
        ];
    }
}
ReportDialog.contextType = ConnectionContext;
