import { WaitFor } from 'cqrs-shared/src/WaitFor';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { FlatList, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ProjectFilter } from 'upmesh-core/src/client/query/filter/ProjectFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
export class ProjectSelector extends PureComponent {
    constructor(props) {
        super(props);
        this.all = [];
        this.onSearch = (search) => {
            const projects = ProjectFilter.filterProjectsByText(search, this.all);
            this.setState({ projects, searchValue: search });
        };
        this.selectProject = (t) => (_e) => {
            this.props.onSelect(t);
        };
        this.renderRow = ({ item }) => (<ListItem key={`ticket_${item.id}`} paddingLeft={ThemeManager.style.contentPaddingValue} title={item.title} onPress={this.selectProject(item)}/>);
        this.state = { isLoading: true, projects: [], searchValue: '' };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    async init() {
        await WaitFor.instance.waitFor(() => UpmeshClient.instance.ready);
        if (AuthClient.instance.ready) {
            await WaitFor.instance.waitFor(() => CurrentUser.userId != null);
            const allProjects = await UpmeshClient.instance.modals.project.get({
                filter: 'deleted ne true and archived ne true',
                orderby: 'title ASC',
            });
            const filtered = [];
            const { projectFilter } = this.props;
            if (projectFilter) {
                const promises = allProjects.map((p) => projectFilter(p).then((v) => {
                    if (v)
                        filtered.push(p);
                }));
                await Promise.all(promises);
                this.all = filtered;
            }
            else
                this.all = allProjects;
            const { searchValue } = this.state;
            let projects = this.all;
            if (searchValue != null && searchValue.length > 0) {
                projects = await ProjectFilter.filterProjectsByText(searchValue, this.all);
            }
            this.setState({ projects, isLoading: false });
        }
    }
    render() {
        const { projects, isLoading, searchValue } = this.state;
        const { paddingHorizontal, paddingVertical, dialogState, placeHolderText } = this.props;
        if (isLoading) {
            return <Spinner />;
        }
        const height = dialogState != null
            ? dialogState.fullscreen
                ? dialogState.windowHeight
                : dialogState.windowHeight * dialogState.maxHeight
            : '100%';
        return (<>
        {placeHolderText && <DialogTitle>{placeHolderText}</DialogTitle>}
        <View style={{ width: '100%', height }}>
          <View style={{
                height: 56,
                paddingHorizontal: paddingHorizontal == null ? ThemeManager.style.contentPaddingValue : paddingHorizontal,
                paddingBottom: paddingVertical == null ? 4 : paddingVertical,
                paddingRight: dialogState ? 48 : undefined,
            }}>
            <SearchBar searchOnChange={this.onSearch} searchBarValue={searchValue} searchBarPlaceholder={I18n.m.getMessage('searching')}/>
          </View>
          <FlatList key="projects" style={{ flex: 1 }} renderItem={this.renderRow} data={projects}/>
        </View>
      </>);
    }
}
export const openProjectSelectorDialog = (props) => (e) => {
    const openPosition = e != null && e.nativeEvent == null && e.nativeEvent.pageX != null
        ? { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY }
        : {
            x: ResizeEvent.current.windowWidth / 2,
            y: ResizeEvent.current.windowHeight / 2,
        };
    Dialog.instance?.open({
        content: <ProjectSelector {...props}/>,
        openPosition,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        showCloseIcon: true,
        closeOnTouchOutside: false,
    });
};
