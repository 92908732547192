import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { UnArchiveProject } from 'upmesh-core/src/client/commands/project/UnArchiveProject';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { UserOrCompany } from 'upmesh-core/src/server/webserver/UserOrCompany';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { StoredFileDownloader } from '../file/StoredFileDownloader';
import { StoredFileDownloaderOptions } from '../file/StoredFileDownloaderOptions';
import { NoRights } from '../NoRights';
import { CurrentProject } from '../project/CurrentProject';
import { DeleteProjectDialog } from '../project/settings/DeleteProjectDialog';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
const noProjectsImg = require('../../assets/img/noCompanies.png');
export class CompanyProjectsView extends PureComponent {
    constructor(props) {
        super(props);
        this.changedProjects = () => {
            this.updateData();
        };
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.onPressDownloadProject = async (project) => {
            Dialog.instance?.close();
            if (!UpmeshClient.instance.serverConnected()) {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('commandOfflineNotPossible'),
                });
            }
            else {
                LoadingEvents.instance.startLoading();
                try {
                    const link = await CurrentProject.instance.createArchive(project.id);
                    if (link != null) {
                        const orgFilename = `${CurrentProject.instance.getCurrentProject()?.title}.zip`;
                        StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                            link,
                            orgFilename,
                        })).catch((e) => {
                            console.error('cant download', link, e);
                            DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                            throw e;
                        });
                    }
                }
                catch (e) {
                    console.debug('Archive Error', e);
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                }
                LoadingEvents.instance.stopLoading();
            }
        };
        this.openArchiveDialog = (project) => (_e) => {
            Dialog.instance?.open({
                content: I18n.m.getMessage('projectUnarchiveProjectDialogText'),
                title: I18n.m.getMessage('projectUnarchiveProjectDialogTitle'),
                showCloseIcon: true,
                closeOnTouchOutside: true,
                fullscreenResponsive: true,
                scrollable: true,
                buttons: [
                    <ContainedButton key="download" title={I18n.m.getMessage('projectUnarchiveProjectDialogButtonDownload')} onPress={() => {
                            this.onPressDownloadProject(project).catch((err) => console.error(err));
                        }} textColor="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary}/>,
                    <ContainedButton key="archive" textColor="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('projectUnarchiveProjectDialogButtonReactivate')} onPress={() => {
                            this.onPressReactivateProject(project).catch((err) => console.error(err));
                        }}/>,
                    <ContainedButton key="archive" backgroundColor={ThemeManager.style.brandDanger} title={I18n.m.getMessage('projectUnarchiveProjectDialogButtonDelete')} onPress={() => {
                            this.onPressDeleteProject(project).catch((err) => console.error(err));
                        }}/>,
                ],
            });
        };
        this.openTeamSite = (item) => (_e) => {
            Routing.instance.goTo(`/company/projectteam/${item.id}`);
        };
        this.onPressDeleteProject = async (project) => {
            if (project == null)
                return;
            Dialog.instance?.open({
                scrollable: false,
                showCloseIcon: true,
                contentPadding: false,
                content: (<DeleteProjectDialog projectId={project.id} downloadAction={() => {
                        this.onPressDownloadProject(project).catch((err) => console.error(err));
                    }}/>),
            });
        };
        this.onPressReactivateProject = async (project) => {
            Dialog.instance?.close();
            if (project == null)
                return;
            LoadingEvents.instance.startLoading();
            try {
                const c = new UnArchiveProject({}, project.id);
                await c.execute();
                Routing.instance.goTo(`/projects/${project.id}/${StoredStartPages.getStoredProjectStartPage()}`);
            }
            catch (e) {
                console.debug('UnArchive Error', e);
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
            }
            LoadingEvents.instance.stopLoading();
        };
        this.state = {
            myRole: this.myRole(),
        };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyProjectsView',
            readModelName: 'CompanyProject',
            callback: this.changedProjects,
        });
        this.updateData();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.companySettings !== this.props.companySettings ||
            prevProps.companySettings.crafts !== this.props.companySettings.crafts) {
            this.updateData();
        }
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('CompanyProject', 'CompanyProjectsView');
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { data, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const { size } = this.props;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(60);
        if (myRole !== 'admin') {
            return (<View>
          <NoRights />
        </View>);
        }
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('projects')}
          </MaterialText>
        </View>
        <Card style={{ width: '100%', height: tableMaxHeight }}>
          <Table tableName="CompanyProjectsView" actionItemsLength={myRole === 'admin' ? 1 : 0} actions={myRole === 'admin'
                ? (item) => {
                    let icons = [
                        {
                            icon: 'account-multiple-outline',
                            onAction: this.openTeamSite,
                            toolTip: I18n.m.getMessage('menuProjectInvolved'),
                        },
                    ];
                    if (item.archived) {
                        icons = [
                            {
                                icon: 'archive-outline',
                                toolTip: I18n.m.getMessage('projectUnarchiveProjectDialogTitle'),
                                onAction: this.openArchiveDialog,
                            },
                        ];
                    }
                    return icons;
                }
                : undefined} maxHeight={tableMaxHeight - 16} emptyTableImage={noProjectsImg} emptyTableHint={I18n.m.getMessage('noProjectsFound')} emptyTableText="" data={data} sortBy="title" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('name'),
                    keyInData: 'title',
                    style: { width: 250 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('projectStart'),
                    keyInData: 'projectStart',
                    style: { width: 250 },
                    sortable: true,
                    dataType: 'Date',
                },
                {
                    title: I18n.m.getMessage('ticketFilterCreatedBy'),
                    keyInData: 'creatorUsername',
                    style: { width: 250 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('members'),
                    keyInData: 'members',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'number',
                },
            ]}/>
        </Card>
      </View>);
    }
    updateData() {
        UpmeshClient.instance.modals.companyProject
            .get({ filter: 'deleted ne true and inCreation ne true' })
            .then((data) => {
            const promises = [];
            data.forEach((c) => {
                promises.push(new Promise((resolve) => {
                    UserOrCompany.getById(c.createdBy)
                        .then((user) => {
                        c['creatorUsername'] = user.getFullName();
                        resolve(c);
                    })
                        .catch((err) => console.debug(err));
                }));
            });
            return Promise.all(promises);
        })
            .then((data) => {
            this.setState({ data });
        })
            .catch((err) => console.debug('cant load company projects', err));
    }
}
