import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateRangeInput } from 'materialTheme/src/theme/components/forminput/DateRangeInput';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useRef, useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ConfigAll } from '../../config/ConfigAll';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
const getTheMondayBefore = (date) => {
    if (date.getDay() === 1)
        return date;
    if (date.getDay() === 0)
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6, 0, 0, 0, 0);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 1, 0, 0, 0, 0);
};
const todayMorning = new Date();
const lastMonday = getTheMondayBefore(todayMorning);
todayMorning.setHours(0, 0, 0, 0);
const thisWeek = {
    from: new Date(lastMonday.getFullYear(), lastMonday.getMonth(), lastMonday.getDate(), 0, 0, 0, 0),
    to: new Date(lastMonday.getFullYear(), lastMonday.getMonth(), lastMonday.getDate() + 7, 0, 0, 0, -1),
};
const lastWeek = {
    from: new Date(lastMonday.getFullYear(), lastMonday.getMonth(), lastMonday.getDate() - 7, 0, 0, 0, 0),
    to: new Date(lastMonday.getFullYear(), lastMonday.getMonth(), lastMonday.getDate(), 0, 0, 0, -1),
};
const thisMonth = {
    from: new Date(todayMorning.getFullYear(), todayMorning.getMonth(), 1, 0, 0, 0, 0),
    to: new Date(todayMorning.getFullYear(), todayMorning.getMonth() + 1, 1, 0, 0, 0, -1),
};
const lastMonth = {
    from: new Date(todayMorning.getFullYear(), todayMorning.getMonth() - 1, 1, 0, 0, 0, 0),
    to: new Date(todayMorning.getFullYear(), todayMorning.getMonth(), 1, 0, 0, 0, -1),
};
const areDatesEqual = (dateA, dateB) => dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate();
const areDateRangesEqual = (rangeA, rangeB) => areDatesEqual(rangeA.from, rangeB.from) && areDatesEqual(rangeA.to, rangeB.to);
const copyRange = (range) => ({
    from: new Date(range.from.getTime()),
    to: new Date(range.to.getTime()),
});
const getDefaultFileName = (range) => I18n.m.getMessage('journalMultiDownloadDefaultFilename', {
    from: `${range.from.getDate()}-${range.from.getMonth() + 1}-${range.from.getFullYear()}`,
    to: `${range.to.getDate()}-${range.to.getMonth() + 1}-${range.to.getFullYear()}`,
});
function JournalDownloadDialog() {
    const quickSelections = useRef([
        { title: I18n.m.getMessage('journalMultiDownloadThisWeek'), range: thisWeek },
        { title: I18n.m.getMessage('journalMultiDownloadLastWeek'), range: lastWeek },
        { title: I18n.m.getMessage('journalMultiDownloadThisMonth'), range: thisMonth },
        { title: I18n.m.getMessage('journalMultiDownloadLastMonth'), range: lastMonth },
    ]);
    const [selectedDate, setSelectedDate] = useState(thisWeek);
    const [filename, setFilename] = useState('');
    const [fileNameInputFocused, setFileNameInputFocused] = useState(false);
    return (<>
      <DialogTitle key="title">{I18n.m.getMessage('journalMultiDownload')}</DialogTitle>
      <DialogContent>
        <MaterialText type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('journalMultiDownloadSelectRange')}
        </MaterialText>
        <ChipGroup chips={quickSelections.current.map((s) => {
            const selected = selectedDate && areDateRangesEqual(s.range, selectedDate);
            return {
                title: s.title,
                onPressChip: () => setSelectedDate(copyRange(s.range)),
                backgroundColor: selected ? ThemeManager.style.brandPrimary : undefined,
                textColor: selected ? '#FFFFFF' : undefined,
            };
        })}/>
        <DateRangeInput selectedDate={selectedDate} placeholder={I18n.m.getMessage('journalMultiDownloadSelectRange')} onChange={(date) => date && setSelectedDate(copyRange(date))}/>
        <View style={{ height: 8 }}/>
        <MaterialText type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('journalMultiDownloadFileName')}
        </MaterialText>
        <FormInputFilled value={filename} onChangeText={(text) => setFilename((old) => (/^[a-zA-Z0-9._-]*$/.test(text) ? text : old))} onFocus={() => setFileNameInputFocused(true)} onBlur={() => setFileNameInputFocused(false)} labelText={filename || fileNameInputFocused
            ? I18n.m.getMessage('journalMultiDownloadFileName')
            : getDefaultFileName(selectedDate)}/>
      </DialogContent>
      <DialogActions key="journalActions">
        <ContainedButton title={I18n.m.getMessage('abort')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" onPress={Dialog.instance?.close} key="abort"/>
        <ContainedButton title={I18n.m.getMessage('journalMultiDownloadDownlaod')} onPress={() => {
            const headers = {};
            headers['authorization'] = `Bearer ${CurrentUser.token}`;
            Dialog.instance?.close();
            const asyncNow = async () => {
                const res = await fetch(`${ConfigAll.b2cURL}/journal/merge/${filename || getDefaultFileName(selectedDate)}/${CurrentProject.instance.getCurrentProjectId()}/${selectedDate?.from.getTime()}/${selectedDate?.to.getTime()}`, { headers, method: 'GET' });
                if (res.status === 200) {
                    const resObj = await res.json();
                    Toast.instance?.open({
                        title: I18n.m.getMessage('journalMultiDownloadFinished', { name: resObj.filename }),
                        buttonTitle: I18n.m.getMessage('journalMultiDownloadShowInFolder'),
                        onPressButton: () => Routing.instance.goTo(`/projects/${CurrentProject.instance.getCurrentProjectId()}/files/private`),
                    });
                }
                else if (res.status === 404) {
                    Toast.instance?.open({ title: I18n.m.getMessage('journalMultiDownloadNoJournalsFound') });
                }
            };
            asyncNow().catch((err) => console.error(err));
        }} key="save"/>
      </DialogActions>
    </>);
}
export default JournalDownloadDialog;
