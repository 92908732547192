"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateTimeTrackings = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var Config_1 = require("../../../../Config");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ClientStore_1 = require("../../../ClientStore");
var TimeTrackingEntity_1 = require("../../../query/entities/TimeTrackingEntity");
var CreateTimeTracking_1 = require("./CreateTimeTracking");
var CreateTimeTrackings = function (_CommandRoot_1$Comman) {
  function CreateTimeTrackings(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateTimeTrackings);
    _this = _callSuper(this, CreateTimeTrackings, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'CreateTimeTrackings';
    return _this;
  }
  (0, _inherits2.default)(CreateTimeTrackings, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(CreateTimeTrackings, [{
    key: "getCompany",
    value: function () {
      var _getCompany = (0, _asyncToGenerator2.default)(function* () {
        if (this._company == null) this._company = yield CommandReadModels_1.CommandReadModels.company.getById(this.data.companyId);
        return this._company;
      });
      function getCompany() {
        return _getCompany.apply(this, arguments);
      }
      return getCompany;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var company = yield this.getCompany();
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `companyId eq '${company.id}' and userId eq '${userId}' and deleted ne true`,
          top: 1
        });
        if (companyMember.length > 0) this._me = companyMember[0];
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive' && companyMember[0].payroll) return true;
        var promisesPeriodCheck = [];
        if (this.data.userIds != null) {
          this.data.userIds.forEach(function (u, _index) {
            promisesPeriodCheck.push(_this2.checkForRecordingPeriod(u, 'userId'));
          });
          yield Promise.all(promisesPeriodCheck);
        } else {
          this.data.memberIds.forEach(function (u, _index) {
            promisesPeriodCheck.push(_this2.checkForRecordingPeriod(u));
          });
          yield Promise.all(promisesPeriodCheck);
        }
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive') return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return [];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "checkForRecordingPeriod",
    value: function () {
      var _checkForRecordingPeriod = (0, _asyncToGenerator2.default)(function* (memberOrUserId) {
        var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'memberId';
        var member;
        if (type === 'userId') {
          var company = yield this.getCompany();
          var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
            filter: `companyId eq '${company.id}' and userId eq '${memberOrUserId}' and deleted ne true`,
            top: 1
          });
          if (companyMember.length === 1) {
            member = companyMember[0];
          }
        } else {
          member = yield CommandReadModels_1.CommandReadModels.companyMember.getById(memberOrUserId);
        }
        if (member != null) {
          var period = yield TimeTrackingEntity_1.TimeTrackingEntity.getRecordingPeriod(member);
          var starts = new Date(this.data.starts);
          if (starts.getTime() < period.start.getTime() || starts.getTime() > period.end.getTime()) {
            throw {
              message: 'Das Startdatum liegt nicht im möglichen Erfassungszeitraum',
              messageCode: 'timeTrackingNotInRecordingPeriod'
            };
          }
        }
      });
      function checkForRecordingPeriod(_x) {
        return _checkForRecordingPeriod.apply(this, arguments);
      }
      return checkForRecordingPeriod;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'note') {
          if (this.data.note != null && this.data.note.length > Config_1.ConfigHandler.config.string_Large) {
            throw {
              key: 'note',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
        yield CreateTimeTrackings.validateTimeTrackingData(this.data, key);
      });
      function validate(_x2) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "execute",
    value: function () {
      var _execute = (0, _asyncToGenerator2.default)(function* (_onStore) {
        if (this.executed) {
          return;
        }
        this.executed = true;
        var canI = yield this.canI();
        if (canI !== true) {
          throw canI;
        }
        yield this.validate();
        var commands = [];
        var userIds = this.data.userIds;
        var company = yield this.getCompany();
        var restrictMembersForUser = new Set();
        if (this._me != null) {
          if (this._me.canTimeTrackFor === 'self') restrictMembersForUser.add(this._me.id);else if (this._me.canTimeTrackFor === 'group') {
            var companyMembers = yield CommandReadModels_1.CommandReadModels.companyMember.get({
              filter: `companyId eq '${company.id}' and groupId eq '${this._me.groupId}' and deleted ne true`
            });
            companyMembers.forEach(function (m) {
              restrictMembersForUser.add(m.id);
            });
          }
        }
        if (userIds != null && userIds.length > 0) {
          for (var u of userIds) {
            var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
              filter: `companyId eq '${company.id}' and userId eq '${u}' and deleted ne true`,
              top: 1
            });
            if (companyMember.length === 1 && (restrictMembersForUser.size === 0 || restrictMembersForUser.has(companyMember[0].id))) {
              commands.push(new CreateTimeTracking_1.CreateTimeTracking(Object.assign({}, this.data, {
                userId: u,
                memberId: companyMember[0].id
              })));
            }
          }
        } else {
          for (var _u of this.data.memberIds) {
            if (restrictMembersForUser.size === 0 || restrictMembersForUser.has(_u)) {
              var _companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.getById(_u);
              commands.push(new CreateTimeTracking_1.CreateTimeTracking(Object.assign({}, this.data, {
                userId: _companyMember.userId,
                memberId: _u
              })));
            }
          }
        }
        for (var c of commands) {
          yield c.execute(ClientStore_1.ClientStore.commandStore);
        }
        this.result = true;
      });
      function execute(_x3) {
        return _execute.apply(this, arguments);
      }
      return execute;
    }()
  }], [{
    key: "validateTimeTrackingData",
    value: function () {
      var _validateTimeTrackingData = (0, _asyncToGenerator2.default)(function* (data, key) {
        var errors = [];
        if (key == null || key === 'companyId') {
          if (data.companyId == null || data.companyId.length === 0) {
            errors.push({
              message: 'Die Firma wird benötigt',
              messageCode: 'required',
              key: 'companyId'
            });
          }
        }
        if (key == null || key === 'costCenterId' || key === 'projectId') {
          if (data.costCenterId == null || data.costCenterId.length === 0) {
            errors.push({
              message: 'Die Kostenstelle wird benötigt',
              messageCode: 'required',
              key: 'costCenterId'
            });
          } else {
            try {
              var cc = yield CommandReadModels_1.CommandReadModels.costCenter.getById(data.costCenterId);
              if (cc.costType === 'costUnit') {
                if (data.projectId == null) {
                  errors.push({
                    message: 'Wenn ein Kostenträger gewählt ist, wird die Projekt id benötigt',
                    messageCode: 'costUnitNeedsProject',
                    key: 'projectId'
                  });
                }
                var isIn = cc.projectIds == null ? -1 : cc.projectIds.findIndex(function (p) {
                  return p === data.projectId;
                });
                if (isIn === -1) {
                  errors.push({
                    message: 'Das Projekt ist dem Kostenträger nicht zugeordnet',
                    messageCode: 'projectNotInCostUnit',
                    key: 'projectId'
                  });
                }
              }
            } catch (e) {
              errors.push({
                message: 'Diese Kostenstelle existiert nicht',
                messageCode: 'costCenterIdNotFound',
                key: 'costCenterId'
              });
            }
          }
        }
        if (key == null || key === 'memberIds') {
          if (data.memberIds == null || data.memberIds.length === 0) {
            errors.push({
              message: 'Min. 1 Benutzer wird benötigt',
              messageCode: 'required',
              key: 'memberIds'
            });
          }
        }
        if (key == null || key === 'starts') {
          if (data.starts == null) {
            errors.push({
              message: 'Die Startzeit wird benötigt',
              messageCode: 'required',
              key: 'starts'
            });
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validateTimeTrackingData(_x4, _x5) {
        return _validateTimeTrackingData.apply(this, arguments);
      }
      return validateTimeTrackingData;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.CreateTimeTrackings = CreateTimeTrackings;