import { PromisePool } from 'cqrs-shared/src/PromisePool';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Datepicker } from 'materialTheme/src/theme/components/datepickerv2/Datepicker';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { ContentHeaderEventHandler, } from 'materialTheme/src/theme/components/header/ContentHeaderEventHandler';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { ProgressBar } from 'materialTheme/src/theme/components/ProgressBar';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { UIManager, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeTimeTracking, } from 'upmesh-core/src/client/commands/companies/timetracking/ChangeTimeTracking';
import { HolidayEntity } from 'upmesh-core/src/client/query/entities/HolidayEntity';
import { Daytime } from 'upmesh-core/src/client/query/entities/WorkingTimeModelEntity';
import { CombinedExtraPayAndTimeTrackingEntity, CombinedExtraPayAndTimeTrackingFilter, } from 'upmesh-core/src/client/query/filter/CombinedExtraPayAndTimeTrackingFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../config/CachedEntities';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { AddOrChangeExtraPayTrackDialog } from '../extrapay/AddOrChangeExtraPayTrackDialog';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { GlobalBar } from '../root/GlobalBar';
import { PageView } from '../root/PageView';
import { AddTimeTrackDialog } from './AddTimeTrackDialog';
import { CombinedExtraPayAndTimeTrackingDialogFilter } from './CombinedExtraPayAndTimeTrackingDialogFilter';
import { TimeTrackingBulkChangeDialogContent } from './TimeTrackingBulkChangeDialogContent';
import { TimeTrackingCalendar } from './TimeTrackingCalender';
import { TimeTrackingExcelExport } from './TimeTrackingExcelExport';
import { TimeTrackingExportDialog } from './TimeTrackingExportDialog';
import { TimeTrackingList } from './TimeTrackingList';
import { TimeTrackingTableView } from './TimeTrackingTableView';
export class TimeTrackingView extends PureComponent {
    constructor(props) {
        super(props);
        this.allEntries = [];
        this.bulkChange = undefined;
        this.exportDialogOpen = false;
        this.searching = false;
        this.closeMultiselectHeader = () => {
            const data = {
                open: false,
                headerHeight: 0,
                headerContent: undefined,
            };
            ContentHeaderEventHandler.statusEvent.post(data);
        };
        this.openTimeTrackingFab = () => {
            if (Fab.instance != null) {
                if (CompanyUserInfo.me?.payroll || CompanyUserInfo.me?.extraPayTracking) {
                    Fab.instance.open({
                        fabIcon: 'plus',
                        fabIconOpen: 'close',
                        fabActions: [
                            {
                                icon: 'database-plus-outline',
                                onPress: this.openAddExtraTrackDialog,
                                text: I18n.m.getMessage('extraPayAddTrack'),
                            },
                            {
                                icon: 'clock-outline',
                                onPress: this.openAddTimeTrackDialog,
                                text: I18n.m.getMessage('timeTrackingCaptureTime'),
                            },
                        ],
                        small: false,
                        fabColor: ThemeManager.style.brandPrimary,
                        fabColorOpen: ThemeManager.style.brandSecondary,
                        extraPaddingBottom: ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0,
                    });
                }
                else {
                    Fab.instance.open({
                        fabIcon: 'plus',
                        fabIconOpen: 'close',
                        small: false,
                        fabColor: ThemeManager.style.brandPrimary,
                        fabColorOpen: ThemeManager.style.brandSecondary,
                        onPressFab: this.openAddTimeTrackDialog,
                        title: I18n.m.getMessage('timeTrackingCaptureTime'),
                        extraPaddingBottom: ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0,
                    });
                }
            }
            else {
                window.setTimeout(this.openTimeTrackingFab, 100);
            }
        };
        this.openAddTimeTrackDialog = () => {
            const { filter } = this.state;
            let selectedDate;
            if (filter && filter.date && filter.dateTo) {
                const date = new Date(filter.date);
                const dateTo = new Date(filter.dateTo);
                if (filter.dateTo < filter.date + 86400000 && dateTo.getDate() === date.getDate())
                    selectedDate = new Date(filter.date);
            }
            Dialog.instance?.open({
                content: <AddTimeTrackDialog selectedDate={selectedDate}/>,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
            });
        };
        this.onPressChangeView = (index, _button) => {
            const { view } = this.state;
            if (view === 'list' && index === 1) {
                SimpleStorage.set('timeTrackingView', 'table');
                this.setState({ view: 'table' });
            }
            else if (view === 'table' && index === 0) {
                this.onChangeMultiSelectStatus(false);
                SimpleStorage.set('timeTrackingView', 'list');
                this.setState({ view: 'list' });
            }
        };
        this.openAddExtraTrackDialog = () => {
            const { filter } = this.state;
            let date;
            if (filter.date != null && filter.dateTo != null) {
                const fdate = new Date(filter.date);
                const todate = new Date(filter.dateTo);
                if (fdate.getDate() === todate.getDate() &&
                    fdate.getMonth() === todate.getMonth() &&
                    fdate.getFullYear() === todate.getFullYear()) {
                    date = new Date(filter.date);
                }
            }
            Dialog.instance?.open({
                content: <AddOrChangeExtraPayTrackDialog selectedDate={date}/>,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
            });
        };
        this.confirmDateChange = (newDate) => {
            const { selectedIDs, bulkChangeProgress } = this.state;
            const content = bulkChangeProgress === 0 ? (<View style={{ padding: ThemeManager.style.contentPaddingValue }} key={`dialogContentQuestion${bulkChangeProgress === 0}`}>
          <MaterialText type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('timeTrackingBulkChangesConfirmationDialogHeader')}
          </MaterialText>
          <MaterialText>{I18n.m.getMessage('bulkChangesConfirmationDialog', { count: selectedIDs.size })}</MaterialText>

          <View style={{ alignSelf: 'flex-end', flexDirection: 'row', paddingTop: 36 }}>
            <ContainedButton title={I18n.m.getMessage('back')} onPress={this.openDatePicker} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
            <ContainedButton title={I18n.m.getMessage('apply')} onPress={() => {
                    this.changeTime(newDate).catch((err) => console.error(err));
                }}/>
          </View>
        </View>) : (<View style={{ padding: ThemeManager.style.contentPaddingValue }} key={`dialogContentProgress${bulkChangeProgress === 0}`}>
          <MaterialText>
            {I18n.m.getMessage('timeTrackingBulkChangesChangingTickets', {
                    count: bulkChangeProgress,
                    sum: selectedIDs.size,
                })}
          </MaterialText>
          <View style={{ height: ThemeManager.style.getScreenRelativePixelSize(16) }}/>
          <ProgressBar progressInPercent={Math.round((bulkChangeProgress / selectedIDs.size) * 100)}/>
        </View>);
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                contentPadding: false,
                content,
            });
        };
        this.filterTimeTrackingState = (filter) => {
            this.getfilteredEntries(filter)
                .then((entriesInDates) => {
                this.setState({ entriesInDates, filter }, () => {
                    if (this.exportDialogOpen) {
                        this.pressExport();
                    }
                });
            })
                .catch((err) => {
                console.debug('cant filterTimeTrackingState', err);
            });
        };
        this.cachedWorkingTimModel = {};
        this.getWorkingTimeModelForMember = async (day, memberId) => {
            if (memberId != null) {
                const member = CachedEntities.knownCompanyMember.has(memberId)
                    ? CachedEntities.knownCompanyMember.get(memberId)
                    : CompanyUserInfo.companyMember.find((m) => m.id === memberId);
                if (member != null && member.workingTimeModelId != null) {
                    try {
                        if (this.cachedWorkingTimModel[memberId] == null) {
                            this.cachedWorkingTimModel[memberId] = await UpmeshClient.instance.modals.workingTimeModel.getById(member.workingTimeModelId);
                        }
                        const workinTimeModel = this.cachedWorkingTimModel[memberId];
                        const d = workinTimeModel.getCorrectDaytime(day);
                        let targetStart;
                        let targetEnd;
                        let targetPause;
                        if (d != null) {
                            if (d.start != null)
                                targetStart = Daytime.toDate(d.start);
                            if (d.stop != null)
                                targetEnd = Daytime.toDate(d.stop);
                            if (d.pause != null)
                                targetPause = Daytime.toDate(d.pause);
                        }
                        const targetTimeInMS = workinTimeModel.getTargetSumInMs(day);
                        let holidayTimeInMS = 0;
                        const holidays = await HolidayEntity.getHolidayForDayAndUser(member, day);
                        if (holidays.length > 0) {
                            let maxHoliday = holidays[0];
                            holidays.forEach((holiday) => {
                                if (holiday.type === 'day') {
                                    maxHoliday = holiday;
                                }
                                else if (holiday.amount > maxHoliday.amount) {
                                    maxHoliday = holiday;
                                }
                            });
                            if (maxHoliday.type === 'day') {
                                holidayTimeInMS = 24 * 60 * 60 * 1000;
                            }
                            else {
                                holidayTimeInMS = maxHoliday.amount * 60 * 60 * 1000;
                            }
                        }
                        return {
                            workinTimeModel,
                            targetStart,
                            targetEnd,
                            targetPause,
                            targetTimeInMS: targetTimeInMS != null ? targetTimeInMS : 0,
                            holidayTimeInMS,
                        };
                    }
                    catch (err) {
                        console.debug('Cant get workingTimeModel', err);
                    }
                }
            }
            return { targetTimeInMS: 0, holidayTimeInMS: 0 };
        };
        this.getfilteredEntries = async (filter) => {
            if (filter.date == null || filter.dateTo == null)
                return [];
            const { showMissing } = this.state;
            const startDate = new Date(filter.date);
            const lastChange = new Date();
            const daysDiff = TimeTrackingCalendar.dateDiffInDays(startDate, new Date(filter.dateTo));
            const entriesInDates = [];
            const { me } = CompanyUserInfo;
            if (me == null)
                return [];
            const userIds = filter.userIds != null && filter.userIds.length > 0 ? [...filter.userIds] : [CurrentUser.userId];
            if (me.payroll && (filter.userIds == null || filter.userIds.length === 0)) {
                CompanyUserInfo.companyMember.forEach((m) => {
                    if (m.userId != null && m.userId !== me.userId) {
                        userIds.push(m.userId);
                    }
                    else if (m.userId == null)
                        userIds.push(m.id);
                });
            }
            const all = this.allEntries;
            const filtered = CombinedExtraPayAndTimeTrackingFilter.filterTimeTrackings(all, filter);
            for (let i = 0; i <= daysDiff; i += 1) {
                const memberEntries = [];
                const day = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + i, 12, 0, 0, 0);
                const today = new Date();
                today.setHours(13);
                if (day.getTime() > today.getTime())
                    break;
                for (const userId of userIds) {
                    const member = CompanyUserInfo.companyMember.find((a) => a.userId == null ? a.id === userId : a.userId === userId);
                    if (member != null && member.role !== 'inactive') {
                        const memberId = member.id;
                        if (showMissing && this.showMissingPossible(filter)) {
                            const w = await this.getWorkingTimeModelForMember(day, memberId);
                            if (w != null && w.targetTimeInMS > 0 && w.targetTimeInMS - w.holidayTimeInMS > 0) {
                                memberEntries.push({
                                    memberId,
                                    memberEntries: [],
                                    totalTime: 0,
                                    projectTitles: [],
                                    tasks: [],
                                    targetTimeInMS: w.targetTimeInMS,
                                    holidayTimeInMS: w.holidayTimeInMS,
                                    targetStart: w.targetStart,
                                    targetEnd: w.targetEnd,
                                    targetPause: w.targetPause,
                                });
                            }
                        }
                    }
                }
                entriesInDates.push({ date: day, lastChange, totalTime: 0, entries: memberEntries });
            }
            for (let i = 0; i < filtered.length; i += 1) {
                const e = filtered[i];
                const starts = typeof e.day === 'number' ? new Date(e.day) : e.day;
                const d = new Date(starts.getFullYear(), starts.getMonth(), starts.getDate(), 12, 0, 0, 0);
                const dateExists = entriesInDates.findIndex((day) => day.date.getTime() === d.getTime());
                const title = e.projectTitle != null && e.projectTitle.length > 0 ? e.projectTitle : e.costCenterName;
                const memberEntry = {
                    memberId: e.memberId,
                    memberEntries: [e],
                    totalTime: e.sumInMS,
                    projectTitles: [title],
                    tasks: e.taskName ? [e.taskName] : [],
                    endTime: e.ends,
                    startTime: e.starts,
                };
                if (dateExists > -1) {
                    const updated = entriesInDates[dateExists];
                    updated.totalTime += e.sumInMS;
                    const memberExists = updated.entries.findIndex((m) => m.memberId === e.memberId);
                    if (memberExists > -1) {
                        const m = updated.entries[memberExists];
                        m.totalTime += e.sumInMS;
                        if (!m.projectTitles?.includes(title))
                            m.projectTitles?.push(title);
                        if (e.taskName != null && !m.tasks.includes(e.taskName))
                            m.tasks.push(e.taskName);
                        if (e.starts != null && (m.startTime == null || e.starts.getTime() < m.startTime.getTime()))
                            m.startTime = e.starts;
                        if (e.ends != null && (m.endTime == null || e.ends.getTime() > m.endTime.getTime()))
                            m.endTime = e.ends;
                        m.memberEntries.push(e);
                    }
                    else {
                        const w = await this.getWorkingTimeModelForMember(d, memberEntry.memberId);
                        memberEntry.targetTimeInMS = w.targetTimeInMS;
                        memberEntry.holidayTimeInMS = w.holidayTimeInMS;
                        memberEntry.workinTimeModel = w.workinTimeModel;
                        updated.entries = [...updated.entries, memberEntry];
                    }
                    entriesInDates[dateExists] = updated;
                }
                else {
                    const w = await this.getWorkingTimeModelForMember(d, memberEntry.memberId);
                    memberEntry.targetTimeInMS = w.targetTimeInMS;
                    memberEntry.holidayTimeInMS = w.holidayTimeInMS;
                    memberEntry.workinTimeModel = w.workinTimeModel;
                    entriesInDates.push({ date: d, lastChange, totalTime: memberEntry.totalTime, entries: [memberEntry] });
                }
            }
            entriesInDates.sort((a, b) => {
                if (a.date.getTime() > b.date.getTime())
                    return -1;
                if (a.date.getTime() < b.date.getTime())
                    return 1;
                return 0;
            });
            entriesInDates.forEach((f) => {
                f.entries.sort((a, b) => {
                    if (a.memberId > b.memberId)
                        return -1;
                    if (a.memberId < b.memberId)
                        return 1;
                    return 0;
                });
            });
            return [...entriesInDates];
        };
        this.openDatePicker = () => {
            Dialog.instance?.close();
            let label = '';
            if (this.bulkChange === 'starttime') {
                label = I18n.m.getMessage('timeTrackingBulkChangesChangeStart');
            }
            else if (this.bulkChange === 'endtime') {
                label = I18n.m.getMessage('timeTrackingBulkChangesChangeEnd');
            }
            else if (this.bulkChange === 'pause') {
                label = I18n.m.getMessage('timeTrackingBulkChangesChangePause');
            }
            const today = new Date();
            Datepicker.open({
                onChange: this.confirmDateChange,
                labelText: label,
                mode: 'time',
                selectedDate: this.bulkChange === 'pause'
                    ? new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)
                    : today,
            });
        };
        this.openNoRightsErrorDialog = (errors) => {
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: (<View>
          <MaterialText>{I18n.m.getMessage('timeTrackingBulkChangesNoRights', { count: errors })}</MaterialText>
          <View style={{ paddingTop: 28, alignSelf: 'flex-end' }}>
            <ContainedButton title={I18n.m.getMessage('ok')} onPress={Dialog.instance?.close}/>
          </View>
        </View>),
            });
        };
        this.changeFilter = (selected) => {
            const currentFilter = this.state.filter;
            const newFilter = {
                ...currentFilter,
                date: new Date(selected.startDate).getTime(),
                dateTo: new Date(selected.endDate).getTime(),
            };
            try {
                const notEqual = JSON.stringify(currentFilter) !== JSON.stringify(newFilter);
                if (notEqual) {
                    requestAnimationFrame(() => {
                        Routing.instance.changeQueryParameter({
                            ft: JSON.stringify(newFilter),
                        });
                    });
                }
            }
            catch (err) {
                console.debug('cant update Filter', err);
            }
        };
        this.onLayoutCalendar = (e) => {
            this.setState({ calendarHeight: e.nativeEvent.layout.height });
        };
        this.openThreeDots = (e) => {
            Menu.instance?.close();
            const { view } = this.state;
            const { target } = e.nativeEvent;
            UIManager.measureInWindow(target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 320,
                };
                const items = [
                    {
                        text: I18n.m.getMessage('bulkChangesIconTooltip'),
                        onPress: () => this.onChangeMultiSelectStatus(true),
                        disabled: view === 'list',
                        thumbnail: {
                            width: 24,
                            thumbnail: (<Icon icon="checkbox-multiple-marked-outline" key="bulkChangeIcon" toolTip="" color={view === 'list' ? ThemeManager.style.disabledColor : ThemeManager.style.defaultIconColor}/>),
                        },
                    },
                    {
                        text: I18n.m.getMessage('exportAsCSV'),
                        onPress: this.pressExport,
                        thumbnail: { width: 24, thumbnail: <Icon icon="export" key="csvexport" toolTip=""/> },
                    },
                    {
                        text: I18n.m.getMessage('timeTrackingExportExcelTooltip'),
                        onPress: this.createAndDownloadExcel,
                        thumbnail: { width: 24, thumbnail: <Icon icon="download-outline" key="excel-export" toolTip=""/> },
                    },
                ];
                Menu.instance?.open({
                    client,
                    items,
                });
            });
        };
        this.getViewIcon = () => {
            const { view } = this.state;
            let icon = {
                icon: 'format-list-bulleted',
                rotation: 0,
                iconIconMoon: false,
                toolTip: I18n.m.getMessage('timeTrackingViewChangeList'),
                onPress: () => this.onPressChangeView(0, undefined),
            };
            if (view === 'list') {
                icon = {
                    icon: 'table-large',
                    rotation: 0,
                    iconIconMoon: false,
                    toolTip: I18n.m.getMessage('timeTrackingViewChangeCalandar'),
                    onPress: () => this.onPressChangeView(1, undefined),
                };
            }
            icon.toolTip = I18n.m.getMessage('changeView');
            icon.color = 'white';
            return icon;
        };
        this.showMissingPossible = (filter) => {
            if (filter != null &&
                ((filter.words != null && filter.words.length > 0) ||
                    (filter.status != null && filter.status.length > 0) ||
                    (filter.taskIds != null && filter.taskIds.length > 0) ||
                    (filter.costCenterIds != null && filter.costCenterIds.length > 0) ||
                    (filter.projectIds != null && filter.projectIds.length > 0)))
                return false;
            return true;
        };
        this.getShowMissingIcon = () => {
            const { view, showMissing, filter } = this.state;
            let icon = {
                icon: 'eye-outline',
                disabled: true,
                rotation: 0,
                iconIconMoon: false,
                toolTip: I18n.m.getMessage('timeTrackingViewChangeShowMissing'),
                onPress: () => this.onPressChangeView(0, undefined),
            };
            if (view === 'list' && this.showMissingPossible(filter)) {
                if (showMissing) {
                    icon = {
                        icon: 'eye-off-outline',
                        disabled: false,
                        rotation: 0,
                        iconIconMoon: false,
                        toolTip: I18n.m.getMessage('timeTrackingViewChangeHideMissing'),
                        onPress: () => {
                            TimeTrackingView.showMissing = false;
                            this.setState({ showMissing: false }, () => {
                                this.updateData().catch((err) => console.error('cant update TimeTrackingData', err));
                            });
                        },
                    };
                }
                else {
                    icon = {
                        icon: 'eye-outline',
                        disabled: false,
                        rotation: 0,
                        iconIconMoon: false,
                        toolTip: I18n.m.getMessage('timeTrackingViewChangeShowMissing'),
                        onPress: () => {
                            TimeTrackingView.showMissing = true;
                            this.setState({ showMissing: true }, () => {
                                this.updateData().catch((err) => console.error('cant update TimeTrackingData', err));
                            });
                        },
                    };
                }
            }
            icon.color = 'white';
            return icon;
        };
        this.searchTimeTrackings = (text) => {
            if (this.searchTimer != null) {
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = window.setTimeout(this.searchNow(text), 250);
        };
        this.selectAll = () => {
            const { entriesInDates, selectedIDs } = this.state;
            let selected = new Set();
            entriesInDates.forEach((entries) => {
                entries.entries.forEach((entryDate) => {
                    entryDate.memberEntries.forEach((entry) => {
                        if (entry.type === 'TimeTrackingEntity' && !entry.orgEntry['absenceId'])
                            selected.add(entry.id);
                    });
                });
            });
            if (selectedIDs.size === selected.size) {
                selected = new Set();
            }
            this.setState({ selectedIDs: selected }, this.openMultiselectHeader);
        };
        this.changeTime = async (newDate) => {
            const { selectedIDs } = this.state;
            let errorCount = 0;
            for (let i = 0; i < this.allEntries.length; i += 1) {
                if (this.allEntries[i].type === 'TimeTrackingEntity' && selectedIDs.has(this.allEntries[i].id)) {
                    this.setState({ bulkChangeProgress: i + 1 });
                    const entry = this.allEntries[i].orgEntry;
                    entry.starts = new Date(entry.starts);
                    if (entry.ends != null)
                        entry.ends = new Date(entry.ends);
                    const data = {
                        changeTime: new Date(),
                        starts: this.bulkChange === 'starttime'
                            ? new Date(entry.starts.getFullYear(), entry.starts.getMonth(), entry.starts.getDate(), newDate.getHours(), newDate.getMinutes())
                            : entry.starts,
                        ends: this.bulkChange === 'endtime'
                            ? new Date(entry.starts.getFullYear(), entry.starts.getMonth(), entry.starts.getDate(), newDate.getHours(), newDate.getMinutes())
                            : entry.ends,
                        pause: this.bulkChange === 'pause' ? { hours: newDate.getHours(), minutes: newDate.getMinutes() } : entry.pause,
                        note: entry.note,
                        taskId: entry.taskId,
                        projectId: entry.projectId,
                    };
                    try {
                        const c = new ChangeTimeTracking(data, entry.id);
                        await c.execute(ClientStore.commandStore);
                    }
                    catch (e) {
                        errorCount += 1;
                    }
                }
            }
            Dialog.instance?.close(() => {
                if (errorCount > 0) {
                    this.openNoRightsErrorDialog(errorCount);
                }
                this.setState({ bulkChangeProgress: 0 }, () => {
                    this.onChangeMultiSelectStatus(false, () => {
                        this.checkSelectedTicketVisibility();
                    });
                });
            });
        };
        this.checkSelectedTicketVisibility = () => {
            const { selectedIDs, activeMultiselect, entriesInDates } = this.state;
            const allIDs = new Set();
            entriesInDates.forEach((entries) => {
                entries.entries.forEach((entryDate) => {
                    entryDate.memberEntries.forEach((entry) => allIDs.add(entry.id));
                });
            });
            const selected = new Set();
            selectedIDs.forEach((s) => {
                if (allIDs.has(s))
                    selected.add(s);
            });
            this.setState({ allIDs, selectedIDs: selected }, () => {
                if (activeMultiselect)
                    this.openMultiselectHeader();
            });
        };
        this.getTimesForExport = () => {
            const { entriesInDates, activeMultiselect, selectedIDs } = this.state;
            const entries = [];
            if (activeMultiselect) {
                selectedIDs.forEach((e) => {
                    const entry = this.allEntries.find((c) => c.id === e);
                    if (entry != null) {
                        const type = entry.type === 'ExtraPayTrackingEntity'
                            ? 'ExtraPayTrackingEntity'
                            : entry.type === 'TimeTrackingEntity' && entry.orgEntry['absenceId']
                                ? 'AbsenceEntity'
                                : 'TimeTrackingEntity';
                        entries.push({ id: e, type, timeTrackingProxy: entry });
                    }
                });
                return entries;
            }
            entriesInDates.forEach((ent) => {
                ent.entries.forEach((e) => {
                    e.memberEntries.forEach((e2) => {
                        const type = e2.type === 'ExtraPayTrackingEntity'
                            ? 'ExtraPayTrackingEntity'
                            : e2.type === 'TimeTrackingEntity' && e2.orgEntry['absenceId']
                                ? 'AbsenceEntity'
                                : 'TimeTrackingEntity';
                        entries.push({ id: e2.id, type, timeTrackingProxy: e2 });
                    });
                });
            });
            return entries;
        };
        this.initRunning = false;
        this.onChangeMultiSelectStatus = (status, callback) => {
            Menu.instance?.close();
            const { selectedIDs } = this.state;
            this.setState({ activeMultiselect: status, selectedIDs: !status ? new Set() : selectedIDs }, () => {
                if (status) {
                    this.openMultiselectHeader();
                }
                else {
                    this.closeMultiselectHeader();
                }
                if (callback != null)
                    callback();
            });
        };
        this.onMultiSelect = (selectedIDs) => {
            this.setState({ selectedIDs }, () => {
                this.onChangeMultiSelectStatus(selectedIDs.size > 0);
            });
        };
        this.openBulkChangeDialog = (topic) => () => {
            const { selectedIDs } = this.state;
            this.bulkChange = topic;
            if (topic !== 'starttime' && topic !== 'endtime' && topic !== 'pause') {
                Dialog.instance?.open({
                    closeOnTouchOutside: true,
                    fullscreenResponsive: topic !== 'delete' && topic !== 'status',
                    content: (<TimeTrackingBulkChangeDialogContent changedTopic={topic} selectedIDs={selectedIDs} onChange={() => {
                            this.onChangeMultiSelectStatus(false, () => {
                                this.checkSelectedTicketVisibility();
                            });
                        }} errorDialog={this.openNoRightsErrorDialog} allEntries={this.allEntries}/>),
                    contentPadding: false,
                });
            }
            else {
                this.openDatePicker();
            }
        };
        this.openMultiselectHeader = () => {
            const { selectedIDs, allIDs } = this.state;
            const globalbar = ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM;
            const headerHeight = globalbar
                ? ThemeManager.style.headerHeight + ThemeManager.style.getScreenRelativePixelSize(48)
                : ThemeManager.style.headerHeight;
            const headerContent = (<View style={{
                    flexDirection: 'row',
                    height: '100%',
                    justifyContent: 'space-between',
                    paddingLeft: ThemeManager.style.contentPaddingValue,
                    paddingRight: ThemeManager.style.contentPaddingValue,
                    width: '100%',
                    alignItems: 'center',
                }}>
        <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
          <Icon toolTip={I18n.m.getMessage('close')} icon="close" color={ThemeManager.style.brandPrimary} onPress={() => this.onChangeMultiSelectStatus(false)}/>
          <MaterialText color={ThemeManager.style.brandPrimary} centeredText centeredBox>
            {selectedIDs.size}
          </MaterialText>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Icon icon="label-outline" toolTip={I18n.m.getMessage('timeTrackingBulkChangesChangeStatus')} color={ThemeManager.style.brandPrimary} onPress={this.openBulkChangeDialog('status')} disabled={selectedIDs.size === 0}/>
          <Icon icon="clock-start" toolTip={I18n.m.getMessage('timeTrackingBulkChangesChangeStart')} color={ThemeManager.style.brandPrimary} onPress={this.openBulkChangeDialog('starttime')} disabled={selectedIDs.size === 0}/>
          <Icon icon="clock-end" toolTip={I18n.m.getMessage('timeTrackingBulkChangesChangeEnd')} color={ThemeManager.style.brandPrimary} onPress={this.openBulkChangeDialog('endtime')} disabled={selectedIDs.size === 0}/>
          <Icon icon="coffee-outline" toolTip={I18n.m.getMessage('timeTrackingBulkChangesChangePause')} color={ThemeManager.style.brandPrimary} onPress={this.openBulkChangeDialog('pause')} disabled={selectedIDs.size === 0}/>
          <Icon icon="domain" toolTip={I18n.m.getMessage('timeTrackingBulkChangesChangeProjekt')} color={ThemeManager.style.brandPrimary} onPress={this.openBulkChangeDialog('project')} disabled={selectedIDs.size === 0}/>
          <Icon icon="hand-coin-outline" toolTip={I18n.m.getMessage('timeTrackingBulkChangesChangeCostCenter')} color={ThemeManager.style.brandPrimary} onPress={this.openBulkChangeDialog('costcenter')} disabled={selectedIDs.size === 0}/>
          <Icon icon="briefcase-outline" toolTip={I18n.m.getMessage('timeTrackingBulkChangesChangeTask')} color={ThemeManager.style.brandPrimary} onPress={this.openBulkChangeDialog('task')} disabled={selectedIDs.size === 0}/>
          <Icon icon="delete-outline" toolTip={I18n.m.getMessage('delete')} color={ThemeManager.style.brandPrimary} onPress={this.openBulkChangeDialog('delete')} disabled={selectedIDs.size === 0}/>
          <Icon icon="export" toolTip={I18n.m.getMessage('exportAsCSV')} color={ThemeManager.style.brandPrimary} onPress={this.pressExport} disabled={selectedIDs.size === 0}/>
          <Icon icon={allIDs.size > 0 && selectedIDs.size < allIDs.size
                    ? 'checkbox-multiple-marked-outline'
                    : 'checkbox-multiple-blank-outline'} toolTip={selectedIDs.size < allIDs.size
                    ? I18n.m.getMessage('bulkChangesSelectAll')
                    : I18n.m.getMessage('bulkChangesSelectNone')} color={ThemeManager.style.brandPrimary} onPress={this.selectAll}/>
        </View>
      </View>);
            const data = {
                headerContent,
                open: true,
                headerHeight,
            };
            ContentHeaderEventHandler.statusEvent.post(data);
        };
        this.createAndDownloadExcel = () => {
            const { entriesInDates, filter } = this.state;
            Menu.instance?.close();
            if (AuthClient.instance.serverConnected() && AuthClient.instance.syncDispatcher.currentSyncStatus.percent >= 100) {
                TimeTrackingExcelExport.createExcel(entriesInDates, filter.date ? new Date(filter.date) : new Date(), filter.dateTo ? new Date(filter.dateTo) : new Date()).catch((e) => {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('timeTrackingExportExcelNotOnline') });
            }
        };
        this.pressExport = () => {
            Menu.instance?.close();
            const { filter, payroll } = this.state;
            const isFilterActive = filter.dateTo != null ||
                (filter.status != null && filter.status.length > 0) ||
                filter.date != null ||
                (filter.userIds != null && filter.userIds.length > 0) ||
                (filter.projectIds != null && filter.projectIds.length > 0) ||
                (filter.costCenterIds != null && filter.costCenterIds.length > 0) ||
                (filter.extraPayIds != null && filter.extraPayIds.length > 0) ||
                (filter.taskIds != null && filter.taskIds.length > 0);
            const allFiltered = this.getTimesForExport();
            const justTimeTrackings = allFiltered
                .filter((element) => {
                return element.type !== 'AbsenceEntity';
            })
                .map((filteredElement) => {
                return {
                    id: filteredElement.id,
                    type: filteredElement.type === 'TimeTrackingEntity'
                        ? filteredElement.type
                        : 'ExtraPayTrackingEntity',
                };
            });
            const justAbsence = allFiltered
                .filter((element) => {
                return element.type === 'AbsenceEntity';
            })
                .map((filteredElement) => {
                return filteredElement.timeTrackingProxy;
            });
            Dialog.instance?.open({
                content: (<TimeTrackingExportDialog ft={this.props.ft} times={justTimeTrackings} openFilter={this.pressFilter} payroll={payroll} filterActive={isFilterActive} absenceAsTimeTracking={justAbsence}/>),
                showCloseIcon: false,
                showCloseButton: false,
                contentPadding: false,
                onClose: () => {
                    this.exportDialogOpen = false;
                },
            });
            this.exportDialogOpen = true;
        };
        this.searchNow = (text) => () => {
            if (this.searching) {
                this.searchTimer = window.setTimeout(this.searchNow(text), 100);
                return;
            }
            this.searching = true;
            this.searching = false;
            const { filter } = this.state;
            filter.words = text;
            Routing.instance.changeQueryParameter({ ft: JSON.stringify(filter) });
            this.setState({ searchWords: text });
        };
        this.updateData = async (en) => {
            if (en && en.entities.size > 0) {
                for (const e of en.entities.values()) {
                    const convert = await CombinedExtraPayAndTimeTrackingEntity.convert(e.entity, CachedEntities.knownUsers, CachedEntities.knownTasks, CachedEntities.knownCompanyMember, CachedEntities.knownExtraPay, CachedEntities.knownCostCenter);
                    const index = this.allEntries.findIndex((s) => s.id === convert.id);
                    if (index > -1) {
                        if (convert.deleted) {
                            this.allEntries.splice(index, 1);
                        }
                        else {
                            this.allEntries[index] = convert;
                        }
                        const { filter } = this.state;
                        const entries = await this.getfilteredEntries(filter);
                        this.setState({ entriesInDates: entries });
                    }
                    else {
                        this.allEntries.unshift(convert);
                        const { filter } = this.state;
                        const entries = await this.getfilteredEntries(filter);
                        this.setState({ entriesInDates: entries });
                    }
                }
            }
            else {
                const { filter } = this.state;
                const entries = await this.getfilteredEntries(filter);
                this.setState({ entriesInDates: entries });
            }
        };
        this.pressFilter = () => {
            if (this.allEntries != null) {
                CombinedExtraPayAndTimeTrackingDialogFilter.open(this.allEntries, this.state.filter);
            }
        };
        let filter = new CombinedExtraPayAndTimeTrackingFilter();
        const t = new Date();
        filter.date = new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0, 0).getTime();
        filter.dateTo = new Date(t.getFullYear(), t.getMonth() + 1, t.getDate(), 0, 0, 0, -1).getTime();
        if (props.ft != null) {
            try {
                const parsed = JSON.parse(props.ft);
                filter = parsed;
            }
            catch (e) {
                console.debug('cant read CombinedExtraPayAndTimeTrackingFilter', e);
            }
        }
        this.state = {
            filter,
            entriesInDates: [],
            loading: true,
            searchWords: filter.words != null ? filter.words : '',
            activeMultiselect: false,
            selectedIDs: new Set(),
            bulkChangeProgress: 0,
            payroll: !!CompanyUserInfo.me?.payroll,
            allIDs: new Set(),
            calendarHeight: 0,
            view: 'list',
            showMissing: TimeTrackingView.showMissing,
        };
    }
    componentDidMount() {
        const { companySettings } = this.props;
        if (companySettings == null || !companySettings.hasModule('timeTracking')) {
            Routing.instance.goTo('/projects', true);
        }
        else {
            this.openTimeTrackingFab();
            requestAnimationFrame(() => {
                this.init().catch((err) => console.debug('cant load time tracking entries', err));
            });
        }
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyTimeTrackingView',
            readModelName: 'TimeTracking',
            callback: (e) => {
                this.updateData(e).catch((err) => console.error(err));
            },
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyTimeTrackingView2',
            readModelName: 'ExtraPayTracking',
            callback: (e) => {
                this.updateData(e).catch((err) => console.error(err));
            },
        });
    }
    componentDidUpdate(prevProps, _prevState) {
        if (prevProps.ft !== this.props.ft) {
            let filter = new CombinedExtraPayAndTimeTrackingFilter();
            if (this.props.ft != null) {
                try {
                    filter = JSON.parse(this.props.ft);
                    SimpleStorage.set(`${CurrentUser.userId}_saved_timetrackingfilter2`, this.props.ft);
                }
                catch (e) {
                    console.debug('cant read CombinedExtraPayAndTimeTrackingFilter', e);
                }
            }
            if (this.checkFilter(filter)) {
                this.filterTimeTrackingState(filter);
                this.checkSelectedTicketVisibility();
            }
        }
    }
    checkFilter(filter) {
        if (!filter.date || !filter.dateTo) {
            const today = new Date();
            const dateEnd = filter.dateTo != null ? new Date(filter.dateTo) : null;
            const date = filter.date != null
                ? new Date(filter.date)
                : dateEnd != null
                    ? new Date(dateEnd.getFullYear(), dateEnd.getMonth() - 1, dateEnd.getDate() + 1, 0, 0, 0, 0)
                    : new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0);
            const newFilter = {
                ...filter,
                date: date.getTime(),
                dateTo: new Date(date.getFullYear(), date.getMonth() + 1, date.getDate(), 0, 0, 0, -1).getTime(),
            };
            try {
                requestAnimationFrame(() => {
                    Routing.instance.changeQueryParameter({
                        ft: JSON.stringify(newFilter),
                    });
                });
                return false;
            }
            catch (err) {
                console.debug('cant update Filter', err);
            }
        }
        return true;
    }
    componentWillUnmount() {
        this.closeMultiselectHeader();
        if (this.initTO)
            clearTimeout(this.initTO);
        Fab.instance?.close();
        UpmeshClient.eventDispatcher.detach('TimeTracking', 'CompanyTimeTrackingView');
        UpmeshClient.eventDispatcher.detach('ExtraPayTracking', 'CompanyTimeTrackingView2');
    }
    render() {
        const { height, size } = this.props;
        const { searchWords, showMissing, entriesInDates, loading, view, selectedIDs, calendarHeight, filter } = this.state;
        const isFilterActive = filter.dateTo != null ||
            (filter.status != null && filter.status.length > 0) ||
            filter.date != null ||
            (filter.userIds != null && filter.userIds.length > 0) ||
            (filter.projectIds != null && filter.projectIds.length > 0) ||
            (filter.costCenterIds != null && filter.costCenterIds.length > 0) ||
            (filter.extraPayIds != null && filter.extraPayIds.length > 0) ||
            (filter.taskIds != null && filter.taskIds.length > 0);
        let filterIcon = (<Icon icon={isFilterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={this.pressFilter} color={isFilterActive ? ThemeManager.style.brandWarning : 'white'} key="filter-outline"/>);
        if (isFilterActive && ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM) {
            filterIcon = (<View key={`filterButtonBigOuter${isFilterActive}`}>
          <ContainedButton title={I18n.m.getMessage('filterRemove')} icon={{ icon: 'filter-remove', color: '#ffffff' }} onPress={this.pressFilter} backgroundColor={ThemeManager.style.brandWarning}/>
        </View>);
        }
        const headerIcons = [filterIcon];
        if (ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM) {
            headerIcons.push(<Icon key="bulkChangeIcon" toolTip={I18n.m.getMessage('bulkChangesIconTooltip')} icon="checkbox-multiple-marked-outline" color="white" onPress={() => this.onChangeMultiSelectStatus(true)} disabled={view === 'list'}/>, <Icon icon="export" toolTip={I18n.m.getMessage('exportTimeTrackingEntries')} onPress={this.pressExport} color="white" key="csvexport"/>, <Icon icon="download-outline" toolTip={I18n.m.getMessage('timeTrackingExportExcelTooltip')} onPress={this.createAndDownloadExcel} color="white" key="excel-export"/>, <Icon {...this.getShowMissingIcon()} key={`missing_${showMissing}`}/>, <View key="segmentedButton" style={{ width: 144 }}>
          <SegmentedButton buttons={[{ icon: { icon: 'format-list-bulleted' } }, { icon: { icon: 'table-large' } }]} onPress={this.onPressChangeView} singleSelectSelected={view === 'table' ? 1 : 0} backgroundColor="transparent" borderColor="#ffffff" textColor="#ffffff" selectedColor="rgba(196, 196, 196, 0.2)" density={2}/>
        </View>);
        }
        else
            headerIcons.push(<Icon {...this.getShowMissingIcon()} key={`missing_${showMissing}`}/>, <Icon {...this.getViewIcon()} key="viewIcon"/>, <Icon key="threeDotKey" toolTip={I18n.m.getMessage('more')} icon="dots-vertical" disabled={false} onPress={this.openThreeDots} color="white"/>);
        const sDisplay = !(ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM);
        const maxHeight = size.contentHeight - calendarHeight - ThemeManager.style.headerHeight - 64;
        return (<View style={{
                width: '100%',
                backgroundColor: 'transparent',
                position: 'absolute',
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
            }}>
        {!sDisplay ? <GlobalBar user={CurrentUser.entity} size={size} site="timeTracking"/> : undefined}
        <PageView showAccountIcon={sDisplay} showMenu={false} style={{
                backgroundColor: 'transparent',
                width: '100%',
                overflow: 'hidden',
                position: 'absolute',
                height: '100%',
                right: 0,
                left: 0,
                top: sDisplay ? 0 : 48,
                bottom: 0,
            }} headerProps={{
                backgroundColor: 'transparent',
                withBorder: false,
                textColor: '#FFFFFF',
                title: '',
                searchBarProps: {
                    backgroundColor: 'transparent',
                    textColor: '#ffffff',
                    iconColor: '#ffffff',
                    hoverColor: ThemeManager.style.getDefaultHoverColor('#ffffff'),
                    searchOnChange: this.searchTimeTrackings,
                    tooltip: I18n.m.getMessage('searchProjects'),
                    onlyAppBar: true,
                    searchBarValue: searchWords,
                    searchBarPlaceholder: I18n.m.getMessage('timeTracking'),
                },
                rightButtons: headerIcons,
            }} scrollable={false}>
          <View style={{
                height: height != null ? height : '100%',
                maxHeight: height != null ? height : '100%',
                width: '100%',
                position: 'relative',
            }}>
            
            {loading || !filter.date || !filter.dateTo ? (<Spinner />) : (<View style={{ width: '100%', maxWidth: '100%', alignItems: 'center' }}>
                <View collapsable={false} onLayout={this.onLayoutCalendar} style={{ width: '100%', maxWidth: 1280, height: 'auto' }}>
                  <TimeTrackingCalendar entriesInDates={entriesInDates} onChangeSelected={this.changeFilter} currentFilter={{ ...filter }} onPressFilter={this.pressFilter}/>
                </View>
                {view === 'table' ? (<TimeTrackingTableView maxHeight={maxHeight} size={size} entriesInDates={entriesInDates} onMultiSelect={this.onMultiSelect} selectedIDs={selectedIDs} onMultiSelectAll={this.selectAll}/>) : (<TimeTrackingList maxHeight={maxHeight} entries={entriesInDates}/>)}
              </View>)}
          </View>
        </PageView>
      </View>);
    }
    async getTimeTrackingEntityForTable(e) {
        return CombinedExtraPayAndTimeTrackingEntity.convert(e, CachedEntities.knownUsers, CachedEntities.knownTasks, CachedEntities.knownCompanyMember, CachedEntities.knownExtraPay, CachedEntities.knownCostCenter);
    }
    async init() {
        if (this.initRunning) {
            if (this.initTO)
                clearTimeout(this.initTO);
            this.initTO = setTimeout(() => {
                this.init().catch((err) => console.debug('cant load time tracking entries', err));
            }, 500);
            return;
        }
        this.initRunning = true;
        let timeTrackings = [];
        let extraPayTrackings = [];
        const collection = [];
        try {
            timeTrackings = await UpmeshClient.instance.modals.timeTracking.get({
                filter: 'deleted ne true',
                orderby: 'memberId ASC, starts DESC',
            });
            collection.push(...timeTrackings);
            extraPayTrackings = await UpmeshClient.instance.modals.extraPayTracking.get({
                filter: 'deleted ne true',
                orderby: 'memberId ASC, day DESC',
            });
            collection.push(...extraPayTrackings);
        }
        catch (err) {
            console.debug('cant get time trackings', err);
        }
        const absences = await UpmeshClient.instance.modals.absence.get({
            filter: `deleted ne true and state eq 'approved'`,
        });
        for (const a of absences) {
            const tts = await a.convertToTimeTracking(this.getWorkingTimeModelForMember);
            collection.push(...tts);
        }
        this.allEntries = [];
        this.allEntries = await PromisePool.run({
            collection,
            maxConcurrency: 5,
            task: this.getTimeTrackingEntityForTable,
        });
        const payroll = CompanyUserInfo.companyMember.findIndex((m) => m.userId === CurrentUser.userId && m.payroll) > -1;
        const entries = await this.getfilteredEntries(this.state.filter);
        const view = SimpleStorage.get('timeTrackingView');
        const entriesInDates = { entries, payroll };
        this.setState({
            loading: false,
            view: view === 'table' ? 'table' : 'list',
            entriesInDates: entriesInDates.entries,
            payroll: entriesInDates.payroll,
        }, () => {
            this.checkSelectedTicketVisibility();
            const { entriesInDates } = this.state;
            if (entriesInDates) {
                const allIDs = new Set();
                entriesInDates.forEach((entries) => {
                    entries.entries.forEach((entryDate) => {
                        entryDate.memberEntries.forEach((entry) => allIDs.add(entry.id));
                    });
                });
                this.setState({ allIDs });
            }
        });
        this.initRunning = false;
    }
}
TimeTrackingView.showMissing = false;
