import { Url } from 'cqrs-shared/src/uri/Url';
import * as _ from 'lodash';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import querystring from 'querystring';
import React, { PureComponent } from 'react';
import { FlatList, View } from 'react-native';
import { CreateTicket } from 'upmesh-core/src/client/commands/tickets/CreateTicket';
import { PlanFilter } from 'upmesh-core/src/client/query/filter/PlanFilter';
import { TicketFilter } from 'upmesh-core/src/client/query/filter/TicketFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { FabWithCamera } from '../FabWithCamera';
import { PlansFilterDialog } from '../plans/PlansFilterDialog';
import { PlanTagFilterIcon } from '../plans/PlanTagFilterIcon';
import { PlanThumb169 } from '../plans/PlanThumb169';
import { CurrentProject } from '../project/CurrentProject';
import { PageView } from '../root/PageView';
import { openPlanSelectorDialog } from './PlanSelector';
import { TicketDialogFilter } from './TicketDialogFilter';
import { TicketNavigation } from './TicketNavigation';
import { TicketsDetailsView } from './TicketsDetailsView';
import { TicketTagFilterIcon } from './TicketTagFilterIcon';
export class TicketsView extends PureComponent {
    constructor(props) {
        super(props);
        this.counterTO = 0;
        this.mounted = false;
        this.filteredTickets = CurrentProject.instance.getCurrentTickets();
        this.plans = CurrentProject.instance.getCurrentPlans();
        this.tickets = CurrentProject.instance.getCurrentTickets();
        this.gotoPlanDetails = (planId) => (_e) => {
            const id = planId == null ? 'all' : planId;
            const projectId = CurrentProject.instance.getCurrentProjectId();
            const { searchWords, searchTicketsWords, ticketFilter } = this.state;
            const { o } = this.props;
            const f = ticketFilter != null ? querystring.stringify({ f: JSON.stringify(ticketFilter) }) : '';
            const fp = this.planFilter != null ? querystring.stringify({ fp: JSON.stringify(this.planFilter) }) : '';
            const url = Url.getURLfromString(`/projects/${projectId}/tickets/${id}/`);
            url.search = `qp=${searchWords}&q=${searchTicketsWords}&${f}&${fp}&o=${o}`;
            Routing.instance.goTo(url);
        };
        this.onChangeTicketOrder = (tickets) => {
            TicketNavigation.setTickets(tickets);
        };
        this.planFilter = new PlanFilter();
        this.openPlanFilter = () => {
            PlansFilterDialog.open(this.plans, this.setPlanFilter, this.planFilter);
        };
        this.setPlanFilter = (filter) => {
            this.planFilter = filter;
            this.getVisibleTickets(this.state.selectedPlanId, this.state.ticketFilter)
                .then((t) => {
                TicketNavigation.setTickets(t.visibleTickets);
                this.setState(t);
            })
                .catch((err) => console.debug('cant update ticket', err));
        };
        this.openTagFilter = async (e) => {
            console.log('e', e);
        };
        this.startMoveOnX = null;
        this.startTreeWidth = null;
        this.onResponderGrant = (e) => {
            if (e != null && e.nativeEvent != null && e.nativeEvent.touches != null) {
                this.startMoveOnX = e.nativeEvent.touches[0].pageX;
                this.startTreeWidth = this.state.planlistWidth;
            }
        };
        this.onResponderEnd = (_e) => {
            this.startMoveOnX = null;
            this.startTreeWidth = null;
            const { planlistWidth } = this.state;
            SimpleStorage.set('ticketPlanlistWidth', planlistWidth.toString());
        };
        this.onTouchMove = (e) => {
            const { startMoveOnX, startTreeWidth } = this;
            if (e != null &&
                e.nativeEvent != null &&
                e.nativeEvent.touches != null &&
                startMoveOnX != null &&
                startTreeWidth != null) {
                const diff = e.nativeEvent.touches[0].pageX - startMoveOnX;
                let planlistWidth = startTreeWidth + diff;
                planlistWidth = Math.min(planlistWidth, ResizeEvent.current.contentWidth / 2);
                planlistWidth = Math.max(planlistWidth, 0);
                if (planlistWidth !== this.state.planlistWidth) {
                    this.setState({ planlistWidth });
                }
            }
        };
        this.openPlanSelector = (e) => {
            const { selectedPlanId } = this.state;
            openPlanSelectorDialog({
                withAll: true,
                plans: this.plans,
                currentFilter: this.planFilter,
                currentSelecedPlanId: selectedPlanId,
                onSelect: (planId, filter) => (e) => {
                    this.gotoPlanDetails(planId)(e);
                    this.setPlanFilter(filter);
                },
                onClose: (f) => this.setPlanFilter(f),
            })(e);
        };
        this.getItemKey = (item, _index) => `plan_${item.id}`;
        this.targetWidth = 100;
        this.onPlanChange = _.debounce((plans) => {
            this.plans = plans;
            this.updateVisibleTickets().catch((err) => console.debug(err));
        }, 1500);
        this.onPressFilter = (_e) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null) {
                const { selectedPlanId } = this.state;
                this.getVisibleTicketsWithoutFilter(this.tickets, selectedPlanId)
                    .then((tickets) => {
                    TicketDialogFilter.open(tickets.visibleTickets, this.setFilter, this.state.ticketFilter, projectId);
                })
                    .catch((err) => console.debug(err));
            }
        };
        this.onSearch = (text) => {
            if (this.searchTimer != null) {
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = window.setTimeout(this.searchNow(text), 250);
        };
        this.onSearchTickets = (text) => {
            if (this.searchTimer != null) {
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = window.setTimeout(this.searchNowTickets(text), 250);
        };
        this.onTicketChange = _.debounce((tickets) => {
            this.tickets = tickets;
            this.updateVisibleTickets().catch((err) => console.debug('cant update visible tickets', err));
        }, 200);
        this.pressCreateTicket = (planId, planX, planY) => async (e) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null) {
                const pid = planId == null || planId.length === 0 || planId === 'all' ? undefined : planId;
                let x = planX;
                let y = planY;
                if (pid != null && (planX == null || planY == null)) {
                    try {
                        if (RouterControl.instance.currentUrl.search != null) {
                            const query = { ...querystring.parse(RouterControl.instance.currentUrl.search) };
                            if (query != null && query['zTo'] != null) {
                                const s = query['zTo'].toString().split('x');
                                if (s.length >= 2) {
                                    x = Number.parseFloat(s[0]);
                                    y = Number.parseFloat(s[1]);
                                }
                            }
                        }
                    }
                    catch (e) {
                        console.debug('cant get query search', e);
                    }
                }
                const catched = await DefaultErrorHandler.getProjectErrors(projectId);
                if (catched) {
                    return;
                }
                const c = new CreateTicket({ projectId, title: '' });
                c.canI()
                    .then(() => Routing.instance.openDialog('ticket', { projectId, planId: pid, planX: x, planY: y, id: 'new' })(e))
                    .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
            }
        };
        this.renderRow = ({ item }) => {
            const { planId } = this.props;
            const { ticketsOnPlan } = this.state;
            const ticketsOnThisPlan = ticketsOnPlan[item.id] != null ? ticketsOnPlan[item.id] : 0;
            const selected = planId === item.id;
            const itemView = (<View style={{
                    padding: ThemeManager.style.getScreenRelativePixelSize(8),
                    width: this.targetWidth,
                    overflow: 'visible',
                }} key={`PlanThumb_${item.id}`}>
        <PlanThumb169 plan={item} projectId={item.projectId} fileSource={item.activePlanId === 'MAP' ? 'map' : 'planVersion'} fileId={item.activePlanId} width={this.targetWidth - ThemeManager.style.getScreenRelativePixelSize(8)} selected={selected} badge={ticketsOnThisPlan.toString()} onPress={this.gotoPlanDetails(item.id)} title={item.title} bottomRightIcon={item.notes && item.notes.length > 0
                    ? { icon: 'information-outline', toolTip: '', onPress: this.showPlanNotes(item) }
                    : undefined}/>
      </View>);
            return itemView;
        };
        this.showPlanNotes = (plan) => () => {
            Dialog.instance?.open({
                title: I18n.m.getMessage('planDetailsPlanNotes'),
                content: plan.notes,
                buttons: [
                    <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('ok')} key="close" onPress={Dialog.instance?.close}/>,
                ],
            });
        };
        this.searchNow = (text) => () => {
            if (this.searching) {
                this.searchTimer = window.setTimeout(this.searchNow(text), 100);
                return;
            }
            this.searching = true;
            this.searching = false;
            Routing.instance.changeQueryParameter({ qp: text });
            this.setState({ searchWords: text }, () => {
                this.updateVisibleTickets().catch((err) => console.debug(err));
            });
        };
        this.searchNowTickets = (text) => () => {
            if (this.searching) {
                this.searchTimer = window.setTimeout(this.searchNowTickets(text), 100);
                return;
            }
            this.searching = true;
            this.searching = false;
            Routing.instance.changeQueryParameter({ q: text });
            this.setState({ searchTicketsWords: text }, () => {
                this.updateVisibleTickets().catch((err) => console.debug('cant update visible tickets', err));
            });
        };
        this.setFilter = (ticketFilter) => {
            this.setState({ ticketFilter }, () => {
                this.updateVisibleTickets()
                    .then(() => {
                    const f = JSON.stringify(ticketFilter);
                    requestAnimationFrame(() => {
                        Routing.instance.changeQueryParameter({ f }, true);
                    });
                })
                    .catch((err) => {
                    console.error(err);
                });
            });
        };
        let ticketFilter;
        try {
            ticketFilter = props.f != null ? JSON.parse(props.f) : undefined;
        }
        catch (e) {
            console.debug('cant parse ticketfilter');
        }
        try {
            this.planFilter = props.fp != null ? JSON.parse(props.fp) : new PlanFilter();
        }
        catch (e) {
            this.planFilter = new PlanFilter();
            console.debug('cant parse planFilter');
        }
        this.plans = CurrentProject.instance.getCurrentPlans();
        this.tickets = CurrentProject.instance.getCurrentTickets();
        this.state = {
            ticketFilter,
            searchWords: props.qp != null ? props.qp : '',
            searchTicketsWords: props.q != null ? props.q : '',
            visiblePlans: [],
            selectedPlanId: this.plans.length === 0 ? 'all' : undefined,
            selectedPlan: undefined,
            selectedPlanVersion: undefined,
            visibleTickets: CurrentProject.instance.getCurrentTickets(),
            ticketsOnPlan: {},
            amountTotal: this.tickets.length,
            planlistWidth: 0,
        };
    }
    componentDidMount() {
        CurrentProject.plansChanged.attach(this.onPlanChange);
        CurrentProject.ticketsChanged.attach(this.onTicketChange);
        this.onTicketChange(CurrentProject.instance.getCurrentTickets());
        this.mounted = true;
        this.setPlan().catch((err) => console.debug(err));
    }
    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        if (this.plans.length > 0 && this.setPlanId !== this.props.planId) {
            this.setPlanId = this.props.planId;
            this.setPlan().catch((err) => console.debug(err));
        }
        if (_prevProps.o !== this.props.o || _prevProps.q !== this.props.q) {
            this.updateVisibleTickets().catch((err) => console.debug(err));
        }
    }
    componentWillUnmount() {
        TicketNavigation.setTickets([]);
        CurrentProject.plansChanged.detach(this.onPlanChange);
        CurrentProject.ticketsChanged.detach(this.onTicketChange);
    }
    renderPlanSelection() {
        const { selectedPlanId, visiblePlans, searchWords, planlistWidth } = this.state;
        const { size } = this.props;
        const splitView = this.getSplit();
        const planThumbs = [];
        const tagIcon = (<PlanTagFilterIcon currentFilter={this.planFilter} setFilter={(f) => {
                Routing.instance.changeQueryParameter({
                    fp: JSON.stringify(f),
                });
                this.setPlanFilter(f);
            }} plans={this.plans}/>);
        let page1Style = {
            position: 'absolute',
            width: '100%',
            height: size.contentHeight,
            overflow: 'hidden',
        };
        page1Style = {
            ...page1Style,
            maxWidth: planlistWidth,
            borderWidth: 0,
            borderRightWidth: ThemeManager.style.borderWidth,
            borderStyle: 'solid',
            borderColor: ThemeManager.style.borderColor,
        };
        planThumbs.push(...visiblePlans);
        let filterIcon;
        if (splitView || selectedPlanId == null || selectedPlanId.length === 0) {
            const isFilterActive = PlanFilter.isSet(this.planFilter);
            filterIcon = (<Icon key={`planFilter_${isFilterActive}`} icon={isFilterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={this.openPlanFilter} color={isFilterActive ? ThemeManager.style.brandPrimary : ThemeManager.style.defaultIconColor}/>);
        }
        const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        const projectId = CurrentProject.instance.getCurrentProjectId();
        const numColumns = this.createNumColumns(splitView ? planlistWidth : size.contentWidth);
        return (<PageView style={page1Style} headerProps={{
                title: '',
                searchBarProps: {
                    searchBarValue: searchWords,
                    searchBarPlaceholder: I18n.m.getMessage('planSiteSearchPlans'),
                    searchOnChange: this.onSearch,
                    tooltip: I18n.m.getMessage('searchTickets'),
                },
                rightButtons: [filterIcon, tagIcon],
            }} scrollable={false}>
        <FabWithCamera size={size} additionalActionButtons={[
                {
                    icon: 'map-marker-check-outline',
                    onPress: (_e) => {
                        if (Fab.instance != null) {
                            Fab.instance.closeButtons();
                        }
                        this.pressCreateTicket(this.state.selectedPlanId)(_e).catch((err) => console.debug(err));
                    },
                    text: I18n.m.getMessage('ticketsAddTicket'),
                },
            ]} projectId={projectId}/>
        <View style={{
                position: 'relative',
                width: '100%',
                height: size.contentHeight - ThemeManager.style.headerHeight - sViewHeight,
            }}>
          <View style={{
                position: 'absolute',
                padding: 0,
                paddingLeft: ThemeManager.style.contentPaddingValue,
                paddingRight: ThemeManager.style.contentPaddingValue,
                paddingTop: ThemeManager.style.getScreenRelativePixelSize(8),
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                margin: 0,
                height: ThemeManager.style.getScreenRelativePixelSize(44),
                alignSelf: 'flex-start',
                width: '100%',
            }}>
            <ContainedButton onPress={this.gotoPlanDetails('all')} title={`${I18n.m.getMessage('allTickets')}(${this.filteredTickets.length})`} raised={false} full backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF"/>
          </View>

          <View style={{
                position: 'absolute',
                width: '100%',
                height: size.contentHeight -
                    ThemeManager.style.headerHeight -
                    ThemeManager.style.getScreenRelativePixelSize(44) -
                    sViewHeight,
                top: ThemeManager.style.getScreenRelativePixelSize(44),
                padding: 0,
                marginLeft: ThemeManager.style.getScreenRelativePixelSize(8),
                marginRight: ThemeManager.style.getScreenRelativePixelSize(8),
            }}>
            <FlatList key={`planTicketsList_${numColumns}`} keyExtractor={this.getItemKey} renderItem={this.renderRow} numColumns={numColumns} data={planThumbs} style={ThemeManager.style.absoluteStyle}/>
          </View>
        </View>
      </PageView>);
    }
    render() {
        const { selectedPlan, selectedPlanVersion, visibleTickets, searchTicketsWords, ticketFilter, planlistWidth } = this.state;
        const { size, o } = this.props;
        const splitView = this.getSplit();
        let ticketsView = null;
        const filter = this.state.ticketFilter;
        const filterActive = this.isFilter(filter);
        ticketsView = (<View key="selectedPlan" style={{
                position: 'absolute',
                left: splitView ? planlistWidth : 0,
                width: splitView ? size.contentWidth - planlistWidth : size.contentWidth,
                height: '100%',
            }}>
        <TicketsDetailsView onChangeTicketOrder={this.onChangeTicketOrder} childHeight={!splitView && this.plans.length > 0 ? 48 : 0} size={size} pressCreateTicket={this.pressCreateTicket} zTo={this.props.zTo} backButton={false} onSearch={this.onSearchTickets} searchWords={searchTicketsWords} currentTicketFilter={ticketFilter != null ? ticketFilter : new TicketFilter()} filterActive={filterActive} onPressFilter={this.onPressFilter} tickets={visibleTickets} key="planTicketsDetails" plan={selectedPlan} planVersion={selectedPlanVersion} splitViewWidth={splitView && planlistWidth > 0 ? planlistWidth : 0} showFab={!splitView} sortBy={o == null ? '' : o} updateTicketFilter={(f) => {
                Routing.instance.changeQueryParameter({
                    ft: JSON.stringify(f),
                });
                this.setFilter(f);
            }} tagIcon={<TicketTagFilterIcon currentFilter={ticketFilter != null ? ticketFilter : new TicketFilter()} setFilter={(f) => {
                    Routing.instance.changeQueryParameter({
                        ft: JSON.stringify(f),
                    });
                    this.setFilter(f);
                }} tickets={this.tickets}/>}>
          {!splitView && this.plans.length > 0 && (<View style={{
                    height: 48,
                    width: '100%',
                    paddingHorizontal: 12,
                    justifyContent: 'center',
                    overflow: 'visible',
                }}>
              <Ripple onPress={this.openPlanSelector} style={{
                    width: '100%',
                    height: 36,
                    elevation: 2,
                    ...ThemeManager.style.elevation2,
                    borderRadius: ThemeManager.style.borderRadius,
                    backgroundColor: '#FFFFFF',
                    paddingLeft: 8,
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                }}>
                <View style={{ flex: 1 }}>
                  <MaterialText type={MaterialTextTypes.Caption}>Plan wählen</MaterialText>
                  <MaterialText strong>
                    {selectedPlan != null ? selectedPlan.title : I18n.m.getMessage('allTickets')} (
                    {visibleTickets.length})
                  </MaterialText>
                </View>
                <View style={{ width: 48 }}>
                  <Icon icon="chevron-down" toolTip=""/>
                </View>
              </Ripple>
            </View>)}
        </TicketsDetailsView>
      </View>);
        const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        return (<View style={{
                width: size.contentWidth,
                position: 'relative',
                flexDirection: 'row',
                height: size.contentHeight - sViewHeight,
            }}>
        {splitView ? this.renderPlanSelection() : null}
        {ticketsView}
        {splitView && (<View style={{
                    position: 'absolute',
                    bottom: 4,
                    left: Math.max(0, planlistWidth - 52),
                    width: 36,
                    height: 36,
                }} onResponderMove={this.onTouchMove} onMoveShouldSetResponderCapture={() => true} onResponderGrant={this.onResponderGrant} onResponderEnd={this.onResponderEnd}>
            <Icon borderStyle="solid" backgroundColor={ThemeManager.style.appBgColor} icon="arrow-left-right" toolTip=""/>
          </View>)}
      </View>);
    }
    createNumColumns(forWidth) {
        let targetWidth = ThemeManager.style.getScreenRelativePixelSize(212);
        const width = forWidth - 2 * ThemeManager.style.contentPaddingValue;
        const numColumns = Math.max(1, Math.round(width / targetWidth));
        targetWidth = width / numColumns;
        this.targetWidth = targetWidth;
        return numColumns;
    }
    async filterTickets(tickets, filter) {
        this.filteredTickets = (await TicketFilter.filterTickets(tickets, filter));
        this.setState({ amountSelected: this.filteredTickets.length });
        return this.filteredTickets;
    }
    getSplit() {
        const { size } = this.props;
        return this.plans.length > 0 && size.windowWidth > ThemeManager.style.breakpointM;
    }
    isFilter(filter) {
        return filter != null && TicketFilter.isSet(filter);
    }
    async setPlan() {
        const { planId } = this.props;
        const { ticketFilter } = this.state;
        const planlistWidth = SimpleStorage.get('ticketPlanlistWidth');
        let selectedPlan;
        let selectedPlanVersion;
        let selectedPlanId = planId;
        if (planId != null && planId.length > 0 && planId !== 'all') {
            try {
                selectedPlan = await UpmeshClient.instance.modals.plan.getById(planId);
            }
            catch (e) {
                ErrorReporter.sendReport({
                    subject: `Cant get plan in TicketsView - ${planId}`,
                    data: e,
                    type: 'warn',
                });
            }
            if (selectedPlan != null && selectedPlan.activePlanId != null && selectedPlan.activePlanId.length > 3) {
                try {
                    selectedPlanVersion = await UpmeshClient.instance.modals.planVersion.getById(selectedPlan.activePlanId);
                }
                catch (e) {
                    e.description = `plan: ${planId}, details: ${e.description}`;
                    ErrorReporter.sendReport({
                        subject: `Cant get planversion in TicketsView - ${e.name} ${e.message}`,
                        data: e,
                        type: 'warn',
                    });
                }
            }
        }
        else if (this.plans.length === 0) {
            selectedPlanId = 'all';
        }
        const t = await this.getVisibleTickets(selectedPlanId, ticketFilter);
        this.setState({
            planlistWidth: planlistWidth != null ? Number.parseInt(planlistWidth, 10) : 256,
            selectedPlan,
            selectedPlanVersion,
            selectedPlanId,
            ...t,
        });
    }
    async getSearchedTickets(tickets, planId) {
        let visibleTickets = tickets;
        const { searchTicketsWords, selectedPlanId } = this.state;
        const pId = planId != null ? planId : selectedPlanId;
        if (pId != null && pId !== 'all') {
            visibleTickets = (await TicketFilter.filterTicketsByPlanIds(visibleTickets, [pId]));
            if (searchTicketsWords != null && searchTicketsWords.length > 0) {
                visibleTickets = (await TicketFilter.filterTicketsByText(searchTicketsWords, visibleTickets, true));
                this.filteredTickets = visibleTickets;
            }
        }
        else if (searchTicketsWords != null && searchTicketsWords.length > 0) {
            visibleTickets = (await TicketFilter.filterTicketsByText(searchTicketsWords, visibleTickets, true));
            this.filteredTickets = visibleTickets;
        }
        const { o } = this.props;
        const sortedTickets = searchTicketsWords.length > 0 || o == null || o.length === 0 || o === 'undefined'
            ? visibleTickets
            : this.sortTickets(visibleTickets, o);
        return sortedTickets;
    }
    async getVisibleTicketsWithoutFilter(vTickets, selectedPlanId) {
        let visibleTickets = await this.getSearchedTickets(vTickets, selectedPlanId);
        let visiblePlans = this.plans;
        const { searchWords } = this.state;
        if (selectedPlanId != null && selectedPlanId !== 'all') {
            visibleTickets = (await TicketFilter.filterTicketsByPlanIds(visibleTickets, [
                selectedPlanId,
            ]));
        }
        if (searchWords != null && searchWords.length > 0) {
            visiblePlans = await PlanFilter.filterPlansByText(searchWords, visiblePlans);
            visiblePlans = await PlanFilter.filterPlans(visiblePlans, this.planFilter);
            const planIds = visiblePlans.map(({ id }) => id);
            this.filteredTickets = visibleTickets = (await TicketFilter.filterTicketsByPlanIds(visibleTickets, planIds));
        }
        const ticketsOnPlan = {};
        for (let i = 0; i < this.filteredTickets.length; i += 1) {
            if (this.filteredTickets[i].planId != null) {
                const id = this.filteredTickets[i].planId;
                if (ticketsOnPlan[id] == null) {
                    ticketsOnPlan[id] = 1;
                }
                else {
                    ticketsOnPlan[id] += 1;
                }
            }
        }
        return { visibleTickets, visiblePlans, ticketsOnPlan };
    }
    async getVisibleTickets(planId, ticketFilter) {
        let filtered = await this.filterTickets(this.tickets, ticketFilter);
        let t = await this.getVisibleTicketsWithoutFilter(filtered, planId);
        let { visiblePlans } = t;
        visiblePlans = await PlanFilter.filterPlans(visiblePlans, this.planFilter);
        if (PlanFilter.isSet(this.planFilter)) {
            const planIds = [];
            visiblePlans.forEach((p) => planIds.push(p.id));
            const f = [];
            filtered.forEach((t) => {
                if (t.planId != null && planIds.includes(t.planId))
                    f.push(t);
            });
            filtered = [...f];
            this.filteredTickets = filtered;
            t = await this.getVisibleTicketsWithoutFilter(filtered, planId);
        }
        const { visibleTickets, ticketsOnPlan } = t;
        if (this.isFilter(ticketFilter)) {
            const visiblePlans2 = [];
            for (let i = 0; i < visiblePlans.length; i += 1) {
                const ticketsOnThisPlan = ticketsOnPlan[visiblePlans[i].id] != null ? ticketsOnPlan[visiblePlans[i].id] : 0;
                if (ticketsOnThisPlan > 0) {
                    visiblePlans2.push(visiblePlans[i]);
                }
            }
            visiblePlans = visiblePlans2;
        }
        const { searchTicketsWords } = this.state;
        const { o } = this.props;
        const sortedTickets = searchTicketsWords.length > 0 || o == null || o.length === 0 || o === 'undefined'
            ? visibleTickets
            : this.sortTickets(visibleTickets, o);
        return { visiblePlans, ticketsOnPlan, visibleTickets: sortedTickets };
    }
    sortTickets(visibleTickets, sortBy) {
        let sortedTickets = [];
        if (sortBy === 'title')
            sortedTickets = visibleTickets.sort((a, b) => {
                return this.sortByString(a.title, b.title);
            });
        else if (sortBy === 'number')
            sortedTickets = visibleTickets.sort(this.sortByTicketNumber);
        else if (sortBy === 'type')
            sortedTickets = visibleTickets.sort((a, b) => {
                return this.sortByString(a.type, b.type);
            });
        else if (sortBy === 'craft')
            sortedTickets = visibleTickets.sort((a, b) => {
                return this.sortByString(a.craft, b.craft);
            });
        else if (sortBy === 'state')
            sortedTickets = visibleTickets.sort((a, b) => {
                return a.ticketStatus - b.ticketStatus;
            });
        else if (sortBy === 'assignee')
            sortedTickets = visibleTickets.sort((a, b) => {
                return this.sortByString(a.assigneeUser == null ? '' : `${a.assigneeUser.firstname} ${a.assigneeUser.lastname}`, b.assigneeUser == null ? '' : `${b.assigneeUser.firstname} ${b.assigneeUser.lastname}`);
            });
        else if (sortBy === 'approver')
            sortedTickets = visibleTickets.sort((a, b) => {
                return this.sortByString(a.approverUser == null ? '' : `${a.approverUser.firstname} ${a.approverUser.lastname}`, b.approverUser == null ? '' : `${b.approverUser.firstname} ${b.approverUser.lastname}`);
            });
        else if (sortBy === 'due')
            sortedTickets = visibleTickets.sort((a, b) => {
                return this.sortByDate(a.completionOn, b.completionOn);
            });
        else if (sortBy === 'edit')
            sortedTickets = visibleTickets.sort((a, b) => {
                return this.sortByDate(a.lastModifiedAt, b.lastModifiedAt);
            });
        return sortedTickets;
    }
    async updateVisibleTickets() {
        const { ticketFilter, selectedPlanId } = this.state;
        const t = await this.getVisibleTickets(selectedPlanId, ticketFilter);
        TicketNavigation.setTickets(t.visibleTickets);
        this.setState(t);
    }
    sortByString(a, b) {
        if (a == null && b == null)
            return 0;
        if (a == null)
            return 1;
        if (b == null)
            return -1;
        const x = a.toLowerCase();
        const y = b.toLowerCase();
        if (x < y) {
            return -1;
        }
        if (x > y) {
            return 1;
        }
        return 0;
    }
    sortByDate(a, b) {
        if (a == null && b == null)
            return 0;
        if (a == null)
            return 1;
        if (b == null)
            return -1;
        return a.getTime() - b.getTime();
    }
    sortByTicketNumber(a, b) {
        return a.ticketNumber - b.ticketNumber;
    }
}
TicketsView.defaultProps = {
    title: 'upmesh',
};
