var _a;
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { CombinedExtraPayAndTimeTrackingEntity } from 'upmesh-core/src/client/query/filter/CombinedExtraPayAndTimeTrackingFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { AddTimeTrackDialog } from '../timetrackings/AddTimeTrackDialog';
import { CombinedExtraPayTrackingDialogsControl } from '../timetrackings/CombinedExtraPayTrackingDialogsControl';
import { CompanyUserInfo } from './CompanyUserInfo';
export class TimeTrackingChip extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const { duration, runningTimeTrack, recordingPause } = await _a.getRuningTimeTrack();
            this.setState({ runningTimeTrack, duration, recordingPause, loading: false });
        };
        this.updateDuration = () => {
            const { runningTimeTrack, recordingPause } = this.state;
            this.setState({ duration: _a.getDuration(runningTimeTrack, recordingPause) });
        };
        this.openAddTimeTrackDialog = () => {
            Menu.instance?.close();
            Dialog.instance?.open({
                content: <AddTimeTrackDialog />,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
            });
        };
        this.stopRecord = (e) => {
            const { runningTimeTrack } = this.state;
            if (runningTimeTrack) {
                this.setState({ loading: true }, () => {
                    try {
                        CombinedExtraPayTrackingDialogsControl.stopTiming(runningTimeTrack, true)(e);
                    }
                    catch (err) {
                        DefaultErrorHandler.showDefaultErrorAlert(err);
                    }
                    this.setState({ loading: false });
                });
            }
        };
        this.state = {
            duration: '',
            loading: true,
        };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'timeTrackingChip',
            readModelName: 'TimeTracking',
            callback: () => {
                this.init().catch((err) => console.error(err));
            },
        });
        this.init().catch((err) => console.debug(err));
        if (this.interval)
            clearInterval(this.interval);
        setInterval(this.updateDuration, 1000);
    }
    componentWillUnmount() {
        if (this.interval)
            clearInterval(this.interval);
        UpmeshClient.eventDispatcher.detach('TimeTracking', 'timeTrackingChip');
    }
    static async getRuningTimeTrack() {
        let runningTimeTrack;
        let duration = '';
        let recordingPause;
        if (CompanyUserInfo.company != null && CompanyUserInfo.me != null) {
            const running = await UpmeshClient.instance.modals.timeTracking.get({
                filter: `deleted ne true and status eq 'Running' and memberId eq '${CompanyUserInfo.me.id}'`,
            });
            if (running != null && running.length > 0) {
                runningTimeTrack = await CombinedExtraPayAndTimeTrackingEntity.convert(running[0]);
                const r = TimeTrackingEntity.getRunningTime(running[0]);
                duration = TimeTrackingEntity.msToTime(r, true, true);
            }
        }
        return { recordingPause, duration, runningTimeTrack };
    }
    static getDuration(runningTimeTrack, recordingPause) {
        let duration = '';
        if (runningTimeTrack != null) {
            const running = TimeTrackingEntity.getRunningTime(runningTimeTrack.orgEntry);
            if (running != null) {
                if (recordingPause != null) {
                    duration = TimeTrackingEntity.msToTime(running - (new Date().getTime() - recordingPause.getTime()), true, true);
                }
                else {
                    duration = TimeTrackingEntity.msToTime(running, true, true);
                }
            }
        }
        return duration;
    }
    render() {
        const hasModule = CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('timeTracking');
        if (!hasModule)
            return null;
        const { duration, runningTimeTrack, loading } = this.state;
        if (loading)
            return <Spinner small/>;
        if (ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM) {
            if (duration.length > 0 && runningTimeTrack != null) {
                return (<View style={{ height: '100%', justifyContent: 'center' }}>
            <Chip title={duration} thumbnail={<Icon icon="stop-circle-outline" toolTip="" color={ThemeManager.style.white87}/>} textColor={ThemeManager.style.white87} backgroundColor={ThemeManager.style.brandDanger} onPressChip={this.stopRecord}/>
          </View>);
            }
            return (<View style={{ height: '100%', justifyContent: 'center' }}>
          <Chip title={I18n.m.getMessage('timeTrackingCaptureTime')} thumbnail={<Icon icon="play-circle-outline" toolTip="" color={ThemeManager.style.white87}/>} textColor={ThemeManager.style.white87} backgroundColor={ThemeManager.style.brandPrimary} onPressChip={this.openAddTimeTrackDialog}/>
        </View>);
        }
        return <View />;
    }
}
_a = TimeTrackingChip;
TimeTrackingChip.getTimeTrackingMenuElement = async () => {
    const hasModule = CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('timeTracking');
    if (!hasModule)
        return [];
    const { duration, runningTimeTrack } = await _a.getRuningTimeTrack();
    if (runningTimeTrack != null) {
        return [
            {
                thumbnail: {
                    thumbnail: <Icon icon="stop-circle-outline" toolTip="" color={ThemeManager.style.brandDanger}/>,
                    width: 24,
                    rounded: true,
                },
                text: duration.slice(0, duration.length - 3),
                onPress: CombinedExtraPayTrackingDialogsControl.stopTiming(runningTimeTrack, true),
                cutLine: true,
            },
        ];
    }
    return [
        {
            thumbnail: {
                thumbnail: <Icon icon="play-circle-outline" toolTip="" color={ThemeManager.style.brandPrimary}/>,
                width: 24,
                rounded: true,
            },
            text: I18n.m.getMessage('timeTrackingCaptureTime'),
            onPress: () => {
                Menu.instance?.close();
                Dialog.instance?.open({
                    content: <AddTimeTrackDialog />,
                    fullscreenResponsive: true,
                    contentPadding: false,
                    scrollable: false,
                });
            },
            cutLine: true,
        },
    ];
};
