var _a;
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import fetchProgress from 'odatarepos/src/fetchprogress/FetchProgress';
import React from 'react';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { EditedFileSaver } from './EditedFileSaver';
import { PDFviewer } from './PDFviewer';
export class StoredFileDownloader {
    static async downloadFile(options, inWindow) {
        try {
            const viewOnly = options.view ? '/?view=1' : '';
            const link = options.target['link'] != null
                ? `${options.target['link']}${viewOnly}`
                : `${UpmeshClient.instance.url}/storedfile/file/${options.target['id']}`;
            if (options.target['link'] != null) {
                if (!options.view) {
                    window.open(link);
                }
                else if (inWindow != null) {
                    if (options['target']['orgFilename'].toLowerCase().endsWith('.pdf') &&
                        options.view &&
                        options.storedfile != null) {
                        return PDFviewer.open({
                            link,
                            originalFilename: options['target']['orgFilename'],
                            id: options['target']['id'],
                            saveFunction: EditedFileSaver.saveEditedImage(options.storedfile, options.editable),
                        })();
                    }
                    inWindow.location.href = link;
                }
                else
                    window.open(link);
            }
            else if (options.target['orgFilename'].toLowerCase().endsWith('.pdf') &&
                options.view &&
                options.storedfile != null) {
                await _a.getDownloadToken(options, inWindow);
            }
            else {
                const headers = {};
                LoadingEvents.instance.startLoading(I18n.m.getMessage('filePreparingDownload'));
                headers['authorization'] = `Bearer ${CurrentUser.token}`;
                fetch(`${UpmeshClient.instance.url}/storedfile/file/${options.target['id']}`, {
                    headers,
                })
                    .then(fetchProgress({
                    onProgress(progress) {
                        if (progress['percentage'] === 100)
                            LoadingEvents.instance.stopLoading();
                        else
                            LoadingEvents.instance.startLoading(progress['percentage'] >= 0 && progress['percentage'] <= 100 ? `${progress['percentage']}%` : '');
                        return null;
                    },
                }))
                    .then((getToken) => {
                    if (getToken != null) {
                        if (getToken.status === 200) {
                            _a.downloadFetch(getToken, options.target.orgFilename != null ? options.target.orgFilename : options.storedfile?.orgFilename).catch((err) => console.debug('ERROR', err));
                        }
                    }
                    LoadingEvents.instance.stopLoading();
                })
                    .catch((err) => {
                    const text = err['message'] != null ? err['message'] : JSON.stringify(err);
                    LoadingEvents.instance.stopLoading();
                    Routing.instance.alert.post({ text });
                });
            }
        }
        catch (e) {
            const text = e['message'] != null ? e['message'] : JSON.stringify(e);
            LoadingEvents.instance.stopLoading();
            Routing.instance.alert.post({ text });
        }
        LoadingEvents.instance.stopLoading();
        return '';
    }
    static async getDownloadToken(options, inWindow) {
        LoadingEvents.instance.startLoading(I18n.m.getMessage('filePreparingDownload'));
        const headers = {};
        headers['authorization'] = `Bearer ${CurrentUser.token}`;
        const viewOnly = options.view ? '/?view=1' : '';
        const openWindow = options.target['orgFilename'].endsWith('.pdf') && options.view
            ? null
            : inWindow != null || !options.view
                ? inWindow
                : window.open('', '_blank');
        if (openWindow != null)
            openWindow.document.write(I18n.m.getMessage('loading'));
        const getToken = await fetch(`${UpmeshClient.instance.url}/storedfile/file/token/${options.target['id']}`, {
            headers,
        });
        if (getToken != null) {
            if (getToken.status === 200) {
                const token = await getToken.text();
                const link = `${UpmeshClient.instance.url}/storedfile/file/download/${token}${viewOnly}`;
                if (options.target['orgFilename'].toLowerCase().endsWith('.pdf') &&
                    options.view &&
                    options.storedfile != null) {
                    LoadingEvents.instance.stopLoading();
                    PDFviewer.open({
                        link,
                        originalFilename: options['target']['orgFilename'],
                        id: options['target']['id'],
                        saveFunction: EditedFileSaver.saveEditedImage(options.storedfile, options.editable),
                    })();
                }
                else if (openWindow == null) {
                    const setTO = setTimeout(() => Alert.instance?.close(), 20000);
                    Routing.instance.alert.post({
                        text: I18n.m.getMessage('fileReadyToDownload'),
                        buttons: [
                            <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={() => {
                                    clearTimeout(setTO);
                                    Alert.instance?.close();
                                }} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                            <ContainedButton key="save" title={I18n.m.getMessage('download')} onPress={() => {
                                    clearTimeout(setTO);
                                    Alert.instance?.close();
                                    _a.downloadFile({ ...options, target: { ...options.target, link } }).catch((err) => console.debug(err));
                                }}/>,
                        ],
                    });
                }
                else {
                    openWindow.location.href = link;
                }
            }
            else {
                const message = await getToken.json();
                const text = message['errorMessage'] != null ? message['errorMessage'] : JSON.stringify(message);
                Routing.instance.alert.post({ text });
                openWindow.close();
            }
        }
    }
}
_a = StoredFileDownloader;
StoredFileDownloader.downloadFetch = async (fetchResult, orgName) => {
    let filename = orgName != null ? orgName : '';
    try {
        filename = fetchResult.headers.get('content-disposition').split('filename=')[1];
    }
    catch (err) {
        console.debug('cant get filename', err);
    }
    const data = await fetchResult.blob();
    const blob = new Blob([data], { type: data.type || 'application/octet-stream' });
    if (typeof window.navigator['msSaveBlob'] !== 'undefined') {
        window.navigator['msSaveBlob'](blob, filename);
        return;
    }
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
    }, 100);
};
