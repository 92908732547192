"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddCompanyProjectState = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var Config_1 = require("../../../../Config");
var CompanyProjectStateAdded_1 = require("../../../../server/events/companies/projectstates/CompanyProjectStateAdded");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var AddCompanyProjectState = function (_CommandRoot_1$Comman) {
  function AddCompanyProjectState(data, companyId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, AddCompanyProjectState);
    _this = _callSuper(this, AddCompanyProjectState, [data, companyId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'AddCompanyProjectState';
    return _this;
  }
  (0, _inherits2.default)(AddCompanyProjectState, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(AddCompanyProjectState, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var companySettings = ReadModels_1.ReadModels.get('CompanySettings');
        var company = yield companies.getById(this.entityId);
        this.cSettings = yield companySettings.getById(company.id);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new CompanyProjectStateAdded_1.CompanyProjectStateAdded(this.entityId, {
          projectState: {
            id: uuid.v1(),
            name: this.data.name,
            order: this.data.order,
            color: this.data.color
          }
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var _this2 = this;
        var errors = [];
        if (key == null || key === 'name') {
          if (this.cSettings.projectStateNames.findIndex(function (elem) {
            return elem.name === _this2.data.name;
          }) !== -1) {
            errors.push({
              key: 'name',
              messageCode: 'companyProjectStateAlreadyExists',
              message: 'Ein Status mit diesem Namen existiert bereits'
            });
          }
          if (this.data.name == null || this.data.name.length === 0) {
            errors.push({
              key: 'name',
              messageCode: 'required',
              message: 'Name wird benötigt'
            });
          }
        }
        if (key == null || key === 'order') {
          if (this.data.order == null) {
            errors.push({
              key: 'order',
              messageCode: 'required',
              message: 'Reihenfolge wird benötigt'
            });
          }
        }
        if (key == null || key === 'color') {
          var regex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
          if (this.data.color == null || !regex.test(this.data.color)) {
            errors.push({
              key: 'color',
              messageCode: 'companyProjectStateColorRequired',
              message: 'Es wird eine Statusfarbe benötigt'
            });
          }
        }
        if (Number.isSafeInteger(this.data.order) && Number.isNaN(this.data.order)) {
          errors.push({
            key: 'order',
            messageCode: 'required',
            message: 'Reihenfolge wird benötigt'
          });
        }
        if (this.data.name.length > Config_1.ConfigHandler.config.string_Large) {
          errors.push({
            key: 'name',
            messageCode: 'badRequest',
            message: 'Name zu lang'
          });
        }
        if (errors.length > 0) {
          throw errors;
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.AddCompanyProjectState = AddCompanyProjectState;