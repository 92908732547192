import Clipboard from '@react-native-clipboard/clipboard';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { FilePicker } from 'materialTheme/src/file/FilePicker';
import { FilePicketComp } from 'materialTheme/src/file/FilePicketComp';
import ImagePickerComp from 'materialTheme/src/file/ImagePicker';
import { ImagePickerOptions } from 'materialTheme/src/file/ImagePickerOptions';
import { DialogContainer, } from 'materialTheme/src/theme/components/dialog/DialogContainer';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { Header } from 'materialTheme/src/theme/components/header/Header';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ImageEditor } from 'materialTheme/src/theme/components/lightbox/ImageEditor';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { ModalBottomSheet } from 'materialTheme/src/theme/components/ModalBottomSheet';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { TabContent } from 'materialTheme/src/theme/components/tabs/TabBarContent';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { KeyboardEvents } from 'materialTheme/src/theme/components/utils/KeyboardEvents';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import * as mime from 'mime';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React from 'react';
import { Keyboard, Platform, UIManager, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ArchiveTicket } from 'upmesh-core/src/client/commands/tickets/ArchiveTicket';
import { ChangeTicketSubTicketFrom } from 'upmesh-core/src/client/commands/tickets/ChangeTicketSubTicketFrom';
import { CreateTicket } from 'upmesh-core/src/client/commands/tickets/CreateTicket';
import { DeleteTicket } from 'upmesh-core/src/client/commands/tickets/DeleteTicket';
import { RemoveTicketPlanAffiliation } from 'upmesh-core/src/client/commands/tickets/RemoveTicketPlanAffiliation';
import { RestoreTicket } from 'upmesh-core/src/client/commands/tickets/RestoreTicket';
import { UnArchiveTicket } from 'upmesh-core/src/client/commands/tickets/UnArchiveTicket';
import { TicketEntity, TicketStatus } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import * as uuid from 'uuid';
import { ConfigAll } from '../../config/ConfigAll';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { AddFilesPU } from '../file/AddFilesPU';
import { AddStoredFileAndUpload } from '../file/AddStoredFileAndUpload';
import { FileStorageSelector } from '../file/FileStorageSelector';
import { SharedFiles } from '../file/SharedFiles';
import { CurrentProject } from '../project/CurrentProject';
import { ActivitiesList } from './activities/ActivitiesList';
import { DuplicateTicketDialog } from './DuplicateTicketDialog';
import { ReportIcon } from './ReportIcon';
import { ChangePositionOnPlan } from './ticketdetails/ChangePositionOnPlan';
import { PlanSnapshotDialog } from './ticketdetails/PlanSnapshotDialog';
import { TicketHeaderTitle } from './ticketdetails/TicketHeaderTitle';
import { WatcherIcon } from './ticketdetails/WatcherIcon';
import { TicketDetailsEditable } from './TicketDetailsEditable';
import { TicketNavigation } from './TicketNavigation';
export class TicketPU extends DialogContainer {
    get inSidebar() {
        const sizes = TicketPU.createSize();
        return (!sizes.fullscreen &&
            CurrentUser.settings.appSettings != null &&
            CurrentUser.settings.appSettings['openTicketsInSidePanel'] === true);
    }
    constructor(props) {
        super(props);
        this.title = I18n.m.getMessage('projectEditTitle');
        this.onUpdateTicketNavigation = () => {
            this.setState({
                currentTicketNumber: this.props.data?.id != null ? TicketNavigation.currentIndex(this.props.data?.id) : 0,
                totalTicketNumbers: TicketNavigation.numberOfTickets(),
            });
        };
        this.startKeyboardEvents = () => {
            this.stopKeyboardEvents();
            KeyboardEvents.onKeyDown((e) => {
                const t = this.state.ticket;
                const ticket = t != null ? t : undefined;
                if (ticket != null) {
                    const prevTicket = TicketNavigation.hasPrev(ticket.id);
                    const nextTicket = TicketNavigation.hasNext(ticket.id);
                    switch (e.keyCode) {
                        case 27:
                            e.preventDefault();
                            this.closeDialog(e);
                            break;
                        case 37:
                            e.preventDefault();
                            if (prevTicket !== false) {
                                this.openTicket(prevTicket)(e);
                            }
                            break;
                        case 39:
                            e.preventDefault();
                            if (nextTicket !== false) {
                                this.openTicket(nextTicket)(e);
                            }
                            break;
                    }
                }
            }, 'TicketPU');
        };
        this.stopKeyboardEvents = () => {
            KeyboardEvents.remove('TicketPU');
        };
        this.openDuplicateTicketDialog = () => {
            const { ticket } = this.state;
            Menu.instance?.close();
            if (ticket) {
                DialogUp.instance?.open({
                    content: (<DuplicateTicketDialog ticket={ticket} errorResult={(e) => {
                            this.errorResult(e).catch((err) => console.error(err));
                        }}/>),
                    fullscreenResponsive: true,
                    contentPadding: false,
                });
            }
        };
        this.removePlanPosition = async () => {
            const t = this.state.ticket;
            const ticket = t != null ? t : undefined;
            Menu.instance?.close();
            if (ticket != null && ticket.planId != null && ticket.planId.length > 0) {
                try {
                    await new Promise((resolve, reject) => {
                        UpmeshClient.eventDispatcher.attach({
                            once: true,
                            entityId: ticket.id,
                            readModelName: 'Ticket',
                            callback: () => {
                                requestAnimationFrame(() => {
                                    resolve();
                                });
                            },
                        });
                        const c = new RemoveTicketPlanAffiliation({}, ticket.id);
                        c.execute()
                            .then(() => {
                            resolve();
                        })
                            .catch((err) => {
                            reject(err);
                        });
                    });
                    LoadingEvents.instance.stopLoading();
                    Routing.instance.openDialog('ticket', { id: ticket.id }, true)(null);
                }
                catch (e) {
                    LoadingEvents.instance.stopLoading();
                    Routing.instance.alert.post({
                        title: I18n.m.getMessage('defaultErrorTitleOnSave'),
                        text: e['messageCode'] != null ? I18n.m.getMessage(e.messageCode) : e.toString(),
                    });
                    ErrorReporter.sendReport({
                        subject: `Cant remove plan position`,
                        data: e,
                        type: 'warn',
                    });
                }
            }
        };
        this.openTicket = (ticket) => (e) => {
            Routing.instance.openDialog('ticket', { id: ticket }, true)(e);
        };
        this.openFileStorage = (e) => {
            ModalBottomSheet.instance?.close();
            const t = this.state.ticket;
            if (t != null && t.projectId != null) {
                UpmeshClient.instance.modals.folder
                    .get({
                    filter: `projectId eq '${t.projectId}' and deleted ne true`,
                    orderby: 'title ASC',
                })
                    .then((folders) => {
                    FileStorageSelector.openSelector(e)({ onSelect: this.onGotFileFromSelector, folders });
                })
                    .catch((err) => {
                    console.error(err);
                });
            }
        };
        this.onGotFileFromSelector = (file) => {
            const { ticket } = this.state;
            try {
                if (ticket != null) {
                    const t = ticket;
                    SharedFiles.fileIds = [file.id];
                    SharedFiles.excludeFolders = null;
                    const data = { type: 'TicketOrFolder' };
                    data.projectId = t.projectId;
                    data.type = 'Tickets';
                    data.entityId = t.id;
                    data.copyFile = true;
                    AddFilesPU.openDialog(data)(null);
                }
            }
            catch (err) {
                DefaultErrorHandler.showDefaultErrorAlert(err);
            }
        };
        this.addAttachment = (e) => {
            UIManager.measure(e.nativeEvent.target, (_originX, _originY, _width, height, pageX, pageY) => {
                const client = {
                    height,
                    width: 300,
                    x: pageX,
                    y: pageY,
                };
                const asyncNow = async () => {
                    const hasCam = this.imagePicker != null ? await ImagePickerComp.hasCamera() : false;
                    const listItems = [
                        {
                            title: I18n.m.getMessage('ticketsDetailsOpenFileStorage'),
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="folder-outline"/>,
                                width: 24,
                            },
                            onPress: this.openFileStorage,
                        },
                        {
                            title: I18n.m.getMessage('uploadFiles'),
                            thumbnail: { thumbnail: <Icon toolTip="" icon="file-multiple-outline"/>, width: 24 },
                            onPress: this.pressAddFile,
                        },
                    ];
                    if (Platform.OS === 'ios') {
                        listItems.push({
                            title: I18n.m.getMessage('fromImageLibrary'),
                            thumbnail: { thumbnail: <Icon toolTip="" icon="folder-image"/>, width: 24 },
                            onPress: this.pressAddImageFromLibrary,
                        });
                    }
                    if (hasCam) {
                        listItems.push({
                            title: I18n.m.getMessage('fromCamera'),
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="camera"/>,
                                width: 24,
                            },
                            onPress: this.openCam,
                        });
                    }
                    listItems.push({
                        title: I18n.m.getMessage('addPlanSnapshot'),
                        thumbnail: {
                            thumbnail: <Icon toolTip="" icon="floor-plan"/>,
                            width: 24,
                        },
                        onPress: this.openPlanSnapshotDialog,
                    });
                    ModalBottomSheet.instance?.open({
                        client,
                        listItems,
                        maxHeight: 500,
                        maxWidth: 500,
                    });
                };
                asyncNow().catch((err) => console.error(err));
            });
        };
        this.archiveTicket = async () => {
            Menu.instance?.close();
            const { ticket } = this.state;
            if (ticket != null) {
                const t = ticket;
                try {
                    if (t.archived === true) {
                        const c = new UnArchiveTicket({}, t.id);
                        await c.execute();
                    }
                    else {
                        const c = new ArchiveTicket({}, t.id);
                        await c.execute();
                    }
                }
                catch (e) {
                    console.debug('archive Ticket Error', e);
                }
            }
        };
        this.deleteTicket = async () => {
            Menu.instance?.close();
            const { ticket } = this.state;
            if (ticket != null) {
                const t = ticket;
                try {
                    if (t.deleted === true) {
                        const c = new RestoreTicket({}, t.id);
                        await c.execute();
                    }
                    else {
                        const c = new DeleteTicket({}, t.id);
                        await c.execute();
                    }
                }
                catch (e) {
                    console.debug('delete Ticket Error', e);
                }
            }
        };
        this.closeDialog = (e) => {
            Routing.instance.closeDialog(false)(e);
        };
        this.createTicket = async (ticket) => {
            LoadingEvents.instance.startLoading();
            const title = I18n.m.getMessage('ticketsDetailsTitlePlaceholder');
            ticket.title = title;
            ticket.id = uuid.v1();
            let projectId = this.props.data.projectId ?? CurrentProject.instance.getCurrentProjectId();
            while (projectId == null) {
                await new Promise((r) => {
                    setTimeout(r, 1000);
                });
                projectId = this.props.data.projectId ?? CurrentProject.instance.getCurrentProjectId();
            }
            try {
                const c = new CreateTicket({
                    title,
                    projectId,
                    planId: ticket.planId != null ? ticket.planId : undefined,
                    planPositionX: ticket.planPositionX != null ? ticket.planPositionX : undefined,
                    planPositionY: ticket.planPositionY != null ? ticket.planPositionY : undefined,
                    planPositionZ: ticket.planPositionZ != null ? ticket.planPositionZ : undefined,
                    address: ticket.address,
                }, ticket.id);
                try {
                    await c.canI();
                }
                catch (e) {
                    LoadingEvents.instance.stopLoading();
                    Routing.instance.closeDialog(true)(null);
                    Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
                    return null;
                }
                await c.execute();
                const count = async () => UpmeshClient.instance.modals.ticket.count(`id eq '${c.entityId}'`);
                let timeout = 0;
                while ((await count()) === 0 && timeout < 20) {
                    await new Promise((r) => {
                        timeout += 1;
                        setTimeout(r, 500);
                    });
                }
                Routing.instance.routeChanged.once((_route) => {
                    LoadingEvents.instance.stopLoading();
                });
                Routing.instance.openDialog('ticket', { id: ticket.id }, true)(null);
            }
            catch (e) {
                console.error('cant createTicket', e);
                LoadingEvents.instance.stopLoading();
                this.errorResult(e).catch((err) => console.debug(err));
                Routing.instance.closeDialog(true)(null);
            }
            return ticket;
        };
        this.errorResult = async (e) => {
            let messageKey = 'ticketsDetailsErrorGenericIssue';
            let attribute = null;
            if (e.messageCode != null && e.message != null && e.messageCode === 'ticketsDetailsErrorStatusChange') {
                attribute = I18n.m.getMessage(`ticketsDetailsState${TicketStatus[e.messageData.stateId]}`);
                messageKey = 'ticketsDetailsErrorStatusChange';
            }
            if (attribute != null) {
                Routing.instance.alert.post({ text: I18n.m.getMessage(messageKey, { newState: attribute }) });
            }
            else {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            }
            this.setState({ isLoading: false });
        };
        this.onGotFiles = async (f) => {
            const uploads = Object.values(f);
            for (let i = 0; i < uploads.length; i += 1) {
                await this.uploadFile(uploads[i]);
            }
        };
        this.onGotImageFiles = (response) => {
            if (Array.isArray(response)) {
                const promises = [];
                response.forEach((e) => promises.push(this.onGotImageFile(e)));
                Promise.all(promises).catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
            else {
                this.onGotImageFile(response).catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        this.onGotImageFile = async (f) => {
            const mimeType = f.mime;
            if (mimeType == null)
                throw new Error(I18n.m.getMessage('fileError'));
            const ext = mime.getExtension(mimeType);
            if (ext == null)
                throw new Error(I18n.m.getMessage('fileError'));
            const a = this.createActivitie();
            await AddStoredFileAndUpload.onGotFile(f, a.projectId, a.ticketId);
            this.setState((prevState) => ({ fileIdCounter: prevState.fileIdCounter + 1 }));
        };
        this.openPlanSnapshotDialog = () => {
            ModalBottomSheet.instance?.close();
            const { ticket } = this.state;
            if (ticket != null) {
                PlanSnapshotDialog.open({
                    onSnapshot: this.onPlanSnapshot,
                    projectId: ticket.projectId,
                });
            }
        };
        this.onPlanSnapshot = (file) => {
            this.planSnapshot = file;
            if (file instanceof Blob) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (event.target != null && event.target.result != null) {
                        ImageEditor.openImageEditor(event.target.result.toString(), file['name'], this.uploadSnapshot, false).catch((err) => {
                            console.debug('cant open image editor', err);
                        });
                    }
                };
                reader.readAsDataURL(file);
            }
            else {
                const uri = file.path;
                const orgFilename = file.filename;
                ImageEditor.openImageEditor(uri, orgFilename, this.uploadSnapshot, false).catch((err) => {
                    console.debug('cant open image editor', err);
                });
            }
        };
        this.uploadSnapshot = async (file) => {
            const f = file != null ? file : this.planSnapshot;
            const { ticket } = this.state;
            try {
                if (ticket != null) {
                    const t = ticket;
                    SharedFiles.files = [f];
                    SharedFiles.excludeFolders = null;
                    const data = { type: 'TicketOrFolder' };
                    data.projectId = t.projectId;
                    data.type = 'Tickets';
                    data.entityId = t.id;
                    AddFilesPU.openDialog(data)(null);
                }
            }
            catch (err) {
                DefaultErrorHandler.showDefaultErrorAlert(err);
            }
            this.planSnapshot = null;
        };
        this.openCam = () => {
            ModalBottomSheet.instance?.close();
            if (this.imagePicker != null) {
                this.imagePicker.execute({ selectMethod: 'camera', multiple: true });
            }
        };
        this.openMenu = async (e) => {
            UIManager.measureInWindow(e.nativeEvent.target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 256,
                };
                const { ticket, isLoading, canIArchive, canIDelete, hasSubtickets, canEditSubTicktes } = this
                    .state;
                if (ticket != null) {
                    const t = ticket;
                    let url = '';
                    const environment = ConfigAll.getEnvironment();
                    if (environment === 'production')
                        url = 'https://app.upmesh.de';
                    if (environment === 'testing')
                        url = 'https://devapp.upmesh.de';
                    if (environment === 'development')
                        url = 'http://127.0.0.1:8085';
                    const items = [
                        {
                            text: I18n.m.getMessage('ticketDetailsDuplicateTicket'),
                            onPress: this.openDuplicateTicketDialog,
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="content-copy"/>,
                                width: 24,
                            },
                        },
                        {
                            text: I18n.m.getMessage('ticketsDetailsCopyLinkToTicket'),
                            onPress: () => {
                                Clipboard.setString(url + RouterControl.instance.currentUrl.toString());
                                Menu.instance?.close();
                                Toast.instance?.open({
                                    title: I18n.m.getMessage('ticketDetailsCopiedLink'),
                                    buttonTitle: I18n.m.getMessage('close'),
                                });
                            },
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="link-variant"/>,
                                width: 24,
                            },
                        },
                        {
                            text: I18n.m.getMessage(t.archived === true ? 'unarchiveTicket' : 'archiveTicket'),
                            disabled: isLoading || !canIArchive,
                            onPress: () => {
                                this.archiveTicket().catch((err) => console.error(err));
                            },
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="archive-outline"/>,
                                width: 24,
                            },
                        },
                        {
                            text: I18n.m.getMessage(t.deleted === true ? 'restoreTicket' : 'deleteTicket'),
                            disabled: isLoading || !canIDelete,
                            onPress: () => {
                                this.deleteTicket().catch((err) => console.error(err));
                            },
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon={t.deleted === true ? 'delete-off-outline' : 'delete-outline'}/>,
                                width: 24,
                            },
                        },
                    ];
                    if (canEditSubTicktes && !hasSubtickets) {
                        items.unshift({
                            text: I18n.m.getMessage('addSubtickets'),
                            disabled: false,
                            onPress: () => {
                                Menu.instance?.close();
                                this.setState({ hasSubtickets: true });
                            },
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="subdirectory-arrow-right"/>,
                                width: 24,
                            },
                        });
                    }
                    if (ticket.planId != null && ticket.planId.length > 0) {
                        items.push({
                            text: I18n.m.getMessage('ticketsDetailsRemoveTicketLocation'),
                            disabled: false,
                            onPress: () => {
                                this.removePlanPosition().catch((err) => console.error(err));
                            },
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="map-marker-off-outline" color={ThemeManager.style.brandDanger}/>,
                                width: 24,
                            },
                        });
                    }
                    Menu.instance?.open({ client, items });
                }
            });
        };
        this.pressAddFile = (_e) => {
            ModalBottomSheet.instance?.close();
            const { fileIdCounter } = this.state;
            FilePicker.show(`uploadFiles_to_ticket_${fileIdCounter}`, true, ['allFiles'], (response) => {
                this.onGotFiles(response).catch((err) => console.error(err));
            });
        };
        this.pressAddImageFromLibrary = (_e) => {
            ModalBottomSheet.instance?.close();
            if (this.imagePicker != null) {
                this.imagePicker.execute({ selectMethod: 'library' });
            }
        };
        this.setCanIArchive = async (ticket) => {
            let canIArchive = false;
            const cArchive = new ArchiveTicket({}, ticket.id);
            try {
                canIArchive = await cArchive.canI();
            }
            catch (e) {
                canIArchive = false;
            }
            return canIArchive === true;
        };
        this.setCanIDelete = async (ticket) => {
            let canIDelete = false;
            const cDelete = new DeleteTicket({}, ticket.id);
            try {
                canIDelete = await cDelete.canI();
            }
            catch (e) {
                canIDelete = false;
            }
            return canIDelete === true;
        };
        this.setImagePicker = (r) => {
            this.imagePicker = r;
        };
        this.updateTicket = (e) => {
            const currentTicket = this.state.ticket;
            if (currentTicket == null || e.id === currentTicket.id) {
                this.setState({ ticket: e });
            }
        };
        const sizes = TicketPU.createSize();
        const hasSidebar = this.inSidebar;
        if (hasSidebar) {
            this.sideBarWidth = Math.min(1024, ResizeEvent.current.windowWidth / 3);
        }
        this.state = {
            open: false,
            fileIdCounter: 0,
            abortCreation: false,
            isLoading: false,
            ticket: undefined,
            closed: false,
            changePlan: false,
            overlayProps: {
                onPress: hasSidebar ? undefined : this.closeDialog,
                showOverlay: !hasSidebar,
                animationDuration: 195,
            },
            currentTab: 0,
            ...sizes,
            currentTicketNumber: props.data?.id != null ? TicketNavigation.currentIndex(props.data?.id) : 0,
            totalTicketNumbers: TicketNavigation.numberOfTickets(),
            hasSubtickets: false,
            canEditSubTicktes: false,
        };
    }
    static getDerivedStateFromProps(nextProps) {
        let changePlan = false;
        if (nextProps.data != null && nextProps.data.changePlan != null && nextProps.data.changePlan === 'true') {
            changePlan = true;
        }
        return { changePlan, ...TicketPU.createSize() };
    }
    componentDidMount() {
        Routing.instance.dialogShouldCloseEvent.attach(this.shouldClose);
        this.dialogAnimation.toValue(1);
        this.checkData(this.props).catch((err) => console.debug('cant checkDatain TicketPU', err));
        this.startKeyboardEvents();
        TicketNavigation.onUpdate.attach(this.onUpdateTicketNavigation);
    }
    componentDidUpdate(_prevProps, prevState, _snapshot) {
        if (JSON.stringify(this.props.data) !== JSON.stringify(_prevProps.data)) {
            this.checkData(this.props).catch((err) => console.debug('cant checkDatain TicketPU', err));
        }
        if (this.state.width !== prevState.width) {
            if (this.state.fullscreen && this._sideBarWidth !== 0) {
                this.sideBarWidth = 0;
            }
            else if (!this.state.fullscreen &&
                CurrentUser.settings.appSettings != null &&
                CurrentUser.settings.appSettings['openTicketsInSidePanel'] === true &&
                this.sideBarWidth !== this.state.width) {
                this.sideBarWidth = this.state.width;
            }
        }
        const ticketId = CurrentProject.instance.getCurrentTicketId();
        if (TicketPU.lastTicket != null && ticketId !== TicketPU.lastTicket) {
            this.notifyForSummary(TicketPU.lastTicket);
        }
        TicketPU.lastTicket = ticketId;
    }
    componentWillUnmount() {
        const ticketId = CurrentProject.instance.getCurrentTicketId();
        this.notifyForSummary(ticketId);
        TicketNavigation.onUpdate.detach(this.onUpdateTicketNavigation);
        this.sideBarWidth = 0;
        this.stopKeyboardEvents();
        Routing.instance.dialogShouldCloseEvent.detach(this.shouldClose);
        CurrentProject.instance.setTicketId(null);
        CurrentProject.ticketChanged.detach(this.updateTicket);
        if (this.attachKey != null) {
            UpmeshClient.eventDispatcher.detach('Ticket', this.attachKey);
            this.attachKey = undefined;
        }
        if (Keyboard != null) {
            Keyboard.dismiss();
        }
    }
    notifyForSummary(ticketId) {
        if (ticketId != null) {
            const headers = {};
            headers['authorization'] = `Bearer ${CurrentUser.token}`;
            fetch(`${ConfigAll.b2cURL}/ticket/${ticketId}/notify`, { headers, method: 'GET' }).catch((e) => {
                console.debug('cant notify server for email summary', e);
            });
        }
    }
    render() {
        const { closed, fileIdCounter, open, currentTicketNumber, totalTicketNumbers } = this.state;
        if (closed) {
            return null;
        }
        const t = this.state.ticket;
        const ticket = t != null ? t : undefined;
        if (ticket == null) {
            return this.renderModal(<View style={{ width: '100%', height: '100%', justifyContent: 'center' }}>{!open ? null : <Spinner />}</View>);
        }
        const { width, height, changePlan } = this.state;
        if (changePlan) {
            return this.renderModal(<View style={{ width: '100%', height: '100%', justifyContent: 'center' }}>
          <View key={ticket.id} style={{ width, height, overflow: 'hidden', paddingBottom: 0, backgroundColor: '#FFFFFF' }}>
            <ChangePositionOnPlan key={`map_${ticket.id}`} createTicket={(t) => {
                    this.createTicket(t).catch((err) => console.error(err));
                }} ticket={ticket} height={height} width={width}/>
          </View>
        </View>);
        }
        const splitView = width > 600;
        const newWidth = splitView ? width / 2 : width;
        const rightButtons = [];
        if (TicketNavigation.hasTickets()) {
            const nextTicket = TicketNavigation.hasNext(ticket.id);
            const prevTicket = TicketNavigation.hasPrev(ticket.id);
            rightButtons.push(<Icon toolTip="" key="last" icon="chevron-left" disabled={prevTicket === false} onPress={prevTicket !== false ? this.openTicket(prevTicket) : undefined} color={ThemeManager.style.defaultIconColor}/>, <View key="ticketCounter" style={{ height: 36, justifyContent: 'center' }}>
          <MaterialText centeredText centeredBox>{`${currentTicketNumber + 1}/${totalTicketNumbers}`}</MaterialText>
        </View>, <Icon toolTip="" key="next" icon="chevron-right" disabled={nextTicket === false} onPress={nextTicket !== false ? this.openTicket(nextTicket) : undefined} color={ThemeManager.style.defaultIconColor}/>);
        }
        rightButtons.push(<WatcherIcon ticket={ticket} key="watcherIcon"/>, <ReportIcon key="reportButton" selectedTickets={[ticket]}/>, <Icon toolTip="" key="moreButton" icon="dots-vertical" disabled={this.state.isLoading} onPress={(e) => {
                this.openMenu(e).catch((err) => console.error(err));
            }} color={ThemeManager.style.defaultIconColor}/>);
        const backButton = [
            <Icon toolTip={I18n.m.getMessage('tooltipBackToTicketSite')} key="TicketBackButton" icon="arrow-left" disabled={this.state.isLoading} color={ThemeManager.style.defaultIconColor} onPress={this.closeDialog}/>,
        ];
        const headerTitle = ticket != null ? <TicketHeaderTitle key={`${ticket.id}_${ticket.title}`} ticket={ticket}/> : '';
        let view;
        let tabs = null;
        let contentHeight = height - ThemeManager.style.getScreenRelativePixelSize(48);
        if (splitView) {
            view = (<View style={{ height, flexDirection: 'row', minHeight: '100%' }}>
          {this.renderLeftView(newWidth, contentHeight, ticket, splitView)}
          {this.renderRightView(newWidth, contentHeight, ticket)}
        </View>);
        }
        else {
            let tabBarWidth = splitView ? 255 : ResizeEvent.current.contentWidth;
            if (this._sideBarWidth > 0)
                tabBarWidth = this._sideBarWidth;
            tabs = (<TabBar textColor={ThemeManager.style.brandPrimary} unfocusedTextColor={ThemeManager.style.disabledColor} contentHeight={0} width={tabBarWidth} tabId="ticketTabs" underlineColor={ThemeManager.style.brandPrimary} tabs={[
                    {
                        title: I18n.m.getMessage('ticketsDetails'),
                    },
                    {
                        title: I18n.m.getMessage('ticketsDetailsActivities'),
                    },
                ]}/>);
            contentHeight = height - ThemeManager.style.getScreenRelativePixelSize(108);
            view = (<View>
          <TabContent width={width} height={contentHeight} tabId="ticketTabs" contents={[
                    <View key="contents_0" style={{
                            height: contentHeight,
                        }}>
                {this.renderLeftView(newWidth, contentHeight, ticket, splitView)}
              </View>,
                    <View key="contents_1" style={{
                            height: contentHeight,
                        }}>
                {this.renderRightView(newWidth, contentHeight, ticket)}
              </View>,
                ]}/>
        </View>);
        }
        return this.renderModal(<View key={ticket.id} style={{
                width,
                height,
                overflow: 'hidden',
                paddingBottom: 0,
                backgroundColor: '#FFFFFF',
            }}>
        <ImagePickerComp dragNdrop={false} ref={this.setImagePicker} options={new ImagePickerOptions({
                includeBase64: false,
                dropMultiHandlerDialogText: I18n.m.getMessage('addImageOnMultiSelect'),
                dropText: I18n.m.getMessage('addImageDropHint'),
                selectMethod: 'any',
                includeExtra: true,
                cameraType: 'back',
                multiple: true,
                mediaType: 'photo',
                filePicketCompId: `accInput_${fileIdCounter}`,
                quality: 0.9,
                editModeEnabled: CurrentUser.settings.appSettings == null ||
                    CurrentUser.settings.appSettings['openImageEditorAfterPhoto'] === true,
            })} callBack={this.onGotImageFiles}/>
        <FilePicketComp dragNdrop id={`uploadFiles_to_ticket_${fileIdCounter}`} multiple gotFile={(f) => {
                this.onGotFiles(f).catch((err) => console.log(err));
            }}/>

        
        <Header title={headerTitle} rightButtons={rightButtons} leftButtons={backButton} additionalContent={tabs} withElevation={false}/>
        {view}
      </View>);
    }
    renderLeftView(contentWidth, contentHeight, ticket, splitView) {
        const { width, fullscreen, project, hasSubtickets, canEditSubTicktes } = this.state;
        const { data, size } = this.props;
        const { currentUrl } = RouterControl.instance;
        const path = currentUrl.pathname.split('/');
        const showProject = (data.sp != null && data.sp) || path.includes('allTickets') || path.includes('dashboard');
        if (project == null)
            return undefined;
        return (<TicketDetailsEditable hasSubtickets={hasSubtickets} canEditSubTicktes={canEditSubTicktes} fullscreen={fullscreen} size={size} showProject={showProject} abortCreation={this.state.abortCreation} key="leftView" contentWidth={contentWidth} contentHeight={contentHeight} ticket={ticket} errorResult={(e) => {
                this.errorResult(e).catch((err) => console.error(err));
            }} isOnS={!(width > 600)} addAttachment={this.addAttachment} openCam={this.openCam} splitView={splitView} project={project}/>);
    }
    renderRightView(contentWidth, contentHeight, ticket) {
        const { currentKeyboardHeight, fullscreen, fileIdCounter } = this.state;
        if (ticket == null || ticket.title == null || ticket.title.length === 0) {
            return null;
        }
        const leftBorder = {
            borderColor: ThemeManager.style.borderColor,
            borderStyle: 'solid',
            borderWidth: 0,
            borderLeftWidth: ThemeManager.style.borderWidth,
        };
        const rightView = (<View key="rightView" style={{
                position: 'relative',
                width: contentWidth,
                height: contentHeight,
                backgroundColor: 'rgba(230,230,230,0.5)',
                ...leftBorder,
            }}>
        <ActivitiesList inSidebar={this.inSidebar} fileIdCounter={fileIdCounter} key={`act_${ticket.id}`} addAttachment={this.addAttachment} ticketId={ticket.id} projectId={ticket.projectId} contentWidth={contentWidth} contentHeight={contentHeight} currentKeyboardHeight={currentKeyboardHeight} fullscreen={fullscreen} uploadFile={(e) => {
                this.uploadFile(e).catch((err) => console.debug('cant upload file', err));
            }}/>
      </View>);
        return rightView;
    }
    async checkData(props) {
        await DateLocale.waitFor(() => CurrentUser.userId != null && CurrentUser.userId.length > 0, 100, 60000);
        if (CurrentUser.userId == null || CurrentUser.userId.length === 0) {
            Routing.instance.goTo('/');
            return;
        }
        await DateLocale.waitFor(() => UpmeshClient.instance.ready);
        let ticket;
        if (props.data != null && props.data.id != null && props.data.id.length > 0) {
            const { id } = props.data;
            if (id === 'new') {
                ticket = new TicketEntity();
                if (!props.data.projectId || props.data.projectId.length === 0) {
                    console.error('projectID Needed for new!');
                    Routing.instance.closeDialog(true);
                    return;
                }
                const close = () => {
                    Routing.instance.closeDialog(true)(null);
                };
                const catched = await DefaultErrorHandler.getProjectErrors(props.data.projectId, true, close);
                if (catched) {
                    return;
                }
                ticket.projectId = props.data.projectId;
                const { connectedToServer } = this.context;
                let localSynced = false;
                if (props.data.planId != null) {
                    const plan = await UpmeshClient.instance.modals.plan.getById(props.data.planId);
                    if (plan.activePlanId != null && plan.activePlanId !== 'MAP') {
                        localSynced = OfflineDataDownloader.isPlanVersionSynced(props.data.projectId, plan.activePlanId) != null;
                    }
                }
                const canIArchive = await this.setCanIArchive(ticket);
                const canIDelete = await this.setCanIDelete(ticket);
                if (props.data.planId != null && (connectedToServer || localSynced)) {
                    ticket.creator = CurrentUser.userId;
                    ticket.planId = props.data.planId;
                    ticket.planPositionX = props.data.planX != null ? Number.parseFloat(props.data.planX) : null;
                    ticket.planPositionY = props.data.planY != null ? Number.parseFloat(props.data.planY) : null;
                    ticket.id = 'new';
                    if (props.data.changePlan) {
                        this.setState({ ticket, canIArchive, canIDelete, isLoading: false, changePlan: true });
                    }
                    else {
                        Routing.instance.openDialog('ticket', { ...props.data, id: 'new', changePlan: true }, true)(null);
                    }
                }
                else {
                    await this.createTicket(ticket);
                }
            }
            else {
                await this.loadTicket(props);
                if (props.data['addFiles'] === 'true') {
                    if (SharedFiles.files != null && SharedFiles.files.length > 0) {
                        this.onGotFiles(SharedFiles.files)
                            .then(() => {
                            Routing.instance.changeQueryParameter({ data: { ...props.data, addFiles: false } }, true);
                            SharedFiles.files = [];
                        })
                            .catch((err) => {
                            Routing.instance.changeQueryParameter({ data: { ...props.data, addFiles: false } }, true);
                            SharedFiles.files = [];
                            DefaultErrorHandler.showDefaultErrorAlert(err);
                        });
                    }
                    else {
                        this.pressAddFile(null);
                    }
                }
            }
        }
    }
    createActivitie() {
        const t = this.state.ticket;
        const ticket = t != null ? t : undefined;
        return {
            comment: '',
            ticketId: ticket != null ? ticket.id : '',
            projectId: ticket != null ? ticket.projectId : '',
            clientCreation: new Date(),
        };
    }
    static createSize() {
        const size = ResizeEvent.current;
        let fullscreen = true;
        let width = size.contentWidth;
        let height = size.contentHeight;
        if (size.windowWidth > ThemeManager.style.breakpointL) {
            fullscreen = false;
            width = 1024;
            height *= 0.9;
        }
        else if (size.windowWidth > ThemeManager.style.breakpointM) {
            fullscreen = false;
            width = Math.min(0.9 * width, 1024);
            height *= 0.9;
        }
        if (!fullscreen &&
            CurrentUser.settings.appSettings != null &&
            CurrentUser.settings.appSettings['openTicketsInSidePanel'] === true) {
            width = Math.min(1024, ResizeEvent.current.windowWidth / 3);
            height = size.contentHeight;
        }
        return {
            fullscreen,
            width: Math.round(width),
            height: Math.round(height),
            maxWidth: Math.round(width),
            maxHeight: Math.round(height),
            currentKeyboardHeight: size.currentKeyboardHeight,
        };
    }
    async loadTicket(props) {
        this.setState({ ticket: undefined });
        let changePlan = false;
        if (props.data != null && props.data.id != null && props.data.id.length > 0) {
            if (props.data.changePlan != null && props.data.changePlan === 'true') {
                changePlan = true;
            }
            const { id } = props.data;
            try {
                const ticket = await UpmeshClient.instance.modals.ticket.getById(id);
                const hasSubtickets = (await UpmeshClient.instance.modals.ticket.count(`subTicketFrom eq '${ticket.id}'`)) > 0;
                let canEditSubTicktes = false;
                try {
                    const change = new ChangeTicketSubTicketFrom({ ticketId: ticket.id }, ticket.id);
                    await change.canI();
                    canEditSubTicktes = true;
                }
                catch (e) {
                    console.debug('cant ChangeTicketSubTicketFrom', e);
                }
                CurrentProject.instance.setTicketId(ticket.id);
                const project = await UpmeshClient.instance.modals.project.getById(ticket.projectId);
                await new Promise((r) => {
                    CurrentProject.projectChanged.once((_p) => {
                        r();
                    });
                    CurrentProject.instance.setProjectId(ticket.projectId);
                });
                CurrentProject.ticketChanged.detach(this.updateTicket);
                CurrentProject.ticketChanged.attach(this.updateTicket);
                const canIArchive = await this.setCanIArchive(ticket);
                const canIDelete = await this.setCanIDelete(ticket);
                await new Promise((resolve) => {
                    this.setState({
                        currentTicketNumber: TicketNavigation.currentIndex(ticket.id),
                        changePlan,
                        hasSubtickets,
                        canEditSubTicktes,
                        canIArchive,
                        canIDelete,
                        ticket,
                        project,
                    }, () => {
                        resolve();
                    });
                });
            }
            catch (e) {
                console.debug('cant get ticket', e);
                Routing.instance.closeDialog(true)(null);
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('ticketNoAccessRight'),
                });
            }
        }
    }
    async uploadFile(f) {
        const a = this.createActivitie();
        await AddStoredFileAndUpload.onGotFile(f, a.projectId, a.ticketId);
        this.setState((prevState) => ({ fileIdCounter: prevState.fileIdCounter + 1 }));
        return '';
    }
}
TicketPU.contextType = ConnectionContext;
TicketPU.lastTicket = null;
TicketPU.defaultProps = {
    data: null,
};
