import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInputFormFilled } from 'materialTheme/src/theme/components/forminput/DateInputFormFilled';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeTimeTracking, } from 'upmesh-core/src/client/commands/companies/timetracking/ChangeTimeTracking';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export class ChangeTimeTrackDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const { timeTrackingEntry } = this.props;
            const projects = await UpmeshClient.instance.modals.project.get({ filter: `archived ne true and deleted ne true` }, CurrentUser.userId);
            const projectPickerList = [];
            projects.forEach((pr, index) => projectPickerList.push({ title: pr.title, onPressChipData: { type: 'project', id: pr.id, index } }));
            if (timeTrackingEntry.projectId != null &&
                timeTrackingEntry.projectTitle != null &&
                projectPickerList.findIndex((p) => p.onPressChipData.id === timeTrackingEntry.projectId) === -1) {
                projectPickerList.push({
                    onPressChipData: { type: 'project', id: timeTrackingEntry.projectId, index: projectPickerList.length },
                    title: timeTrackingEntry.projectTitle,
                });
            }
            const costUnitList = [];
            const costCenterList = [];
            const me = await AuthClient.instance.modals.user.getById(CurrentUser.userId);
            const companyMemberMe = await UpmeshClient.instance.modals.companyMember.get({
                filter: `userId eq '${me.id}'`,
                top: 1,
            });
            const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                filter: `companyId eq '${companyMemberMe[0].companyId}' and deleted ne true and active ne false`,
            });
            costCenters.forEach((cc) => {
                if (cc.costType === 'costUnit')
                    costUnitList.push({
                        title: `${cc.mark} - ${cc.description}`,
                        onPressChipData: { id: cc.id, type: 'costUnit', index: costUnitList.length },
                    });
                else
                    costCenterList.push({
                        title: `${cc.mark} - ${cc.description}`,
                        onPressChipData: { id: cc.id, type: 'costCenter', index: costCenterList.length },
                    });
            });
            let selectedProject = 0;
            let selectedSegment = 2;
            if (timeTrackingEntry.projectId != null && timeTrackingEntry.projectId.length > 0) {
                const pi = projectPickerList.findIndex((t) => t.onPressChipData.id === timeTrackingEntry.projectId);
                if (pi !== -1) {
                    selectedProject = pi;
                    selectedSegment = 0;
                }
            }
            else if (timeTrackingEntry.costCenterId != null) {
                const pi = costCenterList.findIndex((t) => t.onPressChipData.id === timeTrackingEntry.costCenterId);
                if (pi !== -1) {
                    selectedProject = pi;
                    selectedSegment = 1;
                }
            }
            const tasks = await UpmeshClient.instance.modals.task.get({ filter: `deleted ne true and active ne false and productive eq true`, orderby: 'mark' }, CurrentUser.userId);
            const taskList = [];
            tasks.forEach((ta) => taskList.push({ title: `${ta.mark} - ${ta.description}`, data: ta.id }));
            let selectedTask = 0;
            if (timeTrackingEntry.taskId != null) {
                const ti = tasks.findIndex((t) => t.id === timeTrackingEntry.taskId);
                if (ti !== -1) {
                    selectedTask = ti;
                }
            }
            const selectedProjectAndCost = {
                type: '',
                projectId: timeTrackingEntry.projectId,
                costId: timeTrackingEntry.costCenterId ? timeTrackingEntry.costCenterId : '',
            };
            const startTimeQuickSelection = [
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 6, 0, 0, 0)), time: { hour: 6, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 7, 0, 0, 0)), time: { hour: 7, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 8, 0, 0, 0)), time: { hour: 8, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 9, 0, 0, 0)), time: { hour: 9, minute: 0 } },
            ];
            const endTimeQuickSelection = [
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 16, 0, 0, 0)), time: { hour: 16, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 17, 0, 0, 0)), time: { hour: 17, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 18, 0, 0, 0)), time: { hour: 18, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 19, 0, 0, 0)), time: { hour: 19, minute: 0 } },
            ];
            const pauseTimeQuickSelection = [
                { name: '0:15', time: { hour: 0, minute: 15 } },
                { name: '0:30', time: { hour: 0, minute: 30 } },
                { name: '0:45', time: { hour: 0, minute: 45 } },
                { name: '1:00', time: { hour: 1, minute: 0 } },
            ];
            const today = new Date();
            const dateQuickSelection = [
                { name: I18n.m.getMessage('today'), days: today.getTime() },
                {
                    name: I18n.m.getMessage('yesterday'),
                    days: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0).getTime(),
                },
                {
                    name: I18n.m.getMessage('dayBeforeYesterday'),
                    days: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2, 0, 0, 0).getTime(),
                },
            ];
            if (CompanyUserInfo.me != null && CompanyUserInfo.me.workingTimeModelId != null) {
                try {
                    const workingTimeModel = await UpmeshClient.instance.modals.workingTimeModel.getById(CompanyUserInfo.me.workingTimeModelId);
                    const d = workingTimeModel.getCorrectDaytime(new Date());
                    if (d != null) {
                        if (d.start != null) {
                            const find = startTimeQuickSelection.findIndex((e) => e.time.hour === d.start.hours && e.time.minute === d.start.minutes);
                            if (find === -1) {
                                startTimeQuickSelection.unshift({
                                    name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, d.start.hours, d.start.minutes, 0, 0)),
                                    time: { hour: d.start.hours, minute: d.start.minutes },
                                });
                            }
                        }
                        if (d.stop != null) {
                            const find = endTimeQuickSelection.findIndex((e) => e.time.hour === d.stop.hours && e.time.minute === d.stop.minutes);
                            if (find === -1) {
                                endTimeQuickSelection.unshift({
                                    name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, d.stop.hours, d.stop.minutes, 0, 0)),
                                    time: { hour: d.stop.hours, minute: d.stop.minutes },
                                });
                            }
                        }
                        if (d.pause != null) {
                            const find = pauseTimeQuickSelection.findIndex((e) => e.time.hour === d.pause.hours && e.time.minute === d.pause.minutes);
                            if (find === -1) {
                                pauseTimeQuickSelection.unshift({
                                    name: `${d.pause.hours}:${d.stop.minutes > 9 ? d.pause.minutes : `0${d.pause.minutes}`}`,
                                    time: { hour: d.pause.hours, minute: d.pause.minutes },
                                });
                            }
                        }
                    }
                }
                catch (e) {
                    console.debug('cant get working Time Model for user', e);
                }
            }
            this.setState({
                projectPickerList,
                selectedTask,
                selectedSegment,
                selectedProject,
                taskList,
                isLoading: false,
                selectedProjectAndCost,
                costUnitList,
                costCenterList,
                startTimeQuickSelection,
                endTimeQuickSelection,
                pauseTimeQuickSelection,
                dateQuickSelection,
            });
        };
        this.save = () => {
            const { end, start, date, pause, selectedTask, taskList, selectedProjectAndCost, notes } = this.state;
            const { timeTrackingEntry, checkAndSaveTimeTracking } = this.props;
            if (date == null)
                return;
            this.setState({ isLoading: true }, () => {
                try {
                    const s = start != null ? start : new Date();
                    const starts = new Date(date.getFullYear(), date.getMonth(), date.getDate(), s.getHours(), s.getMinutes());
                    const ends = end == null
                        ? undefined
                        : new Date(date.getFullYear(), date.getMonth(), date.getDate(), end.getHours(), end.getMinutes());
                    if (ends != null && ends.getTime() < starts.getTime()) {
                        ends.setDate(ends.getDate() + 1);
                    }
                    const data = {
                        ends,
                        starts,
                        changeTime: new Date(),
                        pause: pause != null ? { hours: pause.getHours(), minutes: pause.getMinutes() } : undefined,
                        taskId: taskList.length > 0 ? taskList[selectedTask].data : undefined,
                        note: notes,
                        projectId: selectedProjectAndCost.projectId == null ? '' : selectedProjectAndCost.projectId,
                        costCenterId: selectedProjectAndCost.costId,
                    };
                    checkAndSaveTimeTracking(new TimeTrackingEntity({ ...timeTrackingEntry, ...data }), this.saveNow(data));
                }
                catch (e) {
                    console.debug('errors', e);
                    this.setState({ isLoading: false }, () => {
                        DefaultErrorHandler.showDefaultErrorAlert(e);
                    });
                }
            });
        };
        this.costCenterCheckForError = async (selected) => {
            let errorMessage;
            let projectAndCost = {
                type: selected.type,
                projectId: selected.type === 'project' ? selected.id : undefined,
                costId: selected.type !== 'project' ? selected.id : '',
            };
            if (selected.type === 'project') {
                const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                    filter: `deleted ne true`,
                });
                const center = costCenters.find((c) => c.projectIds != null && c.projectIds.indexOf(selected.id) > -1);
                projectAndCost = {
                    projectId: projectAndCost.projectId,
                    costId: center != null ? center.id : '',
                    type: projectAndCost.type,
                };
                if (projectAndCost.costId === '') {
                    errorMessage = I18n.m.getMessage('costCenterProjectWithoutCenterError');
                }
            }
            else if (selected.type === 'costUnit') {
                const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                    filter: `deleted ne true and id eq '${selected.id}'`,
                });
                const { projectIds } = costCenters[0];
                if (projectIds == null || projectIds.length === 0) {
                    errorMessage = I18n.m.getMessage('costCenterNoProjectError');
                }
                else if (projectIds.length === 1) {
                    projectAndCost = {
                        projectId: projectIds[0] != null ? projectIds[0] : undefined,
                        costId: projectAndCost.costId,
                        type: projectAndCost.type,
                    };
                }
                else {
                    const getProjects = await UpmeshClient.instance.modals.project.get({
                        filter: projectIds.length === 1
                            ? `id eq '${projectIds[0]}' and deleted ne true`
                            : `(id in ${JSON.stringify(projectIds)}) and deleted ne true`,
                    });
                    if (getProjects.length === 0) {
                        errorMessage = I18n.m.getMessage('costCenterNoProjectError');
                    }
                    else if (getProjects.length > 1) {
                        errorMessage = I18n.m.getMessage('costCenterMultipleProjectsError');
                    }
                    else {
                        projectAndCost = {
                            projectId: getProjects[0].id != null ? getProjects[0].id : undefined,
                            costId: projectAndCost.costId,
                            type: projectAndCost.type,
                        };
                    }
                }
            }
            return { errorMessage, projectAndCost };
        };
        this.saveNow = (data) => () => {
            const { timeTrackingEntry } = this.props;
            const create = new ChangeTimeTracking(data, timeTrackingEntry.id);
            create
                .execute(ClientStore.commandStore)
                .then(() => {
                Dialog.instance?.close();
            })
                .catch((e) => {
                console.debug('errors', e);
                this.setState({ isLoading: false }, () => {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                });
            });
        };
        this.onPressProjectChip = (newSelected) => {
            this.costCenterCheckForError(newSelected)
                .then((checked) => {
                this.setState({
                    selectedSegment: newSelected.type === 'project' ? 0 : newSelected.type === 'costCenter' ? 1 : 2,
                    selectedProjectAndCost: checked.projectAndCost,
                    projectAndCostHelperText: checked.errorMessage,
                    selectedProject: newSelected.index,
                });
            })
                .catch((err) => {
                console.error(err);
            });
        };
        this.state = {
            projectPickerList: [],
            costCenterList: [],
            costUnitList: [],
            selectedProjectAndCost: { type: '', projectId: '', costId: '' },
            taskList: [],
            start: new Date(props.timeTrackingEntry.starts),
            selectedTask: 0,
            notes: props.timeTrackingEntry.note != null ? props.timeTrackingEntry.note : '',
            selectedProject: 0,
            selectedSegment: 0,
            date: new Date(props.timeTrackingEntry.starts),
            isLoading: true,
            end: props.timeTrackingEntry.ends != null ? new Date(props.timeTrackingEntry.ends) : undefined,
            pause: props.timeTrackingEntry.pause != null
                ? new Date(1970, 0, 1, props.timeTrackingEntry.pause.hours, props.timeTrackingEntry.pause.minutes, 0, 0)
                : undefined,
            startTimeQuickSelection: [],
            endTimeQuickSelection: [],
            pauseTimeQuickSelection: [],
            dateQuickSelection: [],
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant load data', err));
    }
    render() {
        const { timeTrackingEntry } = this.props;
        const { costCenterList, costUnitList, projectAndCostHelperText, projectPickerList, taskList, selectedProject, selectedSegment, selectedTask, start, date, end, pause, notes, isLoading, startTimeQuickSelection, endTimeQuickSelection, pauseTimeQuickSelection, dateQuickSelection, } = this.state;
        const note = timeTrackingEntry.note == null ? '' : timeTrackingEntry.note;
        const hasChanged = note !== notes ||
            (timeTrackingEntry.starts == null && start != null) ||
            (timeTrackingEntry.starts != null && start == null) ||
            (timeTrackingEntry.starts != null &&
                start != null &&
                new Date(timeTrackingEntry.starts).getTime() !== start.getTime()) ||
            (timeTrackingEntry.ends == null && end != null) ||
            (timeTrackingEntry.ends != null && end == null) ||
            (timeTrackingEntry.ends != null && end != null && new Date(timeTrackingEntry.ends).getTime() !== end.getTime()) ||
            (timeTrackingEntry.pause == null && pause != null) ||
            (timeTrackingEntry.pause != null && pause == null) ||
            (timeTrackingEntry.pause != null &&
                pause != null &&
                (timeTrackingEntry.pause.hours !== pause.getHours() ||
                    timeTrackingEntry.pause.minutes !== pause.getMinutes())) ||
            (taskList.length > 0 && timeTrackingEntry.taskId !== taskList[selectedTask].data) ||
            (projectPickerList.length > selectedProject &&
                projectPickerList[selectedProject] != null &&
                timeTrackingEntry.projectId !== projectPickerList[selectedProject].onPressChipData.id) ||
            (costCenterList.length > selectedProject &&
                selectedSegment === 1 &&
                costCenterList[selectedProject] != null &&
                timeTrackingEntry.costCenterId !== costCenterList[selectedProject].onPressChipData.id) ||
            date == null ||
            I18n.m.date().localeDateString(new Date(timeTrackingEntry.starts)) !==
                I18n.m.date().localeDateString(new Date(date));
        return [
            <DialogTitle key="title">{I18n.m.getMessage('timeTrackingChangeTimeTrack')}</DialogTitle>,
            <DialogContent key="content">
        <View style={{ padding: 0 }}>
          {projectPickerList.length === 0 && costCenterList.length === 0 && costUnitList.length === 0 ? null : (<OpenableChip key={`picker_${projectPickerList.length}_${costCenterList.length}_${costUnitList.length}`} looksLikeFI formInputLabel={`${I18n.m.getMessage('costCenter')}/${I18n.m.getMessage('costUnit')}/${I18n.m.getMessage('project')}`} dialogTitle={`${I18n.m.getMessage('costCenter')}/${I18n.m.getMessage('costUnit')}/${I18n.m.getMessage('project')}`} formInputHelperText={projectAndCostHelperText} formInputError={projectAndCostHelperText != null} selectedSegment={selectedSegment} selected={selectedProject} onPressChip={this.onPressProjectChip} segmentedChipsList={[
                        { chips: projectPickerList, title: I18n.m.getMessage('projects'), type: 'project' },
                        { chips: costCenterList, title: I18n.m.getMessage('costCenters'), type: 'costCenter' },
                        { chips: costUnitList, title: I18n.m.getMessage('costUnits'), type: 'costUnit' },
                    ]}/>)}
          {taskList.length === 0 ? null : (<FormInputPicker list={taskList} labelText={I18n.m.getMessage('journalEmployeesJob')} selectedIndex={selectedTask} onChangePicker={(newIndex) => this.setState({ selectedTask: newIndex })}/>)}
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ flex: 1, paddingRight: 8 }}>
              <DateInputFormFilled clearButton={false} selectDate={false} selectTime labelText={I18n.m.getMessage('workingTimeModelsStart')} timeQuickSelections={startTimeQuickSelection} value={start} helperText={start == null ? I18n.m.getMessage('timetrackingErrorNoStart') : undefined} error={start == null} onChange={(start) => {
                    this.setState({ start });
                }}/>
            </View>
            <View style={{ flex: 1, paddingLeft: 8 }}>
              <DateInputFormFilled selectDate={false} clearButton={false} selectTime labelText={I18n.m.getMessage('workingTimeModelsStop')} helperText={end == null ? I18n.m.getMessage('timetrackingErrorNoEnd') : undefined} error={end == null} value={end} timeQuickSelections={endTimeQuickSelection} onChange={(end) => {
                    this.setState({ end });
                }}/>
            </View>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ flex: 1, paddingRight: 8 }}>
              <DateInputFormFilled clearButton={false} labelText={I18n.m.getMessage('date')} value={date} quickSelections={dateQuickSelection} helperText={date == null ? I18n.m.getMessage('required') : undefined} error={date == null} onChange={(date) => {
                    this.setState({ date });
                }}/>
            </View>
            <View style={{ flex: 1, paddingLeft: 8 }}>
              <DateInputFormFilled selectDate={false} selectTime timeFormat="24" clearButton={false} labelText={I18n.m.getMessage('workingTimeModelsPause')} timeQuickSelections={pauseTimeQuickSelection} value={pause} onChange={(pause) => {
                    this.setState({ pause });
                }}/>
            </View>
          </View>
          <FormInputFilled labelText={I18n.m.getMessage('timeTrackingNotes')} initValue={notes} onChange={(notes) => this.setState({ notes })}/>
        </View>
      </DialogContent>,
            <DialogActions key="actions" onBottom>
        <ContainedButton title={I18n.m.getMessage('cancel')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" onPress={() => Dialog.instance?.close()} disabled={isLoading}/>
        <ContainedButton title={I18n.m.getMessage('save')} onPress={this.save} loading={isLoading} disabled={!hasChanged || projectAndCostHelperText != null || end == null || start == null || date == null}/>
      </DialogActions>,
        ];
    }
}
