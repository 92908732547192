"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeProjectTag = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var Config_1 = require("../../../../Config");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ProjectTagChanged_1 = require("../../../events/project/tags/ProjectTagChanged");
var ClientProjectCommands_1 = require("../../_root/ClientProjectCommands");
var ChangeProjectTag = function (_ClientProjectCommand) {
  function ChangeProjectTag(data, projectId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeProjectTag);
    _this = _callSuper(this, ChangeProjectTag, [data, projectId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeProjectTag';
    return _this;
  }
  (0, _inherits2.default)(ChangeProjectTag, _ClientProjectCommand);
  return (0, _createClass2.default)(ChangeProjectTag, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(ChangeProjectTag.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, this.entityId, userId);
        var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.entityId);
        if (project.projectSubscription == null || project.projectSubscription === 'basic') {
          throw {
            message: 'Min. Pro-Projekt benötigt',
            messageCode: 'notInBasicProject'
          };
        }
        var tickets = yield CommandReadModels_1.CommandReadModels.ticket.get({
          filter: `projectId eq '${this.entityId}' and tags/tagName eq '${this.data.oldValue.tagName.replace(/'/gi, "''")}'`,
          top: 1
        });
        if (tickets.length > 0) {
          throw {
            message: 'Das Tag ist mindestens einem Ticket zugeordnet und kann deswegen nicht geändert werden',
            messageCode: 'projectTagChangeErrorAssignedToMinOneTicket'
          };
        }
        var plans = yield CommandReadModels_1.CommandReadModels.plan.get({
          filter: `projectId eq '${this.entityId}' and tags/tagName eq '${this.data.oldValue.tagName.replace(/'/gi, "''")}' and deleted ne true`,
          top: 1
        });
        if (plans.length > 0) {
          throw {
            message: 'Das Tag ist mindestens einem Plan zugeordnet und kann deswegen nicht geändert werden',
            messageCode: 'projectTagChangeErrorAssignedToMinOnePlan'
          };
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.entityId, userId, 'commandChangeProjectTags')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new ProjectTagChanged_1.ProjectTagChanged(this.entityId, Object.assign({}, this.data));
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var _this2 = this;
        var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.entityId);
        if (key == null || key === 'newValue') {
          if (this.data.newValue.tagName.length <= 0) {
            throw {
              key: 'newValue.tagName',
              messageCode: 'tagsErrorNameRequired',
              message: 'Tag Name benötigt'
            };
          }
          if (this.data.newValue.groupName == null || this.data.newValue.groupName.length <= 0) {
            throw {
              key: 'newValue.groupName',
              messageCode: 'tagsErrorGroupRequired',
              message: 'Taggruppe wird benötigt'
            };
          }
          if (this.data.newValue.color == null || this.data.newValue.color.length <= 0) {
            throw {
              key: 'newValue.color',
              messageCode: 'tagsErrorColorRequired',
              message: 'Gruppenfarbe wird benötigt'
            };
          }
          if (this.data.newValue.tagName !== this.data.oldValue.tagName && project.tags != null) {
            if (project.tags.findIndex(function (t) {
              return t.tagName === _this2.data.newValue.tagName;
            }) > -1) {
              throw {
                message: 'Das Tag existiert bereits im Projekt',
                messageCode: 'projectTagAlreadyExists'
              };
            }
          }
          if (this.data.newValue.tagName.length > Config_1.ConfigHandler.config.string_Short) {
            throw {
              key: 'tag',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
          if (this.data.newValue.groupName.length > Config_1.ConfigHandler.config.string_Short) {
            throw {
              key: 'tag',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
          if (this.data.newValue.color.length > Config_1.ConfigHandler.config.string_Short) {
            throw {
              key: 'tag',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
        if (key == null || key === 'oldValue') {
          var _project$tags;
          if (((_project$tags = project.tags) == null ? void 0 : _project$tags.findIndex(function (t) {
            return _this2.data.oldValue.tagName === t.tagName;
          })) === -1) {
            throw {
              message: 'Das Tag existiert nicht im Projekt',
              messageCode: 'projectTagNotExists'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeProjectTag = ChangeProjectTag;