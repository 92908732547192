"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WorkerServerConnection = void 0;
var CommandOfflineFirstStore_1 = require("cqrs-core/build/src/client/commands/CommandOfflineFirstStore");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var ServerConnection_1 = require("cqrs-core/build/src/core/ServerConnection");
var socket_io_client_1 = require("socket.io-client");
var ts_events_1 = require("ts-events");
var WorkerRemoteDB_1 = require("./WorkerRemoteDB");
var WorkerIOManager = function (_socket_io_client_1$M) {
  function WorkerIOManager() {
    var _this;
    (0, _classCallCheck2.default)(this, WorkerIOManager);
    _this = _callSuper(this, WorkerIOManager, arguments);
    _this.events = new Map();
    return _this;
  }
  (0, _inherits2.default)(WorkerIOManager, _socket_io_client_1$M);
  return (0, _createClass2.default)(WorkerIOManager, [{
    key: "on",
    value: function on(ev, listener) {
      var _this$events$get;
      if (!this.events.has(ev)) this.events.set(ev, new ts_events_1.AsyncEvent());
      (_this$events$get = this.events.get(ev)) == null || _this$events$get.attach(listener);
      return this;
    }
  }, {
    key: "off",
    value: function off(ev, _data) {
      if (this.events.has(ev)) {
        var _this$events$get2;
        (_this$events$get2 = this.events.get(ev)) == null || _this$events$get2.detach(_data);
      }
      return this;
    }
  }]);
}(socket_io_client_1.Manager);
var WorkerSocket = function (_socket_io_client_1$S) {
  function WorkerSocket(emt, emt2) {
    var _this2;
    (0, _classCallCheck2.default)(this, WorkerSocket);
    _this2 = _callSuper(this, WorkerSocket, [new WorkerIOManager({
      autoConnect: false,
      reconnection: false
    }), '']);
    _this2.events = new Map();
    _this2.connected = true;
    _this2.emitter = emt;
    _this2.emitter2 = emt2;
    return _this2;
  }
  (0, _inherits2.default)(WorkerSocket, _socket_io_client_1$S);
  return (0, _createClass2.default)(WorkerSocket, [{
    key: "post",
    value: function post(ev, _data) {
      var _this$events$get3;
      (_this$events$get3 = this.events.get(ev)) == null || _this$events$get3.post(_data);
      if (ev.includes('-')) this.events.delete(ev);
    }
  }, {
    key: "on",
    value: function on(ev, listener) {
      var _this$events$get4;
      if (!this.events.has(ev)) this.events.set(ev, new ts_events_1.AsyncEvent());
      (_this$events$get4 = this.events.get(ev)) == null || _this$events$get4.attach(listener);
      return this;
    }
  }, {
    key: "off",
    value: function off(ev, _data) {
      var _this$events$get5;
      (_this$events$get5 = this.events.get(ev)) == null || _this$events$get5.detach(_data);
      return this;
    }
  }, {
    key: "once",
    value: function once(ev, _data) {
      var _this$events$get6;
      if (!this.events.has(ev)) this.events.set(ev, new ts_events_1.AsyncEvent());
      (_this$events$get6 = this.events.get(ev)) == null || _this$events$get6.once(_data);
      return this;
    }
  }, {
    key: "emit",
    value: function emit(ev, _data) {
      if (this.emitter != null) this.emitter(ev, _data);
      return this;
    }
  }, {
    key: "emitWithAck",
    value: function () {
      var _emitWithAck = (0, _asyncToGenerator2.default)(function* (ev, _data) {
        if (this.emitter2 != null) {
          var r = yield this.emitter2(ev, _data);
          return r;
        }
        return null;
      });
      function emitWithAck(_x, _x2) {
        return _emitWithAck.apply(this, arguments);
      }
      return emitWithAck;
    }()
  }, {
    key: "connect",
    value: function connect() {
      return this;
    }
  }]);
}(socket_io_client_1.Socket);
var WorkerServerConnection = function (_ServerConnection_1$S) {
  function WorkerServerConnection(postMessage, postMessageWithAnswer, onMessage, clientId, token) {
    var _this3;
    (0, _classCallCheck2.default)(this, WorkerServerConnection);
    _this3 = _callSuper(this, WorkerServerConnection, [{
      remoteOptions: {
        apiUrl: '127.0.0.1',
        authToken: token != null ? token : ''
      },
      autoConnect: false,
      query: {
        clientversion: ''
      }
    }]);
    _this3.emit = function (ev, _data) {
      if (_this3.postMessage != null) _this3.postMessage(`toLeaderµ${_this3.clientId}µ${ev}µ${_data != null ? JSON.stringify(_data) : ''}`);
    };
    _this3.emit2 = function (ev, _data) {
      if (_this3.postMessageWithAnswer != null) {
        return _this3.postMessageWithAnswer(`toLeaderµ${_this3.clientId}µ${ev}µ${_data != null ? JSON.stringify(_data) : ''}`);
      }
      return null;
    };
    _this3.connect = (0, _asyncToGenerator2.default)(function* () {
      if (_this3.isConnected) return true;
      var c = yield new Promise(function (resolve) {
        var r = function r(d) {
          if (d.message.startsWith('connected')) {
            resolve(!!d.message.split('µ')[1]);
          }
          _this3.onMessage.detach(r);
        };
        _this3.onMessage.attach(r);
        _this3.postMessage(`toLeaderµconnect`);
      });
      return c;
    });
    _this3._leaderServerConnection = true;
    _this3.close = (0, _asyncToGenerator2.default)(function* () {});
    _this3.events = {};
    _this3.onConnected = function () {
      if (_this3.isConnected) {
        _this3.onConnect.post(_this3._socket);
      }
      _this3.onConnectionChange();
    };
    _this3.remoteDB = new WorkerRemoteDB_1.WorkerRemoteDB();
    _this3.postMessage = postMessage;
    _this3.postMessageWithAnswer = postMessageWithAnswer;
    _this3.onMessage = onMessage;
    _this3.onMessage.attach(function (d) {
      if (d.message.startsWith('authToken')) {
        var newToken = d.message.split('µ')[1];
        (0, _get2.default)((_this3, (0, _getPrototypeOf2.default)(WorkerServerConnection.prototype)), "changeAuthToken", _this3).call(_this3, newToken).catch(function (err) {
          return void 0;
        });
      }
    });
    _this3.clientId = clientId;
    return _this3;
  }
  (0, _inherits2.default)(WorkerServerConnection, _ServerConnection_1$S);
  return (0, _createClass2.default)(WorkerServerConnection, [{
    key: "post",
    value: function post(ev, _data) {
      this.socket.post(ev, _data);
    }
  }, {
    key: "localDB",
    get: function get() {
      return this.localClientReadDB;
    }
  }, {
    key: "setLocalReadDB",
    value: function setLocalReadDB(localReadDB, postToLocal) {
      this.localClientReadDB = localReadDB;
      this.postToLocal = postToLocal;
    }
  }, {
    key: "getDataFromLocalDB",
    value: function () {
      var _getDataFromLocalDB = (0, _asyncToGenerator2.default)(function* (ev, _data) {
        if (this.localClientReadDB != null) {
          try {
            var result;
            var d = _data.data;
            var r = ReadModels_1.ReadModels.get(d.readmodel);
            var m = this.localClientReadDB.getRepos(r.exampleEntity);
            if (m == null && d.readmodel === 'CommandOfflineModel') {
              var repo = this.localClientReadDB.getRepos(new CommandOfflineFirstStore_1.CommandOfflineModel());
              if (repo != null) m = repo;
            }
            if (m == null) {
              result = '';
            } else if (d.method === 'get') {
              result = yield m.get(d.options);
            } else if (d.method === 'getById' && d.options != null && d.options.id != null) {
              result = yield m.getById(d.options.id, d.options.select);
            } else if (d.method === 'count') {
              var _d$options;
              result = yield m.count((_d$options = d.options) == null ? void 0 : _d$options.filter);
            } else if (d.method === 'distinct' && d.options != null && d.options.select != null && typeof d.options.select === 'string') {
              var _d$options2;
              result = yield m.distinct(d.options.select, (_d$options2 = d.options) == null ? void 0 : _d$options2.filter);
            }
            return result;
          } catch (e) {
            return this.postMessageWithAnswer(`toLeaderµ${this.clientId}µ${ev}µ${_data != null ? JSON.stringify(_data) : ''}`);
          }
        } else {
          return this.postMessageWithAnswer(`toLeaderµ${this.clientId}µ${ev}µ${_data != null ? JSON.stringify(_data) : ''}`);
        }
        return null;
      });
      function getDataFromLocalDB(_x3, _x4) {
        return _getDataFromLocalDB.apply(this, arguments);
      }
      return getDataFromLocalDB;
    }()
  }, {
    key: "leaderServerConnection",
    get: function get() {
      return this._leaderServerConnection;
    },
    set: function set(value) {
      this._leaderServerConnection = value;
      this.onConnectionChanged.post(value);
    }
  }, {
    key: "isConnected",
    get: function get() {
      return true;
    }
  }, {
    key: "createSocket",
    value: function createSocket(_autoConnect) {
      var _this4 = this;
      var emit = function emit(ev, data) {
        return _this4.emit(ev, data);
      };
      var emit2 = function emit2(ev, data) {
        return _this4.emit2(ev, data);
      };
      this._socket = new WorkerSocket(emit, emit2);
      this.addListener();
    }
  }, {
    key: "socket",
    get: function get() {
      return this._socket;
    }
  }, {
    key: "fetch",
    value: function () {
      var _fetch = (0, _asyncToGenerator2.default)(function* (path, init) {
        this.postMessage(`serverµ${path}µ${init != null ? JSON.stringify(init) : ''}`);
        return {};
      });
      function fetch(_x5, _x6) {
        return _fetch.apply(this, arguments);
      }
      return fetch;
    }()
  }, {
    key: "changeAuthToken",
    value: function () {
      var _changeAuthToken = (0, _asyncToGenerator2.default)(function* (newToken) {
        return (0, _get2.default)((0, _getPrototypeOf2.default)(WorkerServerConnection.prototype), "changeAuthToken", this).call(this, newToken);
      });
      function changeAuthToken(_x7) {
        return _changeAuthToken.apply(this, arguments);
      }
      return changeAuthToken;
    }()
  }]);
}(ServerConnection_1.ServerConnection);
exports.WorkerServerConnection = WorkerServerConnection;