"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DatabaseVersion = void 0;
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var uuid = require("uuid");
var DatabaseVersion = function (_OdataEntity_1$OdataE) {
  function DatabaseVersion(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, DatabaseVersion);
    _this = _callSuper(this, DatabaseVersion, [obj]);
    _this.entityName = 'DatabaseVersion';
    _this.dBOptions;
    if (obj != null && obj.name != null && obj.id == null) {
      obj.id = `${uuid.v1()}${obj.name.substr(obj.name.lastIndexOf('.'))}`;
    }
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(DatabaseVersion, _OdataEntity_1$OdataE);
  return (0, _createClass2.default)(DatabaseVersion, [{
    key: "createOne",
    value: function createOne(obj) {
      return new DatabaseVersion(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = OdataEntity_1.OdataEntity.createBaseMap();
      baseMap.set('currentVersion', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        return true;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "fill",
    value: function fill(obj) {
      (0, _get2.default)((0, _getPrototypeOf2.default)(DatabaseVersion.prototype), "fill", this).call(this, obj);
    }
  }]);
}(OdataEntity_1.OdataEntity);
exports.DatabaseVersion = DatabaseVersion;