import { UserImageSyncButton } from 'materialTheme/src/components/account/profile/UserImageSyncButton';
import { FadeInAnimation } from 'materialTheme/src/theme/components/animations/FadeInAnimation';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Header } from 'materialTheme/src/theme/components/header/Header';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { ScrollContainer } from 'materialTheme/src/theme/components/scroll/ScrollContainer';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import { I18n } from '../../i18n/I18n';
import { openSearch } from './globalSearch/GlobalSearch';
import { NotificationBell } from './NotificationBell';
import { openNotificationBellDialog } from './NotificationBellDialog';
import { TimeTrackingChip } from './TimeTrackingChip';
export class PageView extends PureComponent {
    constructor(props) {
        super(props);
        this.scrollPosition = 0;
        this.openSearch = () => {
            openSearch();
        };
        this.addMenuElements = async () => {
            return [
                {
                    thumbnail: {
                        thumbnail: <NotificationBell iconColor={ThemeManager.style.black38}/>,
                        width: 24,
                        rounded: true,
                    },
                    text: I18n.m.getMessage('accountNotifications'),
                    onPress: (e) => {
                        Menu.instance?.close();
                        openNotificationBellDialog()(e);
                    },
                },
                {
                    thumbnail: {
                        thumbnail: <Icon icon="magnify" toolTip="" color={ThemeManager.style.black38}/>,
                        width: 24,
                        rounded: true,
                    },
                    text: I18n.m.getMessage(Platform.OS === 'web' ? 'globalSearch' : 'globalSearchMobile'),
                    onPress: () => {
                        Menu.instance?.close();
                        this.openSearch();
                    },
                },
            ];
        };
        this.handleScroll = (e) => {
            this.scrollPosition = e.nativeEvent.contentOffset.y;
        };
        this.onLayout = (e) => {
            if (e != null && e.nativeEvent != null && e.nativeEvent.layout != null) {
            }
            if (this.onResizeTimeOut) {
                clearTimeout(this.onResizeTimeOut);
            }
            this.onResizeTimeOut = window.setTimeout(this.onResizeNow, 50);
        };
        this.onResize = (_e) => {
            this.onLayout();
            if (this.props.onLayout != null) {
                this.props.onLayout(_e);
            }
        };
        this.onResizeNow = () => {
            const showMenu = this.props.showMenu && ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM;
            this.setState({ showMenu }, () => {
                this.forceUpdate();
            });
        };
        this._animations = new FadeInAnimation();
        this.state = {
            showMenu: props.showMenu && ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM,
        };
    }
    componentDidMount() {
        ResizeEvent.onResize.attach(this.onResize);
        this._animations.toValue(1, () => { });
    }
    componentWillUnmount() {
        ResizeEvent.onResize.detach(this.onResize);
    }
    render() {
        const { scrollable, headerProps, style, showAccountIcon } = this.props;
        const textInputRefs = [];
        if (this.props.textInputRefs != null && this.props.textInputRefs.length > 0) {
            for (let i = 0; i < this.props.textInputRefs.length; i += 1) {
                textInputRefs.push(this.props.textInputRefs[i]);
            }
        }
        let headerBG = headerProps != null && headerProps.backgroundColor != null && headerProps.backgroundColor.length > 0
            ? headerProps.backgroundColor
            : ThemeManager.style.headerBG;
        const leftButtons = [];
        if (headerProps != null && headerProps.leftButtons != null && headerProps.leftButtons.length > 0) {
            leftButtons.push(...headerProps.leftButtons);
        }
        const rightButtons = [];
        if (headerProps != null && headerProps.rightButtons != null && headerProps.rightButtons.length > 0) {
            rightButtons.push(...headerProps.rightButtons);
        }
        let showTimeTrackingChip = false;
        if (this.props.secondaryHeader !== true) {
            if (this.state.showMenu) {
                if (leftButtons == null || leftButtons.length === 0) {
                    leftButtons.push(<ContainedButton key="homeButton" icon={{ icon: 'home-outline' }} onPress={Routing.instance.goToButton(`/${StoredStartPages.getStoredStartPage()}`)} backgroundColor="transparent" textColor={headerProps != null && headerProps.textColor != null
                            ? headerProps.textColor
                            : ThemeManager.style.headerButtonColor}/>);
                    showTimeTrackingChip = true;
                }
            }
            if ((this.state.showMenu && showAccountIcon === 'auto') || showAccountIcon === true) {
                rightButtons.push(<UserImageSyncButton key="userImage" size={24} additionalMenuElementsAfterMyAccount={this.addMenuElements} additionalMenuElements={TimeTrackingChip.getTimeTrackingMenuElement}/>);
                showTimeTrackingChip = true;
            }
        }
        else {
            if (showAccountIcon === true || showAccountIcon === 'auto') {
                rightButtons.push(<UserImageSyncButton key="userImage" size={24} additionalMenuElements={TimeTrackingChip.getTimeTrackingMenuElement}/>);
                showTimeTrackingChip = true;
            }
            if (headerProps == null || headerProps.backgroundColor == null) {
                headerBG = ThemeManager.style.headerBGDarken;
            }
        }
        if (showTimeTrackingChip) {
            rightButtons.reverse();
            rightButtons.push(<TimeTrackingChip />);
            rightButtons.reverse();
        }
        const keyboardShouldPersistTaps = Platform.OS === 'android' ? { keyboardShouldPersistTaps: 'always' } : {};
        return (<View style={[
                {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    flexDirection: 'column',
                },
                {
                    paddingTop: 0,
                },
                style,
            ]}>
        {headerProps != null ? (<Header withElevation={false} withBorder {...headerProps} leftButtons={leftButtons} rightButtons={rightButtons} backgroundColor={headerBG}/>) : null}

        {scrollable === true ? (<ScrollContainer onLayout={this.onLayout} style={{
                    width: '100%',
                    height: ResizeEvent.current.contentHeight,
                }} onScroll={this.handleScroll} ref={(r) => {
                    this.scrollContainer = r;
                }} scrollEnabled={scrollable} {...keyboardShouldPersistTaps} contentContainerStyle={{
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    width: '100%',
                    height: 'auto',
                }}>
            {this.props.children}
          </ScrollContainer>) : (<View onLayout={this.onLayout}>{this.props.children}</View>)}
      </View>);
    }
    scrollTo(props) {
        this.scrollContainer.scrollTo(props);
    }
    scrollToPageY(y, animated = true) {
        let newY = this.scrollPosition + y - ThemeManager.style.contentPaddingValue;
        if (ThemeManager.style.headerHeight > 0) {
            newY -= ThemeManager.style.headerHeight;
        }
        if (this.scrollContainer != null && newY != null && newY > 0) {
            this.scrollContainer.scrollTo({ animated, y: newY, x: 0 });
        }
    }
}
PageView.defaultProps = {
    showMenu: true,
    showAccountIcon: 'auto',
    headerTextColor: ThemeManager.style.black87,
};
