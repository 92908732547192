var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ForgotPwCodeForm = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _Url = require("cqrs-shared/build/src/uri/Url");
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _ChangePasswordWithToken = require("upmesh-auth-core/build/src/client/commands/guest/ChangePasswordWithToken");
var _Login = require("upmesh-auth-core/build/src/client/commands/guest/Login");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _Alert = require("../../../theme/components/Alert");
var _ContainedButton = require("../../../theme/components/button/ContainedButton");
var _Form = require("../../../theme/components/forminput/Form");
var _MaterialText = require("../../../theme/components/text/MaterialText");
var _I18n = require("../../../theme/i18n/I18n");
var _LoadingEvents = require("../../../theme/routing/LoadingEvents");
var _RouterControl = require("../../../theme/routing/RouterControl");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var containerStyle = {
  width: '100%',
  maxWidth: 512,
  alignSelf: 'center'
};
var ForgotPwCodeForm = exports.ForgotPwCodeForm = function (_PureComponent) {
  function ForgotPwCodeForm(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ForgotPwCodeForm);
    _this = _callSuper(this, ForgotPwCodeForm, [props]);
    _this.logout = function () {
      var currentLink = _RouterControl.RouterControl.instance.currentUrl;
      _AuthClient.AuthClient.instance.logOut(true, currentLink).catch(function (err) {
        return void 0;
      });
    };
    _this.reCheck = function () {
      var isLogged = _CurrentUser.CurrentUser.userId != null && _CurrentUser.CurrentUser.userId.length > 0;
      _this.setState({
        isLogged: isLogged
      });
    };
    _this.abort = function () {
      var _Alert$instance;
      (_Alert$instance = _Alert.Alert.instance) == null || _Alert$instance.close(function () {
        _Routing.Routing.instance.goTo('/login');
      });
    };
    _this.catchError = function (e, _data) {
      if (e['messageCode'] != null && e['messageCode'] === 'passwordErrorTokenInvalid') {
        _Routing.Routing.instance.alert.post({
          text: _I18n.I18n.m.getMessage('passwordErrorTokenInvalid'),
          buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('ok'),
            onPress: _this.abort,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "okButton"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('forgotPwRequestCode'),
            onPress: _this.requestCode,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "registerButton")]
        });
        return true;
      }
      return false;
    };
    _this.onSaved = function (_result) {
      _LoadingEvents.LoadingEvents.instance.startLoading();
      var asyncNow = function () {
        var _ref = (0, _asyncToGenerator2.default)(function* () {
          try {
            if (_result != null && _result.token != null && _result.token.length > 0) {
              var tokenData = yield _CurrentUser.CurrentUser.getUnVerifiedDataFromToken(_result.token);
              var login = new _Login.Login({
                email: tokenData.email,
                password: _result.newPassword,
                goTo: _Url.Url.getURLfromString('/account')
              });
              yield login.execute(_AuthClient.AuthClient.instance.commandStore);
              _AuthClient.AuthClient.instance.logIn(login.result).catch(function (err) {
                return void 0;
              });
            } else {
              _Routing.Routing.instance.goTo('/login', true);
            }
          } catch (e) {
            _Routing.Routing.instance.goTo('/login', true);
            _LoadingEvents.LoadingEvents.instance.stopLoading();
          }
        });
        return function asyncNow() {
          return _ref.apply(this, arguments);
        };
      }();
      asyncNow().catch(function (err) {
        return void 0;
      });
    };
    _this.requestCode = function (e) {
      var _Alert$instance2;
      (_Alert$instance2 = _Alert.Alert.instance) == null || _Alert$instance2.close(function () {
        requestAnimationFrame(function () {
          _Routing.Routing.instance.openDialog('/forgotpw')(e);
        });
      });
    };
    var code = _this.props.t != null ? _this.props.t : '';
    var isLogged = _CurrentUser.CurrentUser.userId != null && _CurrentUser.CurrentUser.userId.length > 0;
    _this.state = {
      isLogged: isLogged,
      code: code
    };
    return _this;
  }
  (0, _inherits2.default)(ForgotPwCodeForm, _PureComponent);
  return (0, _createClass2.default)(ForgotPwCodeForm, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _AuthClient.AuthClient.onLogout(this.reCheck);
      _AuthClient.AuthClient.onLogin(this.reCheck);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (_reactNativeWeb.Keyboard != null) {
        _reactNativeWeb.Keyboard.dismiss();
      }
      _AuthClient.AuthClient.onLogoutDetach(this.reCheck);
      _AuthClient.AuthClient.onLoginDetach(this.reCheck);
    }
  }, {
    key: "render",
    value: function render() {
      var isLogged = this.state.isLogged;
      if (_RouterControl.RouterControl.instance.currentUrl != null) {
        var langCode = _RouterControl.RouterControl.instance.currentUrl.search.match(/langCode=(\w*)/);
        if (langCode != null && langCode.groups != null && langCode.groups[0] != null && _I18n.I18n.m.currentLanguage !== langCode.groups[0]) {
          _I18n.I18n.m.changeLanguage(langCode.groups[0]).catch(function (err) {
            return void 0;
          });
        }
      }
      if (isLogged) {
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: [_ThemeManager.ThemeManager.style.contentStyle, containerStyle],
          children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.H6,
            children: _I18n.I18n.m.getMessage('forgotPwCodeCodeTitle')
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              height: _ThemeManager.ThemeManager.style.contentPaddingValue
            }
          }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            children: _I18n.I18n.m.getMessage('forgotPwCodeLogOutFirst')
          }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              paddingTop: _ThemeManager.ThemeManager.style.contentPaddingValue,
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end'
            },
            children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              title: _I18n.I18n.m.getMessage('goToHome'),
              onPress: _Routing.Routing.instance.goToButton('/'),
              backgroundColor: "transparent",
              textColor: _ThemeManager.ThemeManager.style.brandPrimary
            }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                width: 12
              }
            }), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              title: _I18n.I18n.m.getMessage('forgotPwCodeLogOutFirstButton'),
              onPress: this.logout
            })]
          })]
        });
      }
      var dataOptions = new Map();
      dataOptions.set('newPassword', {
        formType: 'password',
        cols: 1,
        props: {
          labelText: _I18n.I18n.m.getMessage('password'),
          autoFocus: true
        }
      });
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          alignSelf: 'stretch',
          alignContent: 'center'
        },
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: _I18n.I18n.m.getMessage('forgotPwCodeCodeTitle')
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            alignSelf: 'stretch'
          },
          children: (0, _jsxRuntime.jsx)(_Form.Form, {
            formHeaderProps: {
              formText: _I18n.I18n.m.getMessage('passwordHint')
            },
            baseCols: 1,
            onSaved: this.onSaved,
            catchError: this.catchError,
            dataOptions: dataOptions,
            store: _AuthClient.AuthClient.instance.commandStore,
            command: new _ChangePasswordWithToken.ChangePasswordWithToken({
              newPassword: '',
              token: this.state.code
            }, '', ''),
            saveButtonLabel: _I18n.I18n.m.getMessage('forgotPwCodeSubmit')
          })
        })]
      });
    }
  }]);
}(_react.PureComponent);