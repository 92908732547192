import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ScrollView, View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../config/CachedEntities';
import { I18n } from '../../i18n/I18n';
import { openAbsenceCreationChangeDialog } from '../absences/AbsenceCreationChangeDialog';
export class OpenAbsenceRequestWidget extends PureComponent {
    constructor(props) {
        super(props);
        this.updateData = async () => {
            const abs = await UpmeshClient.instance.modals.absence.get({
                filter: `deleted ne true and state eq 'open'`,
            });
            const absences = [];
            abs.forEach((a) => {
                absences.push({
                    ...a,
                    userEntity: a.userId ? CachedEntities.knownUsers.get(a.userId) : undefined,
                    taskEntity: CachedEntities.knownTasks.get(a.taskId),
                    memberEntity: CachedEntities.knownCompanyMember.get(a.memberId),
                });
            });
            absences.sort((a, b) => {
                return new Date(a.starts).getTime() - new Date(b.starts).getTime();
            });
            this.setState({ absences });
        };
        this.state = { absences: [] };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'AbsenceRequestWidget',
            readModelName: 'Absence',
            callback: () => {
                this.updateData().catch((err) => console.error(err));
            },
        });
        this.updateData().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('Absence', 'AbsenceRequestWidget');
    }
    getName(absence) {
        if (absence.userEntity != null) {
            return absence.userEntity.getFullName();
        }
        if (absence.memberEntity != null) {
            return `${absence.memberEntity.firstName} ${absence.memberEntity.lastName}`;
        }
        return I18n.m.getMessage('userNotFound');
    }
    renderAbsence(absence) {
        return (<Ripple key={`absenceRequest${absence.id}`} onPress={openAbsenceCreationChangeDialog({ absence })} style={{
                flexDirection: 'row',
                padding: 16,
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderBottomColor: ThemeManager.style.borderColor,
                alignItems: 'center',
                height: 80,
            }}>
        <UserImage user={absence.userEntity} size={48}/>
        <View style={{ flex: 1, paddingLeft: 8 }}>
          <View>
            <MaterialText type={MaterialTextTypes.Caption}>{`${I18n.m.dateCurrent.localeDateString(new Date(absence.starts))} - ${I18n.m.dateCurrent.localeDateString(new Date(absence.ends))}`}</MaterialText>
          </View>
          <MaterialText>{this.getName(absence)}</MaterialText>
          <MaterialText type={MaterialTextTypes.Body2} color={ThemeManager.style.black54} numberOfLines={1}>{`${absence.taskEntity?.mark} - ${absence.taskEntity?.description}`}</MaterialText>
        </View>
      </Ripple>);
    }
    render() {
        const { absences } = this.state;
        return (<View style={{ width: '100%', height: '100%' }}>
        <View style={{ padding: 16, paddingBottom: 0, width: '100%', height: 48 }}>
          <MaterialText type={MaterialTextTypes.H5}>{I18n.m.getMessage('absenceOpenRequests')}</MaterialText>
        </View>
        <ScrollView style={{ flex: 1 }} nestedScrollEnabled>
          {absences.map((t) => this.renderAbsence(t))}
        </ScrollView>
      </View>);
    }
}
