"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = speedometer;
var tick = 1;
var maxTick = 65535;
var resolution = 4;
var inc = function inc() {
  tick = tick + 1 & maxTick;
};
var timer = setInterval(inc, 1000 / resolution | 0);
if (timer.unref) timer.unref();
function speedometer(seconds) {
  var size = resolution * (seconds || 5);
  var buffer = [0];
  var pointer = 1;
  var last = tick - 1 & maxTick;
  return function (delta) {
    var dist = tick - last & maxTick;
    if (dist > size) dist = size;
    last = tick;
    while (dist--) {
      if (pointer === size) pointer = 0;
      buffer[pointer] = buffer[pointer === 0 ? size - 1 : pointer - 1];
      pointer += 1;
    }
    if (delta) buffer[pointer - 1] += delta;
    var top = buffer[pointer - 1];
    var btm = buffer.length < size ? 0 : buffer[pointer === size ? 0 : pointer];
    return buffer.length < resolution ? top : (top - btm) * resolution / buffer.length;
  };
}