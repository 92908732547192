import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { CheckboxListItem } from 'materialTheme/src/theme/components/CheckboxListItem';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AddTicketTag } from 'upmesh-core/src/client/commands/tickets/AddTicketTag';
import { AddTicketWatcher } from 'upmesh-core/src/client/commands/tickets/AddTicketWatcher';
import { AssignTicketTo } from 'upmesh-core/src/client/commands/tickets/AssignTicketTo';
import { ChangeTicketApprover } from 'upmesh-core/src/client/commands/tickets/ChangeTicketApprover';
import { ChangeTicketCraft } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCraft';
import { ChangeTicketDescription } from 'upmesh-core/src/client/commands/tickets/ChangeTicketDescription';
import { ChangeTicketStatus } from 'upmesh-core/src/client/commands/tickets/ChangeTicketStatus';
import { ChangeTicketType } from 'upmesh-core/src/client/commands/tickets/ChangeTicketType';
import { CreateTicket } from 'upmesh-core/src/client/commands/tickets/CreateTicket';
import { SetTicketShouldCompleteDate } from 'upmesh-core/src/client/commands/tickets/SetTicketShouldCompleteDate';
import { TicketEntity } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
export class DuplicateTicketDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.storedDuplicateSettings = '';
        this.duplicateTicket = (e) => {
            const { duplicateOptions } = this.state;
            const t = this.props.ticket;
            const ticket = t != null ? t : undefined;
            this.setState({ isDuplicating: true });
            if (ticket != null) {
                const newTicket = new TicketEntity({
                    title: duplicateOptions[0].checked ? ticket.title : I18n.m.getMessage('ticketsDetailsTitlePlaceholder'),
                    projectId: ticket.projectId,
                    tags: duplicateOptions[9].checked ? ticket.tags : [],
                    address: duplicateOptions[12].checked && ticket.address != null ? ticket.address : undefined,
                });
                const asyncNow = async () => {
                    try {
                        const c = new CreateTicket({
                            title: newTicket.title,
                            projectId: newTicket.projectId,
                            planId: duplicateOptions[10].checked && ticket.planId != null ? ticket.planId : undefined,
                            planPositionX: duplicateOptions[10].checked && ticket.planPositionX != null ? ticket.planPositionX : undefined,
                            planPositionY: duplicateOptions[10].checked && ticket.planPositionY != null ? ticket.planPositionY : undefined,
                            planPositionZ: duplicateOptions[10].checked && ticket.planPositionZ != null ? ticket.planPositionZ : undefined,
                            address: newTicket.address,
                            builder: duplicateOptions[10].checked && ticket.builder != null ? ticket.builder : undefined,
                        }, newTicket.id);
                        try {
                            await c.canI();
                        }
                        catch (e) {
                            LoadingEvents.instance.stopLoading();
                            Routing.instance.closeDialog(true)(null);
                            Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
                            return;
                        }
                        await c.execute();
                        const count = async () => UpmeshClient.instance.modals.ticket.count(`id eq '${c.entityId}'`);
                        let timeout = 0;
                        while ((await count()) === 0 && timeout < 20) {
                            await new Promise((r) => {
                                timeout += 1;
                                setTimeout(r, 500);
                            });
                        }
                        const allPromises = [];
                        if (duplicateOptions[1].checked && ticket.description != null) {
                            const addTicketDescriptionCommand = new ChangeTicketDescription({ description: ticket.description }, newTicket.id);
                            allPromises.push(addTicketDescriptionCommand.execute());
                        }
                        if (duplicateOptions[4].checked) {
                            const ticketStatusCommand = new ChangeTicketStatus({ ticketStatus: ticket.ticketStatus }, newTicket.id);
                            allPromises.push(ticketStatusCommand.execute());
                        }
                        if (duplicateOptions[3].checked && ticket.craft != null) {
                            const ticketCraftCommand = new ChangeTicketCraft({ craft: ticket.craft }, newTicket.id);
                            allPromises.push(ticketCraftCommand.execute());
                        }
                        if (duplicateOptions[9].checked && ticket.tags != null && ticket.tags.length > 0) {
                            ticket.tags.forEach((ticketTag) => {
                                const addTicketTagCommand = new AddTicketTag({ tag: ticketTag }, newTicket.id);
                                allPromises.push(addTicketTagCommand.execute());
                            });
                        }
                        if (duplicateOptions[2].checked && ticket.type != null) {
                            const changeTicketTypeCommand = new ChangeTicketType({ ticketType: ticket.type }, newTicket.id);
                            allPromises.push(changeTicketTypeCommand.execute());
                        }
                        if (duplicateOptions[5].checked && ticket.completionOn != null) {
                            const setTicketShouldCompleteDateCommand = new SetTicketShouldCompleteDate({ completionOn: ticket.completionOn }, newTicket.id);
                            allPromises.push(setTicketShouldCompleteDateCommand.execute());
                        }
                        if (duplicateOptions[6].checked && ticket.assignedToUserId != null) {
                            const assignToUserId = new AssignTicketTo({ assignedToUserId: ticket.assignedToUserId }, newTicket.id);
                            allPromises.push(assignToUserId.execute());
                        }
                        if (duplicateOptions[7].checked && ticket.approverUserId != null) {
                            const changeTicketApproverCommand = new ChangeTicketApprover({ approverUserId: ticket.approverUserId }, newTicket.id);
                            allPromises.push(changeTicketApproverCommand.execute());
                        }
                        if (duplicateOptions[8].checked && ticket.watchers != null && ticket.watchers.length > 0) {
                            ticket.watchers.forEach((watcher) => {
                                const addWatcherCommand = new AddTicketWatcher(watcher, newTicket.id);
                                allPromises.push(addWatcherCommand.execute());
                            });
                        }
                        await Promise.all(allPromises);
                        DialogUp.instance?.close(() => Routing.instance.openDialog('ticket', { id: newTicket.id }, true)(e));
                    }
                    catch (e) {
                        console.error('cant createTicket', e);
                        LoadingEvents.instance.stopLoading();
                        this.props.errorResult(e);
                    }
                };
                asyncNow().catch((err) => console.error(err));
            }
        };
        this.init = async () => {
            const storedSettings = SimpleStorage.get('ticketDuplicateSettings');
            const { duplicateOptions } = this.state;
            if (storedSettings != null) {
                this.storedDuplicateSettings = storedSettings;
                for (let i = 0; i < duplicateOptions.length; i += 1) {
                    if (storedSettings.indexOf(duplicateOptions[i].key) > -1) {
                        duplicateOptions[i].checked = true;
                    }
                }
            }
            this.setState({ duplicateOptions, loading: false });
        };
        this.onChangeCheckedDuplicateOption = (value, index) => {
            const { duplicateOptions } = this.state;
            const options = duplicateOptions;
            options[index].checked = value;
            let storedSettings = '';
            storedSettings = this.storedDuplicateSettings;
            if (value === false) {
                storedSettings = storedSettings.replace(duplicateOptions[index].key, '');
            }
            else {
                storedSettings += duplicateOptions[index].key;
            }
            this.storedDuplicateSettings = storedSettings;
            SimpleStorage.set('ticketDuplicateSettings', storedSettings);
            this.setState({ duplicateOptions: options });
        };
        this.state = {
            isDuplicating: false,
            loading: true,
            duplicateOptions: [
                {
                    key: 'title',
                    title: I18n.m.getMessage('ticketsDetailsTitle'),
                    checked: false,
                },
                {
                    key: 'description',
                    title: I18n.m.getMessage('ticketsDetailsDescription'),
                    checked: false,
                },
                {
                    key: 'type',
                    title: I18n.m.getMessage('ticketsDetailsType'),
                    checked: false,
                },
                {
                    key: 'craft',
                    title: I18n.m.getMessage('ticketsDetailsCraft'),
                    checked: false,
                },
                {
                    key: 'ticketStatus',
                    title: I18n.m.getMessage('ticketsDetailsState'),
                    checked: false,
                },
                {
                    key: 'completionOn',
                    title: I18n.m.getMessage('dashboardFocusTicketsItemDueDate'),
                    checked: false,
                },
                {
                    key: 'assignee',
                    title: I18n.m.getMessage('reportTicketDetailsAssignee'),
                    checked: false,
                },
                {
                    key: 'approverUserId',
                    title: I18n.m.getMessage('reportTicketDetailsApprover'),
                    checked: false,
                },
                {
                    key: 'watchers',
                    title: I18n.m.getMessage('ticketsDetailsObserver'),
                    checked: false,
                },
                {
                    key: 'tags',
                    title: I18n.m.getMessage('ticketsDetailsTags'),
                    checked: false,
                },
                {
                    key: 'planId',
                    title: I18n.m.getMessage('plan'),
                    checked: false,
                },
                {
                    key: 'builder',
                    title: I18n.m.getMessage('builder'),
                    checked: false,
                },
                {
                    key: 'address',
                    title: I18n.m.getMessage('address'),
                    checked: false,
                },
            ],
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    render() {
        const { ticket } = this.props;
        const { duplicateOptions, loading, isDuplicating } = this.state;
        const listItems = [];
        if (!loading) {
            duplicateOptions.forEach((option, index) => {
                if (ticket[option.key] != null) {
                    listItems.push(<CheckboxListItem title={option.title} key={option.key} onChange={(value) => {
                            this.onChangeCheckedDuplicateOption(value, index);
                        }} initValue={option.checked}/>);
                }
            });
        }
        return [
            <DialogTitle key="duplicateTitle">{I18n.m.getMessage('ticketDetailsDuplicateTicket')}</DialogTitle>,
            <DialogContent key="duplicateScroll">
        <View style={{ width: '100%' }}>{listItems}</View>
      </DialogContent>,
            <DialogActions onBottom key="duplicateActions">
        {[
                    <ContainedButton title={I18n.m.getMessage('cancel')} key="cancel" onPress={() => DialogUp.instance?.close()} textColor={ThemeManager.style.brandPrimary} backgroundColor="#ffffff"/>,
                    <ContainedButton title={I18n.m.getMessage('ticketDetailsDuplicateTicket')} onPress={this.duplicateTicket} key="ok" loading={isDuplicating} disabled={isDuplicating}/>,
                ]}
      </DialogActions>,
        ];
    }
}
