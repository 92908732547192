import * as _ from 'lodash';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Page } from 'materialTheme/src/theme/components/Page';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MapMarker } from 'materialTheme/src/theme/components/zoom/MapMarker';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { Platform, UIManager, View } from 'react-native';
import { TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { AddFilesPU } from '../file/AddFilesPU';
import { SharedFiles } from '../file/SharedFiles';
import { PlanZoomTileMapper } from '../plans/PlanZoomTileMapper';
import { CurrentProject } from '../project/CurrentProject';
import { PageView } from '../root/PageView';
import { openTicketExcelImportDialog } from './import/TicketExcelImportDialog';
import { ReportIcon } from './ReportIcon';
import { TicketCallOut } from './TicketCallOut';
import { TicketCardContent } from './TicketCardContent';
import { TicketsOverView } from './TicketsOverView';
import { TicketWithPlanDownloader } from './TicketWithPlanDownloader';
export class TicketsDetailsView extends PureComponent {
    constructor(props) {
        super(props);
        this.onChangeMultiSelectStatus = (status) => {
            Menu.instance?.close();
            this.setState({ activeMultiselect: status });
        };
        this.openSortDialog = () => {
            Menu.instance?.close();
            const { selectedView } = this.state;
            const list = [
                { title: I18n.m.getMessage('ticketsDetailsTitle'), sortBy: 'title' },
                { title: I18n.m.getMessage('ticketsDetailsNumber'), sortBy: 'number' },
                { title: I18n.m.getMessage('ticketsDetailsType'), sortBy: 'type' },
                { title: I18n.m.getMessage('ticketsDetailsCraft'), sortBy: 'craft' },
                { title: I18n.m.getMessage('ticketsDetailsState'), sortBy: 'state' },
                { title: I18n.m.getMessage('dashboardItemsAssignee'), sortBy: 'assignee' },
                { title: I18n.m.getMessage('ticketsDetailsTitleApprover'), sortBy: 'approver' },
                { title: I18n.m.getMessage('ticketDetailsDueDate'), sortBy: 'due' },
                { title: I18n.m.getMessage('ticketsDetailsLastEdit'), sortBy: 'edit' },
            ];
            const listItems = [];
            list.forEach((li) => {
                const disabled = selectedView === 'canban' && li.sortBy === 'state';
                listItems.push(<ListItem title={li.title} onPress={!disabled
                        ? () => {
                            Dialog.instance?.close(() => {
                                Routing.instance.changeQueryParameter({ o: li.sortBy }, true);
                            });
                        }
                        : undefined} textColor={disabled ? ThemeManager.style.disabledColor : ThemeManager.style.black87} key={`listItem${li.sortBy}`} thumbnail={{
                        width: 24,
                        rounded: true,
                        thumbnail: this.props.sortBy === li.sortBy ? (<Icon toolTip="" icon="radiobox-marked" color={ThemeManager.style.brandPrimary} disabled={disabled}/>) : (<Icon toolTip="" icon="radiobox-blank" disabled={disabled}/>),
                    }}/>);
            });
            Dialog.instance?.open({
                title: I18n.m.getMessage('ticketsSortBy'),
                content: <View style={{ padding: 0 }}>{listItems}</View>,
                scrollable: true,
                contentPadding: false,
                fullscreenButtonsBottom: true,
                fullscreenResponsive: true,
                fixedTitle: true,
                buttons: [
                    <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} key="cancel"/>,
                ],
            });
        };
        this.onRegionChange = _.debounce((c) => {
            const { planVersion } = this.state;
            try {
                const p = planVersion != null
                    ? planVersion.getRealpointInCMFromPagepoint({
                        x: c.center.point.x,
                        y: c.center.point.y,
                    })
                    : c.center.point;
                const zTo = `${p.x.toString(10)}x${p.y.toString(10)}x${c.zoom.toString()}`;
                this.lastZoom = zTo;
                Routing.instance.changeQueryParameter({ zTo }, true);
            }
            catch (err) {
                console.debug('cant update hash', err);
            }
        }, 300);
        this.mounted = false;
        this.onLayout = (_e) => {
            const { splitViewWidth, size, childHeight } = this.props;
            const planSize = TicketsDetailsView.getMaxPlanSize(splitViewWidth, size, childHeight);
            this.setState({
                maxPlanWidth: planSize.width,
                maxPlanHeight: planSize.height,
            });
        };
        this.showPlan = () => {
            const { maxPlanHeight } = this.state;
            this.setState({ planHeight: maxPlanHeight });
        };
        this.showTickets = () => {
            this.setState({ planHeight: 0 });
        };
        this.changeSelectedView = (newSelected) => () => {
            Menu.instance?.close();
            SimpleStorage.set('ticketsSelectedView', newSelected);
            this.setState({ selectedView: newSelected });
        };
        this.changeView = (_index, button) => {
            SimpleStorage.set('ticketsSelectedView', button.key);
            this.setState({ selectedView: button.key });
        };
        this.pressedDown = () => {
            const { maxPlanHeight } = this.state;
            if (this.state.planHeight === 0) {
                this.setState({ planHeight: maxPlanHeight / 2 });
            }
            else {
                this.setState({ planHeight: maxPlanHeight });
            }
        };
        this.pressedUp = () => {
            const { maxPlanHeight } = this.state;
            if (this.state.planHeight === maxPlanHeight) {
                this.setState({ planHeight: maxPlanHeight / 2 });
            }
            else {
                this.setState({ planHeight: 0 });
            }
        };
        this.goBack = () => {
            const { currentUrl } = RouterControl.instance;
            const path = currentUrl.pathname.split('/');
            path.splice(path.length - 2, 1);
            const pathNew = `${path.join('/')}?${currentUrl.search}`;
            Routing.instance.goTo(pathNew, true);
        };
        this.openTicket = (e) => {
            Routing.instance.openDialog('ticket', { id: e.id })(e);
        };
        this.setMapRef = (r) => {
            this.zoomTile = r;
        };
        this.createTicket = (point) => {
            const { plan, planVersion } = this.props;
            if (plan != null && (plan.activePlanId === 'MAP' || planVersion != null)) {
                const position = plan.activePlanId === 'MAP' ? point : planVersion.getRealpointInCMFromPagepoint(point);
                this.props.pressCreateTicket(plan.id, position.x, position.y)(point);
            }
        };
        this.downloadVersionNow = () => {
            Menu.instance?.close();
            Alert.instance?.close();
            const { planVersion, plan } = this.state;
            if (plan != null && planVersion != null) {
                const ex = planVersion.orgFilename.substr(planVersion.orgFilename.lastIndexOf('.'));
                CurrentProject.instance.downloadPlanNow(plan, planVersion, ex).catch((error) => {
                    DefaultErrorHandler.showDefaultErrorAlert(error, I18n.m, error);
                });
            }
            else {
                console.debug('plan version is null');
            }
        };
        this.downloadVersionNowWithTickets = () => {
            Menu.instance?.close();
            const { planVersion, tickets, plan } = this.state;
            if (planVersion != null && plan != null) {
                const pdfStarter = new TicketWithPlanDownloader(tickets, planVersion, plan);
                pdfStarter.open().catch((e) => {
                    console.error('error on downloading/creating pdf with tickets as download', e);
                });
            }
        };
        this.getViewIcon = () => {
            const { selectedView } = this.state;
            let icon = {
                icon: 'format-list-bulleted-square',
                rotation: 0,
                iconIconMoon: false,
                toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToListView'),
            };
            if (selectedView === 'table') {
                icon = {
                    icon: 'table-large',
                    rotation: 0,
                    iconIconMoon: false,
                    toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToTableView'),
                };
            }
            else if (selectedView === 'calendar') {
                icon = {
                    icon: 'calendar',
                    rotation: 0,
                    toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToCalendarView'),
                };
            }
            else if (selectedView === 'canban') {
                icon = {
                    icon: 'poll',
                    rotation: 180,
                    toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToCanban'),
                };
            }
            icon.onPress = this.openViewMenu;
            icon.toolTip = I18n.m.getMessage('changeView');
            return icon;
        };
        this.openViewMenu = (e) => {
            const { target } = e.nativeEvent;
            UIManager.measureInWindow(target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 320,
                };
                const asyncNow = async () => {
                    Menu.instance?.open({
                        client,
                        items: [
                            {
                                text: I18n.m.getMessage('ticketsDetailsViewChangeToCanban'),
                                onPress: this.changeSelectedView('canban'),
                                thumbnail: {
                                    thumbnail: <Icon toolTip="" icon="poll" rotation={180}/>,
                                    width: 40,
                                },
                            },
                            {
                                text: I18n.m.getMessage('ticketsDetailsViewChangeToListView'),
                                onPress: this.changeSelectedView('list'),
                                thumbnail: {
                                    thumbnail: <Icon toolTip="" icon="format-list-bulleted-square"/>,
                                    width: 40,
                                },
                            },
                            {
                                text: I18n.m.getMessage('ticketsDetailsViewChangeToTableView'),
                                onPress: this.changeSelectedView('table'),
                                thumbnail: {
                                    thumbnail: <Icon toolTip="" icon="table-large"/>,
                                    width: 40,
                                },
                            },
                            {
                                text: I18n.m.getMessage('ticketsDetailsViewChangeToCalendarView'),
                                onPress: this.changeSelectedView('calendar'),
                                thumbnail: {
                                    thumbnail: <Icon toolTip="" icon="calendar"/>,
                                    width: 40,
                                },
                            },
                        ],
                    });
                };
                asyncNow().catch((err) => console.error(err));
            });
        };
        this.openDownloadMenu = (e) => {
            Menu.instance?.close();
            const { target } = e.nativeEvent;
            UIManager.measureInWindow(target, (x, y, _width, height) => {
                const { planVersion } = this.state;
                const client = {
                    x,
                    y,
                    height,
                    width: 320,
                };
                const items = [];
                if (planVersion != null) {
                    items.push({
                        text: I18n.m.getMessage('downloadPlan'),
                        onPress: this.downloadVersionNow,
                    });
                    items.push({
                        text: I18n.m.getMessage('downloadPlanWithTickets'),
                        onPress: this.downloadVersionNowWithTickets,
                    });
                }
                Menu.instance?.open({
                    client,
                    items,
                });
            });
        };
        this.openThreeDots = (e) => {
            Menu.instance?.close();
            const { target } = e.nativeEvent;
            UIManager.measureInWindow(target, (x, y, _width, height) => {
                const { planVersion, selectedView } = this.state;
                const client = {
                    x,
                    y,
                    height,
                    width: 320,
                };
                const items = [];
                if (selectedView !== 'table') {
                    items.push({
                        text: I18n.m.getMessage('sort'),
                        onPress: this.openSortDialog,
                        thumbnail: {
                            thumbnail: (<Icon toolTip={I18n.m.getMessage('sort')} icon="sort" onPress={() => this.onChangeMultiSelectStatus(true)}/>),
                            width: 40,
                        },
                    });
                }
                const { filterActive, onPressFilter } = this.props;
                const { tickets } = this.state;
                const reportButton = new ReportIcon({ selectedTickets: tickets, filterActive, onPressFilter }, {});
                items.push({
                    text: I18n.m.getMessage('report'),
                    onPress: (e) => {
                        reportButton.openDialogButton(e);
                        Menu.instance?.close();
                    },
                    thumbnail: {
                        thumbnail: (<ReportIcon key="reportButton" selectedTickets={tickets} filterActive={filterActive} onPressFilter={onPressFilter}/>),
                        width: 40,
                    },
                }, {
                    text: I18n.m.getMessage('bulkChangesIconTooltip'),
                    onPress: () => this.onChangeMultiSelectStatus(true),
                    thumbnail: {
                        thumbnail: (<Icon toolTip={I18n.m.getMessage('bulkChangesIconTooltip')} icon="checkbox-multiple-marked-outline" onPress={() => this.onChangeMultiSelectStatus(true)}/>),
                        width: 40,
                    },
                }, {
                    text: I18n.m.getMessage('importExcelToolTip'),
                    onPress: openTicketExcelImportDialog({}),
                    thumbnail: {
                        thumbnail: (<Icon icon="microsoft-excel" toolTip={I18n.m.getMessage('importExcelToolTip')} onPress={openTicketExcelImportDialog({})}/>),
                        width: 40,
                    },
                });
                if (planVersion != null) {
                    items.push({
                        text: I18n.m.getMessage('downloadPlan'),
                        onPress: this.downloadVersionNow,
                        thumbnail: {
                            thumbnail: <Icon toolTip={I18n.m.getMessage('downloadPlan')} icon="download-outline"/>,
                            width: 40,
                        },
                    });
                    items.push({
                        text: I18n.m.getMessage('downloadPlanWithTickets'),
                        onPress: this.downloadVersionNowWithTickets,
                        thumbnail: {
                            thumbnail: <Icon toolTip={`${I18n.m.getMessage('downloadPlan')} + Tickets !!`} icon="download-outline"/>,
                            width: 40,
                        },
                    });
                }
                Menu.instance?.open({
                    client,
                    items,
                });
            });
        };
        this.onSnapShot = (file) => {
            const { plan } = this.state;
            if (plan != null) {
                try {
                    SharedFiles.files = [file];
                    SharedFiles.excludeFolders = null;
                    const data = { type: 'TicketOrFolder' };
                    if (CurrentProject.instance.getCurrentProject() != null) {
                        data.projectId = CurrentProject.instance.getCurrentProject()?.id;
                    }
                    const t = CurrentProject.instance.getCurrentTicketId();
                    if (t != null) {
                        data.type = 'Tickets';
                        data.entityId = t;
                    }
                    AddFilesPU.openDialog(data)(null);
                }
                catch (err) {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                }
            }
        };
        const size = TicketsDetailsView.getMaxPlanSize(props.splitViewWidth, props.size, props.childHeight);
        this.state = {
            init: false,
            planId: props.plan?.id,
            planHeight: size.height / 2,
            maxPlanHeight: size.height,
            maxPlanWidth: size.width,
            plan: props.plan,
            planVersion: props.planVersion,
            tickets: [],
            selectedView: 'canban',
            activeMultiselect: false,
        };
    }
    componentDidMount() {
        this.init(this.props).catch((err) => console.debug('cant init TicketDetailsView', err));
    }
    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        if (_prevState == null || this.lastZoom !== this.state.zoomTo) {
            this.zoomTo();
        }
    }
    zoomTo() {
        if (this.zoomTile != null) {
            const { zoomTo, planVersion, plan } = this.state;
            if (zoomTo != null && (planVersion != null || plan?.activePlanId === 'MAP')) {
                const p = zoomTo.split('x');
                const pointInPage = planVersion == null
                    ? { x: Number.parseFloat(p[0]), y: Number.parseFloat(p[1]) }
                    : planVersion.getPointInPageFromRealpointInCM({
                        x: Number.parseFloat(p[0]),
                        y: Number.parseFloat(p[1]),
                    });
                if (p.length >= 2) {
                    requestAnimationFrame(() => {
                        this.lastZoom = zoomTo;
                        if (this.zoomTile != null) {
                            let zoomLevel = planVersion == null ? 16 : 3;
                            if (p.length >= 3) {
                                zoomLevel = Number.parseFloat(p[2]);
                            }
                            const id = p.length > 3 ? p[3] : undefined;
                            this.zoomTile.zoomTo(pointInPage, zoomLevel, id);
                        }
                    });
                }
            }
        }
    }
    static getDerivedStateFromProps(nextProps, _prevState) {
        const planSize = TicketsDetailsView.getMaxPlanSize(nextProps.splitViewWidth, nextProps.size, nextProps.childHeight);
        return {
            planVersion: nextProps.planVersion,
            init: true,
            planId: nextProps.plan?.id,
            plan: nextProps.plan,
            tickets: nextProps.tickets,
            zoomTo: nextProps.zTo,
            maxPlanWidth: planSize.width,
            maxPlanHeight: planSize.height,
        };
    }
    static getMaxPlanSize(splitViewWidth, size, chlidHeight) {
        let sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        sViewHeight += chlidHeight;
        const height = size.contentHeight - TicketsDetailsView.separatorSize - ThemeManager.style.headerHeight - sViewHeight;
        let width = size.contentWidth;
        if (splitViewWidth)
            width -= splitViewWidth;
        return { width, height };
    }
    async init(props) {
        const ticketsSelectedView = SimpleStorage.get('ticketsSelectedView');
        this.setState({
            selectedView: ticketsSelectedView != null ? ticketsSelectedView : 'canban',
            planVersion: props.planVersion,
            init: true,
            plan: props.plan,
            tickets: props.tickets,
        });
    }
    render() {
        if (!this.state.init) {
            return (<Page>
          <Spinner />
        </Page>);
        }
        const { filterActive, onPressFilter, currentTicketFilter, onSearch, searchWords, backButton, onChangeTicketOrder, tagIcon, updateTicketFilter, showFab, childHeight, } = this.props;
        const { plan, planHeight, maxPlanHeight, planVersion, tickets, selectedView, activeMultiselect } = this.state;
        const selectedPlanVersionId = plan != null && plan.activePlanId != null ? plan.activePlanId : null;
        const { maxPlanWidth } = this.state;
        let thumb;
        if (plan != null &&
            (plan.activePlanId === 'MAP' || (planVersion != null && planVersion.id != null && planVersion.id.length > 0))) {
            const markers = [];
            if (planVersion != null || plan?.activePlanId === 'MAP') {
                for (let i = 0; i < tickets.length; i += 1) {
                    const t = tickets[i];
                    if (t.planPositionY != null && t.planPositionX != null) {
                        const pointInPage = planVersion == null
                            ? { x: t.planPositionX, y: t.planPositionY }
                            : planVersion.getPointInPageFromRealpointInCM({
                                x: t.planPositionX,
                                y: t.planPositionY,
                            });
                        markers.push(new MapMarker(t.id, TicketStatusColor.getMarkerForStatus(t.ticketStatus, t.completionOn), pointInPage, (<View style={{
                                position: 'relative',
                                width: 236,
                                height: 'auto',
                                zIndex: 8,
                                overflow: 'visible',
                                padding: 0,
                            }}>
                    {Platform.OS === 'web' ? (<TicketCardContent project={CurrentProject.instance.getCurrentProject()} ticket={t}/>) : (<TicketCallOut ticket={t}/>)}
                  </View>)));
                    }
                }
            }
            thumb =
                planHeight === 0 ? null : (<PlanZoomTileMapper onSnapShot={this.onSnapShot} plan={plan} planVersion={planVersion} type={planVersion == null ? 'map' : 'plan'} zoomAble ref={this.setMapRef} key={`tickets_plan_${planVersion == null ? plan.id : planVersion.id}`} onCalloutPress={this.openTicket} marker={markers} url={planVersion == null
                        ? 'map'
                        : `${UpmeshClient.instance.url}/plan/tiles/${planVersion.projectId}/planVersion/${planVersion.id}`} onRegionChange={this.onRegionChange} offlineData={planVersion == null
                        ? undefined
                        : OfflineDataDownloader.isPlanVersionSynced(planVersion.projectId, planVersion.id)} onLongPress={this.createTicket}/>);
        }
        let style;
        let webStyle;
        if (Platform.OS === 'web') {
            style = { cursor: 'n-resize' };
        }
        const useThreeDots = ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM;
        const icons = [];
        let viewNumber = 0;
        if (selectedView === 'list')
            viewNumber = 1;
        else if (selectedView === 'table')
            viewNumber = 2;
        else if (selectedView === 'calendar')
            viewNumber = 3;
        let filterIcon = (<Icon key={`filterButton${filterActive}`} icon={filterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={onPressFilter} color={filterActive ? ThemeManager.style.brandPrimary : ThemeManager.style.defaultIconColor}/>);
        if (filterActive && ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM) {
            filterIcon = (<View key={`filterButtonBigOuter${filterActive}`}>
          <ContainedButton key={`filterButtonBig${filterActive}`} title={I18n.m.getMessage('filterRemove')} icon={{ icon: 'filter-remove', color: '#ffffff' }} onPress={onPressFilter}/>
        </View>);
        }
        if (useThreeDots) {
            icons.push(filterIcon, tagIcon, <Icon {...this.getViewIcon()} key="viewIcon"/>, <Icon key="threeDotKey" toolTip={I18n.m.getMessage('more')} icon="dots-vertical" color={ThemeManager.style.defaultIconColor} disabled={false} onPress={this.openThreeDots}/>);
        }
        else {
            icons.push(filterIcon);
            icons.push(tagIcon);
            if (selectedView !== 'table') {
                icons.push(<Icon key="sortButton" toolTip={I18n.m.getMessage('sort')} icon="sort" onPress={this.openSortDialog}/>);
            }
            icons.push(<ReportIcon key="reportButton" selectedTickets={tickets} filterActive={filterActive} onPressFilter={onPressFilter}/>, <Icon key="bulkChangeIcon" toolTip={I18n.m.getMessage('bulkChangesIconTooltip')} icon="checkbox-multiple-marked-outline" onPress={() => this.onChangeMultiSelectStatus(true)}/>, <Icon icon="microsoft-excel" toolTip={I18n.m.getMessage('importExcelToolTip')} onPress={openTicketExcelImportDialog({})}/>, <Icon key="downloadPlanButton" toolTip={I18n.m.getMessage('downloadPlan')} icon="download-outline" color={ThemeManager.style.defaultIconColor} outerSize={36} disabled={planVersion == null} onPress={this.openDownloadMenu}/>, <View key="segmentedButton" style={{ width: 216, marginLeft: 12 }}>
          <SegmentedButton buttons={[
                    { icon: { icon: 'poll', rotation: 180 }, key: 'canban' },
                    { icon: { icon: 'format-list-bulleted-square' }, key: 'list' },
                    { icon: { icon: 'table-large' }, key: 'table' },
                    { icon: { icon: 'calendar' }, key: 'calendar' },
                ]} onPress={this.changeView} singleSelectSelected={viewNumber} density={2}/>
        </View>);
        }
        const { size } = this.props;
        let sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 96 : 48;
        sViewHeight += childHeight;
        return (<PageView onLayout={this.onLayout} key="Plan" headerProps={{
                title: '',
                searchBarProps: {
                    searchBarValue: searchWords,
                    searchBarPlaceholder: I18n.m.getMessage('searchTickets'),
                    searchOnChange: onSearch,
                    tooltip: I18n.m.getMessage('searchTickets'),
                },
                leftButtons: !backButton
                    ? undefined
                    : [
                        <Icon key="BackButton" toolTip={I18n.m.getMessage('tooltipBackToTicketSite')} icon="arrow-left" onPress={this.goBack} outerSize={36}/>,
                    ],
                rightButtons: icons,
            }}>
        {this.props.children != null && (<View style={{ width: '100%', height: childHeight }}>{this.props.children}</View>)}
        <View style={{
                position: 'relative',
                height: size.contentHeight - sViewHeight,
                width: maxPlanWidth,
            }}>
          {selectedPlanVersionId != null && selectedPlanVersionId.length > 0 ? (<View style={{
                    width: maxPlanWidth,
                    height: size.contentHeight - sViewHeight,
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                }} ref={(r) => {
                    this.secondView = r;
                }}>
              <View key={`tickets_plan_${selectedPlanVersionId}`} style={{
                    ...webStyle,
                    position: 'absolute',
                    top: 0,
                    height: planHeight,
                    left: 0,
                    right: 0,
                    overflow: 'hidden',
                }}>
                {thumb}
              </View>
              <View style={[
                    {
                        position: 'absolute',
                        top: planHeight,
                        flexDirection: 'row',
                        left: 0,
                        right: 0,
                        height: TicketsDetailsView.separatorSize,
                        width: maxPlanWidth,
                        backgroundColor: 'transparent',
                        borderTopWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'rgba(0,0,0,0.3)',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        alignItems: 'center',
                    },
                ]}>
                <View style={{
                    flexDirection: 'row',
                    width: '33%',
                }}>
                  {planHeight === 0 && size.windowWidth > 600 ? (<ContainedButton title={I18n.m.getMessage('ticketsShowPlan')} onPress={this.showPlan} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>) : null}
                  {planHeight === maxPlanHeight && size.windowWidth > 600 ? (<ContainedButton title={I18n.m.getMessage('ticketsShowTickets')} onPress={this.showTickets} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>) : null}
                </View>
                <View style={{
                    flexDirection: 'row',
                    width: '33%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                  <Icon icon="chevron-up" onPress={this.pressedUp} disabled={planHeight === 0} toolTip={I18n.m.getMessage('tooltipUp')}/>
                  <Icon toolTip={I18n.m.getMessage('tooltipDown')} icon="chevron-down" onPress={this.pressedDown} disabled={planHeight === maxPlanHeight}/>
                </View>
                <View style={{
                    flexDirection: 'row',
                    width: '33%',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                }}>
                  <View style={[
                    {
                        height: 8,
                        width: 54,
                        backgroundColor: 'transparent',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    },
                    style,
                ]}/>
                </View>
              </View>
              <View style={{
                    position: 'absolute',
                    top: planHeight + TicketsDetailsView.separatorSize,
                    left: 0,
                    right: 0,
                    width: maxPlanWidth,
                    bottom: 8,
                    height: size.contentHeight -
                        planHeight -
                        TicketsDetailsView.separatorSize -
                        ThemeManager.style.headerHeight,
                }}>
                <TicketsOverView size={size} onChangeTicketOrder={onChangeTicketOrder} activeMultiselect={activeMultiselect} onChangeMultiselectStatus={this.onChangeMultiSelectStatus} showFab={showFab} pressCreateTicket={this.props.pressCreateTicket} currentTicketFilter={currentTicketFilter} updateTicketFilter={updateTicketFilter} onPressFilter={onPressFilter} filterActive={filterActive} width={maxPlanWidth} height={size.contentHeight - planHeight - TicketsDetailsView.separatorSize - sViewHeight} style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    right: 0,
                    width: maxPlanWidth,
                    height: size.contentHeight - planHeight - TicketsDetailsView.separatorSize - sViewHeight,
                }} tickets={tickets} planId={this.props.plan != null ? this.props.plan.id : undefined} selectedView={selectedView}/>
              </View>
            </View>) : (<TicketsOverView size={size} onChangeTicketOrder={onChangeTicketOrder} activeMultiselect={activeMultiselect} onChangeMultiselectStatus={this.onChangeMultiSelectStatus} pressCreateTicket={this.props.pressCreateTicket} currentTicketFilter={currentTicketFilter} onSearch={onSearch} searchWords={searchWords} onPressFilter={onPressFilter} filterActive={filterActive} width={maxPlanWidth} height={size.contentHeight - sViewHeight} showFab={showFab} tickets={tickets} selectedView={selectedView} updateTicketFilter={updateTicketFilter}/>)}
        </View>
      </PageView>);
    }
}
TicketsDetailsView.separatorSize = 32;
TicketsDetailsView.defaultProps = {
    title: 'upmesh',
};
