var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rippleDefaultProps = exports.Ripple = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _UseComponentSize = _interopRequireDefault(require("../../hooks/UseComponentSize"));
var _ToolTipEventHandler = require("../tooltips/ToolTipEventHandler");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var rippleDefaultProps = exports.rippleDefaultProps = {
  rippleColor: 'rgb(0, 0, 0)',
  rippleEffectEnabled: true,
  rippleOpacity: 0.26,
  rippleDuration: 400,
  rippleSize: 0,
  rippleContainerBorderRadius: 0,
  rippleCentered: false,
  rippleSequential: false,
  disabled: false,
  onPress: undefined,
  onPressIn: undefined,
  style: {
    width: 'auto',
    height: 'auto',
    backgroundColor: 'transparent'
  }
};
var Ripple = exports.Ripple = function Ripple(newProps) {
  var props = Object.assign({}, rippleDefaultProps, newProps);
  var _useState = (0, _react.useState)(false),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    hover = _useState2[0],
    setHover = _useState2[1];
  var _useState3 = (0, _react.useState)([]),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    ripples = _useState4[0],
    setRipples = _useState4[1];
  var _useState5 = (0, _react.useState)(),
    _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
    touchRef = _useState6[0],
    setTouchRef = _useState6[1];
  var _useComponentSize = (0, _UseComponentSize.default)(),
    _useComponentSize2 = (0, _slicedToArray2.default)(_useComponentSize, 2),
    layout = _useComponentSize2[0],
    onRootLayout = _useComponentSize2[1];
  var _useState7 = (0, _react.useState)(),
    _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
    toolTips = _useState8[0],
    setToolTips = _useState8[1];
  var styles = {
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'transparent',
      overflow: 'hidden'
    },
    radius: 10,
    ripple: {
      width: 10 * 2,
      height: 10 * 2,
      borderRadius: 10,
      overflow: 'hidden',
      position: 'absolute'
    }
  };
  var closeTimeout;
  var unique;
  var controller;
  var hideToolTip = function hideToolTip() {
    if (toolTips != null && toolTips.id != null) {
      if (closeTimeout != null) clearTimeout(closeTimeout);
      _ToolTipEventHandler.ToolTipEventHandler.closeToolTip(toolTips.id);
      setToolTips(undefined);
    }
  };
  var measure = function () {
    var _ref = (0, _asyncToGenerator2.default)(function* (ref) {
      var _controller;
      if ((_controller = controller) != null && _controller.signal.aborted) {
        return Promise.reject(new Error('AbortError'));
      }
      return new Promise(function (resolve, _reject) {
        try {
          ref.measure(function (_x, _y, width, height, pageX, pageY) {
            var x = pageX;
            var y = pageY;
            if (x != null && y != null && width != null && height != null) {
              return resolve({
                x: x,
                y: y,
                width: width,
                height: height
              });
            }
            return resolve({
              x: 0,
              y: 0,
              width: 0,
              height: 0
            });
          });
        } catch (e) {
          resolve({
            x: 0,
            y: 0,
            width: 0,
            height: 0
          });
        }
      });
    });
    return function measure(_x2) {
      return _ref.apply(this, arguments);
    };
  }();
  var showToolTip = function showToolTip() {
    if (toolTips != null && toolTips.id != null) {
      _ToolTipEventHandler.ToolTipEventHandler.openToolTip(toolTips);
      if (closeTimeout != null) clearTimeout(closeTimeout);
      closeTimeout = setTimeout(hideToolTip, 2500);
    }
  };
  var createToolTip = function createToolTip() {
    var toolTip = props.toolTip;
    if (touchRef != null && toolTips == null && toolTip != null && toolTip.length > 0) {
      measure(touchRef).then(function (position) {
        var x = position.x,
          y = position.y,
          width = position.width,
          height = position.height;
        if (x != null && y != null && width != null && height != null) {
          var tt = {
            text: toolTip,
            id: '1',
            elementPosition: {
              width: width,
              height: height,
              x: x,
              y: y
            }
          };
          setToolTips(tt);
        }
      }).catch(function (e) {
        if (e['message'] !== 'AbortError') {}
      });
    }
  };
  (0, _react.useEffect)(function () {
    if (toolTips != null && toolTips.id != null) {
      showToolTip();
    }
    return function () {
      hideToolTip();
    };
  }, [toolTips, hover, props.toolTip]);
  var onMouseEnter = function onMouseEnter() {
    if (!hover && props.disabled !== true) setHover(true);
    if (props.onMouseEnter != null) props.onMouseEnter();
    createToolTip();
  };
  var onMouseLeave = function onMouseLeave() {
    setHover(false);
    if (props.onMouseLeave != null) props.onMouseLeave();
    hideToolTip();
  };
  (0, _react.useEffect)(function () {
    if (controller == null) controller = new AbortController();
    if (_reactNativeWeb.Platform.OS === 'web' && touchRef != null) {
      touchRef.removeEventListener('mouseenter', onMouseEnter);
      touchRef.removeEventListener('mouseleave', onMouseLeave);
      touchRef.addEventListener('mouseenter', onMouseEnter);
      touchRef.addEventListener('mouseleave', onMouseLeave);
    }
    return function () {
      var _controller2;
      (_controller2 = controller) == null || _controller2.abort();
      if (_reactNativeWeb.Platform.OS === 'web' && touchRef != null) {
        touchRef.removeEventListener('mouseenter', onMouseEnter);
        touchRef.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, [touchRef]);
  var preventMouseSpecailButtons = function preventMouseSpecailButtons(_event) {
    return !!(_event != null && _event.nativeEvent != null && _event.nativeEvent.which != null && _event.nativeEvent.which > 1);
  };
  var onLongPress = function onLongPress(event) {
    var onLongPress = props.onLongPress;
    if (typeof onLongPress === 'function') {
      event.persist();
      requestAnimationFrame(function () {
        return onLongPress(event);
      });
    }
    createToolTip();
  };
  var onPress = function onPress(event) {
    if (preventMouseSpecailButtons(event)) return;
    if (props.onPress != null && typeof props.onPress === 'function') {
      if (event != null) event.persist();
      props.onPress(event);
    }
  };
  var startRipple = function startRipple(event) {
    var rippleDuration = props.rippleDuration,
      rippleCentered = props.rippleCentered,
      rippleSize = props.rippleSize,
      rippleEffectEnabled = props.rippleEffectEnabled;
    if (rippleEffectEnabled && layout != null && layout.width > 0 && layout.height > 0) {
      var w2 = 0.5 * layout.width;
      var h2 = 0.5 * layout.height;
      var _ref2 = rippleCentered != null && rippleCentered ? {
          locationX: w2,
          locationY: h2
        } : event.nativeEvent,
        locationX = _ref2.locationX,
        locationY = _ref2.locationY;
      var offsetX = Math.abs(w2 - locationX);
      var offsetY = Math.abs(h2 - locationY);
      var R = rippleSize != null && rippleSize > 0 ? 0.5 * rippleSize : Math.sqrt((w2 + offsetX) ** 2 + (h2 + offsetY) ** 2);
      var ripple = {
        locationX: locationX,
        locationY: locationY,
        R: R,
        unique: unique += 1,
        progress: new _reactNativeWeb.Animated.Value(0)
      };
      _reactNativeWeb.Animated.timing(ripple.progress, {
        toValue: 1,
        easing: _reactNativeWeb.Easing.out(_reactNativeWeb.Easing.ease),
        duration: rippleDuration,
        useNativeDriver: false
      }).start(function () {
        setRipples(ripples.slice(1));
      });
      setRipples(ripples.concat(ripple));
    }
  };
  var onPressIn = function onPressIn(event) {
    var rippleSequential = props.rippleSequential;
    if (preventMouseSpecailButtons(event)) return;
    if (rippleSequential == null || ripples.length === 0) {
      if (props.onPressIn != null && typeof props.onPressIn === 'function') {
        requestAnimationFrame(function () {
          if (props.onPressIn != null && typeof props.onPressIn === 'function') props.onPressIn(event);
        });
      }
      startRipple(event);
    }
  };
  var onPressOut = function onPressOut(event) {
    if (preventMouseSpecailButtons(event)) return;
    var onPressOut = props.onPressOut;
    if (typeof onPressOut === 'function') {
      event.persist();
      onPressOut(event);
    }
  };
  var renderRipple = function renderRipple(_ref3) {
    var unique = _ref3.unique,
      progress = _ref3.progress,
      locationX = _ref3.locationX,
      locationY = _ref3.locationY,
      R = _ref3.R;
    var rippleColor = props.rippleColor,
      rippleOpacity = props.rippleOpacity;
    var rippleStyle = {
      top: locationY - styles.radius,
      left: locationX - styles.radius,
      backgroundColor: rippleColor,
      transform: [{
        scale: progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0.5 / styles.radius, R / styles.radius]
        })
      }],
      opacity: progress.interpolate({
        inputRange: [0, 1],
        outputRange: [rippleOpacity, 0]
      })
    };
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
      style: [styles.ripple, rippleStyle]
    }, unique);
  };
  var render = function render() {
    var delayLongPress = props.delayLongPress,
      disabled = props.disabled,
      hitSlop = props.hitSlop,
      pressRetentionOffset = props.pressRetentionOffset,
      children = props.children,
      rippleContainerBorderRadius = props.rippleContainerBorderRadius,
      accessibilityLabel = props.accessibilityLabel,
      style = props.style,
      backgroundColor = props.backgroundColor,
      hoverColor = props.hoverColor,
      tabIndex = props.tabIndex;
    var defaultBackgroundColor = backgroundColor != null ? backgroundColor : style != null && style['backgroundColor'] != null ? style['backgroundColor'] : 'transparent';
    var bgColor = hover && hoverColor != null ? hoverColor : defaultBackgroundColor;
    var touchableProps = {
      delayLongPress: delayLongPress,
      disabled: disabled,
      hitSlop: hitSlop,
      accessibilityLabel: accessibilityLabel,
      pressRetentionOffset: pressRetentionOffset,
      testId: accessibilityLabel,
      onPress: onPress,
      onPressIn: onPressIn,
      onPressOut: onPressOut,
      onLayout: onRootLayout,
      onLongPress: onLongPress,
      style: Object.assign({
        width: 'auto',
        height: 'auto',
        padding: 0
      }, style, {
        backgroundColor: bgColor
      })
    };
    if (_reactNativeWeb.Platform.OS === 'web') {
      touchableProps['style']['cursor'] = !disabled ? 'pointer' : 'auto';
      touchableProps['tabindex'] = tabIndex && tabIndex >= 0 ? tabIndex : -1;
      touchableProps['tabIndex'] = tabIndex && tabIndex >= 0 ? tabIndex : -1;
      touchableProps['focusable'] = false;
    }
    var containerStyle = Object.assign({}, styles.container, {
      borderRadius: rippleContainerBorderRadius
    });
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.TouchableWithoutFeedback, Object.assign({}, touchableProps, {
      ref: _reactNativeWeb.Platform.OS === 'web' || props.toolTip != null && props.toolTip.length >= 0 ? setTouchRef : undefined,
      children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: Object.assign({}, touchableProps.style),
        children: [children, props.rippleEffectEnabled && (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: containerStyle,
          children: ripples.map(renderRipple)
        })]
      })
    }));
  };
  return render();
};