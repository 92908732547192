import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ChangeStoredFileComment } from 'upmesh-core/src/client/commands/storedfile/ChangeStoredFileComment';
import { ChangeStoredFileType } from 'upmesh-core/src/client/commands/storedfile/ChangeStoredFileType';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export function AddFileTypeAndCommentDialog(props) {
    const [avTypes, setAvTypes] = useState([I18n.m.getMessage('filesChangeToNoType')]);
    const [pickerIndex, setPickerIndex] = useState(0);
    const [storedFile, setStoredFile] = useState();
    const [comment, setComment] = useState();
    const save = async () => {
        const { fileId } = props;
        if (storedFile != null && comment != null && comment !== storedFile.comment) {
            try {
                const changeCommentCommand = new ChangeStoredFileComment({ comment }, fileId, CurrentUser.token);
                await changeCommentCommand.execute();
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            }
        }
        if (pickerIndex > 0) {
            const changeFileTypeCommand = new ChangeStoredFileType({ type: avTypes[pickerIndex] }, fileId, CurrentUser.token);
            try {
                await changeFileTypeCommand.execute();
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            }
        }
        else if (storedFile != null && storedFile.type != null) {
            const changeFileTypeCommand = new ChangeStoredFileType({ type: null }, fileId, CurrentUser.token);
            try {
                await changeFileTypeCommand.execute();
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            }
        }
        Dialog.instance?.close();
    };
    const notSave = () => {
        Dialog.instance?.close();
    };
    useEffect(() => {
        const { fileId, fileTypes } = props;
        const inputTypes = [I18n.m.getMessage('filesChangeToNoType')];
        setAvTypes(inputTypes.concat(fileTypes));
        UpmeshClient.instance.modals.storedFile
            .getById(fileId)
            .then((element) => {
            if (element != null) {
                setStoredFile(element);
                setComment(element.comment != null ? element.comment : '');
                const value = fileTypes.findIndex((type) => {
                    return type === element.type;
                });
                if (value > -1) {
                    setPickerIndex(value + 1);
                }
            }
        })
            .catch((e) => {
            DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
        });
    }, []);
    const changeType = (e) => {
        setPickerIndex(e.index);
    };
    return (<>
      {comment != null ? (<>
          <DialogTitle key="addFileTypeAndCommentTitle" iconRight={{
                icon: 'close',
                disabled: false,
                toolTip: I18n.m.getMessage('close'),
                onPress: notSave,
            }}>
            {I18n.m.getMessage('filesChangeFileTypeDialog')}
          </DialogTitle>
          <DialogContent key="addFileTypeAndCommentContent">
            <FormInputFilled labelText={I18n.m.getMessage('filesCommentHeadline')} onChange={setComment} initValue={comment}/>
            <FormInputPicker labelText={I18n.m.getMessage('filesFileTypeHeadline')} list={avTypes} onChange={changeType} selectedIndex={pickerIndex}/>
          </DialogContent>
          <DialogActions key="addFileTypeAndCommentAction">
            <ContainedButton title={I18n.m.getMessage('cancel')} onPress={notSave} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
            <ContainedButton title={I18n.m.getMessage('save')} onPress={() => {
                save().catch((err) => console.error(err));
            }}/>
          </DialogActions>
        </>) : null}
    </>);
}
