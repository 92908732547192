"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.User = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var UserEntity_1 = require("./entities/UserEntity");
var User = function (_ReadModelClientOffli) {
  function User() {
    var _this;
    (0, _classCallCheck2.default)(this, User);
    _this = _callSuper(this, User, arguments);
    _this.exampleEntity = new UserEntity_1.UserEntity();
    _this.syncPriority = 2;
    _this.authNeeded = true;
    _this.onEvents = [{
      eventName: 'UserPhoneChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'UserNamesChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'UserUploadedUserImage',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'UserSetLocale',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'UserSetCompany',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'UserModificationDateUpdate',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'UserSetColorCode',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'AccountStatusChanged',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(User, _ReadModelClientOffli);
  return (0, _createClass2.default)(User);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.User = User;