import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateRangeInput } from 'materialTheme/src/theme/components/forminput/DateRangeInput';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { StoredFileFilter } from 'upmesh-core/src/client/query/filter/StoredFileFilter';
import { I18n } from '../../i18n/I18n';
const styles = {
    spacer: { height: 6 },
};
export class FilesFilterDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.setFe = (e) => {
            this.setState({ selectedExtensions: e }, this.calculateFiltered);
        };
        this.setPersons = (e) => {
            this.setState({ selectedCreator: e }, this.calculateFiltered);
        };
        this.formats = {
            images: ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'svg'],
            videos: ['mp4', 'mov', 'wmv', 'avchd', 'flv', 'f4v', 'swf', 'mkv', 'avi', 'webm'],
            audio: ['mp3', 'wav', 'flac', 'aac', 'oog', 'rm'],
            documents: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'pptx', 'ppt', 'txt', 'rtf', 'asc', 'odt', 'pps'],
        };
        this.quickSelect = (type) => (_e) => {
            if (this.formats[type] && this.formats[type].length > 0) {
                const { extensions, selectedExtensions } = this.state;
                this.formats[type].forEach((e) => {
                    const exists = extensions.findIndex((a) => a.title.toLowerCase() === `.${e}`);
                    if (exists > -1) {
                        const d = extensions[exists];
                        const selected = selectedExtensions.findIndex((a) => a.id === d.id);
                        if (selected === -1) {
                            selectedExtensions.push(d);
                        }
                    }
                });
                this.setState({ selectedExtensions: [...selectedExtensions] }, this.calculateFiltered);
            }
        };
        this.calculateFiltered = () => {
            const { files } = this.props;
            this.setState({ calculateAmount: true }, () => {
                try {
                    const filter = this.createCurrentFilter();
                    const filtered = StoredFileFilter.filterFiles(files, filter);
                    this.setState({ calculateAmount: false, amountSelected: filtered.length });
                }
                catch (e) {
                    this.setState({ calculateAmount: false, amountSelected: 0 });
                }
            });
        };
        this.init = async () => {
            const { currentFilter } = this.props;
            const extensions = await this.getFe();
            const availableTeammember = await this.getPersons();
            const selectedCreator = await this.getSelectedChipsByIds(availableTeammember, currentFilter?.u);
            const selectedExtensions = await this.getSelectedChipsByIds(extensions, currentFilter?.fe);
            this.setState({
                selectedCreator,
                extensions,
                selectedExtensions,
                availableTeammember,
                loading: false,
            }, this.calculateFiltered);
        };
        this.onReset = () => {
            this.resetFilters();
        };
        this.onSelect = () => {
            Dialog.instance?.close(() => {
                const filter = this.createCurrentFilter();
                Routing.instance.changeQueryParameter({
                    ff: JSON.stringify(filter),
                });
                if (this.props.callback != null)
                    this.props.callback(filter);
            });
        };
        this.state = {
            extensions: [],
            selectedExtensions: [],
            loading: true,
            amountSelected: 0,
            calculateAmount: true,
            availableTeammember: [],
            selectedCreator: [],
            intervalStart: props.currentFilter?.date ? props.currentFilter?.date : undefined,
            intervalEnd: props.currentFilter?.dateTo ? props.currentFilter?.dateTo : undefined,
        };
    }
    static open(files, callback, currentFilter) {
        if (Dialog.instance != null) {
            const content = <FilesFilterDialog files={files} callback={callback} currentFilter={currentFilter}/>;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content,
                contentPadding: false,
                scrollable: false,
                fullscreenResponsive: true,
            });
        }
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    render() {
        const { files } = this.props;
        const { availableTeammember, intervalStart, intervalEnd, loading, amountSelected, selectedExtensions, selectedCreator, extensions, } = this.state;
        if (loading) {
            return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
          <Spinner />
        </View>);
        }
        return (<View>
        <DialogTitle>{I18n.m.getMessage('storedFileFilterHeader')}</DialogTitle>
        <View style={{
                position: 'absolute',
                top: ThemeManager.style.contentPaddingValue,
                right: ThemeManager.style.contentPaddingValue,
            }}>
          <ContainedButton key="ticketFilterModalCloseButton" textColor="#000000" icon={{ icon: 'close' }} onPress={() => Dialog.instance?.close()} backgroundColor="transparent"/>
        </View>
        <View style={{ padding: ThemeManager.style.contentPaddingValue }}>
          <ChipGroup dialogTitle={I18n.m.getMessage('storedFileFilterExtensions')} label={I18n.m.getMessage('storedFileFilterExtensions')} bordered={false} availableChips={extensions} chips={selectedExtensions} onChanged={this.setFe}/>
          <View style={{ width: '100%', flexDirection: 'row' }}>
            <Chip title={I18n.m.getMessage('images')} onPressChip={this.quickSelect('images')}/>
            <Chip title={I18n.m.getMessage('videos')} onPressChip={this.quickSelect('videos')}/>
            <Chip title={I18n.m.getMessage('audio')} onPressChip={this.quickSelect('audio')}/>
            <Chip title={I18n.m.getMessage('documents')} onPressChip={this.quickSelect('documents')}/>
          </View>
          <View style={styles.spacer}/>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('storedFileFilterPersons')}</MaterialText>
          <ChipGroup dialogTitle={I18n.m.getMessage('storedFileFilterPersons')} chips={selectedCreator} bordered={false} availableChips={availableTeammember} onChanged={this.setPersons} tooltipAddChip={I18n.m.getMessage('storedFileFilterAddTeammember')}/>
          <View style={styles.spacer}/>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('storedFileFilterCreatedAt')}</MaterialText>
          <DateRangeInput selectedDate={intervalStart == null || intervalEnd == null
                ? undefined
                : { from: new Date(intervalStart), to: new Date(intervalEnd) }} placeholder={I18n.m.getMessage('date')} key={`intervalStart${intervalStart}`} onChange={(date) => {
                if (date)
                    this.setState({ intervalStart: new Date(date.from), intervalEnd: new Date(date.to) }, this.calculateFiltered);
                else
                    this.setState({ intervalStart: undefined, intervalEnd: undefined }, this.calculateFiltered);
            }}/>
          <View style={styles.spacer}/>
        </View>
        <DialogActions key="actions" onBottom>
          <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('clear')} onPress={this.onReset}/>
          <View style={{ width: 8 }}/>
          <ContainedButton title={`${I18n.m.getMessage('apply')} ${amountSelected}/${files.length}`} onPress={this.onSelect} disabled={amountSelected === 0 && files.length > 0}/>
        </DialogActions>
      </View>);
    }
    createCurrentFilter() {
        const currentFilter = new StoredFileFilter();
        const { selectedExtensions, selectedCreator, intervalStart, intervalEnd } = this.state;
        if (selectedExtensions != null && selectedExtensions.length > 0) {
            currentFilter.fe = [];
            selectedExtensions.forEach((t) => {
                if (t.id != null)
                    currentFilter.fe.push(t.id);
            });
        }
        if (selectedCreator != null && selectedCreator.length > 0) {
            currentFilter.u = [];
            if (selectedCreator != null && selectedCreator.length > 0) {
                for (let i = 0; i < selectedCreator.length; i += 1) {
                    const c = selectedCreator[i];
                    if (c != null && c.id != null) {
                        currentFilter.u.push(c.id);
                    }
                }
            }
        }
        currentFilter.date = intervalStart != null ? intervalStart.getTime() : undefined;
        currentFilter.dateTo = intervalEnd != null ? intervalEnd.getTime() : undefined;
        return currentFilter;
    }
    async getFe() {
        const { files } = this.props;
        const fileExtensions = [];
        const extensions = [];
        files.forEach((t) => {
            if (t.orgFilename != null && t.orgFilename.length > 0) {
                const ext = t.orgFilename.slice(t.orgFilename.lastIndexOf('.')).toLowerCase();
                if (!extensions.includes(ext))
                    extensions.push(ext);
            }
        });
        for (let i = 0; i < extensions.length; i += 1) {
            fileExtensions.push({
                id: extensions[i],
                title: extensions[i],
            });
        }
        return fileExtensions;
    }
    async getPersons() {
        const { files } = this.props;
        const values = [];
        files.forEach((t) => {
            if (t.createdBy != null &&
                t.createdBy.length > 0 &&
                t.uploadedByUsername != null &&
                t.uploadedByUsername.length > 0) {
                const exists = values.findIndex((e) => e.id === t.createdBy);
                if (exists === -1) {
                    values.push({
                        id: t.createdBy,
                        title: t.uploadedByUsername,
                    });
                }
            }
        });
        return values;
    }
    async getSelectedChipsByIds(chips, ids) {
        const selectedTags = [];
        if (ids != null && ids.length > 0) {
            for (let i = 0; i < ids.length; i += 1) {
                for (let j = 0; j < chips.length; j += 1) {
                    if (ids[i] === chips[j].id) {
                        selectedTags.push(chips[j]);
                        break;
                    }
                }
            }
        }
        return selectedTags;
    }
    resetFilters() {
        this.setState({
            selectedCreator: [],
            selectedExtensions: [],
        }, this.calculateFiltered);
    }
}
