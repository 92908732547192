"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RemoteRepo = void 0;
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var ts_events_1 = require("ts-events");
var RemoteRepo = function () {
  function RemoteRepo(exampleEntity, remoteDB) {
    (0, _classCallCheck2.default)(this, RemoteRepo);
    this.changeEvent = new ts_events_1.AsyncEvent();
    this._exampleEntity = exampleEntity;
    this._remoteDB = remoteDB;
  }
  return (0, _createClass2.default)(RemoteRepo, [{
    key: "action",
    value: function () {
      var _action = (0, _asyncToGenerator2.default)(function* (_command, _data) {
        throw new Error('not supported');
      });
      function action(_x, _x2) {
        return _action.apply(this, arguments);
      }
      return action;
    }()
  }, {
    key: "count",
    value: function () {
      var _count = (0, _asyncToGenerator2.default)(function* (filter) {
        return this._remoteDB.fetchData(this._exampleEntity.entityName, 'count', {
          filter: filter
        });
      });
      function count(_x3) {
        return _count.apply(this, arguments);
      }
      return count;
    }()
  }, {
    key: "create",
    value: function create(c) {
      return new c();
    }
  }, {
    key: "delete",
    value: function () {
      var _delete2 = (0, _asyncToGenerator2.default)(function* (_entityId) {
        throw new Error('not supported');
      });
      function _delete(_x4) {
        return _delete2.apply(this, arguments);
      }
      return _delete;
    }()
  }, {
    key: "deleteFilter",
    value: function () {
      var _deleteFilter = (0, _asyncToGenerator2.default)(function* (filter) {
        return this._remoteDB.fetchData(this._exampleEntity.entityName, 'deleteFilter', {
          filter: filter
        });
      });
      function deleteFilter(_x5) {
        return _deleteFilter.apply(this, arguments);
      }
      return deleteFilter;
    }()
  }, {
    key: "distinct",
    value: function () {
      var _distinct = (0, _asyncToGenerator2.default)(function* (select, filter) {
        return this._remoteDB.fetchData(this._exampleEntity.entityName, 'distinct', {
          filter: filter,
          select: select.toString()
        });
      });
      function distinct(_x6, _x7) {
        return _distinct.apply(this, arguments);
      }
      return distinct;
    }()
  }, {
    key: "get",
    value: function () {
      var _get = (0, _asyncToGenerator2.default)(function* (options) {
        var _this = this;
        var result = yield this._remoteDB.fetchData(this._exampleEntity.entityName, 'get', options);
        var typedResult = [];
        if (result != null && Array.isArray(result)) {
          result == null || result.forEach(function (e) {
            typedResult.push(_this._exampleEntity.createOne(e));
          });
        } else if (result != null && result['error']) {
          throw result['error'];
        }
        return typedResult;
      });
      function get(_x8) {
        return _get.apply(this, arguments);
      }
      return get;
    }()
  }, {
    key: "getById",
    value: function () {
      var _getById = (0, _asyncToGenerator2.default)(function* (id, select) {
        var result = select != null ? yield this._remoteDB.fetchData(this._exampleEntity.entityName, 'getById', {
          id: id,
          select: select.toString()
        }) : yield this._remoteDB.fetchData(this._exampleEntity.entityName, 'getById', {
          id: id
        });
        if (result != null) {
          return this._exampleEntity.createOne(result);
        }
        throw StdApplicationError_1.StdApplicationError.notFound(`${this._exampleEntity.entityName}notFound`);
      });
      function getById(_x9, _x10) {
        return _getById.apply(this, arguments);
      }
      return getById;
    }()
  }, {
    key: "getByIdAt",
    value: function () {
      var _getByIdAt = (0, _asyncToGenerator2.default)(function* (id, time) {
        var result = yield this._remoteDB.fetchData(this._exampleEntity.entityName, 'getById', {
          id: id,
          date: time
        });
        if (result != null) {
          return this._exampleEntity.createOne(result);
        }
        throw StdApplicationError_1.StdApplicationError.notFound(`${this._exampleEntity.entityName}notFound`);
      });
      function getByIdAt(_x11, _x12) {
        return _getByIdAt.apply(this, arguments);
      }
      return getByIdAt;
    }()
  }, {
    key: "patch",
    value: function () {
      var _patch = (0, _asyncToGenerator2.default)(function* (_entity) {
        throw new Error('not supported');
      });
      function patch(_x13) {
        return _patch.apply(this, arguments);
      }
      return patch;
    }()
  }, {
    key: "post",
    value: function () {
      var _post = (0, _asyncToGenerator2.default)(function* (_entity) {
        throw new Error('not supported');
      });
      function post(_x14) {
        return _post.apply(this, arguments);
      }
      return post;
    }()
  }, {
    key: "postOrPatchMany",
    value: function () {
      var _postOrPatchMany = (0, _asyncToGenerator2.default)(function* (_entity) {
        throw new Error('not supported');
      });
      function postOrPatchMany(_x15) {
        return _postOrPatchMany.apply(this, arguments);
      }
      return postOrPatchMany;
    }()
  }, {
    key: "patchManyWithFilter",
    value: function () {
      var _patchManyWithFilter = (0, _asyncToGenerator2.default)(function* (_body, _filter) {
        throw new Error('not supported');
      });
      function patchManyWithFilter(_x16, _x17) {
        return _patchManyWithFilter.apply(this, arguments);
      }
      return patchManyWithFilter;
    }()
  }]);
}();
exports.RemoteRepo = RemoteRepo;