import { WaitFor } from 'cqrs-shared/src/WaitFor';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { FlatList, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { PlanEntity } from 'upmesh-core/src/client/query/entities/PlanEntity';
import { PlanFilter } from 'upmesh-core/src/client/query/filter/PlanFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
export class PlanSelector extends PureComponent {
    constructor(props) {
        super(props);
        this.allPlans = [];
        this.onSearch = (searchValue) => {
            PlanFilter.filterPlansByText(searchValue, this.allPlans)
                .then((plans) => {
                this.setState({ plans, searchValue });
            })
                .catch((err) => console.error(err));
        };
        this.selectPlan = (p) => (_e) => {
            if (p.id === 'new') {
                this.props.onSelect('new');
            }
            else {
                this.props.onSelect(p);
            }
        };
        this.renderRow = ({ item }) => {
            if (item.id === 'new') {
                return (<ListItem key="new Plan" paddingLeft={ThemeManager.style.contentPaddingValue} title={`+ ${item.title}`} onPress={this.selectPlan(item)}/>);
            }
            return (<ListItem key={`ticket_${item.id}`} paddingLeft={ThemeManager.style.contentPaddingValue} title={item.title} onPress={this.selectPlan(item)}/>);
        };
        this.state = {
            isLoading: true,
            plans: [],
            searchValue: '',
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    async init() {
        await WaitFor.instance.waitFor(() => UpmeshClient.instance.ready);
        if (AuthClient.instance.ready) {
            await WaitFor.instance.waitFor(() => CurrentUser.userId != null);
            const { projectId, showNew } = this.props;
            this.allPlans = await UpmeshClient.instance.modals.plan.get({
                filter: `projectId eq '${projectId}' and activePlanId ne 'MAP' and deleted ne true`,
                orderby: 'title',
            });
            if (showNew) {
                const canCreate = await RightsManager.instance.hasWriteRight(projectId, CurrentUser.userId, 'commandCreateTicket');
                if (canCreate) {
                    this.allPlans.unshift(new PlanEntity({ title: I18n.m.getMessage('camCreateNewPlan'), id: 'new' }));
                }
            }
            const { searchValue } = this.state;
            let plans = this.allPlans;
            if (searchValue.length > 0) {
                plans = await PlanFilter.filterPlansByText(searchValue, this.allPlans);
            }
            this.setState({ plans, isLoading: false });
        }
    }
    render() {
        const { plans, isLoading, searchValue } = this.state;
        if (isLoading) {
            return <Spinner />;
        }
        return (<View style={{ width: '100%', height: '100%' }}>
        <View style={{ height: 56, paddingHorizontal: ThemeManager.style.contentPaddingValue, paddingVertical: 4 }}>
          <SearchBar searchOnChange={this.onSearch} searchBarValue={searchValue} maxWidth={526}/>
        </View>
        <FlatList key={`plans_${plans.length}`} style={{
                height: '100%',
                width: '100%',
            }} renderItem={this.renderRow} data={plans}/>
      </View>);
    }
}
