import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeHolidayRegions } from 'upmesh-core/src/client/commands/companies/holiday/ChangeHolidayRegions';
import { DeleteHoliday } from 'upmesh-core/src/client/commands/companies/holiday/DeleteHoliday';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddOrChangeHolidayToCompanyDialog } from './AddOrChangeHolidayToCompanyDialog';
import { HolidayImportDialog } from './HolidayImportDialog';
const noGroupImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class CompanyHolidaysView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.updateWrapper = () => {
            if (this.updateTimeOut != null)
                clearTimeout(this.updateTimeOut);
            this.updateTimeOut = setTimeout(() => {
                this.updateAll().catch((err) => console.error(err));
            }, 300);
        };
        this.updateAll = async () => {
            const { company } = this.props;
            const holidays = await UpmeshClient.instance.modals.holiday.get({
                filter: `deleted ne true and companyId eq '${company.id}'`,
            });
            const companyRegions = await UpmeshClient.instance.modals.region.get({
                filter: `companyId eq '${company.id}' and deleted ne true`,
            });
            const data = [];
            for (const holiday of holidays) {
                const filteredString = [];
                companyRegions.forEach((element) => {
                    if (holiday.regionIds.includes(element.id)) {
                        filteredString.push(element.region);
                    }
                });
                data.push({
                    id: holiday.id,
                    name: holiday.name,
                    regionIds: holiday.regionIds,
                    regionNames: filteredString.join(', '),
                    wageType: holiday.wageType,
                    date: holiday.date,
                    type: holiday.type,
                    amount: holiday.amount,
                    annual: holiday.annual,
                    regions: companyRegions.filter((region) => holiday.regionIds.indexOf(region.id) > -1).length,
                    entity: holiday,
                });
            }
            const companyRegionListItems = [];
            for (let i = 0; i < companyRegions.length; i += 1) {
                const region = companyRegions[i];
                companyRegionListItems.push({
                    title: region.region,
                    secondTextLine: `${region.region} ${region.country}`,
                    data: region.id,
                });
            }
            this.setState({ data, companyRegionListItems });
        };
        this.removeHoliday = (holiday) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveHolidayQuestion', { holiday }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeHolidayNow(holiday.entity)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeHolidayNow = (holiday) => (_e) => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new DeleteHoliday({}, holiday.id, CurrentUser.token);
                remove
                    .execute(ClientStore.commandStore)
                    .catch((err) => {
                    console.error(err);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(err, I18n.m) });
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.openFab = () => {
            if (Fab.instance == null) {
                window.setTimeout(this.openFab, 100);
                return;
            }
            const permitted = this.myRole() === 'admin';
            Fab.instance.open({
                fabIcon: 'plus',
                fabIconOpen: 'close',
                small: false,
                fabColor: ThemeManager.style.brandPrimary,
                fabColorOpen: ThemeManager.style.brandSecondary,
                fabActions: [
                    {
                        text: I18n.m.getMessage('companyAddHolidayDialogTitle'),
                        icon: 'plus',
                        onPress: permitted
                            ? this.openAddDialog
                            : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
                    },
                    {
                        text: I18n.m.getMessage('companyImportHolidayHeadline'),
                        icon: 'calendar-import',
                        onPress: permitted
                            ? this.importHolidays
                            : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
                    },
                ],
            });
        };
        this.openAddDialog = (_e) => {
            const { company } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddOrChangeHolidayToCompanyDialog company={company}/>,
                contentPadding: false,
                scrollable: false,
            });
        };
        this.openEditDialog = (holiday) => (_e) => {
            if (this.myRole() === 'admin') {
                const { company } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <AddOrChangeHolidayToCompanyDialog holiday={holiday.entity} company={company}/>,
                    contentPadding: false,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.importHolidays = (_e) => {
            const { company } = this.props;
            DialogBetween.instance?.open({
                content: <HolidayImportDialog companyId={company.id}/>,
                scrollable: false,
                fullscreenResponsive: true,
                contentPadding: false,
            });
        };
        this.openChangeHolidayAffiliation = (_item) => (_e) => {
            const { companyRegionListItems } = this.state;
            const list = [...companyRegionListItems];
            for (const p of list) {
                p.selected = p.data != null && _item.regionIds?.includes(p.data);
            }
            Dialog.instance?.open({
                content: (<ChipDialogForm key="holidayMemberDialogForm" title={I18n.m.getMessage('companyHolidayAffiliateWithRegion')} items={list} onCancel={() => Dialog.instance?.close()} multiselect onSave={(e) => {
                        this.onSaveHolidayAffiliation(e, _item).catch((err) => console.error(err));
                    }} canAddNewChips={false} searchBarPlaceholder={I18n.m.getMessage('search')}/>),
                scrollable: true,
                contentPadding: false,
                fullscreenResponsive: true,
            });
        };
        this.onSaveHolidayAffiliation = async (items, holiday) => {
            let changed = false;
            const changedRegionIds = [];
            for (const p of items) {
                const tmp = p.selected !== holiday.regionIds.includes(p.data);
                if (tmp) {
                    changed = true;
                }
                if (p.selected) {
                    changedRegionIds.push(p.data);
                }
            }
            if (changed) {
                const c = new ChangeHolidayRegions({
                    regionIds: changedRegionIds,
                }, holiday.id, CurrentUser.token);
                c.execute(ClientStore.commandStore).catch((e) => {
                    console.error(e);
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                });
            }
            Dialog.instance?.close();
        };
        this.renderYearly = (item, columnData, index) => {
            const { style } = columnData;
            const resultString = item.annual ? I18n.m.getMessage('yes') : I18n.m.getMessage('no');
            return (<View key={`cellStatus-${item.id}-${index}`} style={[{ justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 16 }, style]}>
        <MaterialText ellipsizeMode="tail" numberOfLines={1}>
          {resultString}
        </MaterialText>
      </View>);
        };
        this.renderDayOrHour = (item, columnData, index) => {
            const { style } = columnData;
            const resultString = item.type === 'day' ? I18n.m.getMessage('day') : I18n.m.getMessage('hours');
            return (<View key={`cellStatus-${item.id}-${index}`} style={[{ justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 16 }, style]}>
        <MaterialText ellipsizeMode="tail" numberOfLines={1}>
          {resultString}
        </MaterialText>
      </View>);
        };
        this.renderDate = (item, columnData, index) => {
            const { style } = columnData;
            let date = I18n.m.dateCurrent.localeDateString(item.date);
            if (item.annual) {
                const start = date.indexOf(new Date(item.date).getFullYear().toString());
                if (start != null) {
                    if (start > 0) {
                        date = date.slice(0, start - 1) + date.slice(start + 5, date.length);
                    }
                    else {
                        date = date.slice(start + 5, date.length);
                    }
                }
            }
            return (<View key={`cellDate-${item.id}-${index}`} style={[{ justifyContent: 'center', alignItems: 'flex-end', paddingHorizontal: 16 }, style]}>
        <MaterialText ellipsizeMode="tail" numberOfLines={1} textAlign="right" additionalTextStyle={{ alignSelf: 'flex-end' }}>
          {date}
        </MaterialText>
      </View>);
        };
        this.state = {
            myRole: this.myRole(),
            companyRegionListItems: [],
            data: [],
        };
    }
    componentDidMount() {
        const { company } = this.props;
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyHolidaysView',
            readModelName: 'Holiday',
            callback: this.updateWrapper,
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyHolidaysViewCompany',
            readModelName: 'Company',
            entityId: company.id,
            callback: this.updateWrapper,
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'companyHolidaysViewCompanyRegion',
            readModelName: 'Region',
            callback: this.updateWrapper,
        });
        this.openFab();
        this.updateAll().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('Holiday', 'CompanyHolidaysView');
        UpmeshClient.eventDispatcher.detach('Company', 'CompanyHolidaysViewCompany');
        UpmeshClient.eventDispatcher.detach('Region', 'CompanyHolidaysViewCompanyRegion');
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { company, size } = this.props;
        const { data, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(60);
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('holidays')}
          </MaterialText>
        </View>
        <Card style={{ width: '100%', height: tableMaxHeight }}>
          <Table tableName="CompanyHolidays" actionItemsLength={3} actions={myRole === 'admin'
                ? (_item) => [
                    {
                        icon: 'pencil-outline',
                        onAction: this.openEditDialog,
                        toolTip: I18n.m.getMessage('edit'),
                    },
                    {
                        icon: 'delete',
                        onAction: this.removeHoliday,
                        toolTip: I18n.m.getMessage('delete'),
                        disabled: _item.id === company.id,
                    },
                    {
                        icon: 'earth',
                        onAction: this.openChangeHolidayAffiliation,
                        toolTip: I18n.m.getMessage('companyHolidayAffiliateWithRegion'),
                    },
                ]
                : undefined} maxHeight={tableMaxHeight - 16} emptyTableImage={noGroupImg} emptyTableHint={I18n.m.getMessage('companyHolidayNoDataHint')} emptyTableText={I18n.m.getMessage('companyHolidayNoDataText')} data={data} sortBy="name" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('companyHolidayName'),
                    keyInData: 'name',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('companyHolidayWageType'),
                    keyInData: 'wageType',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('date'),
                    keyInData: 'date',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'Date',
                    cellRenderer: this.renderDate,
                },
                {
                    title: I18n.m.getMessage('companyHolidayDayOrHours'),
                    keyInData: 'type',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                    cellRenderer: this.renderDayOrHour,
                },
                {
                    title: I18n.m.getMessage('quantity'),
                    keyInData: 'amount',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'number',
                },
                {
                    title: I18n.m.getMessage('companyHolidayYearly'),
                    keyInData: 'annual',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                    cellRenderer: this.renderYearly,
                },
                {
                    title: I18n.m.getMessage('regions'),
                    keyInData: 'regionNames',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
            ]}/>
        </Card>
      </View>);
    }
}
