import { ReadModelChangedDefaultClientDispatcher } from 'cqrs-core/src/client/query/ReadModelChangedDefaultClientDispatcher';
import { ServerConnection } from 'cqrs-core/src/core/ServerConnection';
import { Url } from 'cqrs-shared/src/uri/Url';
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { FileHandler } from 'materialTheme/src/FileHandler';
import { ClientDBadapter } from 'materialTheme/src/odataDB/loki/ClientDBadapterSharedWorker';
import { SecureLocalStoreB } from 'materialTheme/src/securestore/SecureLocalStoreB';
import { LokiDBOptions } from 'odatarepos/src/db/loki/LokiDataBase';
import { LokiODataDB } from 'odatarepos/src/db/loki/LokiODataDB';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { AsyncEvent } from 'ts-events';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { WorkerInterface } from 'upmesh-auth-core/src/client/WorkerInterface';
import { ClientDB } from 'upmesh-auth-core/src/ClientDB';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { Config } from './Config';
import { ConfigAll } from './ConfigAll';
import { SharedWorkerStorage } from './SharedWorkerStorage';
export class SharedWorkerWeb extends WorkerInterface {
    constructor() {
        super(...arguments);
        this.startUploads = () => {
            this.getUploadDB()
                .then((localDB) => {
                return Uploads.instance.startUploads(localDB);
            })
                .then(() => {
                console.debug('start Uploads sw');
            })
                .catch((err) => console.debug('cant start Upload Service', err));
        };
        this.messagesToClientCounter = 0;
    }
    onLogout() {
        SecureLocalStoreB.instance
            .removeItem(`tt_${Url.getURLfromString(Config.b2cURL).host}`)
            .catch((e) => console.debug('cant set token', e));
        AuthClient.instance.logOut(true).catch((err) => console.warn('logout error', err));
        Uploads.instance.stopUploads().catch((err) => console.debug('cant stop Upload Service', err));
    }
    async onWorkerLogout() {
        try {
            await AuthClient.instance.logOut(true);
        }
        catch (err) {
            console.warn('logout error', err);
        }
    }
    startSyncInWorker() {
        AuthClient.instance.startSync(true).catch((err) => console.debug('cant start sync in worker', err));
    }
    async getUploadDB() {
        if (this._localdb == null) {
            this._localdb = new LokiODataDB();
            const options = new LokiDBOptions(new FileHandler(`${Config.b2cURL}/files`));
            await this._localdb.initDB(options);
        }
        return this._localdb;
    }
    async freshDatabase() {
        return AuthClient.instance.freshDatabase();
    }
    onLogin(token) {
        if (token) {
            AuthClient.instance.setToken({ token }).catch((err) => console.warn('logout error', err));
            AuthClient.instance.syncDispatcher.syncStop.detach(this.startUploads);
            AuthClient.instance.syncDispatcher.syncStop.attach(this.startUploads);
            setTimeout(() => {
                this.startUploads();
            }, 5000);
        }
        else {
            this.onLogout();
        }
    }
    postToWorker(msg) {
        if (SharedWorkerWeb.port != null)
            SharedWorkerWeb.port.postMessage(msg);
    }
    async postToWorkerWithAnswer(msg) {
        return new Promise((resolve) => {
            if (SharedWorkerWeb.port != null) {
                const messageId = uuid.v4();
                let to;
                const attacher = (d) => {
                    if (d.messageId === messageId) {
                        this.onResult.detach(attacher);
                        if (to)
                            clearTimeout(to);
                        resolve(d.data);
                    }
                };
                to = setTimeout(() => {
                    if (to)
                        clearTimeout(to);
                    this.onResult.detach(attacher);
                    resolve(null);
                }, 60000);
                this.onResult.attach(attacher);
                SharedWorkerWeb.port.postMessage(`${msg}µ${messageId}`);
            }
            else {
                resolve(null);
            }
        });
    }
    postToClient(msg) {
        this.messagesToClientCounter += 1;
        SharedWorkerWeb.browserInstances.forEach((instance) => {
            instance.postMessage(msg);
        });
    }
    async createWorkerConnections(token) {
        if (this.init === false) {
            this.init = 'started';
            try {
                if (this.sc == null) {
                    const clientversion = Config.getVersion().split('@')[1];
                    this.sc = new ServerConnection({
                        remoteOptions: {
                            apiUrl: Config.b2cURL,
                            authToken: token,
                        },
                        autoConnect: false,
                        query: { clientversion },
                    });
                    this.sc.onConnectionChanged.attach((connected) => {
                        this.postToClient(`clientµallµconnectionµ${connected ? '1' : ''}`);
                    });
                    await this.sc.connect(2000);
                    ReadModelChangedDefaultClientDispatcher.instance.attach({
                        readModelName: 'any',
                        callback: (e) => {
                            this.postToClient(`clientµallµreadmodelµ${e != null
                                ? JSON.stringify({ ...e, entities: e.entities.size > 0 ? Array.from(e.entities) : undefined })
                                : ''}`);
                        },
                    });
                    const reInitDB = async (userId) => {
                        console.log('Database: reinitDB !!!!!', userId);
                        if (this.lastUserId === userId && userId.length > 0 && this.cb) {
                            return { db: this.cb.localDb, dbOptions: {} };
                        }
                        if (this.lastUserId !== userId && userId.length > 0) {
                            this.cb = new ClientDB(new LokiODataDB());
                            console.log('initializeDB 0 1');
                            const adapter = await ClientDBadapter.getAdapter(userId);
                            console.log('initializeDB 0 2');
                            const dbOptions = new LokiDBOptions(new FileHandler(`${Config.b2cURL}/files`), `${userId}.json`, adapter);
                            this.lastUserId = userId;
                            return { db: this.cb.localDb, dbOptions };
                        }
                        if (userId.length === 0)
                            return { db: undefined, dbOptions: {} };
                        return { db: this.cb?.localDb, dbOptions: {} };
                    };
                    if (this.cb == null) {
                        this.lastUserId = '';
                    }
                    AuthClient.onLogin(() => {
                        console.log('On Login!');
                    });
                    console.log(`INIT UPMESHCLIENT, clientDB: ${this.cb == null ? 'nein' : 'ja'}`);
                    await UpmeshClient.instance.init({
                        serverConnection: () => this.sc,
                        reInitDB,
                        remoteUrl: Config.b2cURL,
                        startSync: true,
                        discardOnErrors: -1,
                    }, token);
                    console.log('UpmeshClient.instance.syncDispatcher', UpmeshClient.instance.syncDispatcher);
                    UpmeshClient.instance.syncDispatcher.syncStop.attach(() => {
                        this.postToClient('clientµallµsyncStopped');
                    });
                    UpmeshClient.instance.syncDispatcher.syncStart.attach(() => {
                        this.postToClient(`clientµallµsyncStarted`);
                    });
                    UpmeshClient.instance.syncDispatcher.syncStatus.attach((s) => {
                        this.postToClient(`clientµallµsyncStatusµ${JSON.stringify(s)}`);
                    });
                    AuthClient.onLogin((e) => {
                        this.postToClient(`clientµallµonLoginµ${JSON.stringify(e)}`);
                    });
                }
                await AuthClient.instance.logIn({ token });
                this.commandStore = AuthClient.instance.commandStore;
                if (!this.sc.isConnected)
                    await this.sc.connect();
                this.initError = null;
                this.init = true;
            }
            catch (e) {
                console.debug('cant login in worker', e);
                this.init = 'error';
                this.initError = e;
            }
        }
    }
}
SharedWorkerWeb.instance = new SharedWorkerWeb();
SharedWorkerWeb.browserInstances = [];
onconnect = (e) => {
    AsyncEvent.setScheduler(function (callback) {
        setTimeout(callback, 0);
    });
    require('./setImmediate');
    console.debug('starting Sharedworker', e);
    const port = e.ports[0];
    ConfigAll.init()
        .then(() => {
        SharedWorkerWeb.browserInstances.push(port);
        port.addEventListener('message', (m) => WorkerInterface.instance.toWorkerMessage(m.data));
        port.start();
        const s = new SharedWorkerStorage(SharedWorkerWeb.instance);
        return s.initStorage();
    })
        .then((s) => {
        console.debug('started Simplestorage', e);
        SimpleStorage.init(s);
        port.postMessage('startedWorker');
    })
        .catch((err) => {
        console.error('cant start Shared Worker');
        throw err;
    });
};
