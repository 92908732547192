import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../CurrentProject';
import { DashBoardItem } from './DashBoardItem';
export class FilesItem extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.addTeamMember = async (e) => {
            Routing.instance.openDialog('addTeam')(e);
        };
        this.loadDashData = () => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null && this.mounted) {
                const canRead = true;
                const storedFiles = CurrentProject.instance.getCurrentFiles();
                if (storedFiles != null) {
                    const counts = storedFiles.length;
                    this.setState({ counts, projectId, canRead }, () => { });
                }
                else {
                    this.setState({ projectId, canRead, counts: 0 }, () => { });
                }
            }
        };
        this.state = { counts: 0, projectId: '', canRead: false };
    }
    componentDidMount() {
        this.mounted = true;
        CurrentProject.storedFilesChanged.attach(this.loadDashData);
        this.loadDashData();
    }
    componentWillUnmount() {
        this.mounted = false;
        CurrentProject.storedFilesChanged.detach(this.loadDashData);
    }
    render() {
        const { counts, projectId, canRead } = this.state;
        if (!canRead) {
            return null;
        }
        return (<Ripple style={{ width: '100%', paddingBottom: 16 }} onPress={Routing.instance.goToButton(`/projects/${projectId}/files`)}>
        <View style={{ width: '100%' }}>
          <DashBoardItem title={I18n.m.getMessage('menuProjectFiles')} icon="text-box-multiple-outline" iconIconMoon={false} iconColor="#536976" counts={counts}/>
        </View>
      </Ripple>);
    }
}
