import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { CheckboxListItem } from 'materialTheme/src/theme/components/CheckboxListItem';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { FilterChips } from 'materialTheme/src/theme/components/chips/FilterChips';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { DateRangeInput } from 'materialTheme/src/theme/components/forminput/DateRangeInput';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { BackHandler, Platform, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { TicketStatus, TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { TicketFilter } from 'upmesh-core/src/client/query/filter/TicketFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { PlansFilterDialog } from '../plans/PlansFilterDialog';
import { CurrentProject } from '../project/CurrentProject';
const styles = {
    headerContainer: {
        flex: 0,
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 0,
        paddingTop: 5,
        paddingRight: Platform.OS === 'ios' ? 35 : 40,
    },
    chipContainer: { flexDirection: 'row' },
    scrollContainer: { flex: 1 },
    checkboxListItemContainer: { marginLeft: -16 },
    formInputPicketContainer: {},
    spacer: { height: 6 },
};
export class TicketDialogFilter extends PureComponent {
    static open(availableTickets, callback, currentFilter, projectId, resetDay, saveWithNoResult) {
        if (DialogBetween.instance != null) {
            const content = (<TicketDialogFilter availableTickets={availableTickets} projectId={projectId} callback={callback} currentFilter={currentFilter} resetToDay={resetDay} saveWithNoResult={saveWithNoResult}/>);
            DialogBetween.instance?.open({
                closeOnTouchOutside: false,
                content,
                contentPadding: false,
                scrollable: false,
                fullscreenResponsive: true,
            });
        }
    }
    constructor(props) {
        super(props);
        this.onSelect = () => {
            const { callback } = this.props;
            if (callback != null) {
                DialogBetween.instance?.close(() => {
                    const currentFilter = this.createCurrentTicketFilter();
                    callback(currentFilter);
                });
            }
        };
        this.calculateFiltered = () => {
            const { availableTickets } = this.props;
            this.setState({ calculateAmount: true }, () => {
                const filter = this.createCurrentTicketFilter();
                TicketFilter.filterTickets(availableTickets, filter)
                    .then((filteredTickets) => {
                    this.setState({ calculateAmount: false, amountSelected: filteredTickets.length });
                })
                    .catch((e) => {
                    console.debug('cant filter tickets', e);
                    this.setState({ calculateAmount: false, amountSelected: 0 });
                });
            });
        };
        this.createCurrentTicketFilter = () => {
            const currentFilter = new TicketFilter();
            const { selectedProjects, selectedTags, selectedTicketTypes, selectedCrafts, selectedPersons, selectedStatuus, selectedPersonsWhere, completionSmallerThan, showArchivedTickets, showDeletedTickets, selectedSub, subTicketOptions, showNotAssigendTickets, showMyTickets, intervalStart, intervalEnd, customFieldsFilter, } = this.state;
            if (selectedTags != null && selectedTags.length > 0) {
                currentFilter.t = [];
                selectedTags.forEach((t) => {
                    if (t.id != null)
                        currentFilter.t.push(t.id);
                });
            }
            if (selectedTicketTypes != null && selectedTicketTypes.length > 0) {
                currentFilter.tt = [];
                selectedTicketTypes.forEach((t) => {
                    if (t.id != null)
                        currentFilter.tt.push(t.id);
                });
            }
            if (selectedCrafts != null && selectedCrafts.length > 0) {
                currentFilter.c = [];
                selectedCrafts.forEach((t) => {
                    if (t.id != null)
                        currentFilter.c.push(t.id);
                });
            }
            if ((selectedPersons != null && selectedPersons.length > 0) || showMyTickets || showNotAssigendTickets) {
                currentFilter.u = [];
                if (showMyTickets)
                    currentFilter.u.push(CurrentUser.userId);
                if (showNotAssigendTickets)
                    currentFilter.u.push('notAssigned');
                if (selectedPersons != null && selectedPersons.length > 0) {
                    for (let i = 0; i < selectedPersons.length; i += 1) {
                        const c = selectedPersons[i];
                        if (c != null && c.id != null) {
                            currentFilter.u.push(c.id);
                        }
                    }
                }
                if (selectedPersonsWhere.length > 0) {
                    currentFilter.u2 = selectedPersonsWhere;
                }
            }
            if (selectedProjects != null && selectedProjects.length > 0) {
                currentFilter.p = [];
                if (selectedProjects != null && selectedProjects.length > 0) {
                    for (let i = 0; i < selectedProjects.length; i += 1) {
                        const c = selectedProjects[i];
                        if (c != null && c.id != null) {
                            currentFilter.p.push(c.id);
                        }
                    }
                }
            }
            if (customFieldsFilter != null && customFieldsFilter.length > 0) {
                currentFilter.cf = [];
                if (customFieldsFilter != null && customFieldsFilter.length > 0) {
                    for (let i = 0; i < customFieldsFilter.length; i += 1) {
                        currentFilter.cf.push({ ...customFieldsFilter[i] });
                    }
                }
            }
            if (selectedStatuus.length > 0) {
                currentFilter.s = selectedStatuus;
            }
            currentFilter.st = completionSmallerThan != null ? completionSmallerThan : undefined;
            if (showArchivedTickets === true) {
                currentFilter.a = true;
            }
            if (showDeletedTickets === true) {
                currentFilter.d = true;
            }
            if (selectedSub > 0) {
                currentFilter.sub = subTicketOptions[selectedSub].value;
            }
            currentFilter.is = intervalStart != null ? intervalStart : undefined;
            currentFilter.ie = intervalEnd != null ? intervalEnd : undefined;
            return currentFilter;
        };
        this.onReset = () => {
            const { resetToDay } = this.props;
            let intervalStart;
            let intervalEnd;
            if (resetToDay != null) {
                const r = new Date(resetToDay);
                intervalStart = new Date(r.getFullYear(), r.getMonth(), r.getDate(), 0, 0, 0, 0).getTime();
                intervalEnd = new Date(r.getFullYear(), r.getMonth(), r.getDate() + 1, 0, 0, 0, -1).getTime();
            }
            this.setState({
                selectedProjects: [],
                selectedPersonsWhere: [],
                selectedTags: [],
                selectedCrafts: [],
                selectedTicketTypes: [],
                selectedStatuus: [],
                selectedPersons: [],
                completionSmallerThan: undefined,
                showArchivedTickets: undefined,
                showDeletedTickets: undefined,
                showMyTickets: false,
                showNotAssigendTickets: false,
                intervalStart,
                intervalEnd,
                customFieldsFilter: [],
                selectedSub: 0,
            }, this.calculateFiltered);
        };
        this.preventEnterNew = (_e) => { };
        this.onPressMyTickets = () => {
            const { showMyTickets } = this.state;
            this.setState({ showMyTickets: !showMyTickets }, this.calculateFiltered);
        };
        this.onPressNotAssigendTickets = () => {
            const { showNotAssigendTickets } = this.state;
            this.setState({ showNotAssigendTickets: !showNotAssigendTickets }, this.calculateFiltered);
        };
        this.setPersons = (e) => {
            this.setState({ selectedPersons: e }, this.calculateFiltered);
        };
        this.changedStatuus = (_ind, chip) => {
            const { selectedStatuus } = this.state;
            const index = selectedStatuus.indexOf(`${chip.data}`);
            if (chip.checked === true && index === -1) {
                selectedStatuus.push(`${chip.data}`);
            }
            else if (chip.checked !== true && index >= 0) {
                selectedStatuus.splice(index, 1);
            }
            this.setState({ selectedStatuus }, this.calculateFiltered);
        };
        this.changedRoles = (_ind, chip) => {
            const { selectedPersonsWhere } = this.state;
            const index = selectedPersonsWhere.indexOf(`${chip.data}`);
            if (chip.checked === true && index === -1) {
                selectedPersonsWhere.push(`${chip.data}`);
            }
            else if (chip.checked !== true && index >= 0) {
                selectedPersonsWhere.splice(index, 1);
            }
            this.setState({ selectedPersonsWhere }, this.calculateFiltered);
        };
        this.setTags = (e) => {
            this.setState({ selectedTags: e }, this.calculateFiltered);
        };
        this.setProjects = (e) => {
            this.setState({ selectedProjects: e }, this.calculateFiltered);
        };
        this.setTicketTypes = (e) => {
            this.setState({ selectedTicketTypes: e }, this.calculateFiltered);
        };
        this.setCrafts = (e) => {
            this.setState({ selectedCrafts: e }, this.calculateFiltered);
        };
        this.changeCompletionSmallerThan = (newDate) => {
            const completionSmallerThan = newDate == null ? undefined : typeof newDate === 'number' ? newDate : newDate.getTime();
            this.setState({ completionSmallerThan }, this.calculateFiltered);
        };
        this.setCompletionSmallerThan = (newDate) => (_e) => {
            const completionSmallerThan = newDate == null ? undefined : typeof newDate === 'number' ? newDate : newDate.getTime();
            this.setState({ completionSmallerThan }, this.calculateFiltered);
        };
        this.showArchivedTickets = (showArchivedTickets) => {
            this.setState({ showArchivedTickets }, this.calculateFiltered);
        };
        this.showDeletedTickets = (showDeletedTickets) => {
            this.setState({ showDeletedTickets }, this.calculateFiltered);
        };
        this.hideSubTickets = (val) => {
            this.setState({ selectedSub: val }, this.calculateFiltered);
        };
        this.closeDialog = (_e) => {
            const { callback, currentFilter } = this.props;
            DialogBetween.instance?.close(() => {
                if (callback != null) {
                    callback(currentFilter == null ? new TicketFilter() : currentFilter);
                }
            });
        };
        this.state = {
            customFieldsFilterAvalaible: [],
            availableTeammember: [],
            init: false,
            projectTags: [],
            selectedTags: [],
            projectCrafts: [],
            selectedCrafts: [],
            projectTicketTypes: [],
            selectedTicketTypes: [],
            projects: [],
            selectedProjects: [],
            selectedPersons: [],
            selectedPersonsWhere: [],
            selectedStatuus: [],
            showArchivedTickets: false,
            showDeletedTickets: false,
            selectedSub: 0,
            subTicketOptions: [],
            showMyTickets: false,
            showNotAssigendTickets: false,
            calculateAmount: true,
            amountSelected: 0,
            projectTagGroups: [],
            customFieldsFilter: [],
        };
    }
    componentDidMount() {
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
            DialogBetween.instance?.close();
            return true;
        });
        this.init(this.props).catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        if (this.backHandler)
            this.backHandler.remove();
    }
    async getCustomFieldsFilter() {
        const { availableTickets } = this.props;
        const fields = [];
        const availableProjectIds = [];
        availableTickets.forEach((t) => {
            if (t.projectId != null && t.projectId.length > 0) {
                if (availableProjectIds.indexOf(t.projectId) === -1)
                    availableProjectIds.push(t.projectId);
            }
        });
        for (let i = 0; i < availableProjectIds.length; i += 1) {
            try {
                const project = await UpmeshClient.instance.modals.project.getById(availableProjectIds[i]);
                if (project.ticketLayouts != null && project.ticketLayouts.length > 0) {
                    project.ticketLayouts.forEach((a) => {
                        if (a.fields != null && a.fields.length > 0) {
                            a.fields.forEach((b) => {
                                if (b.customField && (b.customField.type === 'Date' || b.customField.type === 'DateRange')) {
                                    const i = fields.findIndex((c) => c.id === b.id);
                                    if (i === -1)
                                        fields.push({ id: b.id, type: b.customField.type, label: b.label });
                                }
                                else if (b.customField &&
                                    (b.customField.type === 'List' ||
                                        b.customField.type === 'MultiselectList' ||
                                        b.customField.type === 'CheckboxList')) {
                                    const i = fields.findIndex((c) => c.id === b.id);
                                    const options = [];
                                    const list = b.customField.options['list'] ? b.customField.options['list'] : [];
                                    list.forEach((l) => {
                                        options.push({
                                            title: l,
                                            id: l,
                                        });
                                    });
                                    if (i === -1)
                                        fields.push({ id: b.id, type: b.customField.type, label: b.label, options });
                                }
                            });
                        }
                    });
                }
            }
            catch (e) {
                console.debug('cant get projects', availableProjectIds[i]);
            }
        }
        fields.sort((a, b) => a.label.localeCompare(b.label));
        return fields;
    }
    static getTags(availableTickets) {
        const projectTags = [];
        const tags = [];
        availableTickets.forEach((t) => {
            if (t.tags != null && t.tags.length > 0) {
                t.tags.forEach((tag) => {
                    if (tags.findIndex((tt) => tt.tagName === tag.tagName) === -1)
                        tags.push(tag);
                });
            }
        });
        for (let i = 0; i < tags.length; i += 1) {
            projectTags.push({
                id: tags[i].tagName,
                backgroundColor: tags[i].color,
                groupId: tags[i].groupName,
                title: tags[i].tagName,
                textColor: '#FFFFFF',
            });
        }
        return projectTags;
    }
    async getTypesOrCrafts(which) {
        const { availableTickets } = this.props;
        const values = [];
        const available = ['null'];
        availableTickets.forEach((t) => {
            if (t[which] != null && t[which].length > 0) {
                if (available.indexOf(t[which]) === -1)
                    available.push(t[which]);
            }
        });
        for (let i = 0; i < available.length; i += 1) {
            values.push({
                id: available[i],
                title: available[i] === 'null'
                    ? which === 'craft'
                        ? I18n.m.getMessage('ticketsCraftNotSelected')
                        : I18n.m.getMessage('ticketTypeNotSelected')
                    : available[i],
            });
        }
        return values;
    }
    async getProjects() {
        const { availableTickets } = this.props;
        const values = [];
        const availableProjectIds = [];
        availableTickets.forEach((t) => {
            if (t.projectId != null && t.projectId.length > 0) {
                if (availableProjectIds.indexOf(t.projectId) === -1)
                    availableProjectIds.push(t.projectId);
            }
        });
        const projects = await UpmeshClient.instance.modals.project.get({
            filter: `id in ${JSON.stringify(availableProjectIds)}`,
        });
        for (const p of projects) {
            values.push({
                id: p.id,
                title: p.title,
            });
        }
        return { projects, chips: values };
    }
    async getPersons() {
        const { availableTickets } = this.props;
        const values = [];
        const availableUserIds = [];
        availableTickets.forEach((t) => {
            if (t.assignedToUserId != null && t.assignedToUserId.length > 0 && t.assignedToUserId !== CurrentUser.userId) {
                if (availableUserIds.indexOf(t.assignedToUserId) === -1)
                    availableUserIds.push(t.assignedToUserId);
            }
            if (t.approverUserId != null && t.approverUserId.length > 0 && t.approverUserId !== CurrentUser.userId) {
                if (availableUserIds.indexOf(t.approverUserId) === -1)
                    availableUserIds.push(t.approverUserId);
            }
            if (t.creator != null && t.creator.length > 0 && t.creator !== CurrentUser.userId) {
                if (availableUserIds.indexOf(t.creator) === -1)
                    availableUserIds.push(t.creator);
            }
            if (t.watchers != null && t.creator.length > 0) {
                t.watchers.forEach((w) => {
                    if (w.userId !== CurrentUser.userId && availableUserIds.indexOf(w.userId) === -1) {
                        availableUserIds.push(w.userId);
                    }
                });
            }
        });
        for (let i = 0; i < availableUserIds.length; i += 1) {
            try {
                const user = await AuthClient.instance.modals.user.getById(availableUserIds[i]);
                values.push({
                    id: user.id,
                    title: user.getFullName(),
                });
            }
            catch (e) {
                console.debug('cant get user', availableUserIds[i]);
            }
        }
        return values;
    }
    static getSelectedChipsByIds(fromTags, ids) {
        const selectedTags = [];
        if (ids != null && ids.length > 0) {
            for (let i = 0; i < ids.length; i += 1) {
                for (let j = 0; j < fromTags.length; j += 1) {
                    if (ids[i] === fromTags[j].id) {
                        selectedTags.push(fromTags[j]);
                        break;
                    }
                }
            }
        }
        return selectedTags;
    }
    async init(_p) {
        const { currentFilter, projectId, availableTickets } = this.props;
        const projectTags = TicketDialogFilter.getTags(availableTickets);
        const projectTicketTypes = await this.getTypesOrCrafts('type');
        const selectedTicketTypes = currentFilter != null && currentFilter.tt != null
            ? TicketDialogFilter.getSelectedChipsByIds(projectTicketTypes, currentFilter.tt)
            : [];
        const projectCrafts = await this.getTypesOrCrafts('craft');
        const selectedCrafts = currentFilter != null && currentFilter.c != null
            ? TicketDialogFilter.getSelectedChipsByIds(projectCrafts, currentFilter.c)
            : [];
        const tags = currentFilter != null && currentFilter.t != null ? currentFilter.t : [];
        const selectedTags = TicketDialogFilter.getSelectedChipsByIds(projectTags, tags);
        const availableTeammember = await this.getPersons();
        const persons = currentFilter != null && currentFilter.u != null ? currentFilter.u : [];
        const selectedPersonsWhere = currentFilter != null && currentFilter.u2 != null ? currentFilter.u2 : [];
        const selectedPersons = TicketDialogFilter.getSelectedChipsByIds(availableTeammember, persons);
        let showMyTickets = false;
        let showNotAssigendTickets = false;
        if (currentFilter != null && currentFilter.u != null && currentFilter.u.length > 0) {
            showMyTickets = currentFilter.u.indexOf(CurrentUser.userId) > -1;
            showNotAssigendTickets = currentFilter.u.indexOf('notAssigned') > -1;
        }
        const selectedStatuus = currentFilter != null && currentFilter.s != null ? currentFilter.s : [];
        const completionSmallerThan = currentFilter != null && currentFilter.st != null ? currentFilter.st : undefined;
        const showArchivedTickets = currentFilter != null && currentFilter.a != null ? currentFilter.a : undefined;
        const showDeletedTickets = currentFilter != null && currentFilter.d != null ? currentFilter.d : undefined;
        const intervalStart = currentFilter != null && currentFilter.is != null ? currentFilter.is : undefined;
        const intervalEnd = currentFilter != null && currentFilter.ie != null ? currentFilter.ie : undefined;
        const c = CurrentProject.instance.getCurrentProject();
        const projects = projectId != null ? { projects: c != null ? [c] : [], chips: [] } : await this.getProjects();
        const currentProjects = currentFilter != null && currentFilter.p != null ? currentFilter.p : [];
        const selectedProjects = TicketDialogFilter.getSelectedChipsByIds(projects.chips, currentProjects);
        const projectTagGroups = PlansFilterDialog.getTagsGroups(projects != null && projects.projects != null && projects.projects.length > 0 ? projects.projects : []);
        const customFieldsFilterAvalaible = await this.getCustomFieldsFilter();
        const customFieldsFilter = currentFilter && currentFilter.cf ? currentFilter.cf : [];
        const parentTickets = availableTickets
            .filter((t) => t.subTicketsCounter > 0)
            .map((t) => ({ value: t.id, title: t.title }));
        const subTicketOptions = [
            { value: undefined, title: `\u200A${I18n.m.getMessage('ticketFilterTicketHierarchyAll')}` },
            { value: '1', title: `\u200A${I18n.m.getMessage('ticketFilterTicketHierarchyOnlySub')}` },
            { value: '2', title: `\u200A${I18n.m.getMessage('ticketFilterTicketHierarchyOnlyMain')}` },
            ...parentTickets,
        ];
        this.setState({
            customFieldsFilter,
            customFieldsFilterAvalaible,
            availableTeammember,
            completionSmallerThan,
            projectTags,
            projects: projects.chips,
            projectTagGroups,
            selectedProjects,
            projectTicketTypes,
            selectedTicketTypes,
            projectCrafts,
            selectedCrafts,
            selectedStatuus,
            selectedPersonsWhere,
            selectedTags,
            showArchivedTickets,
            showDeletedTickets,
            subTicketOptions,
            selectedPersons,
            showMyTickets,
            showNotAssigendTickets,
            intervalStart,
            intervalEnd,
            init: true,
        }, this.calculateFiltered);
    }
    static getStatuusList(selectedStatuus) {
        const chips = [];
        chips.push({
            title: I18n.m.getMessage('ticketsDetailsStateopen'),
            data: TicketStatus.open,
            backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.open),
            textColor: '#FFFFFF',
            checked: selectedStatuus.indexOf(`${TicketStatus.open}`) >= 0,
        }, {
            title: I18n.m.getMessage('ticketsDetailsStateprocessing'),
            data: TicketStatus.processing,
            backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.processing),
            textColor: '#FFFFFF',
            checked: selectedStatuus.indexOf(`${TicketStatus.processing}`) >= 0,
        }, {
            title: I18n.m.getMessage('ticketsDetailsStateclosed'),
            data: TicketStatus.closed,
            backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.closed),
            textColor: '#FFFFFF',
            checked: selectedStatuus.indexOf(`${TicketStatus.closed}`) >= 0,
        }, {
            title: I18n.m.getMessage('ticketsDetailsStatechecked'),
            data: TicketStatus.checked,
            backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.checked),
            textColor: '#FFFFFF',
            checked: selectedStatuus.indexOf(`${TicketStatus.checked}`) >= 0,
        }, {
            title: I18n.m.getMessage('ticketsDetailsStateNoStatus'),
            data: TicketStatus.noStatus,
            backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.noStatus),
            textColor: '#000000',
            checked: selectedStatuus.indexOf(`${TicketStatus.noStatus}`) >= 0,
            outlined: true,
        });
        return chips;
    }
    static getPersonsWhereList(selectedPersonsWhere) {
        const chips = [];
        chips.push({
            title: I18n.m.getMessage('ticketFilterAssignedTo'),
            data: 'assignedTo',
            checked: selectedPersonsWhere.indexOf('assignedTo') >= 0,
        }, {
            title: I18n.m.getMessage('ticketFilterApprovedBy'),
            data: 'approvedBy',
            checked: selectedPersonsWhere.indexOf('approvedBy') >= 0,
        }, {
            title: I18n.m.getMessage('ticketFilterWatchedBy'),
            data: 'watchedBy',
            checked: selectedPersonsWhere.indexOf('watchedBy') >= 0,
        }, {
            title: I18n.m.getMessage('ticketFilterCreatedBy'),
            data: 'createdBy',
            checked: selectedPersonsWhere.indexOf('createdBy') >= 0,
        }, {
            title: I18n.m.getMessage('ticketFilterLastModifiedBy'),
            data: 'lastModifiedBy',
            checked: selectedPersonsWhere.indexOf('lastModifiedBy') >= 0,
        });
        return chips;
    }
    renderWarning() {
        const { intervalStart, intervalEnd } = this.state;
        let content = null;
        if ((intervalStart == null || intervalEnd == null) && !(intervalStart == null && intervalEnd == null)) {
            content = I18n.m.getMessage('ticketsFilterStartOrEndNotSet');
        }
        if (intervalStart != null && intervalEnd != null && intervalStart > intervalEnd) {
            content = I18n.m.getMessage('ticketsFilterEndBeforeStart');
        }
        if (content != null) {
            return <MaterialText color={ThemeManager.style.brandDanger}>{content}</MaterialText>;
        }
        return null;
    }
    render() {
        if (!this.state.init) {
            return <Spinner />;
        }
        const { selectedTags, selectedCrafts, selectedStatuus, availableTeammember, selectedPersonsWhere, completionSmallerThan, showArchivedTickets, showDeletedTickets, selectedSub, subTicketOptions, showMyTickets, showNotAssigendTickets, selectedPersons, intervalStart, intervalEnd, selectedTicketTypes, amountSelected, calculateAmount, selectedProjects, customFieldsFilterAvalaible, customFieldsFilter, projectTagGroups, } = this.state;
        const { availableTickets, projectId, saveWithNoResult } = this.props;
        const allArchived = availableTickets.filter((t) => t.archived).length === availableTickets.length;
        const statuus = TicketDialogFilter.getStatuusList(selectedStatuus);
        const roles = TicketDialogFilter.getPersonsWhereList(selectedPersonsWhere);
        const rolesV = (<View>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('ticketFilterPersonRoles')}</MaterialText>
        <FilterChips chips={roles} onPressChip={this.changedRoles}/>
      </View>);
        const rolesView = selectedPersons.length > 0 || showMyTickets || showNotAssigendTickets ? rolesV : null;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date();
        tomorrow.setHours(0, 0, 0, 0);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const in2Weeks = new Date();
        in2Weeks.setDate(in2Weeks.getDate() + 14);
        in2Weeks.setHours(0, 0, 0, 0);
        return [
            <View style={{ width: '100%', position: 'relative' }}>
        <DialogTitle>{I18n.m.getMessage('ticketFilter')}</DialogTitle>
        <View style={{
                    position: 'absolute',
                    top: ThemeManager.style.contentPaddingValue,
                    right: ThemeManager.style.contentPaddingValue,
                }}>
          <ContainedButton key="ticketFilterModalCloseButton" textColor="#000000" icon={{ icon: 'close' }} onPress={this.closeDialog} backgroundColor="transparent"/>
        </View>
      </View>,
            <DialogContent>
        {projectId != null ? null : (<View>
            <View style={styles.spacer}/>
            <ChipGroup key={JSON.stringify(selectedProjects)} dialogTitle={I18n.m.getMessage('projects')} label={I18n.m.getMessage('projects')} bordered={false} availableChips={this.state.projects} chips={selectedProjects} onChanged={this.setProjects}/>
          </View>)}
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('ticketsFilterTimePeriod')}</MaterialText>
        <DateRangeInput selectedDate={intervalStart == null || intervalEnd == null
                    ? undefined
                    : { from: new Date(intervalStart), to: new Date(intervalEnd) }} placeholder={I18n.m.getMessage('date')} key={`intervalStart${intervalStart}`} onChange={(date) => {
                    if (date)
                        this.setState({ intervalStart: date.from.getTime(), intervalEnd: date.to.getTime() }, this.calculateFiltered);
                    else
                        this.setState({ intervalStart: undefined, intervalEnd: undefined }, this.calculateFiltered);
                }}/>
        {this.renderWarning()}
        <View style={styles.spacer}/>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('ticketsFilterPersons')}</MaterialText>
        <ChipGroup dialogTitle={I18n.m.getMessage('ticketsFilterPersons')} chips={selectedPersons} bordered={false} availableChips={availableTeammember} onChanged={this.setPersons} tooltipAddChip={I18n.m.getMessage('ticketFilterAddTeammember')}/>
        <View style={{ flexDirection: 'row' }}>
          <Chip title={I18n.m.getMessage('ticketFilterMyTickets')} backgroundColor={showMyTickets ? ThemeManager.style.brandPrimary : undefined} textColor={showMyTickets ? '#FFFFFF' : undefined} onPressChip={this.onPressMyTickets} thumbnail={<UserImage user={CurrentUser.entity} size={24}/>}/>
          <Chip title={I18n.m.getMessage('ticketFilterNotAssignedTickets')} backgroundColor={showNotAssigendTickets ? ThemeManager.style.brandPrimary : undefined} textColor={showNotAssigendTickets ? '#FFFFFF' : undefined} onPressChip={this.onPressNotAssigendTickets} thumbnail={<Icon icon="help" toolTip="" color={showNotAssigendTickets ? '#FFFFFF' : ThemeManager.style.defaultIconColor}/>}/>
        </View>
        {rolesView}
        <View style={styles.spacer}/>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('ticketsDetailsState')}</MaterialText>
        <FilterChips chips={statuus} onPressChip={this.changedStatuus}/>
        <View style={styles.spacer}/>
        <ChipGroup dialogTitle={I18n.m.getMessage('ticketsDetailsType')} label={I18n.m.getMessage('ticketsDetailsType')} bordered={false} availableChips={this.state.projectTicketTypes} chips={selectedTicketTypes} onChanged={this.setTicketTypes}/>
        <View style={styles.spacer}/>
        <ChipGroup dialogTitle={I18n.m.getMessage('crafts')} label={I18n.m.getMessage('crafts')} bordered={false} availableChips={this.state.projectCrafts} chips={selectedCrafts} onChanged={this.setCrafts}/>
        <View style={styles.spacer}/>
        <ChipGroup dialogTitle={I18n.m.getMessage('ticketsDetailsTags')} label={I18n.m.getMessage('ticketsDetailsTags')} bordered={false} chipGroups={projectTagGroups} sortFormByGroup availableChips={this.state.projectTags} chips={selectedTags} onChanged={this.setTags}/>
        <View style={styles.spacer}/>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('ticketFilterCompletionTill')}</MaterialText>
        <DateInput placeholder={I18n.m.getMessage('date')} key={`completionOn${completionSmallerThan}`} onChange={this.changeCompletionSmallerThan} selectedDate={completionSmallerThan == null ? undefined : new Date(completionSmallerThan)}/>
        <View style={styles.chipContainer}>
          <Chip key={`completionOnToday${completionSmallerThan}`} backgroundColor={completionSmallerThan != null && completionSmallerThan === today.getTime()
                    ? ThemeManager.style.brandPrimary
                    : undefined} textColor={completionSmallerThan != null && completionSmallerThan === today.getTime() ? '#FFFFFF' : undefined} title={I18n.m.getMessage('ticketsFilterToday')} onPressChip={this.setCompletionSmallerThan(today)}/>
          <Chip key={`completionOnTomorrow${completionSmallerThan}`} backgroundColor={completionSmallerThan != null && completionSmallerThan === tomorrow.getTime()
                    ? ThemeManager.style.brandPrimary
                    : undefined} textColor={completionSmallerThan != null && completionSmallerThan === tomorrow.getTime() ? '#FFFFFF' : undefined} title={I18n.m.getMessage('ticketsFilterTomorrow')} onPressChip={this.setCompletionSmallerThan(tomorrow)}/>
          <Chip key={`completionOnIn2Weeksy${completionSmallerThan}`} backgroundColor={completionSmallerThan != null && completionSmallerThan === in2Weeks.getTime()
                    ? ThemeManager.style.brandPrimary
                    : undefined} textColor={completionSmallerThan != null && completionSmallerThan === in2Weeks.getTime() ? '#FFFFFF' : undefined} title={I18n.m.getMessage('ticketsFilterInTwoWeeks')} onPressChip={this.setCompletionSmallerThan(in2Weeks)}/>
        </View>
        {customFieldsFilterAvalaible.map((a) => {
                    if (a.type === 'Date' || a.type === 'DateRange') {
                        return (<View style={{ width: '100%' }}>
                <View style={styles.spacer}/>
                <MaterialText type={MaterialTextTypes.Caption}>{a.label}</MaterialText>
                <DateRangeInput selectedDate={customFieldsFilter.find((b) => a.id === b.id)?.value} selectTime={false} onChange={(e) => {
                                this.setState((prevState) => {
                                    const { customFieldsFilter } = prevState;
                                    const i = customFieldsFilter.findIndex((b) => b.id === a.id);
                                    if (i >= 0 && e == null) {
                                        customFieldsFilter.splice(i, 1);
                                    }
                                    else if (i === -1 && e != null) {
                                        customFieldsFilter.push({ id: a.id, type: a.type, value: e });
                                    }
                                    else if (i >= 0 && e != null) {
                                        customFieldsFilter[i] = { id: a.id, type: a.type, value: e };
                                    }
                                    return { customFieldsFilter };
                                }, this.calculateFiltered);
                            }}/>
              </View>);
                    }
                    if (a.options != null &&
                        a.options.length > 0 &&
                        (a.type === 'List' || a.type === 'MultiselectList' || a.type === 'CheckboxList')) {
                        const f = customFieldsFilter.find((b) => a.id === b.id);
                        const chips = [];
                        if (f && f.value && f.value.length > 0) {
                            f.value.forEach((a) => {
                                chips.push({
                                    title: a,
                                    id: a,
                                });
                            });
                        }
                        return (<View style={{ width: '100%' }}>
                <View style={styles.spacer}/>
                <ChipGroup key={JSON.stringify(selectedProjects)} dialogTitle={a.label} label={a.label} bordered={false} availableChips={a.options} chips={chips} onChanged={(val) => {
                                this.setState((prevState) => {
                                    const { customFieldsFilter } = prevState;
                                    const i = customFieldsFilter.findIndex((b) => b.id === a.id);
                                    const value = [];
                                    val.forEach((v) => {
                                        value.push(v.title);
                                    });
                                    if (i >= 0 && value.length === 0) {
                                        customFieldsFilter.splice(i, 1);
                                    }
                                    else if (i === -1 && value.length > 0) {
                                        customFieldsFilter.push({ id: a.id, type: a.type, value });
                                    }
                                    else if (i >= 0 && value.length > 0) {
                                        customFieldsFilter[i] = { id: a.id, type: a.type, value };
                                    }
                                    return { customFieldsFilter };
                                }, this.calculateFiltered);
                            }}/>
              </View>);
                    }
                    return null;
                })}
        <View style={styles.checkboxListItemContainer}>
          <CheckboxListItem key={`archivedTickets_${showArchivedTickets}`} value={showArchivedTickets === true} onChange={this.showArchivedTickets} title={I18n.m.getMessage('ticketFilterArchived')}/>
        </View>
        <View style={styles.checkboxListItemContainer}>
          <CheckboxListItem key={`deletedTickets_${showDeletedTickets}`} value={showDeletedTickets === true} onChange={this.showDeletedTickets} title={I18n.m.getMessage('ticketFilterDeleted')}/>
        </View>
        <View style={styles.formInputPicketContainer}>
          <OpenableChip looksLikeFI formInputLabel={I18n.m.getMessage('ticketFilterTicketHierarchyLabel')} chipsList={subTicketOptions.map((s, i) => ({ title: s.title, onPressChipData: i }))} selected={selectedSub} onPressChip={this.hideSubTickets} dialogTitle={I18n.m.getMessage('ticketFilterTicketHierarchyLabel')}/>
        </View>
      </DialogContent>,
            <View key="errorText" style={{ alignSelf: 'flex-end', padding: 0, paddingRight: 16 }}>
        <MaterialText textPadding={0} type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {amountSelected === 0 && !allArchived ? I18n.m.getMessage('noTicketsAvailable') : ''}
        </MaterialText>
      </View>,
            <DialogActions>
        <ContainedButton title={I18n.m.getMessage('reset')} onPress={this.onReset} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton disabled={calculateAmount ||
                    (!saveWithNoResult && amountSelected === 0 && availableTickets.length > 0 && !allArchived)} title={`${I18n.m.getMessage('apply')} ${amountSelected}/${availableTickets.length}`} onPress={this.onSelect}/>
      </DialogActions>,
        ];
    }
}
