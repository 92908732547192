import { Checkbox } from 'materialTheme/src/theme/components/Checkbox';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { getStyleForType, MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { ScrollView, TextInput, View } from 'react-native';
import { ChangeNote } from 'upmesh-core/src/client/commands/project/notes/ChangeNote';
import { CreateNote } from 'upmesh-core/src/client/commands/project/notes/CreateNote';
import { DeleteNote } from 'upmesh-core/src/client/commands/project/notes/DeleteNote';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CurrentProject } from '../CurrentProject';
function NoteInput({ item, changeNote, removeTextInput, index, focussed, onFocus, cursorUp, cursorDown, unFocus, }) {
    const [cursorPos, setCursorPos] = useState(0);
    const [value, setValue] = useState(item.text);
    return (<TextInput value={value} onChangeText={(text) => setValue(text)} onSelectionChange={(e) => setCursorPos(e.nativeEvent.selection.end)} onKeyPress={(e) => {
            if (e.nativeEvent.key === 'Backspace' && cursorPos === 0) {
                e.preventDefault();
                return removeTextInput(index);
            }
            if (e.nativeEvent.key === 'ArrowUp')
                return cursorUp();
            if (e.nativeEvent.key === 'ArrowDown')
                return cursorDown();
            return true;
        }} ref={(ref) => {
            if (focussed && !ref?.isFocused()) {
                ref?.focus();
            }
        }} onFocus={onFocus} onBlur={() => {
            if (value !== item.text) {
                changeNote(index, value);
            }
            unFocus();
        }} placeholder={I18n.m.getMessage('notesTypeHere')} placeholderTextColor="#444" style={[
            getStyleForType(MaterialTextTypes.Body1),
            {
                width: '100%',
                fontSize: ThemeManager.style.getTextInputFontSize(),
            },
        ]}/>);
}
export function NotesWidget(props) {
    const [currentNote, setCurrentNote] = useState();
    const [allNotes, setAllNotes] = useState([]);
    const [focussed, setFocussed] = useState();
    const [projectName, setProjectName] = useState();
    const [projectNames, setProjectNames] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentNoteIndex, setCurrentNoteIndex] = useState(0);
    const loadNext = () => {
        if (allNotes.length <= currentNoteIndex + 1) {
            if (!currentNote)
                return;
            setCurrentNoteIndex((prev) => prev + 1);
            setCurrentNote(null);
            return;
        }
        setCurrentNote([...allNotes[currentNoteIndex + 1].notes]);
        setCurrentNoteIndex(currentNoteIndex + 1);
    };
    const deleteNote = async (id) => {
        const command = new DeleteNote({}, id);
        await command.execute();
    };
    const loadPrevious = () => {
        if (currentNoteIndex <= 0)
            return;
        setCurrentNote([...allNotes[currentNoteIndex - 1].notes]);
        setCurrentNoteIndex(currentNoteIndex - 1);
    };
    const getHeaderIcons = () => {
        return [
            <Icon icon="delete-outline" disabled={!(allNotes[currentNoteIndex] && allNotes[currentNoteIndex].id)} toolTip="" onPress={() => {
                    if (allNotes[currentNoteIndex] && allNotes[currentNoteIndex].id)
                        deleteNote(allNotes[currentNoteIndex].id).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                }} key="deleteNote"/>,
            <Icon icon="chevron-left" toolTip="" onPress={() => loadPrevious()} key="prevNote"/>,
            <Icon icon="chevron-right" toolTip="" onPress={() => loadNext()} key="nextNote"/>,
        ];
    };
    const loadProjectTitle = async () => {
        const { projectId } = props;
        let pn;
        if (projectId != null && CurrentProject.instance.getCurrentProjectId() === projectId) {
            pn = CurrentProject.instance.getCurrentProject()?.title;
        }
        else if (projectId) {
            try {
                const project = await UpmeshClient.instance.modals.project.getById(projectId);
                pn = project.title;
            }
            catch (e) {
                console.debug(e);
            }
        }
        setProjectName(pn);
    };
    const loadProjectNames = async (notes) => {
        if (props.projectId)
            return null;
        const projectNamesCopy = { ...projectNames };
        const projectIds = [...new Set(notes.map((note) => note.projectId))];
        const promises = [];
        projectIds.forEach((id) => {
            if (!id || !!projectNames[id])
                return;
            promises.push(new Promise((r) => {
                UpmeshClient.instance.modals.project
                    .getById(id)
                    .then((p) => r(p))
                    .catch((err) => {
                    console.debug(err);
                    r(null);
                });
            }));
        });
        const projects = await Promise.all(promises);
        projects.forEach((p) => {
            if (p != null)
                projectNamesCopy[p.id] = p.title;
        });
        return projectNamesCopy;
    };
    const loadAllNotes = async (noteIndex) => {
        let filter = 'deleted ne true';
        if (props.projectId)
            filter = `deleted ne true and projectId eq ${props.projectId}`;
        const notes = await UpmeshClient.instance.modals.note.get({
            filter,
        });
        const p = await loadProjectNames(notes);
        if (p !== null) {
            for (let i = notes.length - 1; i >= 0; i -= 1) {
                const n = notes[i];
                if (n.projectId && !p[n.projectId]) {
                    notes.splice(i, 1);
                }
            }
            setProjectNames(p);
        }
        setAllNotes(notes);
        if (notes.length === 0) {
            setCurrentNote(null);
            setCurrentNoteIndex(0);
        }
        else if (notes.length > noteIndex && noteIndex >= 0) {
            setCurrentNoteIndex(noteIndex);
            setCurrentNote(notes[noteIndex].notes);
        }
        else {
            setCurrentNoteIndex(0);
            setCurrentNote(notes[0].notes);
        }
        setIsLoading(false);
    };
    const updateNotes = (noteIndex) => (e) => {
        if (e.entities && e.entities.size > 0) {
            loadAllNotes(noteIndex).catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
        }
    };
    useEffect(() => {
        const key = uuid.v4();
        UpmeshClient.eventDispatcher.attach({
            attachKey: key,
            readModelName: 'Note',
            callback: updateNotes(currentNoteIndex),
        });
        return () => UpmeshClient.eventDispatcher.detach('Note', key);
    }, [currentNoteIndex]);
    useEffect(() => {
        loadProjectTitle().catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
        loadAllNotes(0).catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
    }, [props.projectId]);
    const saveNote = async (notes, id) => {
        if (!notes)
            return;
        const command = new ChangeNote({ notes }, id);
        await command.execute();
    };
    const changeNote = (index, note) => {
        const newNotes = JSON.parse(JSON.stringify(currentNote));
        newNotes[index].text = note;
        setCurrentNote(newNotes);
        saveNote(newNotes, allNotes[currentNoteIndex].id).catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
        setFocussed(null);
    };
    const removeTextInput = (index) => {
        const newNotes = JSON.parse(JSON.stringify(currentNote));
        if (index === 0) {
            if (newNotes[0].text === '' && newNotes[0].checked == null)
                return;
            newNotes[0] = { text: '', checked: undefined };
        }
        else {
            newNotes.splice(index, 1);
        }
        setFocussed(null);
        setCurrentNote(newNotes);
        saveNote(newNotes, allNotes[currentNoteIndex].id).catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
    };
    const addTextInput = () => {
        if (!currentNote)
            return;
        const newNotes = JSON.parse(JSON.stringify(currentNote));
        newNotes.push({ text: '' });
        setFocussed(newNotes.length - 1);
        setCurrentNote(newNotes);
        saveNote(newNotes, allNotes[currentNoteIndex].id).catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
    };
    const addCheckbox = (index) => {
        const newNotes = JSON.parse(JSON.stringify(currentNote));
        newNotes[index].checked = false;
        setCurrentNote(newNotes);
        saveNote(newNotes, allNotes[currentNoteIndex].id).catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
    };
    const createNote = () => {
        setIsLoading(true);
        const command = new CreateNote({ notes: [{ text: '', checked: null }], projectId: props.projectId });
        command
            .execute()
            .catch((err) => {
            console.error(err);
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    const changeChecked = (index, val) => {
        const newNotes = JSON.parse(JSON.stringify(currentNote));
        newNotes[index].checked = val;
        setCurrentNote(newNotes);
        saveNote(newNotes, allNotes[currentNoteIndex].id).catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
    };
    const deleteLine = (index) => (_e) => {
        removeTextInput(index);
    };
    const renderNote = ({ item, index }) => {
        const noteFocussed = focussed === index;
        return (<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }} key={`note_${index.toString()}_${item.checked?.toString()}_${item.text}`}>
        <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}>
          <View style={{ width: 48 }}>
            {item.checked != null && <Checkbox value={item.checked} onChange={(val) => changeChecked(index, val)}/>}
            {item.checked == null && (<Icon icon="plus-circle-outline" outerSize={36} color={ThemeManager.style.brandPrimary} toolTip={I18n.m.getMessage('notesAddCheckbox')} onPress={() => addCheckbox(index)}/>)}
          </View>
          <View style={{ flex: 1 }}>
            <NoteInput item={item} changeNote={changeNote} removeTextInput={removeTextInput} cursorUp={() => {
                if (focussed > 0)
                    setFocussed(focussed - 1);
            }} cursorDown={() => {
                if (focussed < currentNote.length - 1)
                    setFocussed(focussed + 1);
            }} index={index} focussed={noteFocussed} onFocus={() => setFocussed(index)} unFocus={() => setFocussed(null)}/>
          </View>
        </View>
        <Icon icon="delete-outline" toolTip={I18n.m.getMessage('delete')} onPress={deleteLine(index)}/>
      </View>);
    };
    return (<View style={{ width: '100%', height: '100%' }}>
      <View style={{ padding: 0, paddingBottom: 4, width: '100%', height: '100%' }}>
        <View style={{
            padding: 16,
            paddingBottom: 0,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}>
          <MaterialText type={MaterialTextTypes.H5}>{I18n.m.getMessage('dashboardNotes')}</MaterialText>
          <View style={{ width: 112, flexDirection: 'row', justifyContent: 'space-between' }}>{getHeaderIcons()}</View>
        </View>

        <View style={{ height: 'auto', paddingHorizontal: 16 }}>
          {allNotes.length > 0 && (<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <MaterialText>
                
                {allNotes[currentNoteIndex]?.projectId == null
                ? ' '
                : projectNames[allNotes[currentNoteIndex].projectId]
                    ? projectNames[allNotes[currentNoteIndex].projectId]
                    : projectName}
              </MaterialText>
              <MaterialText>
                ({Math.min(currentNoteIndex + 1, allNotes.length)}/{allNotes.length})
              </MaterialText>
            </View>)}
        </View>
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingHorizontal: 16 }} nestedScrollEnabled>
          {currentNote ? (<View key={`note_${currentNoteIndex}_${JSON.stringify(currentNote)}`}>
                {currentNote.map((item, i) => renderNote({ item, index: i }))}
                <Icon icon="plus" toolTip={I18n.m.getMessage('add')} onPress={addTextInput}/>
              </View>) : isLoading ? (<View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <Spinner small/>
              </View>) : (<Ripple style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }} onPress={createNote}>
                <MaterialText textAlign="center" additionalTextStyle={{ alignSelf: 'center' }}>
                  {props.projectId
                ? allNotes.length > 0
                    ? I18n.m.getMessage('notesAddNoteToProject', { projectName })
                    : I18n.m.getMessage('notesNoNotesInProject', { projectName })
                : allNotes.length > 0
                    ? I18n.m.getMessage('notesAddNote')
                    : I18n.m.getMessage('notesNoExistingNotes')}
                </MaterialText>
              </Ripple>)}
        </ScrollView>
      </View>
    </View>);
}
