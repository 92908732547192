"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateHoliday = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var Config_1 = require("../../../../Config");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var HolidayCreated_1 = require("../../../events/company/holiday/HolidayCreated");
var HolidayEntity_1 = require("../../../query/entities/HolidayEntity");
var CreateHoliday = function (_CommandRoot_1$Comman) {
  function CreateHoliday(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateHoliday);
    _this = _callSuper(this, CreateHoliday, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'CreateHoliday';
    return _this;
  }
  (0, _inherits2.default)(CreateHoliday, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(CreateHoliday, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.data.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var savedDate = HolidayEntity_1.HolidayEntity.getNormalizedDate(this.data.date, this.data.annual);
        return new HolidayCreated_1.HolidayCreated(this.entityId, {
          name: this.data.name,
          companyId: this.data.companyId,
          regionIds: this.data.regionIds,
          wageType: this.data.wageType,
          date: savedDate,
          type: this.data.type,
          amount: this.data.amount,
          annual: this.data.annual
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'name') {
          if (this.data.name == null || this.data.name.length === 0) {
            throw {
              message: 'Name wird benötigt',
              messageCode: 'required',
              key: 'name'
            };
          }
          if (this.data.name.length > Config_1.ConfigHandler.config.string_Large) {
            throw {
              key: 'name',
              messageCode: 'stringToLong',
              message: 'Eingabe ist zu lang'
            };
          }
        }
        if (key == null || key === 'companyId') {
          if (this.data.companyId == null || this.data.companyId.length === 0) {
            throw {
              message: 'Firma wird benötigt',
              messageCode: 'required',
              key: 'companyId'
            };
          }
        }
        if (key == null || key === 'wageType') {
          if (this.data.wageType == null || this.data.wageType.length === 0) {
            throw {
              message: 'Lohnart wird benötigt',
              messageCode: 'required',
              key: 'wageType'
            };
          }
        }
        if (key == null || key === 'date') {
          if (this.data.date == null) {
            throw {
              message: 'Datum wird benötigt',
              messageCode: 'required',
              key: 'date'
            };
          }
        }
        if (key == null || key === 'amount') {
          if (this.data.date == null || !(this.data.amount > 0)) {
            throw {
              message: 'Anzahl muss größer als 0 sein',
              messageCode: 'required',
              key: 'amount'
            };
          }
        }
        if (key == null || key === 'annual') {
          if (this.data.date == null) {
            throw {
              message: 'Jährlich wird benötigt',
              messageCode: 'required',
              key: 'annual'
            };
          }
        }
        var holidays = yield CommandReadModels_1.CommandReadModels.holiday.get({
          filter: `deleted ne true and companyId eq '${this.data.companyId}' and name eq '${this.data.name}' and date eq ${new Date(this.data.date).toISOString()}`
        });
        if (holidays.length > 0) {
          throw {
            message: 'Feiertag existiert bereits',
            messageCode: 'companyHolidayAlreadyExists'
          };
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.CreateHoliday = CreateHoliday;