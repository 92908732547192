import { getAddressInfoFromGoogleResponse, GooglePlacesInput, } from 'materialTheme/src/theme/components/addressCard/GooglePlacesInput';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { Header } from 'materialTheme/src/theme/components/header/Header';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { BackHandler, FlatList, Image, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { OfflineDataDownloader } from '../../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { PlanThumb169 } from '../../plans/PlanThumb169';
import { PlanZoomTileMapper } from '../../plans/PlanZoomTileMapper';
import { CurrentProject } from '../../project/CurrentProject';
const notFoundImage = require('../../../assets/img/no_blueprint.png');
export class PlanSnapshotDialog extends PureComponent {
    static open(props) {
        DialogUp.instance?.open({
            content: <PlanSnapshotDialog {...props}/>,
            contentPadding: false,
            fullscreen: true,
        });
    }
    constructor(props) {
        super(props);
        this.apiUrl = '';
        this.plans = CurrentProject.instance.getCurrentPlans();
        this.setCurrentCenter = (r) => {
            this.currentCenter = r;
            const region = { latitude: r.center.latlng.lat, longitude: r.center.latlng.lng };
            try {
                if (this.fetchTO != null)
                    clearTimeout(this.fetchTO);
                this.abortController.abort();
            }
            catch (e) {
                console.debug('cannot abort fetch');
            }
            this.abortController = new AbortController();
            const { signal } = this.abortController;
            this.fetchTO = setTimeout(() => {
                fetch(`${this.apiUrl}/geocode/json?&lat=${region.latitude}&long=${region.longitude}`, { signal })
                    .then((result) => {
                    if (result.status === 200) {
                        result
                            .json()
                            .then((json) => {
                            if (json == null || json.results == null)
                                return;
                            const { placesKey } = this.state;
                            const bestMatch = json.results.filter((addressItem) => addressItem.address_components.length >= 7)[0];
                            if (bestMatch != null) {
                                const newAddress = getAddressInfoFromGoogleResponse(bestMatch.formatted_address, bestMatch);
                                if (newAddress.addressInput != null)
                                    this.setState({ addressInput: newAddress.addressInput, placesKey: placesKey + 1 });
                            }
                        })
                            .catch((err) => console.debug(err));
                    }
                })
                    .catch((err) => {
                    console.debug('catched', err);
                });
            }, 200);
        };
        this.stepBack = (_e) => {
            if (this.state.planId == null || this.plans == null || this.plans.length === 0) {
                DialogUp.instance?.close();
            }
            else {
                this.setState({ planId: undefined });
            }
        };
        this.setMapRef = (r) => {
            this.zoomTile = r;
        };
        this.onGoogleAddressSelected = (newAddress) => {
            if (newAddress.addressInput != null) {
                this.setState({ addressInput: newAddress.addressInput }, () => {
                    if (newAddress.longitude != null && newAddress.latitude != null)
                        this.zoomTile?.zoomTo({ x: Number.parseFloat(newAddress.longitude), y: Number.parseFloat(newAddress.latitude) }, 17);
                });
            }
        };
        this.onCustomAddressTextSelected = (text) => {
            this.setState({ addressInput: text });
        };
        this.goToPlanUpload = () => {
            Routing.instance.goTo(`/projects/${this.props.projectId}/plans`);
        };
        this.getItemKey = (item, _index) => `plan_${item.id}`;
        this.onLayout = (e) => {
            const getNumColums = this.createNumColumns(e != null ? e.windowWidth : this.state.windowWidth);
            this.setState({
                ...getNumColums,
            });
        };
        this.onPlanChange = (plans) => {
            this.plans = plans;
            this.forceUpdate();
        };
        this.renderRow = ({ item }) => {
            const { targetWidth } = this.state;
            return (<View style={{
                    padding: ThemeManager.style.getScreenRelativePixelSize(8),
                    width: targetWidth,
                    height: Math.round(((targetWidth - ThemeManager.style.getScreenRelativePixelSize(8)) / 16) * 9) + 32,
                    justifyContent: 'center',
                    alignSelf: 'center',
                }} key={`PlanThumb_${item.id}`}>
        <View style={{
                    alignSelf: 'center',
                    justifyContent: 'center',
                    width: targetWidth,
                    height: Math.round(((targetWidth - ThemeManager.style.getScreenRelativePixelSize(8)) / 16) * 9),
                }}>
          <PlanThumb169 plan={item} projectId={item.projectId} fileSource={item.activePlanId === 'MAP' ? 'map' : 'planVersion'} fileId={item.activePlanId} width={targetWidth - ThemeManager.style.getScreenRelativePixelSize(8)} onPress={this.setPlanId(item.id)} title={item.title}/>
        </View>
      </View>);
        };
        this.saveSnapshot = (_e) => {
            if (this.zoomTile != null) {
                this.zoomTile
                    .takeSnapshot()
                    .then((f) => {
                    DialogUp.instance?.close(() => {
                        this.props.onSnapshot(f);
                    });
                })
                    .catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
            }
        };
        this.setPlanId = (planId) => (_e) => {
            const asyncNow = async () => {
                try {
                    const selectedPlan = await UpmeshClient.instance.modals.plan.getById(planId);
                    Toast.instance?.open({
                        title: I18n.m.getMessage('zoomAndMoveToDefinePlanSnapshot'),
                    });
                    if (selectedPlan.activePlanId === 'MAP') {
                        const initialPlanPosition = this.getInitialPlanPosition(selectedPlan);
                        this.setState({
                            planId,
                            selectedPlan,
                            initialPlanPosition,
                            selectedPlanVersion: undefined,
                            addressInput: '',
                        });
                    }
                    else {
                        const selectedPlanVersion = await UpmeshClient.instance.modals.planVersion.getById(selectedPlan.activePlanId);
                        const initialPlanPosition = this.getInitialPlanPosition(selectedPlan, selectedPlanVersion);
                        this.setState({ planId, selectedPlan, selectedPlanVersion, initialPlanPosition });
                    }
                }
                catch (e) {
                    ErrorReporter.sendReport({
                        subject: `Cant get plan - ${planId}`,
                        data: e,
                        type: 'warn',
                    });
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.apiUrl = `${AuthClient.instance.url.replace('3011/auth/', '3012/')}/backend/googleapis`;
        this.state = {
            ...DialogUp.defaultState,
            selectedPlan: undefined,
            selectedPlanVersion: undefined,
            planId: props.planId,
            targetWidth: 256,
            numColumns: 1,
            placesKey: 0,
            addressInput: '',
        };
    }
    componentDidMount() {
        ResizeEvent.onResize.attach(this.onLayout);
        this.plans = CurrentProject.instance.getCurrentPlans();
        CurrentProject.plansChanged.attach(this.onPlanChange);
        this.onLayout();
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
            const { planId } = this.state;
            if (planId != null)
                this.setState({ planId: undefined });
            else
                DialogUp.instance?.close();
            return true;
        });
    }
    componentWillUnmount() {
        if (this.backHandler)
            this.backHandler.remove();
        ResizeEvent.onResize.detach(this.onLayout);
        CurrentProject.plansChanged.detach(this.onPlanChange);
    }
    render() {
        const content = this.renderContent();
        const leftButton = [
            <Icon toolTip={I18n.m.getMessage('close')} key="close2Button" icon="arrow-left" onPress={this.stepBack}/>,
        ];
        const rightButton = this.state.planId == null || this.plans == null || this.plans.length === 0
            ? []
            : [<Icon toolTip={I18n.m.getMessage('save')} key="check2Button" icon="check" onPress={this.saveSnapshot}/>];
        return (<View style={{ width: '100%', height: '100%' }}>
        <View style={{
                overflow: 'hidden',
                paddingBottom: 0,
            }}>
          <Header title={content.title} rightButtons={rightButton} leftButtons={leftButton} withElevation={false}/>
          <View style={{ flexDirection: 'row', minHeight: '100%' }}>{content.view}</View>
        </View>
      </View>);
    }
    getInitialPlanPosition(selectedPlan, selectedPlanVersion) {
        if (selectedPlan?.activePlanId === 'MAP' || selectedPlanVersion != null) {
            let pointInPage = selectedPlanVersion == null
                ? { x: 0, y: 0 }
                : {
                    x: selectedPlanVersion.pageWidth / 2,
                    y: selectedPlanVersion.pageHeight / 2,
                };
            if (selectedPlan?.address != null) {
                pointInPage = { x: selectedPlan.address.longitude, y: selectedPlan.address.latitude };
            }
            const zoomLevel = selectedPlanVersion == null ? 16 : 2;
            return { center: { point: pointInPage, latlng: { lat: pointInPage.y, lng: pointInPage.x } }, zoom: zoomLevel };
        }
        return undefined;
    }
    renderAddressField() {
        const { selectedPlan, placesKey, addressInput } = this.state;
        if (selectedPlan != null && selectedPlan.activePlanId === 'MAP') {
            return (<View style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: 72,
                    padding: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    left: 0,
                    zIndex: 200,
                    elevation: 200,
                    backgroundColor: 'transparent',
                }} pointerEvents="box-none">
          <GooglePlacesInput key={`places_${placesKey.toString()}`} initialText={addressInput} onChange={this.onGoogleAddressSelected} onChangeText={this.onCustomAddressTextSelected} backendURL={this.apiUrl}/>
        </View>);
        }
        return null;
    }
    renderContent() {
        const { planId, numColumns } = this.state;
        if (planId == null && (this.plans == null || this.plans.length === 0)) {
            return {
                view: (<View style={{ width: '100%', height: '100%' }}>
            <View style={{ alignItems: 'center', alignContent: 'center' }}>
              <Image style={{ height: 200, width: 400, resizeMode: 'contain', marginTop: 16 }} resizeMode="contain" source={notFoundImage}/>
              <View style={{ marginTop: 16 }}>
                <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2}>
                  {I18n.m.getMessage('plansNotFound')}
                </MaterialText>
                <Ripple disabled={false} style={{
                        width: ThemeManager.style.getScreenRelativePixelSize(264),
                        height: ThemeManager.style.getScreenRelativePixelSize(48),
                    }} onPress={this.goToPlanUpload}>
                  <MaterialText centeredBox centeredText color={ThemeManager.style.black87} type={MaterialTextTypes.Subtitle2} strong>
                    {I18n.m.getMessage('planUploadDescription')}
                  </MaterialText>
                </Ripple>
              </View>
            </View>
          </View>),
                title: I18n.m.getMessage('ticketChangePlanSelectPlanTitle'),
            };
        }
        if (planId === undefined) {
            return {
                view: (<View style={{
                        width: '100%',
                        height: ResizeEvent.current.contentHeight -
                            ThemeManager.style.headerHeight -
                            ThemeManager.style.statusBarHeight,
                        paddingLeft: 8,
                    }}>
            <FlatList key={`planTicketsList_${numColumns}`} keyExtractor={this.getItemKey} renderItem={this.renderRow} numColumns={numColumns} data={[...this.plans]} style={{ width: '100%', height: '100%' }}/>
          </View>),
                title: I18n.m.getMessage('ticketChangePlanSelectPlanTitle'),
            };
        }
        const { selectedPlanVersion, selectedPlan, initialPlanPosition } = this.state;
        if (selectedPlan != null && (selectedPlanVersion != null || selectedPlan?.activePlanId === 'MAP')) {
            const { windowWidth, windowHeight } = this.state;
            const cHeight = windowHeight - ThemeManager.style.headerHeight;
            return {
                view: (<View style={{ width: windowWidth, height: cHeight, position: 'relative' }}>
            <PlanZoomTileMapper initialPlanPosition={initialPlanPosition} plan={selectedPlan} type={selectedPlanVersion == null ? 'map' : 'plan'} planVersion={selectedPlanVersion} zoomAble offlineData={selectedPlanVersion == null
                        ? undefined
                        : OfflineDataDownloader.isPlanVersionSynced(selectedPlanVersion.projectId, selectedPlanVersion.id)} ref={this.setMapRef} onRegionChange={this.setCurrentCenter} url={selectedPlanVersion == null
                        ? 'map'
                        : `${UpmeshClient.instance.url}/plan/tiles/${selectedPlanVersion.projectId}/planVersion/${selectedPlanVersion.id}`}/>
            {this.renderAddressField()}
          </View>),
                title: selectedPlan.title,
            };
        }
        return {
            view: (<View style={{ width: '100%', height: '100%' }}>
          <Spinner />
        </View>),
            title: '',
        };
    }
    createNumColumns(forWidth) {
        let targetWidth = ThemeManager.style.getScreenRelativePixelSize(212);
        const width = forWidth - ThemeManager.style.contentPaddingValue;
        const numColumns = Math.max(1, Math.round(width / targetWidth));
        targetWidth = width / numColumns;
        return { targetWidth, numColumns };
    }
}
