import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Calendar } from 'materialTheme/src/theme/components/Calendar';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Image, ScrollView, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { JournalEntity } from 'upmesh-core/src/client/query/entities/JournalEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
import { JournalPreview } from './JournalPreview';
const noJournalImage = require('../../assets/img/no_journal.png');
export class JournalCalendarView extends PureComponent {
    constructor(props) {
        super(props);
        this.datesForCalander = async (startDate, endDate) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            const draftJournals = await UpmeshClient.instance.modals.journal.get({
                filter: `projectId eq '${projectId}' and forDay ge ${Number.parseInt(JournalEntity.getNormalizedDateShort(startDate), 10)} and forDay le ${Number.parseInt(JournalEntity.getNormalizedDateShort(endDate), 10)}`,
            }, CurrentUser.userId);
            const coloredDates = [];
            const protocols = await UpmeshClient.instance.modals.journalProtocol.get({ filter: `projectId eq '${projectId}'` });
            for (const draft of draftJournals) {
                const isSendOnce = protocols.find((element) => {
                    return element.journalId === draft.id;
                });
                coloredDates.push({
                    date: JournalEntity.getNormalizedDateShortToDate(draft.forDay.toString()),
                    color: isSendOnce ? ThemeManager.style.brandSecondary : ThemeManager.style.brandWarning,
                });
            }
            return coloredDates;
        };
        this.onCalendarLayout = (e) => {
            this.setState({ calendarHeight: e.nativeEvent.layout.height });
        };
        this.onChangeCalendarView = (fromDate = this.state.fromDate, toDate = this.state.toDate) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null && projectId.length > 0) {
                const asyncNow = async () => {
                    const tmpMarkedDateArray = [];
                    const tmpDraftDataArray = [];
                    const draftJournals = await UpmeshClient.instance.modals.journal.get({
                        filter: `projectId eq '${projectId}' and forDay ge ${Number.parseInt(JournalEntity.getNormalizedDateShort(fromDate), 10)} and forDay le ${Number.parseInt(JournalEntity.getNormalizedDateShort(toDate), 10)}`,
                    }, CurrentUser.userId);
                    if (draftJournals != null && draftJournals.length > 0) {
                        const protocols = await UpmeshClient.instance.modals.journalProtocol.get({
                            filter: `projectId eq '${projectId}'`,
                        });
                        for (const draft of draftJournals) {
                            const isSendOnce = protocols.find((element) => {
                                return element.journalId === draft.id;
                            });
                            if (isSendOnce) {
                                tmpMarkedDateArray.push(JournalEntity.getNormalizedDateShortToDate(draft.forDay.toString()));
                            }
                            else {
                                tmpDraftDataArray.push(JournalEntity.getNormalizedDateShortToDate(draft.forDay.toString()));
                            }
                        }
                    }
                    this.setState({
                        draftedDates: tmpDraftDataArray,
                        markedDates: tmpMarkedDateArray,
                        fromDate,
                        toDate,
                    });
                };
                asyncNow().catch((err) => console.error(err));
            }
        };
        this.onPressDate = (newDate) => {
            Routing.instance.routeBlock.post(false);
            requestAnimationFrame(() => {
                Routing.instance.goTo(`/projects/${this.props.projectId}/dailyreports/${JournalEntity.getNormalizedDateShort(newDate)}`);
            });
        };
        this.state = {
            draftedDates: [],
            markedDates: [],
            fromDate: new Date(),
            toDate: new Date(),
            selectedDate: props.selectedDate,
            calendarHeight: 195,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.selectedDate !== prevState.selectedDate) {
            return { selectedDate: nextProps.selectedDate };
        }
        return {};
    }
    render() {
        const { previewIsLoading, sendIsLoading } = this.props;
        const { draftedDates, markedDates, selectedDate } = this.state;
        return (<View>
        <View onLayout={this.onCalendarLayout} style={{ padding: 8, paddingBottom: 16, backgroundColor: '#FFFFFF', width: '100%' }}>
          <Calendar datePickerLabel={I18n.m.getMessage('journalDatepickerLabel')} onChangeView={this.onChangeCalendarView} selectedDate={selectedDate} draftedDates={draftedDates} markedDates={markedDates} onPressDate={this.onPressDate} disableEverything={previewIsLoading || sendIsLoading} coloredDates={this.datesForCalander}/>
        </View>
        {this.renderReportsView()}
      </View>);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.journalProtocol !== this.props.journalProtocol) {
            this.onChangeCalendarView();
        }
    }
    renderFutureDayView() {
        return (<View style={{ padding: 32, alignContent: 'center', alignItems: 'center', width: '100%' }}>
        <MaterialText centeredText centeredBox>
          {I18n.m.getMessage('journalNoReportFuture')}
        </MaterialText>
      </View>);
    }
    renderNoReportsView() {
        const { openEditDialog, canI, selectedDate, previewIsLoading, sendIsLoading } = this.props;
        return (<Card>
        <View style={{
                width: '100%',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                padding: 16,
            }}>
          {ResizeEvent.current.contentHeight >= 612 ? (<Image style={{ width: '100%', height: 200, resizeMode: 'contain' }} resizeMode="contain" source={noJournalImage}/>) : undefined}
          <View style={{ width: '100%', marginTop: 16, alignItems: 'center' }}>
            <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2} strong>
              {I18n.m.getMessage('journalNoReport', { date: I18n.m.dateCurrent.localeDateString(selectedDate, true) })}
            </MaterialText>
            <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2}>
              {I18n.m.getMessage('journalNoReportCreateNew')}
            </MaterialText>
          </View>
          <View style={{ paddingTop: 8, alignSelf: 'center' }}>
            <ContainedButton icon={{ icon: 'plus', toolTip: '' }} title={I18n.m.getMessage('journalCreate')} onPress={() => openEditDialog(selectedDate)} disabled={!canI || previewIsLoading || sendIsLoading}/>
          </View>
        </View>
      </Card>);
    }
    renderReportsView() {
        const { size, openEditDialog, openSendDialog, previewIsLoading, canI, sendIsLoading, onGetAsPDF, selectedDate, journalData, init, journalAvailable, journalProtocol, sendCurrentDraft, journalSettings, } = this.props;
        const { calendarHeight } = this.state;
        const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        const scrollViewHeight = size.contentHeight - calendarHeight - ThemeManager.style.headerHeight - sViewHeight;
        if (Number.parseInt(JournalEntity.getNormalizedDateShort(selectedDate), 10) >
            Number.parseInt(JournalEntity.getNormalizedDateShort(), 10)) {
            return this.renderFutureDayView();
        }
        if (journalAvailable && journalData != null && journalProtocol != null) {
            if (init) {
                return (<ScrollView style={{ height: scrollViewHeight, width: '100%' }} contentContainerStyle={{ paddingBottom: 64 }}>
            <JournalPreview key={`journal_${journalData.id}_${journalData.lastModifiedAt.toISOString()}`} journalProtocol={journalProtocol} selectedDate={selectedDate} journalData={journalData} openEditDialog={() => openEditDialog(selectedDate)} canI={canI} previewOrSendIsLoading={previewIsLoading || sendIsLoading} getAsPdf={onGetAsPDF} openSendDialog={openSendDialog} sendCurrentDraft={sendCurrentDraft} journalSettings={journalSettings}/>
          </ScrollView>);
            }
            return <Spinner />;
        }
        return this.renderNoReportsView();
    }
}
