import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { PlanFilter } from 'upmesh-core/src/client/query/filter/PlanFilter';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
const styles = {
    spacer: { height: 6 },
};
export class PlansFilterDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.setTags = (e) => {
            this.setState({ selectedTags: e }, this.calculateFiltered);
        };
        this.setIntervalStart = (newDate) => {
            const intervalStart = newDate == null ? undefined : newDate.getTime();
            this.setState({ intervalStart }, this.calculateFiltered);
        };
        this.setIntervalEnd = (newDate) => {
            const intervalEnd = newDate == null ? undefined : newDate.getTime();
            this.setState({ intervalEnd }, this.calculateFiltered);
        };
        this.setPersons = (e) => {
            this.setState({ selectedCreator: e }, this.calculateFiltered);
        };
        this.calculateFiltered = () => {
            const { plans } = this.props;
            this.setState({ calculateAmount: true }, () => {
                try {
                    const filter = this.createCurrentFilter();
                    PlanFilter.filterPlans(plans, filter)
                        .then((filtered) => {
                        this.setState({ calculateAmount: false, amountSelected: filtered.length });
                    })
                        .catch((_err) => {
                        this.setState({ calculateAmount: false, amountSelected: 0 });
                    });
                }
                catch (e) {
                    this.setState({ calculateAmount: false, amountSelected: 0 });
                }
            });
        };
        this.init = async () => {
            const { currentFilter, plans } = this.props;
            const project = CurrentProject.instance.getCurrentProject();
            if (project == null)
                return;
            const projectTagGroups = PlansFilterDialog.getTagsGroups([project]);
            const projectTags = PlansFilterDialog.getTags(plans);
            const availableTeammember = await this.getPersons();
            const selectedCreator = PlansFilterDialog.getSelectedChipsByIds(availableTeammember, currentFilter?.u);
            const selectedTags = PlansFilterDialog.getSelectedChipsByIds(projectTags, currentFilter?.t);
            const intervalStart = currentFilter != null && currentFilter.is != null ? currentFilter.is : undefined;
            const intervalEnd = currentFilter != null && currentFilter.ie != null ? currentFilter.ie : undefined;
            this.setState({
                selectedCreator,
                selectedTags,
                projectTagGroups,
                projectTags,
                intervalEnd,
                intervalStart,
                availableTeammember,
                loading: false,
            }, this.calculateFiltered);
        };
        this.onReset = () => {
            this.resetFilters();
        };
        this.onSelect = () => {
            DialogBetween.instance?.close(() => {
                const filter = this.createCurrentFilter();
                Routing.instance.changeQueryParameter({
                    fp: JSON.stringify(filter),
                });
                if (this.props.callback != null)
                    this.props.callback(filter);
            });
        };
        this.state = {
            projectTags: [],
            selectedTags: [],
            projectTagGroups: [],
            loading: true,
            amountSelected: 0,
            calculateAmount: true,
            availableTeammember: [],
            selectedCreator: [],
        };
    }
    static open(plans, callback, currentFilter) {
        if (DialogBetween.instance != null) {
            const content = <PlansFilterDialog plans={plans} callback={callback} currentFilter={currentFilter}/>;
            DialogBetween.instance?.open({
                closeOnTouchOutside: false,
                content,
                contentPadding: false,
                scrollable: false,
                fullscreenResponsive: true,
            });
        }
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    render() {
        const { plans } = this.props;
        const { availableTeammember, intervalStart, intervalEnd, loading, amountSelected, selectedTags, selectedCreator, projectTags, projectTagGroups, } = this.state;
        if (loading) {
            return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
          <Spinner />
        </View>);
        }
        return (<View>
        <DialogTitle>{I18n.m.getMessage('planFilterHeader')}</DialogTitle>
        <View style={{
                position: 'absolute',
                top: ThemeManager.style.contentPaddingValue,
                right: ThemeManager.style.contentPaddingValue,
            }}>
          <ContainedButton key="ticketFilterModalCloseButton" textColor="#000000" icon={{ icon: 'close' }} onPress={() => DialogBetween.instance?.close()} backgroundColor="transparent"/>
        </View>
        <View style={{ padding: ThemeManager.style.contentPaddingValue }}>
          <ChipGroup dialogTitle={I18n.m.getMessage('planFilterTags')} label={I18n.m.getMessage('planFilterTags')} bordered={false} sortFormByGroup chipGroups={projectTagGroups} availableChips={projectTags} chips={selectedTags} onChanged={this.setTags}/>
          <View style={styles.spacer}/>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('planFilterPersons')}</MaterialText>
          <ChipGroup dialogTitle={I18n.m.getMessage('planFilterPersons')} chips={selectedCreator} bordered={false} availableChips={availableTeammember} onChanged={this.setPersons} tooltipAddChip={I18n.m.getMessage('planFilterAddTeammember')}/>
          <View style={styles.spacer}/>

          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('planFilterTimePeriod')}</MaterialText>
          <View style={{ alignContent: 'center', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
            <DateInput selectedDate={intervalStart == null ? undefined : new Date(intervalStart)} placeholder={I18n.m.getMessage('date')} key={`intervalStart${intervalStart}`} onChange={this.setIntervalStart}/>
            <View style={{
                justifyContent: 'center',
                paddingLeft: ThemeManager.style.contentPaddingValue,
                paddingRight: ThemeManager.style.contentPaddingValue,
            }}>
              <MaterialText centeredText>{I18n.m.getMessage('till')}</MaterialText>
            </View>
            <DateInput selectedDate={intervalEnd == null ? undefined : new Date(intervalEnd)} placeholder={I18n.m.getMessage('date')} key={`intervalEnd${intervalEnd}`} onChange={this.setIntervalEnd}/>
          </View>
        </View>
        <DialogActions key="actions" onBottom>
          <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('clear')} onPress={this.onReset}/>
          <ContainedButton title={`${I18n.m.getMessage('apply')} ${amountSelected}/${plans.length}`} onPress={this.onSelect} disabled={amountSelected === 0}/>
        </DialogActions>
      </View>);
    }
    createCurrentFilter() {
        const currentFilter = new PlanFilter();
        const { selectedTags, selectedCreator, intervalStart, intervalEnd } = this.state;
        if (selectedTags != null && selectedTags.length > 0) {
            currentFilter.t = [];
            selectedTags.forEach((t) => {
                if (t.id != null)
                    currentFilter.t.push(t.id);
            });
        }
        if (selectedCreator != null && selectedCreator.length > 0) {
            currentFilter.u = [];
            if (selectedCreator != null && selectedCreator.length > 0) {
                for (let i = 0; i < selectedCreator.length; i += 1) {
                    const c = selectedCreator[i];
                    if (c != null && c.id != null) {
                        currentFilter.u.push(c.id);
                    }
                }
            }
        }
        currentFilter.is = intervalStart != null ? intervalStart : undefined;
        currentFilter.ie = intervalEnd != null ? intervalEnd : undefined;
        return currentFilter;
    }
    static getTagsGroups(projects) {
        const projectTagGroups = [];
        for (const project of projects) {
            for (const key in project.tagGroups) {
                const { groupName } = project.tagGroups[key];
                if (groupName != null && projectTagGroups.findIndex((a) => a.id === groupName) === -1)
                    projectTagGroups.push({
                        backgroundColor: project.tagGroups[key].color,
                        groupName,
                        id: groupName,
                    });
            }
        }
        return projectTagGroups;
    }
    static getTags(plans) {
        const projectTags = [];
        const tags = [];
        plans.forEach((t) => {
            if (t.tags != null && t.tags.length > 0) {
                t.tags.forEach((tag) => {
                    if (tags.findIndex((tt) => tt.tagName === tag.tagName) === -1)
                        tags.push(tag);
                });
            }
        });
        for (let i = 0; i < tags.length; i += 1) {
            projectTags.push({
                id: tags[i].tagName,
                backgroundColor: tags[i].color,
                groupId: tags[i].groupName,
                title: tags[i].tagName,
                textColor: '#FFFFFF',
            });
        }
        return projectTags;
    }
    async getPersons() {
        const { plans } = this.props;
        const values = [];
        const availableUserIds = [];
        plans.forEach((t) => {
            if (t.createdBy != null && t.createdBy.length > 0) {
                if (availableUserIds.indexOf(t.createdBy) === -1)
                    availableUserIds.push(t.createdBy);
            }
        });
        for (let i = 0; i < availableUserIds.length; i += 1) {
            try {
                const user = await AuthClient.instance.modals.user.getById(availableUserIds[i]);
                values.push({
                    id: user.id,
                    title: user.getFullName(),
                });
            }
            catch (e) {
                console.debug('cant get user', availableUserIds[i]);
            }
        }
        return values;
    }
    static getSelectedChipsByIds(chips, ids) {
        const selectedTags = [];
        if (ids != null && ids.length > 0) {
            for (let i = 0; i < ids.length; i += 1) {
                for (let j = 0; j < chips.length; j += 1) {
                    if (ids[i] === chips[j].id) {
                        selectedTags.push(chips[j]);
                        break;
                    }
                }
            }
        }
        return selectedTags;
    }
    resetFilters() {
        this.setState({
            selectedCreator: [],
            intervalStart: undefined,
            intervalEnd: undefined,
            selectedTags: [],
        }, this.calculateFiltered);
    }
}
