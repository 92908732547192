import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import BackButton from 'materialTheme/src/views/components/BackButton';
import { PageView } from 'materialTheme/src/views/root/PageView';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { AddMeAsProjectManager } from 'upmesh-core/src/client/commands/user/AddMeAsProjectManager';
import { RemoveProjectUser } from 'upmesh-core/src/client/commands/user/RemoveProjectUser';
import { TicketFilter } from 'upmesh-core/src/client/query/filter/TicketFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { NoRights } from '../NoRights';
import { TeamMembersList } from '../team/TeamMembersList';
export class CompanyProjectTeamView extends PureComponent {
    constructor(props) {
        super(props);
        this.openFab = () => {
            if (Fab.instance != null) {
                const { projectId } = this.props.pathvars;
                const asyncNow = async () => {
                    try {
                        const project = await UpmeshClient.instance.modals.project.getById(projectId);
                        const projectUser = await UpmeshClient.instance.modals.projectUser.get({
                            filter: `userId eq '${CurrentUser.userId}' and projectId eq '${projectId}' and status eq 'accepted'`,
                        });
                        const u = projectUser[0];
                        if (u.roleId === '1') {
                            Fab.instance?.open({
                                fabIcon: 'location-enter',
                                fabIconOpen: 'close',
                                small: false,
                                fabColor: ThemeManager.style.brandPrimary,
                                fabColorOpen: ThemeManager.style.brandSecondary,
                                onPressFab: Routing.instance.goToButton(`/projects/${project.id}/${StoredStartPages.getStoredProjectStartPage()}`),
                            });
                        }
                        else {
                            Fab.instance?.open({
                                fabIcon: 'chevron-up',
                                fabIconOpen: 'chevron-down',
                                fabActions: [
                                    {
                                        text: I18n.m.getMessage('enterProject'),
                                        icon: 'location-enter',
                                        onPress: Routing.instance.goToButton(`/projects/${project.id}/${StoredStartPages.getStoredProjectStartPage()}`),
                                    },
                                    {
                                        text: I18n.m.getMessage('changeRoleToManager'),
                                        icon: 'account-edit-outline',
                                        onPress: this.addMeAsManager,
                                    },
                                ],
                                small: false,
                                fabColor: ThemeManager.style.brandPrimary,
                                fabColorOpen: ThemeManager.style.brandSecondary,
                            });
                        }
                    }
                    catch (e) {
                        Fab.instance?.open({
                            fabIcon: 'plus',
                            fabIconOpen: 'close',
                            small: false,
                            fabColor: ThemeManager.style.brandPrimary,
                            fabColorOpen: ThemeManager.style.brandSecondary,
                            onPressFab: this.addMeAsManager,
                        });
                    }
                };
                asyncNow().catch((err) => console.error(err));
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.addMeAsManager = (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyProjectTeamAddMeAsManagerQuestion'),
                buttons: [
                    <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                    <ContainedButton key="add" title={I18n.m.getMessage('companyProjectTeamAddMeAsManagerButton')} onPress={() => {
                            this.addMeAsManagerNow().catch((err) => console.error(err));
                        }}/>,
                ],
            });
        };
        this.addMeAsManagerNow = async () => {
            const { projectId } = this.props.pathvars;
            LoadingEvents.instance.startLoading();
            Alert.instance?.close();
            try {
                const c = new AddMeAsProjectManager({ projectId });
                await c.execute();
                const count = async () => UpmeshClient.instance.modals.project.count(`id eq '${projectId}'`);
                let timeout = 0;
                while ((await count()) === 0 && timeout < 20) {
                    await new Promise((r) => {
                        timeout += 1;
                        setTimeout(r, 500);
                    });
                }
                if (AuthClient.instance != null && AuthClient.instance.serverConnection != null) {
                    await new Promise((resolve) => {
                        AuthClient.instance.syncDispatcher.syncStop.once(() => {
                            resolve();
                        });
                        if (AuthClient.instance != null)
                            AuthClient.instance.startSync().catch((err) => console.debug(err));
                        else
                            resolve();
                    });
                }
                await LoadingEvents.instance.stopLoading();
                requestAnimationFrame(() => {
                    Routing.instance.goTo(`/projects/${projectId}/${StoredStartPages.getStoredProjectStartPage()}`);
                });
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
            }
            LoadingEvents.instance.stopLoading();
        };
        this.abortDelete = (_e) => {
            Alert.instance?.close();
        };
        this.isLoading = false;
        this.loadTeamMember = async () => {
            const { pathvars } = this.props;
            const project = await UpmeshClient.instance.modals.companyProject.getById(pathvars.projectId);
            let inProject = false;
            if (this.isLoadingTo != null)
                clearTimeout(this.isLoadingTo);
            if (this.isLoading) {
                this.isLoadingTo = setTimeout(() => {
                    this.loadTeamMember().catch((err) => console.error(err));
                }, 100);
            }
            else {
                this.isLoading = true;
                if (CurrentUser.entity == null) {
                    this.setState({ error: 'forbidden' });
                    return;
                }
                const teammembers = [];
                const projectUser = await UpmeshClient.instance.modals.projectUser.get({
                    filter: `projectId eq '${project.id}' and status ne 'deleted'`,
                });
                for (let i = 0; i < projectUser.length; i += 1) {
                    let username = '';
                    try {
                        let user;
                        if (projectUser[i].userId != null && projectUser[i].status === 'accepted') {
                            user = await AuthClient.instance.modals.user.getById(projectUser[i].userId);
                            username = `${user.firstname} ${user.lastname}`;
                        }
                        else {
                            username = I18n.m.getMessage('teamInvitationPending');
                        }
                        const role = await UpmeshClient.instance.modals.projectRoles.getById(projectUser[i].roleId);
                        const translatedRole = I18n.m.getMessage(role.roleName);
                        teammembers.push({
                            id: projectUser[i].id,
                            translatedRole,
                            user,
                            company: projectUser[i].userGroup,
                            emails: user != null ? (user.emails == null ? '' : user.emails.join(', ')) : projectUser[i].toEmail,
                            toMail: projectUser[i].toEmail,
                            name: username,
                            status: projectUser[i].status,
                            userId: projectUser[i].userId,
                        });
                    }
                    catch (e) {
                        console.debug(e);
                    }
                    if (CurrentUser.userId === projectUser[i].userId)
                        inProject = true;
                }
                this.setState({ teammembers, project, inProject, init: true }, () => {
                    this.isLoading = false;
                    this.openFab();
                });
            }
        };
        this.removeMe = async () => {
            const { project } = this.state;
            if (project == null)
                return;
            const me = await UpmeshClient.instance.modals.projectUser.get({
                filter: `userId eq '${CurrentUser.userId}' and projectId eq '${project.id}'`,
            });
            if (me.length > 0) {
                this.removeUser({
                    id: me[0].id,
                    status: 'accepted',
                    name: CurrentUser.entity?.getFullName(),
                    emails: '',
                    toMail: '',
                    userId: me[0].userId,
                    user: CurrentUser.entity,
                    company: '',
                    translatedRole: '',
                })();
            }
        };
        this.removeUser = (pu) => () => {
            Menu.instance?.close();
            const message = pu.userId === CurrentUser.userId ? 'teamRemovePeopleMe' : 'teamRemovePeople';
            const actionMessage = pu.userId === CurrentUser.userId ? 'teamLeaveProject' : 'teamRemoveUser';
            Routing.instance.alert.post({
                text: I18n.m.getMessage(message, { username: pu.name }),
                buttons: [
                    <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={this.abortDelete}/>,
                    <ContainedButton key="save" title={I18n.m.getMessage(actionMessage)} onPress={() => {
                            this.removeUserNow(pu).catch((err) => console.error(err));
                        }} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeUserNow = async (pu) => {
            Alert.instance?.close();
            LoadingEvents.instance.startLoading();
            const { project } = this.state;
            if (project != null) {
                const r = new RemoveProjectUser({}, pu.id);
                try {
                    await r.execute();
                    LoadingEvents.instance.stopLoading();
                    if (pu.userId === CurrentUser.userId) {
                        if (AuthClient.instance != null)
                            AuthClient.instance.startSync().catch((err) => console.debug(err));
                        Routing.instance.goTo('/');
                    }
                    else {
                        this.loadTeamMember().catch((e) => console.debug('cant load Team member', e));
                    }
                }
                catch (e) {
                    if (e.messageCode != null) {
                        if (e.messageCode === 'cantDeleteHasAssigendTickets') {
                            const alertMessage = pu.userId === CurrentUser.userId ? 'cantDeleteHasAssigendTicketsMe' : 'cantDeleteHasAssigendTickets';
                            Routing.instance.alert.post({
                                text: I18n.m.getMessage(alertMessage),
                                buttons: [
                                    <ContainedButton key="showTickets" title={I18n.m.getMessage('teamRemoveUserShowTickets')} onPress={this.showTickets(pu, project.id)}/>,
                                    <ContainedButton key="abort" title={I18n.m.getMessage('abort')} onPress={Alert.instance?.close}/>,
                                ],
                                closeOnTouchOutside: true,
                            });
                        }
                        else {
                            Routing.instance.alert.post({
                                text: I18n.m.getMessage(e.messageCode),
                                buttons: [
                                    <ContainedButton key="abort" title={I18n.m.getMessage('abort')} onPress={Alert.instance?.close}/>,
                                ],
                                closeOnTouchOutside: true,
                            });
                        }
                    }
                    else {
                        ErrorReporter.sendReport({
                            data: e,
                            subject: e.messageCode != null ? e.messageCode : 'cant remove user',
                            type: 'warn',
                        });
                    }
                    LoadingEvents.instance.stopLoading();
                }
            }
        };
        this.showTickets = (pu, projectId) => (_e) => {
            Alert.instance?.close(() => {
                const userTicketFilter = new TicketFilter();
                userTicketFilter.u = [pu.userId];
                userTicketFilter.a = true;
                const f = JSON.stringify(userTicketFilter);
                const deepLinkState = `/projects/${projectId}/tickets?f=${f}`;
                Routing.instance.goTo(deepLinkState);
            });
        };
        this.teamChanged = (en) => {
            const { project } = this.state;
            en.entities.forEach((e) => {
                if (e != null &&
                    project != null &&
                    e.entity != null &&
                    e.entity.projectId != null &&
                    e.entity.projectId === project.id) {
                    this.loadTeamMember().catch((er) => console.debug('cant load Team member', er));
                }
            });
        };
        this.state = {
            init: false,
            teammembers: [],
            inProject: false,
        };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'ProjectUser',
            attachKey: 'companyteamview',
            callback: this.teamChanged,
        });
        this.loadTeamMember().catch((e) => console.debug('cant load Team member', e));
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('ProjectUser', 'companyteamview');
        if (this.isLoadingTo != null)
            clearTimeout(this.isLoadingTo);
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { error, project } = this.state;
        if (project == null) {
            return (<PageView showAccountIcon headerProps={{
                    leftButtons: [<BackButton onAllPlatforms key="backButtonCompanyTeamView"/>],
                    rightButtons: [],
                    title: I18n.m.getMessage('accountCompanySettingsPageTitle'),
                }} scrollable={false} style={{ backgroundColor: ThemeManager.style.appBgColor }}>
          <Spinner />
        </PageView>);
        }
        if (error != null && error.length > 0) {
            return (<PageView showAccountIcon headerProps={{
                    leftButtons: [<BackButton onAllPlatforms key="backButtonCompanyTeamView"/>],
                    rightButtons: [],
                    title: project.title,
                }} scrollable={false} style={{ backgroundColor: ThemeManager.style.appBgColor }}>
          <NoRights error={error}/>
        </PageView>);
        }
        return (<PageView showAccountIcon headerProps={{
                leftButtons: [<BackButton onAllPlatforms key="backButtonCompanyTeamView"/>],
                rightButtons: [],
                title: project.title,
            }} scrollable={false} style={{ backgroundColor: ThemeManager.style.appBgColor }}>
        {!this.state.init || this.state.project == null ? <Spinner /> : <View>{this.renderContent()}</View>}
      </PageView>);
    }
    renderContent() {
        const { teammembers, inProject } = this.state;
        const { size } = this.props;
        const { project } = this.state;
        if (project == null)
            return null;
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
            }}>
        <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('projectMembers')}</MaterialText>
        </View>
        <Card style={{
                alignContent: 'center',
                width: '100%',
                maxHeight: size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(60),
            }}>
          <TeamMembersList size={size} companyProject={project} canEdit canIInvite emptyTableText={!inProject ? I18n.m.getMessage('teamNoMemberTextCompany') : undefined} emptyTableHint={!inProject ? I18n.m.getMessage('teamNoMemberHintCompany') : undefined} users={teammembers} maxHeight={size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(44)} removeUser={this.removeUser}/>
        </Card>
      </View>);
    }
}
CompanyProjectTeamView.defaultProps = {
    title: 'upmesh',
};
