import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Page } from 'materialTheme/src/theme/components/Page';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { FlatList, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { DeletePlanVersion } from 'upmesh-core/src/client/commands/plans/DeletePlanVersion';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
import { PlanThumb169 } from './PlanThumb169';
import { PlanVersionTicketMappingDialog } from './PlanVersionTicketMappingDialog';
export class PlanVersionsList extends PureComponent {
    constructor(props) {
        super(props);
        this.gotoPlanVersion = (id) => () => {
            const { plan } = this.props;
            Routing.instance.goTo(`/projects/${plan.projectId}/plans/${plan.id}/versions/${id}`);
        };
        this.handleVersionsChange = (e) => {
            const { plan } = this.state;
            if (plan != null && plan.projectId === e.projectId) {
                UpmeshClient.instance.modals.planVersion
                    .get({
                    filter: `planId eq '${plan.id}' and deleted ne true`,
                })
                    .then((getPlanVersions) => {
                    getPlanVersions.sort((a, b) => (a.version > b.version ? -1 : a.version < b.version ? 1 : 0));
                    this.setState({ planVersions: getPlanVersions });
                })
                    .catch((err) => console.error(err));
            }
        };
        this.mounted = false;
        this.pressEditPlan = async () => {
            const { plan } = this.state;
            Routing.instance.openDialog('editPlan', { type: 'edit', planId: plan.id });
        };
        this.activateVersion = (id) => (_e) => {
            const { plan, planVersions } = this.props;
            const planVersionNewIndex = planVersions.findIndex((p) => p.id === id);
            const planVersionOldIndex = planVersions.findIndex((p) => p.id === plan.activePlanId);
            PlanVersionTicketMappingDialog.openDialog({
                plan,
                planVersionNew: planVersions[planVersionNewIndex],
                planVersionOld: planVersions[planVersionOldIndex],
            })(_e);
        };
        this.deleteVersion = (item) => () => {
            RightsManager.hasWriteRight(item.projectId, CurrentUser.userId, 'commandDiscardPlanFile')
                .then((hasRight) => {
                if (!hasRight)
                    throw { message: 'Keine Berechtigung', messageCode: 'forbiddenCommand' };
                Routing.instance.alert.post({
                    title: I18n.m.getMessage('planVersionDeleteQuestionTitle', { plantitle: item.orgFilename }),
                    text: I18n.m.getMessage('planVersionDeleteQuestionText'),
                    buttons: [
                        <ContainedButton key="cancel" onPress={Alert.instance?.close} title={I18n.m.getMessage('cancel')}/>,
                        <ContainedButton key="del" onPress={this.deleteVersionNow(item)} title={I18n.m.getMessage('delete')} backgroundColor={ThemeManager.style.brandDanger}/>,
                    ],
                });
            })
                .catch((e) => {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            });
        };
        this.deleteVersionNow = (item) => () => {
            Alert.instance?.close(() => {
                const { selectedPlanVersionId, plan } = this.props;
                try {
                    const d = new DeletePlanVersion({}, item.id);
                    d.execute()
                        .then(() => {
                        if (selectedPlanVersionId === item.id) {
                            requestAnimationFrame(() => {
                                Routing.instance.goTo(`/projects/${plan.projectId}/plans/${plan.id}/versions/${plan.activePlanId}`, true);
                            });
                        }
                    })
                        .catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e));
                }
                catch (e) {
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                }
            });
        };
        this.downloadVersionNow = (planVersion) => () => {
            Alert.instance?.close(() => {
                const { plan } = this.props;
                const ex = planVersion.orgFilename.substr(planVersion.orgFilename.lastIndexOf('.'));
                CurrentProject.instance.downloadPlanNow(plan, planVersion, ex).catch((error) => {
                    DefaultErrorHandler.showDefaultErrorAlert(error, I18n.m, error);
                });
            });
        };
        this.renderRow = ({ item }) => {
            const { selectedPlanVersionId, plan } = this.props;
            const selected = item.id === selectedPlanVersionId;
            const active = item.id === plan.activePlanId;
            return (<View style={{ padding: ThemeManager.style.contentPaddingValue, paddingBottom: 0 }} key={`PV1${item.id}`}>
        <View style={{
                    paddingBottom: 0,
                    backgroundColor: '#FFFFFF',
                    margin: 0,
                    borderWidth: selected ? ThemeManager.style.borderWidth * 2 : ThemeManager.style.borderWidth,
                    borderStyle: 'solid',
                    borderColor: selected === true ? ThemeManager.style.brandPrimary : ThemeManager.style.borderColor,
                    borderRadius: ThemeManager.style.borderRadius,
                    overflow: 'hidden',
                }}>
          <PlanThumb169 projectId={item.projectId} onPress={this.gotoPlanVersion(item.id)} noBorder fileSource="planVersion" fileId={item.id} width={256 - 2 * ThemeManager.style.contentPaddingValue} title={item.orgFilename} deleteFunction={active ? undefined : this.deleteVersion(item)} downloadFunction={this.downloadVersionNow(item)}/>
          <View style={{
                    width: '100%',
                    padding: 0,
                }}>
            <View style={{ marginTop: 0, paddingRight: 8, paddingLeft: 6, paddingBottom: 0 }}>
              <MaterialText color={ThemeManager.style.black54} type={MaterialTextTypes.Body1}>
                {I18n.m.dateCurrent.localeDateString(item.createdAt)}
              </MaterialText>
            </View>
            {active ? (<View key="planBadge" style={{
                        alignSelf: 'stretch',
                        backgroundColor: ThemeManager.style.brandPrimary,
                        justifyContent: 'center',
                        padding: 0,
                    }}>
                <MaterialText centeredText centeredBox type={MaterialTextTypes.Body1} color="#FFFFFF">
                  {I18n.m.getMessage('planVersionActive')}
                </MaterialText>
              </View>) : (<ContainedButton disabled={active} title={I18n.m.getMessage('planActivate')} full onPress={this.activateVersion(item.id)} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>)}
          </View>
        </View>
      </View>);
        };
        this.getItemKey = (item, _index) => `planversion_${item.id}`;
        this.state = {
            init: false,
            planVersions: [],
            plan: props.plan,
            selectedPlanVersionId: props.planVersionId,
        };
    }
    componentDidMount() {
        CurrentProject.planVersionsChanged.attach(this.handleVersionsChange);
        this.init(this.props).catch((err) => console.debug(err));
    }
    async init(props) {
        const { plan, planVersions } = props;
        this.setState({ plan, planVersions, init: true }, () => { });
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.plan.id !== prevState.plan.id ||
            nextProps.selectedPlanVersionId !== prevState.selectedPlanVersionId) {
            return { init: true, selectedPlanVersionId: nextProps.selectedPlanVersionId };
        }
        return null;
    }
    componentWillUnmount() {
        CurrentProject.planVersionsChanged.detach(this.handleVersionsChange);
        this.mounted = false;
    }
    render() {
        if (!this.state.init) {
            return (<Page>
          <Spinner />
        </Page>);
        }
        const { planVersions } = this.state;
        const { style, width, height } = this.props;
        const itemWidth = 256;
        const numColumns = Math.floor(width / itemWidth);
        return (<View style={[
                {
                    width,
                    height,
                    padding: 0,
                },
                style,
            ]}>
        <FlatList key={`planVersionsList_${numColumns}`} keyExtractor={this.getItemKey} renderItem={this.renderRow} numColumns={numColumns} data={planVersions} contentContainerStyle={{
                alignItems: numColumns < 2 ? 'center' : 'flex-start',
                paddingBottom: 32,
            }} style={{
                width,
                height,
            }}/>
      </View>);
    }
}
