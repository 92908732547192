var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var React = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _constants = require("./constants");
var _SizeAndPositionManager = _interopRequireDefault(require("./SizeAndPositionManager"));
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
var _excluded = ["estimatedItemSize", "height", "overscanCount", "renderItem", "itemCount", "itemSize", "onItemsRendered", "onScroll", "scrollDirection", "scrollOffset", "scrollToIndex", "scrollToAlignment", "style", "width", "numColumns", "align"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var STYLE_WRAPPER = {
  overflow: 'visible'
};
var STYLE_INNER = {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  minHeight: '100%'
};
var STYLE_ITEM = {
  position: 'absolute',
  left: 0,
  width: '100%'
};
var VirtualGridList = function (_React$PureComponent) {
  function VirtualGridList(props) {
    var _this;
    (0, _classCallCheck2.default)(this, VirtualGridList);
    _this = _callSuper(this, VirtualGridList, [props]);
    _this._currentScrollPosition = 0;
    _this.styleCache = {};
    _this.getRef = function (node) {
      _this.rootNode = node;
    };
    _this.handleScroll = function (ev) {
      var e = ev.nativeEvent;
      var onScroll = _this.props.onScroll;
      var scrollDirection = _this.props.scrollDirection;
      var offset = scrollDirection === _constants.DIRECTION_VERTICAL ? e.contentOffset.y : e.contentOffset.x;
      _this._currentScrollPosition = offset;
      if (offset < 0 || _this.state.offset === offset) {
        return;
      }
      _this.setState({
        offset: offset,
        scrollChangeReason: _constants.SCROLL_CHANGE_OBSERVED
      });
      if (typeof onScroll === 'function') {
        onScroll(offset, e);
      }
    };
    _this.construct(props);
    _this.state = {
      offset: props.scrollOffset != null ? props.scrollOffset : props.scrollToIndex != null ? _this.getOffsetForIndex(props.scrollToIndex) : 0,
      scrollChangeReason: _constants.SCROLL_CHANGE_REQUESTED
    };
    if (props.scrollToIndex >= 0) {
      requestAnimationFrame(function () {
        _this.scrollToIndex(props.scrollToIndex);
      });
    }
    return _this;
  }
  (0, _inherits2.default)(VirtualGridList, _React$PureComponent);
  return (0, _createClass2.default)(VirtualGridList, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;
      var _this$props = this.props,
        scrollOffset = _this$props.scrollOffset,
        scrollToIndex = _this$props.scrollToIndex;
      if (scrollOffset != null) {
        this.scrollTo(scrollOffset);
      } else if (scrollToIndex != null) {
        requestAnimationFrame(function () {
          _this2.scrollToIndex(scrollToIndex);
        });
      }
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, prevState) {
      var _this3 = this;
      var _this$state = this.state,
        offset = _this$state.offset,
        scrollChangeReason = _this$state.scrollChangeReason;
      if (prevState.offset !== offset && scrollChangeReason === _constants.SCROLL_CHANGE_REQUESTED) {
        this.scrollTo(offset);
      }
      var _this$props2 = this.props,
        estimatedItemSize = _this$props2.estimatedItemSize,
        itemCount = _this$props2.itemCount,
        itemSize = _this$props2.itemSize,
        scrollOffset = _this$props2.scrollOffset,
        scrollToAlignment = _this$props2.scrollToAlignment,
        scrollToIndex = _this$props2.scrollToIndex,
        numColumns = _this$props2.numColumns;
      var scrollPropsHaveChanged = prevProps.scrollToIndex !== scrollToIndex || prevProps.scrollToAlignment !== scrollToAlignment;
      var itemPropsHaveChanged = prevProps.itemCount !== itemCount || prevProps.itemSize !== itemSize || prevProps.estimatedItemSize !== estimatedItemSize;
      if (prevProps.itemCount !== itemCount || prevProps.estimatedItemSize !== estimatedItemSize || prevProps.numColumns !== numColumns) {
        this.construct(prevProps);
        this.forceUpdate();
      }
      if (itemPropsHaveChanged) {
        this.recomputeSizes();
      }
      if (prevProps.scrollOffset !== scrollOffset) {
        this.setState({
          offset: prevProps.scrollOffset != null ? prevProps.scrollOffset : 0,
          scrollChangeReason: _constants.SCROLL_CHANGE_REQUESTED
        });
      } else if (typeof prevProps.scrollToIndex === 'number' && (scrollPropsHaveChanged || itemPropsHaveChanged)) {
        this.setState({
          offset: this.getOffsetForIndex(prevProps.scrollToIndex, prevProps.scrollToAlignment, prevProps.itemCount),
          scrollChangeReason: _constants.SCROLL_CHANGE_REQUESTED
        }, function () {
          requestAnimationFrame(function () {
            _this3.scrollToIndex(prevProps.scrollToIndex);
          });
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props3 = this.props,
        estimatedItemSize = _this$props3.estimatedItemSize,
        height = _this$props3.height,
        _this$props3$overscan = _this$props3.overscanCount,
        overscanCount = _this$props3$overscan === void 0 ? 3 : _this$props3$overscan,
        renderItem = _this$props3.renderItem,
        itemCount = _this$props3.itemCount,
        itemSize = _this$props3.itemSize,
        onItemsRendered = _this$props3.onItemsRendered,
        onScroll = _this$props3.onScroll,
        _this$props3$scrollDi = _this$props3.scrollDirection,
        scrollDirection = _this$props3$scrollDi === void 0 ? _constants.DIRECTION_VERTICAL : _this$props3$scrollDi,
        scrollOffset = _this$props3.scrollOffset,
        scrollToIndex = _this$props3.scrollToIndex,
        scrollToAlignment = _this$props3.scrollToAlignment,
        style = _this$props3.style,
        width = _this$props3.width,
        numColumns = _this$props3.numColumns,
        align = _this$props3.align,
        props = (0, _objectWithoutProperties2.default)(_this$props3, _excluded);
      var offset = this.state.offset;
      var _this$sizeAndPosition = this.sizeAndPositionManager.getVisibleRange({
          offset: offset,
          overscanCount: overscanCount,
          containerSize: this.props[_constants.sizeProp[scrollDirection]] ? this.props[_constants.sizeProp[scrollDirection]] : 0
        }),
        start = _this$sizeAndPosition.start,
        stop = _this$sizeAndPosition.stop;
      var items = [];
      if (typeof start !== 'undefined' && typeof stop !== 'undefined') {
        for (var index = start; index <= stop; index += 1) {
          items.push(renderItem({
            index: index,
            style: this.getStyle(index)
          }));
        }
        if (typeof onItemsRendered === 'function') {
          onItemsRendered({
            startIndex: start,
            stopIndex: stop
          });
        }
      }
      var columnStyle = {};
      if (numColumns != null && numColumns > 1) {
        columnStyle = {
          flexDirection: 'row',
          flexWrap: 'wrap'
        };
      }
      var wrapperStyle = Object.assign({}, STYLE_WRAPPER, style, {
        height: height,
        width: width
      });
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: width,
          height: height,
          overflow: 'hidden'
        },
        collapsable: false,
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, Object.assign({
          scrollEventThrottle: 16
        }, props, {
          keyboardShouldPersistTaps: "always",
          collapsable: false,
          ref: this.getRef,
          onScroll: this.handleScroll,
          horizontal: scrollDirection === 'horizontal',
          style: wrapperStyle,
          contentContainerStyle: (0, _defineProperty2.default)((0, _defineProperty2.default)({}, _constants.sizePropInVert[scrollDirection], align === 'center' ? numColumns * itemSize[_constants.sizePropInVert[scrollDirection]] : '100%'), "alignSelf", align),
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: Object.assign({
              height: height
            }, STYLE_INNER, columnStyle, (0, _defineProperty2.default)({}, _constants.sizeProp[scrollDirection], this.sizeAndPositionManager.getTotalSize())),
            children: items
          })
        }), "scrollView")
      });
    }
  }, {
    key: "scrollToIndex",
    value: function scrollToIndex(index) {
      var _this$props4 = this.props,
        scrollDirection = _this$props4.scrollDirection,
        itemSize = _this$props4.itemSize;
      var offset = this.getOffsetForIndex(index);
      var containerSize = this.props[_constants.sizeProp[scrollDirection]];
      var visibleArea = {
        min: this._currentScrollPosition,
        max: this._currentScrollPosition + containerSize - itemSize[_constants.sizeProp[scrollDirection]]
      };
      if (offset > visibleArea.max) {
        this.scrollTo(offset - containerSize + itemSize[_constants.sizeProp[scrollDirection]]);
      } else if (offset < visibleArea.min) {
        this.scrollTo(offset);
      } else {
        this.forceUpdate();
      }
    }
  }, {
    key: "construct",
    value: function construct(props) {
      this.sizeAndPositionManager = new _SizeAndPositionManager.default({
        itemCount: props.itemCount,
        itemSize: props.itemSize,
        direction: props.scrollDirection,
        numColumns: props.numColumns
      });
    }
  }, {
    key: "getOffsetForIndex",
    value: function getOffsetForIndex(index) {
      var _scrollToAlignment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.props.scrollToAlignment;
      var _itemCount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.props.itemCount;
      var ind = 0;
      if (index != null) {
        ind = index;
      }
      var _this$props5 = this.props,
        scrollDirection = _this$props5.scrollDirection,
        numColumns = _this$props5.numColumns,
        itemSize = _this$props5.itemSize;
      return Math.floor(ind / numColumns) * itemSize[_constants.sizeProp[scrollDirection]];
    }
  }, {
    key: "getStyle",
    value: function getStyle(index) {
      var style = this.styleCache[index];
      if (style != null) {
        return style;
      }
      var _this$props6 = this.props,
        _this$props6$scrollDi = _this$props6.scrollDirection,
        scrollDirection = _this$props6$scrollDi === void 0 ? _constants.DIRECTION_VERTICAL : _this$props6$scrollDi,
        numColumns = _this$props6.numColumns;
      var _this$sizeAndPosition2 = this.sizeAndPositionManager.getSizeAndPositionForIndex(index),
        offset = _this$sizeAndPosition2.offset,
        offset2 = _this$sizeAndPosition2.offset2;
      var percentWidth = 100 / numColumns;
      var cStyle = {
        width: `${percentWidth}%`
      };
      this.styleCache[index] = Object.assign({}, STYLE_ITEM, cStyle, this.props.itemSize, (0, _defineProperty2.default)((0, _defineProperty2.default)({}, _constants.positionProp[scrollDirection], offset), _constants.positionPropInvert[scrollDirection], offset2));
      return this.styleCache[index];
    }
  }, {
    key: "recomputeSizes",
    value: function recomputeSizes() {
      var startIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this.styleCache = {};
      this.sizeAndPositionManager.resetItem(startIndex);
    }
  }, {
    key: "scrollTo",
    value: function scrollTo(value) {
      var scrollDirection = this.props.scrollDirection;
      var x = 0;
      var y = 0;
      if (scrollDirection === _constants.DIRECTION_VERTICAL) {
        y = value;
      } else {
        x = value;
      }
      if (this.rootNode != null && typeof this.rootNode.scrollTo === 'function') {
        this.rootNode.scrollTo({
          x: x,
          y: y,
          animated: false
        });
      }
    }
  }]);
}(React.PureComponent);
VirtualGridList.defaultProps = {
  overscanCount: 3,
  scrollDirection: _constants.DIRECTION_VERTICAL
};
var _default = exports.default = VirtualGridList;