import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddProjectToCostUnit } from 'upmesh-core/src/client/commands/companies/costcenter/AddProjectToCostUnit';
import { DeleteCostCenter } from 'upmesh-core/src/client/commands/companies/costcenter/DeleteCostCenter';
import { RemoveProjectFromCostUnit } from 'upmesh-core/src/client/commands/companies/costcenter/RemoveProjectFromCostUnit';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddOrChangeCostCenterToCompanyDialog } from './AddOrChangeCostCenterToCompanyDialog';
const noCostCenterImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class CompanyCostCenterView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.updateWrapper = () => {
            if (this.updateTimeOut != null)
                clearTimeout(this.updateTimeOut);
            this.updateTimeOut = setTimeout(this.updateAll, 300);
        };
        this.updateAll = () => {
            const { company } = this.props;
            const asyncNow = async () => {
                const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                    filter: `deleted ne true and companyId eq '${company.id}'`,
                });
                const data = [];
                const companyProjects = await UpmeshClient.instance.modals.companyProject.get();
                for (const costCenter of costCenters) {
                    const projectTitles = [];
                    const projects = [];
                    costCenter.projectIds?.forEach((id) => {
                        const c = companyProjects.find((p) => p.id === id);
                        if (c != null && !c.deleted) {
                            projectTitles.push(c.title);
                            projects.push({ id: c.id, title: c.title });
                        }
                        else {
                            const title = c != null ? `${c.title} (${I18n.m.getMessage('deletedProject')})` : I18n.m.getMessage('deletedProject');
                            projectTitles.push(title);
                            projects.push({ id, title });
                        }
                    });
                    data.push({
                        ...costCenter,
                        id: costCenter.id,
                        projectTitles: projectTitles.join(', '),
                        projects,
                    });
                }
                const projectListItems = [];
                for (let i = 0; i < companyProjects.length; i += 1) {
                    const project = companyProjects[i];
                    projectListItems.push({
                        title: `${project.title}`,
                        data: project.id,
                    });
                }
                this.setState({ data, projectListItems });
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.removeCostCenter = (costCenter) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveCostCenterQuestion'),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeCostCenterNow(costCenter)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeCostCenterNow = (CostCenter) => (_e) => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new DeleteCostCenter({}, CostCenter.id, CurrentUser.token);
                remove
                    .execute(ClientStore.commandStore)
                    .catch((e) => {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.openFab = () => {
            if (Fab.instance != null) {
                const permitted = this.myRole() === 'admin';
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: permitted
                        ? this.openAddDialog
                        : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
                });
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.openAddDialog = (_e) => {
            const { company } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddOrChangeCostCenterToCompanyDialog company={company}/>,
                contentPadding: false,
                showCloseIcon: true,
                scrollable: false,
            });
        };
        this.openEditDialog = (costCenter) => (_e) => {
            if (this.myRole() === 'admin') {
                const { company } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <AddOrChangeCostCenterToCompanyDialog costCenter={costCenter} company={company}/>,
                    contentPadding: false,
                    showCloseIcon: true,
                    scrollable: false,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.openChangeProjectssDialog = (_item) => (_e) => {
            const { projectListItems } = this.state;
            const list = [...projectListItems];
            for (const p of list) {
                p.selected = p.data != null && _item.projectIds?.includes(p.data);
            }
            if (_item.projectIds != null) {
                for (const p of _item.projectIds) {
                    const isInList = list.find((a) => a.data === p);
                    if (!isInList) {
                        list.push({
                            title: I18n.m.getMessage('deletedProject'),
                            data: p,
                            selected: true,
                        });
                    }
                }
            }
            Dialog.instance?.open({
                content: (<ChipDialogForm key="MembersDialogForm" title={I18n.m.getMessage('companyCostCenterChangeProjects')} items={list} onCancel={() => Dialog.instance?.close()} multiselect onSave={(e) => {
                        this.onSaveCostCenterProects(e, _item).catch((err) => console.error(err));
                    }} canAddNewChips={false} searchBarPlaceholder={I18n.m.getMessage('search')}/>),
                contentPadding: false,
                fullscreenResponsive: true,
            });
        };
        this.onSaveCostCenterProects = async (items, costCenter) => {
            try {
                const addToCostCenterPromises = [];
                items.forEach((p) => {
                    const ind = costCenter.projectIds == null ? -1 : costCenter.projectIds.findIndex((a) => a === p.data);
                    if (!p.selected && ind > -1) {
                        const c = new RemoveProjectFromCostUnit({ projectId: p.data }, costCenter.id);
                        addToCostCenterPromises.push(c.execute(ClientStore.commandStore));
                    }
                    else if (p.selected && ind === -1) {
                        const c = new AddProjectToCostUnit({ projectId: p.data }, costCenter.id);
                        addToCostCenterPromises.push(c.execute(ClientStore.commandStore));
                    }
                });
                await Promise.all(addToCostCenterPromises);
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            }
            Dialog.instance?.close();
        };
        this.state = {
            myRole: this.myRole(),
            projectListItems: [],
            data: [],
        };
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyCostCenterView',
            readModelName: 'CostCenter',
            callback: this.updateWrapper,
        });
        this.openFab();
        this.updateAll();
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('CostCenter', 'CompanyCostCenterView');
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { company, size } = this.props;
        const { data, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(60);
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('costCenters')}
          </MaterialText>
        </View>
        <Card style={{ width: '100%', height: tableMaxHeight }}>
          <Table tableName="CompanyCostCenter" actionItemsLength={3} actions={myRole === 'admin'
                ? (_item) => {
                    const buttons = [
                        {
                            icon: 'pencil-outline',
                            onAction: this.openEditDialog,
                            toolTip: I18n.m.getMessage('edit'),
                        },
                        {
                            icon: 'delete',
                            onAction: this.removeCostCenter,
                            toolTip: I18n.m.getMessage('delete'),
                            disabled: _item.id === company.id,
                        },
                    ];
                    if (_item.costType === 'costUnit') {
                        buttons.unshift({
                            icon: 'factory',
                            onAction: this.openChangeProjectssDialog,
                            toolTip: I18n.m.getMessage('companyCostCenterChangeProjects'),
                        });
                    }
                    return buttons;
                }
                : undefined} maxHeight={tableMaxHeight - 16} emptyTableImage={noCostCenterImg} emptyTableHint={I18n.m.getMessage('companyCostCenterNoDataHint')} emptyTableText={I18n.m.getMessage('companyCostCenterNoDataText')} data={data} sortBy="mark" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('costCenterMark'),
                    keyInData: 'mark',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('costCenterDescription'),
                    keyInData: 'description',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('costCenterType'),
                    keyInData: 'costType',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                    cellRenderer: (item, column, _index) => {
                        return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
                      <MaterialText>
                        {item.costType === 'costUnit' ? I18n.m.getMessage('yes') : I18n.m.getMessage('no')}
                      </MaterialText>
                    </View>);
                    },
                },
                {
                    title: I18n.m.getMessage('active'),
                    keyInData: 'active',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                    cellRenderer: (item, column, _index) => {
                        return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
                      <MaterialText>
                        {item.active !== false ? I18n.m.getMessage('yes') : I18n.m.getMessage('no')}
                      </MaterialText>
                    </View>);
                    },
                },
                {
                    title: I18n.m.getMessage('projects'),
                    keyInData: 'projectTitles',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
            ]}/>
        </Card>
      </View>);
    }
}
