"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SaveJournalSettings = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var Config_1 = require("../../../../Config");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ClientStore_1 = require("../../../ClientStore");
var ProjectEntity_1 = require("../../../query/entities/ProjectEntity");
var SaveJournalSettings = function (_CommandRoot_1$Comman) {
  function SaveJournalSettings(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, SaveJournalSettings);
    _this = _callSuper(this, SaveJournalSettings, [data, entityId, token]);
    _this.commandName = 'SaveJournalSettings';
    _this.aggregate = 'project';
    return _this;
  }
  (0, _inherits2.default)(SaveJournalSettings, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(SaveJournalSettings, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var project = yield CommandReadModels_1.CommandReadModels.instance.project.getById(this.data.projectId);
        var companyMember = yield CommandReadModels_1.CommandReadModels.instance.companyMember.get({
          filter: `userId eq '${userId}' and deleted ne true and role ne 'inactive'`
        });
        if (companyMember != null && companyMember.length > 0) {
          if (project.creator !== userId) {
            if (companyMember[0].disableJournalEdit) {
              throw StdApplicationError_1.StdApplicationError.forbidden('keine Berechtigung');
            }
          }
          this.company = yield CommandReadModels_1.CommandReadModels.company.getById(companyMember[0].companyId);
        }
        yield ProjectEntity_1.ProjectEntity.checkProjectStatusAndGeneralAccess(this.data.projectId, userId);
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'projectId') {
          if (this.data.projectId.length <= 0) {
            throw {
              key: 'projectId',
              messageCode: 'required',
              message: 'wird benötigt'
            };
          }
        }
        if (key == null || key === 'additionalFields') {
          var _this$data$additional;
          if (this.data.additionalFields != null && ((_this$data$additional = this.data.additionalFields) == null ? void 0 : _this$data$additional.length) > 0) {
            for (var field of this.data.additionalFields) {
              if (field.name.length > Config_1.ConfigHandler.config.string_Short) {
                throw {
                  key: 'additionalFields',
                  messageCode: 'stringToLong',
                  message: 'Eingabe ist zu lang'
                };
              }
            }
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return undefined;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "execute",
    value: function () {
      var _execute = (0, _asyncToGenerator2.default)(function* () {
        var onStore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ClientStore_1.ClientStore.commandStore;
        return (0, _get2.default)((0, _getPrototypeOf2.default)(SaveJournalSettings.prototype), "execute", this).call(this, onStore);
      });
      function execute() {
        return _execute.apply(this, arguments);
      }
      return execute;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.SaveJournalSettings = SaveJournalSettings;