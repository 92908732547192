import * as EmailValidator from 'email-validator';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { CheckboxListItem } from 'materialTheme/src/theme/components/CheckboxListItem';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeCompanyUser } from 'upmesh-core/src/client/commands/companies/ChangeCompanyUser';
import { GroupEntity } from 'upmesh-core/src/client/query/entities/GroupEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export class ChangeCompanyUserDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onChange = (value) => (text) => {
            const s = { ...this.state };
            s[value] = text;
            s['errors'] = this.checkForErrors(s);
            this.setState(s);
        };
        this.onChangePickerIndex = (type) => (value) => {
            const s = { ...this.state };
            s[type] = value.index;
            s['errors'] = this.checkForErrors(s);
            this.setState(s);
        };
        this.onChangeLicenseIndex = (value) => {
            const s = { ...this.state };
            s.selectedLicenseIndex = value.index;
            s.payroll = false;
            s['errors'] = this.checkForErrors(s);
            this.setState(s);
        };
        this.checkForErrors = (s) => {
            const { firstName, lastName, email, errors, selectedLicenseIndex } = s;
            const { companyMember } = this.props;
            if (companyMember.userId == null || companyMember.userId.length === 0) {
                if (selectedLicenseIndex < 2 && email.length === 0) {
                    errors['email'] = I18n.m.getMessage('required');
                }
                else if (email.length > 0 && !EmailValidator.validate(email)) {
                    errors['email'] = I18n.m.getMessage('emailValidationError');
                }
                else {
                    errors['email'] = null;
                }
                if (firstName.length === 0) {
                    errors['firstName'] = I18n.m.getMessage('required');
                }
                else {
                    errors['firstName'] = null;
                }
                if (lastName.length === 0) {
                    errors['lastName'] = I18n.m.getMessage('required');
                }
                else {
                    errors['lastName'] = null;
                }
            }
            return errors;
        };
        this.cancel = () => {
            Dialog.instance?.close();
        };
        this.submit = () => {
            this.setState({ isLoading: true }, this.submitNow);
        };
        this.submitNow = (force) => {
            const { company, companyMember } = this.props;
            const { selectedLicenseIndex, staffNumber, selectedGroupIndex, selectedTimeModelIndex, groupList, timeModelList, payroll, disableJournalEdit, isAdmin, email, firstName, lastName, phone, canInviteEmployees, extraPayTracking, selectedTimeTrackingForIndex, selectedTimeTrackingFromIndex, selectedAbsenceForIndex, selectedAbsenceFromIndex, selectedCanPermitAbsenceForIndex, } = this.state;
            const licence = this.getLicenseList()[selectedLicenseIndex].data;
            let role = 'user';
            if (licence === 'full' && isAdmin) {
                role = 'admin';
            }
            else if (licence === 'full') {
                role = 'user';
            }
            else if (licence === 'timeTracker') {
                role = 'timeTracker';
            }
            else if (licence === 'collaborator') {
                role = 'collaborator';
            }
            else {
                role = 'inactive';
            }
            const timeTrackingFor = this.getTimeTrackingForList()[selectedTimeTrackingForIndex].data;
            const timeTrackingFrom = this.getTimeTrackingFromList()[selectedTimeTrackingFromIndex].data;
            const list = this.getAbsenceForList(selectedLicenseIndex);
            const absenceFor = list[Math.min(list.length - 1, selectedAbsenceForIndex)].data;
            const list2 = this.getCanPermitAbsenceForList(selectedLicenseIndex);
            const canPermitAbsenceFor = list2[Math.min(list2.length - 1, selectedCanPermitAbsenceForIndex)].data;
            const listFrom = this.getAbsenceFromList(selectedLicenseIndex);
            const absenceFrom = listFrom[Math.min(listFrom.length - 1, selectedAbsenceFromIndex)].data;
            const asyncNow = async () => {
                try {
                    const data = {
                        staffNumber,
                        companyId: company.id,
                        role,
                        groupId: groupList[selectedGroupIndex].data,
                        workingTimeModelId: timeModelList[selectedTimeModelIndex].data,
                        disableJournalEdit,
                        email,
                        firstName,
                        lastName,
                        phone,
                        force,
                        canInviteEmployees: selectedLicenseIndex === 0 ? canInviteEmployees : false,
                        payroll: selectedLicenseIndex === 0 ? payroll : false,
                        extraPayTracking: selectedLicenseIndex === 3 ? false : payroll ? true : extraPayTracking,
                        timeTrackingFor: payroll ? 'all' : timeTrackingFor,
                        timeTrackingFrom: payroll ? 'all' : timeTrackingFrom,
                        absenceFor: payroll ? 'all' : absenceFor,
                        absenceFrom: payroll ? 'all' : absenceFrom,
                        canPermitAbsenceFor: payroll ? 'all' : canPermitAbsenceFor,
                    };
                    const changeUser = new ChangeCompanyUser(data, companyMember.id);
                    await changeUser.execute(ClientStore.commandStore);
                    this.setState({ isLoading: false });
                    Dialog.instance?.close();
                }
                catch (e) {
                    if (e['messageCode'] === 'cantRemoveUserHeIsInvolvedInEnterpriseProjects') {
                        Routing.instance.alert.post({
                            text: `${I18n.m.getMessage('cantRemoveUserHeIsInvolvedInEnterpriseProjects')}\r\n${I18n.m.getMessage('cantRemoveUserHeIsInvolvedInEnterpriseProjectsForceRemoveOrDowngrade')}`,
                            buttons: [
                                <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="later" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                                <ContainedButton key="now" title={I18n.m.getMessage('companyUserForceDowngrade')} onPress={() => {
                                        Alert.instance?.close();
                                        this.setState({ isLoading: true }, () => {
                                            this.submitNow('downgrade');
                                        });
                                    }}/>,
                                <ContainedButton key="now" title={I18n.m.getMessage('companyUserForceRemove')} onPress={() => {
                                        Alert.instance?.close();
                                        this.setState({ isLoading: true }, () => {
                                            this.submitNow('remove');
                                        });
                                    }}/>,
                            ],
                        });
                    }
                    else {
                        DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                    }
                    this.setState({ isLoading: false });
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        let selectedTimeTrackingForIndex = 0;
        if (props.companyMember?.canTimeTrackFor === 'all')
            selectedTimeTrackingForIndex = 2;
        else if (props.companyMember?.canTimeTrackFor === 'group')
            selectedTimeTrackingForIndex = 1;
        let selectedTimeTrackingFromIndex = 0;
        if (props.companyMember?.canSeeTimeTrackFrom === 'all')
            selectedTimeTrackingFromIndex = 2;
        else if (props.companyMember?.canSeeTimeTrackFrom === 'group')
            selectedTimeTrackingFromIndex = 1;
        let selectedAbsenceForIndex = 0;
        if (props.companyMember?.absenceFor === 'all')
            selectedAbsenceForIndex = 3;
        else if (props.companyMember?.absenceFor === 'group')
            selectedAbsenceForIndex = 2;
        else if (props.companyMember?.absenceFor === 'self')
            selectedAbsenceForIndex = 1;
        let selectedCanPermitAbsenceForIndex = 0;
        if (props.companyMember?.canPermitAbsenceFor === 'all')
            selectedCanPermitAbsenceForIndex = 2;
        else if (props.companyMember?.canPermitAbsenceFor === 'group')
            selectedCanPermitAbsenceForIndex = 1;
        let selectedAbsenceFromIndex = 0;
        if (this.props.companyMember?.absenceFrom === 'all')
            selectedAbsenceFromIndex = 2;
        else if (this.props.companyMember?.absenceFrom === 'group')
            selectedAbsenceFromIndex = 1;
        else if (this.props.companyMember?.absenceFrom === 'self')
            selectedAbsenceFromIndex = 0;
        const s = {
            canInviteEmployees: !!props.companyMember.canInviteEmployees,
            firstName: props.companyMember.firstName != null ? props.companyMember.firstName : '',
            lastName: props.companyMember.lastName != null ? props.companyMember.lastName : '',
            phone: props.companyMember.phone != null ? props.companyMember.phone : '',
            email: props.companyMember.email != null ? props.companyMember.email : '',
            isAdmin: props.companyMember.role === 'admin',
            disabledForm: false,
            staffNumber: props.companyMember.staffNumber,
            groupList: [{ title: I18n.m.getMessage('loading'), data: props.company.id }],
            timeModelList: [{ title: I18n.m.getMessage('loading'), data: props.company.id }],
            isLoading: true,
            disabledSave: true,
            disableJournalEdit: !!props.companyMember.disableJournalEdit,
            selectedGroupIndex: 0,
            payroll: !!props.companyMember.payroll,
            extraPayTracking: !!props.companyMember.extraPayTracking,
            selectedLicenseIndex: props.companyMember.role === 'admin'
                ? 0
                :
                    props.companyMember.role === 'user'
                        ? 0
                        : props.companyMember.role === 'collaborator'
                            ? 1
                            : props.companyMember.role === 'timeTracker'
                                ? 2
                                : 3,
            selectedTimeModelIndex: 0,
            errors: {},
            selectedTimeTrackingForIndex,
            selectedTimeTrackingFromIndex,
            selectedAbsenceForIndex,
            selectedCanPermitAbsenceForIndex,
            selectedAbsenceFromIndex,
        };
        this.state = { ...s, errors: this.checkForErrors(s) };
    }
    componentDidMount() {
        const { company, companyMember } = this.props;
        UpmeshClient.instance.modals.group
            .get({ filter: `companyId eq '${company.id}' and deleted ne true` })
            .then((groups) => {
            let selectedGroupIndex = 0;
            const groupList = [];
            groups.forEach((g, index) => {
                groupList.push({
                    title: g.groupName === GroupEntity.getDefaultName() ? I18n.m.getMessage('companyGroupDefaultName') : g.groupName,
                    data: g.id,
                });
                if (companyMember.groupId === g.id)
                    selectedGroupIndex = index;
            });
            this.setState((prevState) => {
                return {
                    groupList,
                    selectedGroupIndex,
                    isLoading: prevState.timeModelList[0].title === I18n.m.getMessage('loading'),
                };
            });
        })
            .catch((err) => console.debug(err));
        UpmeshClient.instance.modals.workingTimeModel
            .get({ filter: `companyId eq '${company.id}' and deleted ne true` })
            .then((groups) => {
            let selectedTimeModelIndex = 0;
            const timeModelList = [];
            groups.forEach((g, index) => {
                timeModelList.push({ title: g.mark, data: g.id });
                if (companyMember.workingTimeModelId === g.id)
                    selectedTimeModelIndex = index;
            });
            this.setState({ timeModelList });
            this.setState((prevState) => {
                return {
                    timeModelList,
                    selectedTimeModelIndex,
                    isLoading: prevState.groupList[0].title === I18n.m.getMessage('loading'),
                };
            });
        })
            .catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        Alert.instance?.close();
    }
    getAbsenceForList(licenseIndex) {
        const d = [
            {
                title: `${I18n.m.getMessage('companyUserCanAbsenceForNobody')}`,
                data: 'nobody',
            },
        ];
        if (licenseIndex < 3)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceForSelf')}`,
                data: 'self',
            });
        if (licenseIndex < 2)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceForGroup')}`,
                data: 'group',
            });
        if (licenseIndex < 1)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceForAll')}`,
                data: 'all',
            });
        return d;
    }
    getCanPermitAbsenceForList(licenseIndex) {
        const d = [
            {
                title: `${I18n.m.getMessage('companyUserCanAbsenceForNobody')}`,
                data: 'nobody',
            },
        ];
        if (licenseIndex < 2)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceForGroup')}`,
                data: 'group',
            });
        if (licenseIndex < 1)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceForAll')}`,
                data: 'all',
            });
        return d;
    }
    getAbsenceFromList(licenseIndex) {
        const d = [
            {
                title: `${I18n.m.getMessage('companyUserCanAbsenceFromSelf')}`,
                data: 'self',
            },
        ];
        if (licenseIndex < 2)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceFromGroup')}`,
                data: 'group',
            });
        if (licenseIndex < 1)
            d.push({
                title: `${I18n.m.getMessage('companyUserCanAbsenceForAll')}`,
                data: 'all',
            });
        return d;
    }
    getLicenseList() {
        return [
            {
                title: `${I18n.m.getMessage('companyUserLicenseFull')}`,
                data: 'full',
            },
            {
                title: `${I18n.m.getMessage('companyUserLicenseCollaborator')}`,
                data: 'collaborator',
            },
            {
                title: `${I18n.m.getMessage('companyUserLicenseTimeTracking')}`,
                data: 'timeTracker',
            },
            {
                title: `${I18n.m.getMessage('companyRoleInactive')}`,
                data: 'inactive',
            },
        ];
    }
    getTimeTrackingForList() {
        return [
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackForSelf')}`,
                data: 'self',
            },
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackForGroup')}`,
                data: 'group',
            },
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackForAll')}`,
                data: 'all',
            },
        ];
    }
    getTimeTrackingFromList() {
        return [
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackFromSelf')}`,
                data: 'self',
            },
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackFromGroup')}`,
                data: 'group',
            },
            {
                title: `${I18n.m.getMessage('companyUserCanTimeTrackFromAll')}`,
                data: 'all',
            },
        ];
    }
    openInfoAlert(text) {
        Alert.instance?.open({
            content: I18n.m.getMessage(text),
            closeOnTouchOutside: false,
            buttons: [
                <ContainedButton title={I18n.m.getMessage('ok')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ],
        });
    }
    renderTimeTrackingModuleExtensions() {
        const { selectedTimeModelIndex, disabledForm, timeModelList, selectedLicenseIndex, payroll, selectedTimeTrackingForIndex, selectedTimeTrackingFromIndex, selectedAbsenceForIndex, selectedCanPermitAbsenceForIndex, selectedAbsenceFromIndex, } = this.state;
        const result = [
            <FormInputPicker selectedIndex={selectedTimeModelIndex} disabled={disabledForm} list={timeModelList} labelText={I18n.m.getMessage('workingTimeModel')} onChange={this.onChangePickerIndex('selectedTimeModelIndex')}/>,
        ];
        console.log('selectedLicenseIndex', { selectedLicenseIndex, payroll });
        if (selectedLicenseIndex !== 3) {
            result.push(<FormInputPicker key={`companyUserCanTimeTrackFor_${selectedLicenseIndex}`} selectedIndex={payroll ? 2 : selectedLicenseIndex >= 2 ? 0 : selectedTimeTrackingForIndex} disabled={disabledForm || payroll || selectedLicenseIndex >= 2} list={this.getTimeTrackingForList()} labelText={I18n.m.getMessage('companyUserCanTimeTrackFor')} onChange={this.onChangePickerIndex('selectedTimeTrackingForIndex')}/>, <FormInputPicker key={`companyUserCanTimeTrackFrom_${selectedLicenseIndex}`} selectedIndex={payroll ? 2 : selectedLicenseIndex >= 2 ? 0 : selectedTimeTrackingFromIndex} disabled={disabledForm || payroll || selectedLicenseIndex >= 2} list={this.getTimeTrackingFromList()} labelText={I18n.m.getMessage('companyUserCanTimeTrackFrom')} onChange={this.onChangePickerIndex('selectedTimeTrackingFromIndex')}/>, <FormInputPicker key={`companyUserCanAbsenceFor_${selectedLicenseIndex}`} selectedIndex={payroll ? 3 : Math.min(this.getAbsenceForList(selectedLicenseIndex).length - 1, selectedAbsenceForIndex)} disabled={disabledForm || payroll || selectedLicenseIndex >= 3} list={this.getAbsenceForList(selectedLicenseIndex)} labelText={I18n.m.getMessage('companyUserCanAbsenceFor')} onChange={this.onChangePickerIndex('selectedAbsenceForIndex')}/>, <FormInputPicker key={`companyUserCanAbsenceFrom_${selectedLicenseIndex}`} selectedIndex={payroll ? 2 : Math.min(this.getAbsenceFromList(selectedLicenseIndex).length - 1, selectedAbsenceFromIndex)} disabled={disabledForm || payroll || selectedLicenseIndex >= 3} list={this.getAbsenceFromList(selectedLicenseIndex)} labelText={I18n.m.getMessage('companyUserCanAbsenceFrom')} onChange={this.onChangePickerIndex('selectedAbsenceFromIndex')}/>, <FormInputPicker key={`companyUserCanPermitAbsenceFor_${selectedLicenseIndex}`} selectedIndex={payroll
                    ? 2
                    : Math.min(this.getCanPermitAbsenceForList(selectedLicenseIndex).length - 1, selectedCanPermitAbsenceForIndex)} disabled={disabledForm || payroll || selectedLicenseIndex >= 2} list={this.getCanPermitAbsenceForList(selectedLicenseIndex)} labelText={I18n.m.getMessage('companyUserCanPermitAbsenceFor')} onChange={this.onChangePickerIndex('selectedCanPermitAbsenceForIndex')}/>);
        }
        return result;
    }
    render() {
        const { companyMember } = this.props;
        const { staffNumber, disabledForm, groupList, timeModelList, isLoading, selectedTimeModelIndex, selectedGroupIndex, selectedLicenseIndex, payroll, extraPayTracking, canInviteEmployees, disableJournalEdit, isAdmin, email, lastName, firstName, phone, selectedTimeTrackingForIndex, selectedTimeTrackingFromIndex, selectedAbsenceForIndex, selectedAbsenceFromIndex, errors, } = this.state;
        const currentRole = selectedLicenseIndex === 0 && isAdmin
            ? 'admin'
            :
                selectedLicenseIndex === 0
                    ? 'user'
                    : selectedLicenseIndex === 1
                        ? 'collaborator'
                        : selectedLicenseIndex === 2
                            ? 'timeTracker'
                            : 'inactive';
        const currentTimeTrackFor = this.getTimeTrackingForList()[selectedTimeTrackingForIndex].data;
        const currentTimeTrackFrom = this.getTimeTrackingFromList()[selectedTimeTrackingFromIndex].data;
        const l = this.getAbsenceForList(selectedLicenseIndex);
        const currentAbsenceFor = payroll
            ? 'all'
            : l[Math.min(l.length - 1, selectedAbsenceForIndex)].data;
        const fromList = this.getAbsenceFromList(selectedLicenseIndex);
        const currentAbsenceFrom = payroll
            ? 'all'
            : fromList[Math.min(fromList.length - 1, selectedAbsenceFromIndex)].data;
        const hasChanged = !!companyMember.disableJournalEdit !== disableJournalEdit ||
            companyMember.canInviteEmployees !== canInviteEmployees ||
            companyMember.firstName !== firstName ||
            companyMember.lastName !== lastName ||
            companyMember.email !== email ||
            companyMember.payroll !== payroll ||
            companyMember.extraPayTracking !== extraPayTracking ||
            companyMember.staffNumber !== staffNumber ||
            companyMember.absenceFor !== currentAbsenceFor ||
            companyMember.absenceFrom !== currentAbsenceFrom ||
            companyMember.canTimeTrackFor !== currentTimeTrackFor ||
            companyMember.canSeeTimeTrackFrom !== currentTimeTrackFrom ||
            groupList[selectedGroupIndex].data !== companyMember.groupId ||
            timeModelList[selectedTimeModelIndex].data !== companyMember.workingTimeModelId ||
            companyMember.phone !== phone ||
            currentRole !== companyMember.role;
        const hasOldTimeTrackingModule = CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('timeTrackingJournalEdit');
        const hasTimeTracking = CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('timeTracking');
        return [
            <DialogTitle key="title">{I18n.m.getMessage('companyEditUserDilaogTitle')}</DialogTitle>,
            <DialogContent key="content">
        <FormInputPicker selectedIndex={selectedLicenseIndex} disabled={disabledForm} list={this.getLicenseList()} labelText={I18n.m.getMessage('companyLicence')} onChange={this.onChangeLicenseIndex}/>
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <View style={{ flex: 1, paddingRight: 8 }}>
            <FormInputFilled value={firstName} disabled={disabledForm} labelText={I18n.m.getMessage('firstName')} onChange={this.onChange('firstName')} error={errors['firstName'] != null} helperText={errors['firstName']}/>
          </View>
          <View style={{ flex: 1, paddingLeft: 8 }}>
            <FormInputFilled value={lastName} disabled={disabledForm} labelText={I18n.m.getMessage('lastName')} onChange={this.onChange('lastName')} error={errors['lastName'] != null} helperText={errors['lastName']}/>
          </View>
        </View>
        <View style={{ width: '100%' }}>
          <FormInputFilled value={phone} disabled={disabledForm} labelText={I18n.m.getMessage('phone')} onChange={this.onChange('phone')} error={errors['phone'] != null} helperText={errors['phone']}/>
        </View>
        {companyMember.userId == null || companyMember.userId.length === 0 ? (<View style={{ width: '100%' }}>
            <FormInputFilled value={email} labelText={I18n.m.getMessage('email')} keyboardType="email-address" onChange={this.onChange('email')} error={errors['email'] != null} helperText={errors['email']}/>
          </View>) : null}
        <CheckboxListItem onChange={(isAdmin) => this.setState({ isAdmin })} disabled={selectedLicenseIndex !== 0} value={selectedLicenseIndex === 0 ? isAdmin : false} title={I18n.m.getMessage('companyRoleAdmin')} key={`admin_${(selectedLicenseIndex === 0 ? isAdmin : false).toString()}`} listItemProps={{
                    backgroundColor: ThemeManager.style.formInputFilledBackgroundColor,
                    iconRight: {
                        icon: 'information-outline',
                        onPress: () => this.openInfoAlert('companyAddEditUserDialogAdminInfo'),
                    },
                }}/>
        {hasTimeTracking ? <View style={{ height: 8 }}/> : null}
        {hasTimeTracking ? (<CheckboxListItem onChange={(payrollSel) => {
                        if (payrollSel) {
                            this.setState({ payroll: true, extraPayTracking: true });
                        }
                        else {
                            this.setState({ payroll: false });
                        }
                    }} disabled={selectedLicenseIndex !== 0} key={`payroll_${(selectedLicenseIndex === 0 ? payroll : false).toString()}`} value={selectedLicenseIndex === 0 ? payroll : false} title={I18n.m.getMessage('payroll')} listItemProps={{
                        backgroundColor: ThemeManager.style.formInputFilledBackgroundColor,
                        iconRight: {
                            icon: 'information-outline',
                            onPress: () => this.openInfoAlert('companyAddEditUserDialogPayrollInfo'),
                        },
                    }}/>) : null}
        {hasTimeTracking ? <View style={{ height: 8 }}/> : null}
        {hasTimeTracking ? (<CheckboxListItem onChange={(extraPayTracking) => this.setState({ extraPayTracking })} disabled={selectedLicenseIndex >= 3 || (payroll && selectedLicenseIndex === 0)} key={`extraPayTracking${(selectedLicenseIndex >= 3 ? false : extraPayTracking).toString()}`} value={selectedLicenseIndex >= 3 ? false : payroll ? true : extraPayTracking} title={I18n.m.getMessage('canTrackExtraPay')} listItemProps={{
                        backgroundColor: ThemeManager.style.formInputFilledBackgroundColor,
                        iconRight: {
                            icon: 'information-outline',
                            onPress: () => this.openInfoAlert('companyAddEditUserDialogExtraPayInfo'),
                        },
                    }}/>) : null}
        <View style={{ height: 8 }}/>
        <CheckboxListItem onChange={(canInviteEmployees) => this.setState({ canInviteEmployees })} disabled={selectedLicenseIndex !== 0} key={`canInviteEmployees_${(selectedLicenseIndex === 0 ? canInviteEmployees : false).toString()}`} value={selectedLicenseIndex === 0 ? canInviteEmployees : false} title={I18n.m.getMessage('companyMemberCanInviteCompanyUserToProjects')} listItemProps={{
                    backgroundColor: ThemeManager.style.formInputFilledBackgroundColor,
                    iconRight: {
                        icon: 'information-outline',
                        onPress: () => this.openInfoAlert('companyAddEditUserDialogAddMemberInfo'),
                    },
                }}/>
        <View style={{ height: 8 }}/>
        <CheckboxListItem onChange={(disableJournalEdit) => this.setState({ disableJournalEdit: !disableJournalEdit })} disabled={(selectedLicenseIndex >= 2 && !hasOldTimeTrackingModule) ||
                    (selectedLicenseIndex >= 3 && hasOldTimeTrackingModule)} key={`disableJournalEdit_${((selectedLicenseIndex >= 2 && !hasOldTimeTrackingModule) ||
                    (selectedLicenseIndex >= 3 && hasOldTimeTrackingModule)
                    ? false
                    : !disableJournalEdit).toString()}`} value={(selectedLicenseIndex >= 2 && !hasOldTimeTrackingModule) ||
                    (selectedLicenseIndex >= 3 && hasOldTimeTrackingModule)
                    ? false
                    : !disableJournalEdit} title={I18n.m.getMessage('canEditJournals')} listItemProps={{
                    backgroundColor: ThemeManager.style.formInputFilledBackgroundColor,
                    iconRight: {
                        icon: 'information-outline',
                        onPress: () => this.openInfoAlert('companyAddEditUserDialogJournalsInfo'),
                    },
                }}/>
        <View style={{ height: 8 }}/>
        <FormInputFilled onChange={this.onChange('staffNumber')} value={staffNumber} disabled={disabledForm} labelText={I18n.m.getMessage('staffNumber')}/>
        <FormInputPicker selectedIndex={selectedGroupIndex} disabled={disabledForm} list={groupList} labelText={I18n.m.getMessage('group')} onChange={this.onChangePickerIndex('selectedGroupIndex')}/>
        {hasTimeTracking ? this.renderTimeTrackingModuleExtensions() : null}
      </DialogContent>,
            <DialogActions key="actions" onBottom>
        <ContainedButton disabled={isLoading} title={I18n.m.getMessage('abort')} textColor={ThemeManager.style.brandPrimary} onPress={this.cancel} backgroundColor="transparent"/>
        <ContainedButton disabled={isLoading ||
                    errors['email'] != null ||
                    errors['firstName'] != null ||
                    errors['lastName'] != null ||
                    !hasChanged} loading={isLoading} onPress={this.submit} title={I18n.m.getMessage('save')} backgroundColor={ThemeManager.style.brandPrimary}/>
      </DialogActions>,
        ];
    }
}
