import { Card } from 'materialTheme/src/theme/components/Card';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeProject } from 'upmesh-core/src/client/commands/project/ChangeProject';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import { I18n } from '../../i18n/I18n';
import { GanttChartItem } from './board/GanttChartItem';
import { CompanyUserInfo } from './CompanyUserInfo';
export function HomeProjectsGanttChartView(props) {
    const [data, setData] = useState([]);
    const [sortBy, setSortBy] = useState('title');
    const [timeScale, setTimeScale] = useState('year/month');
    const [timeType, setTimeType] = useState();
    const changeProjectDate = async (id, start, end) => {
        const currentData = {};
        if (timeType === 'build') {
            currentData.constructionStart = start;
            currentData.constructionEnd = end;
        }
        else {
            currentData.projectStart = start;
            currentData.projectEnd = end;
        }
        const c = new ChangeProject(currentData, id);
        await c.execute(ClientStore.commandStore);
        const dataIndex = data.findIndex((d) => d.id === id);
        if (dataIndex > -1) {
            data[dataIndex].start = start;
            data[dataIndex].end = end;
        }
    };
    const sortData = (sort, datas) => {
        const d = [...datas];
        if (sort === 'state')
            d.sort((a, b) => {
                if (a.state == null && b.state == null) {
                    return 0;
                }
                if (a.state == null) {
                    return 1;
                }
                if (b.state == null) {
                    return -1;
                }
                return a.state.order - b.state.order;
            });
        if (sort === 'start')
            d.sort((a, b) => a.start.getTime() - b.start.getTime());
        else if (sort === 'end')
            d.sort((a, b) => a.end.getTime() - b.end.getTime());
        else if (sort === 'title')
            d.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        return d;
    };
    const getProjectsData = async (projects, sort) => {
        const { companySettings } = CompanyUserInfo;
        if (companySettings) {
            for (const p of projects) {
                const projectInfo = await UpmeshClient.instance.modals.companyProjectInfo.get({
                    filter: `projectId eq '${p.id}' and companyId eq '${companySettings.id}' and deleted ne true`,
                });
                if (projectInfo.length > 0) {
                    p.state = companySettings.projectStateNames.find((elem) => elem.id === projectInfo[0].projectStateId);
                }
            }
        }
        const newData = [];
        for (const p of projects) {
            const start = timeType !== 'build' ? p.projectStart : p.constructionStart;
            const end = timeType !== 'build' ? p.projectEnd : p.constructionEnd;
            if (start != null && end != null) {
                const canEdit = await RightsManager.hasWriteRight(p.id, CurrentUser.userId, 'commandChangeProject');
                let color = ThemeManager.style.brandPrimary;
                if (p.state) {
                    color = p.state.color;
                }
                else if (p.color) {
                    color = p.color;
                }
                newData.push({
                    name: p.title,
                    color,
                    start: new Date(start.getTime()),
                    end: new Date(end.getTime()),
                    id: p.id,
                    onPress: (id) => Routing.instance.goTo(`/projects/${id}/${StoredStartPages.getStoredProjectStartPage()}`),
                    onChangeDate: canEdit ? changeProjectDate : undefined,
                    state: p.state,
                });
            }
        }
        return sortData(sort || sortBy, newData);
    };
    const init = async (projects) => {
        if (timeType == null) {
            try {
                const t = SimpleStorage.get('homeProjectsGanttChartDateType');
                if (t != null && (t === 'build' || t === 'project')) {
                    setTimeType(t);
                    return [];
                }
            }
            catch (err) {
                console.error(err);
            }
            setTimeType('build');
            return [];
        }
        const sort = SimpleStorage.get('homeProjectsGanttChartSortBy');
        let s = 'title';
        if (sort && (sort === 'end' || sort === 'start' || sort === 'title' || sort === 'state')) {
            s = sort;
        }
        setSortBy(s);
        const scale = SimpleStorage.get('homeProjectsGanttChartTimeScale');
        if (scale && (scale === 'year' || scale === 'year/month' || scale === 'month/day'))
            setTimeScale(scale);
        return await getProjectsData(projects, s);
    };
    useEffect(() => {
        let aborted = false;
        init(props.projects)
            .then((d) => {
            if (!aborted)
                setData(d);
        })
            .catch((err) => console.debug('cant load data', err));
        return () => {
            aborted = true;
        };
    }, [props, timeType, props.projects]);
    const selectedTimescale = () => {
        if (timeScale === 'year')
            return 0;
        if (timeScale === 'year/month')
            return 1;
        return 2;
    };
    const selectedSortBy = () => {
        if (sortBy === 'title')
            return 0;
        if (sortBy === 'start')
            return 1;
        if (sortBy === 'end')
            return 2;
        if (sortBy === 'state')
            return 3;
        return 3;
    };
    const renderChart = () => {
        return (<GanttChartItem visibleDaysBeforeAndAfter={10} data={data} timeScale={timeScale} additionalButtons={[
                <View style={{ alignSelf: 'center' }}>
            <MaterialText type={MaterialTextTypes.Caption}>
              {I18n.m.getMessage('dashboardGanttChartTimeScale')}
            </MaterialText>
            <OpenableChip chipsList={[
                        { title: I18n.m.getMessage('year'), onPressChipData: 'year' },
                        {
                            title: `${I18n.m.getMessage('year')} / ${I18n.m.getMessage('month')}`,
                            onPressChipData: 'year/month',
                        },
                        {
                            title: `${I18n.m.getMessage('month')} / ${I18n.m.getMessage('day')}`,
                            onPressChipData: 'month/day',
                        },
                    ]} onPressChip={(t) => {
                        setTimeScale(t);
                        void SimpleStorage.set('homeProjectsGanttChartTimeScale', t);
                    }} selected={selectedTimescale()}/>
          </View>,
                <View style={{ alignSelf: 'center' }}>
            <MaterialText type={MaterialTextTypes.Caption}>
              {I18n.m.getMessage('dashboardGanttChartTimeSortBy')}
            </MaterialText>
            <OpenableChip formInputLabel={I18n.m.getMessage('sort')} chipsList={[
                        { title: I18n.m.getMessage('projectName'), onPressChipData: 'title' },
                        {
                            title: `${I18n.m.getMessage('projectStart')} / ${I18n.m.getMessage('projectBuildingStart')}`,
                            onPressChipData: 'start',
                        },
                        {
                            title: `${I18n.m.getMessage('projectEnd')} / ${I18n.m.getMessage('projectBuildingEnd')}`,
                            onPressChipData: 'end',
                        },
                        {
                            title: I18n.m.getMessage('projectState'),
                            onPressChipData: 'state',
                        },
                    ]} onPressChip={(s) => {
                        setSortBy(s);
                        setData(sortData(s, data));
                        void SimpleStorage.set('homeProjectsGanttChartSortBy', s);
                    }} selected={selectedSortBy()}/>
          </View>,
                <View style={{ alignSelf: 'center' }}>
            <MaterialText type={MaterialTextTypes.Caption}>
              {I18n.m.getMessage('dashboardGanttChartDateRange')}
            </MaterialText>
            <OpenableChip formInputLabel={I18n.m.getMessage('sort')} chipsList={[
                        { title: I18n.m.getMessage('projectTime'), onPressChipData: 'project' },
                        { title: I18n.m.getMessage('buildTime'), onPressChipData: 'build' },
                    ]} onPressChip={(s) => {
                        setTimeType(s);
                        SimpleStorage.set('homeProjectsGanttChartDateType', s);
                    }} selected={timeType === 'build' ? 1 : 0}/>
          </View>,
            ]}/>);
    };
    return (<Card style={{ width: '100%', height: props.height }} outerPadding={0}>
      {timeType == null ? (<Spinner />) : (<View style={{ width: '100%', padding: 16, height: props.height }}>{renderChart()}</View>)}
    </Card>);
}
