import color from 'color';
import { ChoiceChips } from 'materialTheme/src/theme/components/chips/ChoiceChips';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { UIManager, View } from 'react-native';
import { PieChart } from 'react-native-chart-kit';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { TicketStatus, TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { TicketFilter } from 'upmesh-core/src/client/query/filter/TicketFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../../config/CachedEntities';
import { I18n } from '../../../i18n/I18n';
import { TicketEntitySynced } from '../../tickets/TicketEntitySynced';
import { CurrentProject } from '../CurrentProject';
export class ChartItemWidget extends PureComponent {
    constructor(props) {
        super(props);
        this.chartConfig = {
            backgroundGradientFrom: '#FFFFFF',
            backgroundGradientFromOpacity: 0,
            backgroundGradientTo: '#FFFFFF',
            backgroundGradientToOpacity: 0,
            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            strokeWidth: 2,
            barPercentage: 0.5,
            useShadowColorFromDataset: false,
            withHorizontalLabels: true,
        };
        this.colors = [
            ThemeManager.style.brandPrimary,
            ThemeManager.style.brandSecondary,
            ThemeManager.style.brandWarning,
            ThemeManager.style.brandDanger,
            'rgb(255,213,0)',
        ];
        this.getHeaderIcons = () => {
            const { showAbsoluteNumbers } = this.state;
            return [
                <Icon icon={showAbsoluteNumbers ? 'percent-outline' : 'numeric'} toolTip="" onPress={this.changeNumbers} key="chartitemwidgeticon"/>,
            ];
        };
        this.getTicketData = (t) => {
            const tu = new TicketEntitySynced(t);
            try {
                if (t.approverUserId != null && t.approverUserId.length > 1) {
                    const user = CachedEntities.knownUsers.get(t.approverUserId);
                    if (user) {
                        tu.approverUser = user;
                        tu.approver = user.getFullName();
                    }
                }
                if (t.assignedToUserId != null && t.assignedToUserId.length > 1) {
                    const user = CachedEntities.knownUsers.get(t.assignedToUserId);
                    if (user) {
                        tu.assigneeUser = user;
                        tu.assignee = user.getFullName();
                    }
                }
            }
            catch (e) {
                console.debug('cant get user');
            }
            return tu;
        };
        this._cachedTickets = [];
        this.abortController = new AbortController();
        this.messagesOverrideList = [];
        this.getCurrentTickets = async () => {
            if (this._cachedTickets != null && this._cachedTickets.length > 0)
                return this._cachedTickets;
            const { projectId } = this.props;
            const useGlobalTickets = this.props.projectId == null;
            let t = [];
            if (!useGlobalTickets && CurrentProject.instance.getCurrentProjectId() === this.props.projectId) {
                t = CurrentProject.instance.getCurrentTickets();
                this.messagesOverrideList.push([undefined]);
            }
            else if (projectId) {
                const tickets2 = await UpmeshClient.instance.modals.ticket.get({
                    filter: `(projectId eq '${projectId}')`,
                });
                const projects = await UpmeshClient.instance.modals.project.get({
                    filter: `deleted ne true and archived ne true and id eq ${projectId}`,
                });
                this.messagesOverrideList.push(projects[0].messagesOverride);
                t = await this.convertTicktetsToSynced(tickets2, { signal: this.abortController.signal });
            }
            else {
                const projects = await UpmeshClient.instance.modals.project.get({
                    filter: 'deleted ne true and archived ne true',
                });
                const projectIds = projects.map((p) => p.id);
                this.messagesOverrideList.push([undefined]);
                const tickets2 = await UpmeshClient.instance.modals.ticket.get({
                    filter: `(projectId in ${JSON.stringify(projectIds)})`,
                });
                t = await this.convertTicktetsToSynced(tickets2, { signal: this.abortController.signal });
            }
            const filtered = t.filter((t) => !t.archived && !t.deleted);
            this._cachedTickets = [...filtered];
            return filtered;
        };
        this.changeNumbers = () => {
            const { showAbsoluteNumbers } = this.state;
            Menu.instance?.close();
            SimpleStorage.set('dashboardChartItemshowAbsoluteNumbers', !showAbsoluteNumbers ? 'true' : 'false');
            this.setState({ showAbsoluteNumbers: !showAbsoluteNumbers });
        };
        this.changeSort = (index) => {
            const asyncNow = async () => {
                const chartData = await this.getChartData(index);
                SimpleStorage.set('dashboardChartItemSortBy', index.toString());
                this.setState({ chartData, sortBy: index });
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.getChartData = async (sortBy) => {
            if (sortBy === 0) {
                return this.getChartDataByStatus();
            }
            if (sortBy === 1) {
                return this.getChartDataByTypeOrCraft('type');
            }
            if (sortBy === 2) {
                return this.getChartDataByTypeOrCraft('craft');
            }
            if (sortBy === 3) {
                return this.getChartDataByPeople('assignee');
            }
            return this.getChartDataByPeople('approver');
        };
        this.getChartDataByPeople = async (people) => {
            const key = people === 'assignee' ? 'assignedToUserId' : 'approverUserId';
            const tickets = await this.getCurrentTickets();
            const data = [];
            const counts = {};
            tickets.forEach((t) => {
                const keyV = t[key];
                const keyValue = keyV != null && typeof keyV === 'string' && keyV.length > 1 ? t[key] : 'notAssigned';
                counts[keyValue] = counts[keyValue] >= 0 ? counts[keyValue] + 1 : 1;
            });
            for (const keyValue in counts) {
                let username = I18n.m.getMessage('ticketsDetailsTitleNotAssignedToUser');
                if (keyValue !== 'notAssigned') {
                    try {
                        const user = await AuthClient.instance.modals.user.getById(keyValue);
                        username = user.getFullName();
                    }
                    catch (err) {
                        console.warn('cant get username', err, keyValue);
                    }
                }
                data.push({ name: username, count: counts[keyValue], color: '', data: keyValue });
            }
            return this.sortChartData(data);
        };
        this.getChartDataByStatus = async () => {
            const tickets = await this.getCurrentTickets();
            const overrideList = this.messagesOverrideList[0];
            const data = [
                {
                    name: overrideList.ticketsDetailsStateopen != null
                        ? overrideList.ticketsDetailsStateopen
                        : I18n.m.getMessage('ticketsDetailsStateopen'),
                    count: 0,
                    color: TicketStatusColor.getColorForStatus(TicketStatus.open),
                    data: 0,
                },
                {
                    name: overrideList.ticketsDetailsStateprocessing != null
                        ? overrideList.ticketsDetailsStateprocessing
                        : I18n.m.getMessage('ticketsDetailsStateprocessing'),
                    count: 0,
                    color: TicketStatusColor.getColorForStatus(TicketStatus.processing),
                    data: 1,
                },
                {
                    name: overrideList.ticketsDetailsStateclosed != null
                        ? overrideList.ticketsDetailsStateclosed
                        : I18n.m.getMessage('ticketsDetailsStateclosed'),
                    count: 0,
                    color: TicketStatusColor.getColorForStatus(TicketStatus.closed),
                    data: 2,
                },
                {
                    name: overrideList.ticketsDetailsStatechecked != null
                        ? overrideList.ticketsDetailsStatechecked
                        : I18n.m.getMessage('ticketsDetailsStatechecked'),
                    count: 0,
                    color: TicketStatusColor.getColorForStatus(TicketStatus.checked),
                    data: 3,
                },
            ];
            if (tickets && tickets.length > 0) {
                for (let i = 0; i < tickets.length; i += 1) {
                    const ticket = tickets[i];
                    const index = data.findIndex((data) => data.data === ticket.ticketStatus);
                    if (index !== -1)
                        data[index].count += 1;
                }
            }
            return data;
        };
        this.getChartDataByTypeOrCraft = async (key) => {
            const tickets = await this.getCurrentTickets();
            const data = [];
            const counts = {};
            const noneText = key === 'craft' ? I18n.m.getMessage('ticketsCraftNotSelected') : I18n.m.getMessage('ticketTypeNotSelected');
            tickets.forEach((t) => {
                const keyValue = t[key] != null ? t[key] : '';
                counts[keyValue] = counts[keyValue] >= 0 ? counts[keyValue] + 1 : 1;
            });
            for (const keyValue in counts) {
                data.push({
                    name: keyValue === '' ? noneText : keyValue,
                    count: counts[keyValue],
                    color: '',
                    data: keyValue,
                });
            }
            return this.sortChartData(data);
        };
        this.getUserName = async (id) => {
            const user = await AuthClient.instance.modals.user.getById(id);
            return user.getFullName();
        };
        this.init = async () => {
            const { projectId } = this.props;
            let projectName;
            if (projectId && CurrentProject.instance.getCurrentProjectId() !== projectId) {
                try {
                    const project = await UpmeshClient.instance.modals.project.getById(projectId);
                    projectName = project.title;
                }
                catch (e) {
                    console.debug(e);
                }
            }
            let tickets = [];
            try {
                tickets = await this.getCurrentTickets();
            }
            catch (err) {
                console.debug('cant get current Tickets', err);
            }
            const showAbsoluteNumbersStorage = SimpleStorage.get('dashboardChartItemshowAbsoluteNumbers');
            const showAbsoluteNumbers = showAbsoluteNumbersStorage === 'true';
            const sortByStorage = SimpleStorage.get('dashboardChartItemSortBy');
            let sortBy = 0;
            if (sortByStorage === '1') {
                sortBy = 1;
            }
            if (sortByStorage === '2') {
                sortBy = 2;
            }
            if (sortByStorage === '3') {
                sortBy = 3;
            }
            const chartData = await this.getChartData(sortBy);
            this.setState({ tickets, sortBy, showAbsoluteNumbers, chartData, projectName });
        };
        this.openMenu = (e) => {
            const { showAbsoluteNumbers } = this.state;
            UIManager.measureInWindow(e.nativeEvent.target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 256,
                };
                Menu.instance?.open({
                    client,
                    items: [
                        {
                            text: I18n.m.getMessage('dashboardChartItemShowPercentage'),
                            onPress: this.changeNumbers,
                            thumbnail: {
                                width: 24,
                                thumbnail: !showAbsoluteNumbers ? <Icon icon="check" toolTip=""/> : <View />,
                            },
                        },
                    ],
                });
            });
        };
        this.renderLegend = () => {
            const { chartData, showAbsoluteNumbers, tickets, cardContentWidth } = this.state;
            if (tickets && tickets.length > 0 && chartData.length > 0) {
                const legendEntries = [];
                const allTickets = tickets.length;
                let columns = Math.floor(cardContentWidth / 250);
                columns = Math.max(columns, 1);
                for (let i = 0; i < chartData.length; i += 1) {
                    const count = showAbsoluteNumbers ? chartData[i].count : Math.round((chartData[i].count / allTickets) * 100);
                    const entryData = { name: chartData[i].name, color: chartData[i].color, count, data: chartData[i].data };
                    legendEntries.push(this.renderLegendEntry(entryData, i, cardContentWidth / columns));
                }
                return (<View style={{ flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', flexDirection: 'row' }}>
          {legendEntries}
        </View>);
            }
            return <View />;
        };
        this.openTickets = (data) => (_e) => {
            const { sortBy } = this.state;
            const ticketFilter = new TicketFilter();
            if (sortBy === 0)
                ticketFilter.s = [data.toString()];
            else if (sortBy === 1)
                ticketFilter.tt = [data && data.length > 0 ? data : 'null'];
            else if (sortBy === 2)
                ticketFilter.c = [data && data.length > 0 ? data : 'null'];
            else if (sortBy === 3) {
                ticketFilter.u = [data];
                ticketFilter.u2 = ['assignedTo'];
            }
            else {
                ticketFilter.u = [data];
                ticketFilter.u2 = ['approvedBy'];
            }
            const f = encodeURIComponent(JSON.stringify(ticketFilter));
            const { projectId } = this.props;
            if (projectId == null) {
                const deepLinkState = `/allTickets?init=1&fat=${f}`;
                Routing.instance.goTo(deepLinkState);
            }
            else {
                const deepLinkState = `/projects/${projectId}/tickets/all/?f=${f}`;
                Routing.instance.goTo(deepLinkState);
            }
        };
        this.renderLegendEntry = (chartData, index, width) => {
            const { showAbsoluteNumbers } = this.state;
            const { count, name, color, data } = chartData;
            return (<Ripple key={index} style={{ flexDirection: 'row', flex: 1, width, minWidth: 250, maxWidth: width }} onPress={this.openTickets(data)}>
        <Icon icon="circle" toolTip="" color={color}/>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', width: 60 }}>
          <MaterialText type={MaterialTextTypes.H6} centeredBox numberOfLines={1}>
            {showAbsoluteNumbers ? count : `${count}%`}
          </MaterialText>
        </View>
        <View style={{ width: 8 }}/>
        <MaterialText ellipsizeMode="tail" centeredBox numberOfLines={1} fixedWidth={width - (showAbsoluteNumbers ? 60 : 74)}>
          {name}
        </MaterialText>
      </Ripple>);
        };
        this.state = {
            showAbsoluteNumbers: true,
            sortBy: 0,
            tickets: [],
            chartData: [],
            cardContentWidth: 350,
            chartSize: { width: 200, height: 200 },
        };
    }
    async convertTicktetsToSynced(tickets, options) {
        const t = [];
        let i = 0;
        for (const ticket of tickets) {
            i += 1;
            if (options.signal.aborted) {
                break;
            }
            else {
                t.push(this.getTicketData(ticket));
                if (i % 1000 === 0) {
                    await new Promise((r) => {
                        setTimeout(() => {
                            r();
                        }, 1);
                    });
                }
            }
        }
        return t;
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        if (this.abortController)
            this.abortController.abort();
        Menu.instance?.close();
    }
    measureView(e) {
        this.setState({ cardContentWidth: e.nativeEvent.layout.width - ThemeManager.style.contentPaddingValue * 2 });
    }
    render() {
        const { styles } = this.props;
        const { tickets, projectName } = this.state;
        return (<View style={[styles, { width: '100%', height: '100%' }]}>
        <View style={{ padding: 16, paddingBottom: 4, width: '100%', height: '100%' }}>
          <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MaterialText type={MaterialTextTypes.H5}>{`${I18n.m.getMessage('dashboardChartItemTicketOverview')}${projectName ? ` (${projectName})` : ''}`}</MaterialText>
            {this.getHeaderIcons()}
          </View>
          {this.renderChoiceChips()}
          <View style={{ flex: 1 }} onLayout={(l) => {
                if (l.nativeEvent.layout.height) {
                    this.setState({ chartSize: { ...l.nativeEvent.layout } });
                }
            }}>
            {tickets && tickets.length > 0 && this.renderChart()}
          </View>
          {this.renderLegend()}
        </View>
      </View>);
    }
    renderChart() {
        const { chartData, showAbsoluteNumbers, chartSize } = this.state;
        const m = Math.min(chartSize.width, chartSize.height);
        return (<View style={{
                position: 'relative',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                width: '100%',
                minHeight: chartSize.height,
                minWidth: chartSize.width,
            }}>
        <PieChart absolute={showAbsoluteNumbers} data={chartData} width={m} height={m} chartConfig={this.chartConfig} accessor="count" backgroundColor="transparent" paddingLeft="0" center={[m / 4, 0]} hasLegend={false}/>
        <View style={{ width: m / 2, height: m / 2, borderRadius: m / 4, backgroundColor: '#FFFFFF', position: 'absolute' }}/>
      </View>);
    }
    renderChoiceChips() {
        const { sortBy } = this.state;
        return (<View>
        <ChoiceChips selected={sortBy} onPressChip={this.changeSort} textColorSelected={ThemeManager.style.brandPrimary} backgroundColorChecked={color(ThemeManager.style.brandPrimary).alpha(0.12).toString()} chips={[
                { title: I18n.m.getMessage('dashboardItemsStatus') },
                { title: I18n.m.getMessage('dashboardChartItemType') },
                { title: I18n.m.getMessage('dashboardChartItemCraft') },
                { title: I18n.m.getMessage('dashboardItemsAssignee') },
                { title: I18n.m.getMessage('dashboardChartItemApprovers') },
            ]}/>
      </View>);
    }
    sortChartData(data) {
        data.sort((a, b) => b.count - a.count);
        if (data.length > this.colors.length) {
            let restCount = 0;
            for (let i = this.colors.length; i < data.length; i += 1) {
                restCount += data[i].count;
            }
            data.splice(this.colors.length, data.length - this.colors.length);
            data.push({
                name: I18n.m.getMessage('dashboardChartItemOther'),
                data: 'Sonstige',
                color: '#CCCCCC',
                count: restCount,
            });
        }
        data.forEach((d, i) => {
            d.color = i < this.colors.length ? this.colors[i] : '#CCCCCC';
        });
        return data;
    }
}
