import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { RemoveCompanyReportTemplate } from 'upmesh-core/src/client/commands/companies/reporttemplates/RemoveCompanyReportTemplate';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CachedEntities } from '../../config/CachedEntities';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { StoredFileDownloader } from '../file/StoredFileDownloader';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { openAddOrChangeReportTemplate } from './AddOrChangeReportTemplate';
import { openCompanyReportTemplatesNotesDialog } from './CompanyReportTemplatesNotesDialog';
const noDataImage = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class CompanyReportTemplatesView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.init = async () => {
            const { companySettings } = this.props;
            const data = [];
            if (companySettings.reportTemplates != null && companySettings.reportTemplates.length > 0) {
                for (const tl of companySettings.reportTemplates) {
                    const t = tl;
                    t.creatorName = CachedEntities.knownUsers.get(t.createdBy)?.getFullName() || '-';
                    data.push(t);
                }
            }
            this.setState({ data });
        };
        this.download = (reportTemplate) => (_e) => {
            if (reportTemplate != null) {
                this.downloadNow(reportTemplate).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
        };
        this.downloadNow = async (reportTemplate) => {
            const { companySettings } = this.props;
            const headers = {};
            headers['authorization'] = `Bearer ${CurrentUser.token}`;
            const getToken = await fetch(`${UpmeshClient.instance.url}/companysettings/${companySettings.id}/${reportTemplate.id}/token`, {
                headers,
            });
            if (getToken != null && getToken.status === 200) {
                const token = await getToken.text();
                const link = `${UpmeshClient.instance.url}/companysettings/template/download/${token}`;
                const setTO = setTimeout(() => Alert.instance?.close(), 20000);
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('fileReadyToDownload'),
                    buttons: [
                        <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={() => {
                                clearTimeout(setTO);
                                Alert.instance?.close();
                            }} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                        <ContainedButton key="save" title={I18n.m.getMessage('download')} onPress={() => {
                                clearTimeout(setTO);
                                Alert.instance?.close();
                                StoredFileDownloader.downloadFile({
                                    view: false,
                                    editable: false,
                                    target: { link, orgFilename: reportTemplate.orgFilename },
                                }).catch((err) => console.debug(err));
                            }}/>,
                    ],
                });
            }
        };
        this.openEditDialog = (reportTemplate) => (e) => {
            const { companySettings } = this.props;
            openAddOrChangeReportTemplate({ companySettings, reportTemplate })(e);
        };
        this.openFab = () => {
            if (Fab.instance != null) {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    title: I18n.m.getMessage('reportTemplateTitleCreateNew'),
                    onPressFab: this.openEditDialog(),
                });
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.removeLayout = (layout) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveReportTemplateQuestion', { title: layout.title }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeLayoutNow(layout.id)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeLayoutNow = (id) => (_e) => {
            const { companySettings } = this.props;
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new RemoveCompanyReportTemplate({ companyId: companySettings.id }, id, CurrentUser.token);
                remove
                    .execute(ClientStore.commandStore)
                    .catch((e) => {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.renderTable = () => {
            const { data } = this.state;
            const { size } = this.props;
            const height = size.contentHeight;
            const columns = [
                {
                    title: I18n.m.getMessage('projectSettingsName'),
                    keyInData: 'title',
                    dataType: 'string',
                    style: { width: 150 },
                },
                {
                    title: I18n.m.getMessage('creator'),
                    keyInData: 'creatorName',
                    dataType: 'string',
                    style: { width: 150 },
                },
                {
                    title: I18n.m.getMessage('createdAt'),
                    keyInData: 'createdAt',
                    dataType: 'Date',
                    style: { width: 150 },
                },
                {
                    title: I18n.m.getMessage('companyReportTemplateType'),
                    keyInData: 'reportType',
                    sortable: true,
                    dataType: 'string',
                    style: { width: 150 },
                    cellRenderer: (item, column, _index) => {
                        return (<View key={`fields${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
              <MaterialText>{I18n.m.getMessage(`reportType${item.reportType}`)}</MaterialText>
            </View>);
                    },
                },
            ];
            return (<Table actionItemsLength={3} tableName="copmanyReportTemplates" actions={(_item) => [
                    {
                        toolTip: I18n.m.getMessage('download'),
                        onAction: this.download,
                        icon: 'download-outline',
                    },
                    {
                        toolTip: I18n.m.getMessage('edit'),
                        onAction: this.openEditDialog,
                        icon: 'pencil-outline',
                    },
                    {
                        toolTip: I18n.m.getMessage('delete'),
                        onAction: this.removeLayout,
                        icon: 'delete-outline',
                    },
                ]} columns={columns} maxHeight={height - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(108)} emptyTableHint={I18n.m.getMessage('copmanyReportTemplateNoDataHint')} emptyTableText={I18n.m.getMessage('copmanyReportTemplateNoDataText')} emptyTableImage={noDataImage} data={data}/>);
        };
        this.state = {
            myRole: this.myRole(),
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init copmanyReportTemplateSettings', err));
        this.openFab();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.companySettings !== this.props.companySettings) {
            this.init().catch((err) => console.debug('cant init copmanyReportTemplateSettings', err));
        }
    }
    componentWillUnmount() {
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { companySettings } = this.props;
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue, flexDirection: 'row' }}>
          <MaterialText type={MaterialTextTypes.H6} centeredBox>
            {I18n.m.getMessage('reportTemplates')}
          </MaterialText>
          <Icon toolTip="" icon="information-outline" onPress={openCompanyReportTemplatesNotesDialog({ companySettings })}/>
        </View>
        <Card style={{ width: '100%', height: 'auto' }}>{this.renderTable()}</Card>
      </View>);
    }
}
