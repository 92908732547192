"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LokiODataDB = void 0;
var Loki = require("../../../../../upmesh-client/node_modules/lokijs");
var FetchProgress_1 = require("../../fetchprogress/FetchProgress");
var ODataDB_1 = require("../../odata/root/ODataDB");
var ODataLokiDBRepo_1 = require("./ODataLokiDBRepo");
var LokiODataDB = function (_ODataDB_1$ODataDB) {
  function LokiODataDB() {
    var _this;
    (0, _classCallCheck2.default)(this, LokiODataDB);
    _this = _callSuper(this, LokiODataDB);
    _this.odataRepoClass = ODataLokiDBRepo_1.ODataLokiDBRepo;
    _this.init = false;
    if (LokiODataDB._mainInstance == null) {
      LokiODataDB._mainInstance = _this;
    }
    return _this;
  }
  (0, _inherits2.default)(LokiODataDB, _ODataDB_1$ODataDB);
  return (0, _createClass2.default)(LokiODataDB, [{
    key: "connected",
    get: function get() {
      return true;
    }
  }, {
    key: "addCollection",
    value: function addCollection(name, options) {
      return this.db.addCollection(name, options);
    }
  }, {
    key: "closeDB",
    value: function () {
      var _closeDB = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        try {
          yield new Promise(function (resolve, reject) {
            _this2.db.close(function (err) {
              if (err != null) {
                reject(err);
              }
              resolve();
            });
          });
        } catch (e) {}
        return true;
      });
      function closeDB() {
        return _closeDB.apply(this, arguments);
      }
      return closeDB;
    }()
  }, {
    key: "getCollection",
    value: function getCollection(name) {
      return this.db.getCollection(name);
    }
  }, {
    key: "initDB",
    value: function () {
      var _initDB = (0, _asyncToGenerator2.default)(function* (lokiOptions) {
        var _this3 = this;
        if (this.init) {
          return true;
        }
        if (lokiOptions != null) {
          this.options = lokiOptions;
          this.options.autoload = false;
          try {
            var adapter = this.options.adapter;
            if (this.options.db == null) {
              adapter.orgLoadFunction = adapter.loadDatabase;
              var opt = this.options;
              adapter.loadDatabase = function (dbname, callback) {
                var c = function c(data) {
                  if (opt.loadFromServer && !data && opt.backendUrl && opt.token) {
                    var headers = {};
                    headers['authorization'] = `Bearer ${opt.token}`;
                    if (!opt.token) throw new Error('no token');
                    fetch(`${opt.backendUrl}/sync/file`, {
                      headers: headers
                    }).then((0, FetchProgress_1.default)({
                      onProgress: function onProgress(progress) {
                        if (this.options.progress) this.options.progress(progress);
                        return null;
                      }
                    })).then(function (result) {
                      if (result.status === 200) return result.json();
                      return new Promise(function (resolve) {
                        resolve(null);
                      });
                    }).then(function (d) {
                      if (d == null) callback(data);else {
                        callback(d);
                      }
                    }).catch(function (err) {
                      callback(data);
                    });
                  } else {
                    callback(data);
                  }
                };
                _this3.options.adapter.orgLoadFunction(dbname, c);
              };
            }
            this.db = this.options.db != null ? this.options.db : new Loki(this.options.filename, Object.assign({}, this.options, {
              adapter: adapter
            }));
            var loaded = yield new Promise(function (resolve, reject) {
              _this3.db.loadDatabase(undefined, function (err) {
                if (err != null) {
                  reject(err);
                }
                resolve(true);
              });
            });
            this.fileHandler = this.options.fileHandler;
            this.init = loaded;
            return loaded;
          } catch (e) {
            throw e;
          }
        }
        return false;
      });
      function initDB(_x) {
        return _initDB.apply(this, arguments);
      }
      return initDB;
    }()
  }, {
    key: "deleteDB",
    value: function () {
      var _deleteDB = (0, _asyncToGenerator2.default)(function* () {
        try {
          if (typeof this.options.adapter.deleteDB === 'function') {
            yield this.options.adapter.deleteDB(this.options.filename);
          }
        } catch (e) {}
        return true;
      });
      function deleteDB() {
        return _deleteDB.apply(this, arguments);
      }
      return deleteDB;
    }()
  }, {
    key: "saveDB",
    value: function () {
      var _saveDB = (0, _asyncToGenerator2.default)(function* () {
        var _this4 = this;
        try {
          if (typeof this.db.saveDatabase === 'function') {
            yield new Promise(function (resolve, reject) {
              _this4.db.saveDatabase(function (err) {
                if (err) reject(err);else resolve(true);
              });
            });
          }
        } catch (e) {}
        return true;
      });
      function saveDB() {
        return _saveDB.apply(this, arguments);
      }
      return saveDB;
    }()
  }], [{
    key: "mainInstance",
    get: function get() {
      if (LokiODataDB._mainInstance == null) {
        LokiODataDB._mainInstance = new LokiODataDB();
      }
      return LokiODataDB._mainInstance;
    }
  }]);
}(ODataDB_1.ODataDB);
exports.LokiODataDB = LokiODataDB;