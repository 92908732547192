"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SyncDispatcher = void 0;
var WaitFor_1 = require("cqrs-shared/build/src/WaitFor");
var ts_events_1 = require("ts-events");
var ReadModels_1 = require("./ReadModels");
var SyncDispatcher = function () {
  function SyncDispatcher(localDb, dbOptions, userId, token, serverConnection, backendUrl) {
    var _this = this;
    (0, _classCallCheck2.default)(this, SyncDispatcher);
    this.syncStart = new ts_events_1.AsyncEvent();
    this.syncStop = new ts_events_1.AsyncEvent();
    this.syncStatus = new ts_events_1.AsyncEvent();
    this.currentSyncStatus = {
      percent: 0,
      running: false,
      step: {
        readModelName: '',
        percent: 0
      }
    };
    this.maxParallelSyncs = 4;
    this.syncs = new Map();
    this.currentSyncs = [];
    this.syncsRunning = [];
    this._syncedOnce = false;
    this.is_Syncing = false;
    this.commandsSynced = false;
    this.postSyncStatus = function (status) {
      _this.currentSyncStatus = status;
      _this.syncStatus.post(_this.currentSyncStatus);
    };
    this.sortSync = function (a, b) {
      if (a.priority !== b.priority) return a.priority - b.priority;
      var aDate = a.lastRun != null ? a.lastRun.getTime() : 0;
      var bDate = b.lastRun != null ? b.lastRun.getTime() : 0;
      if (aDate < bDate) {
        return -1;
      }
      if (aDate > bDate) {
        return 1;
      }
      return 0;
    };
    this.startTime = new Date();
    this.serverConnection = serverConnection;
    this.localDb = localDb;
    this._dbOptions = Object.assign({}, dbOptions, {
      token: token
    });
    this._userId = userId;
    this.token = token;
    this._backendUrl = backendUrl;
  }
  return (0, _createClass2.default)(SyncDispatcher, [{
    key: "syncedOnce",
    get: function get() {
      return this._syncedOnce;
    }
  }, {
    key: "db",
    get: function get() {
      return this.localDb;
    }
  }, {
    key: "userId",
    get: function get() {
      return this._userId;
    }
  }, {
    key: "dbOptions",
    get: function get() {
      return this._dbOptions;
    }
  }, {
    key: "backendUrl",
    get: function get() {
      return this._backendUrl;
    }
  }, {
    key: "syncRunning",
    get: function get() {
      var _this$currentSyncStat;
      return (_this$currentSyncStat = this.currentSyncStatus) == null ? void 0 : _this$currentSyncStat.running;
    }
  }, {
    key: "isSyncing",
    get: function get() {
      return this.syncsRunning.length > 0;
    }
  }, {
    key: "registerCommandSync",
    value: function registerCommandSync(startScript, abortScript, statusEvent, serverConnection) {
      var _this2 = this;
      if (this.commandSync) {}
      statusEvent.attach(function (a) {
        _this2.setCommandsSyncStatus(a);
      });
      if (this.serverConnection == null) this.serverConnection = serverConnection;
      this.commandSync = {
        startScript: startScript,
        abortScript: abortScript
      };
    }
  }, {
    key: "resetSync",
    value: function () {
      var _resetSync = (0, _asyncToGenerator2.default)(function* (localDb, dbOptions, userId, token, backendUrl) {
        try {
          if (this.localDb != null) {
            yield this.localDb.closeDB();
          }
        } catch (err) {}
        this.localDb = localDb;
        this._dbOptions = Object.assign({}, dbOptions, {
          token: token
        });
        this._userId = userId;
        this.token = token;
        if (backendUrl) this._backendUrl = backendUrl;
        this.syncs = new Map();
        this.syncsRunning = [];
        this.currentSyncs = [];
      });
      function resetSync(_x, _x2, _x3, _x4, _x5) {
        return _resetSync.apply(this, arguments);
      }
      return resetSync;
    }()
  }, {
    key: "registerSync",
    value: function registerSync(readModelName, startScript, syncEvent) {
      if (this.startSyncTO) clearTimeout(this.startSyncTO);
      var s = {
        readModelName: readModelName,
        startScript: startScript,
        priority: 4,
        syncEvent: syncEvent
      };
      var has = this.syncs.has(readModelName);
      if (!has) {
        var readmodel = ReadModels_1.ReadModels.get(readModelName);
        if (readmodel && readmodel.config && readmodel.config['priority']) {
          s.priority = readmodel.config['priority'];
        }
        this.syncs.set(s.readModelName, s);
      }
    }
  }, {
    key: "initSD",
    value: function () {
      var _initSD = (0, _asyncToGenerator2.default)(function* () {
        var _this3 = this;
        if (this.localDb == null) return false;
        this.postSyncStatus({
          percent: 1,
          running: true,
          step: {
            percent: 0,
            readModelName: 'initDatabase'
          }
        });
        var progress = function progress(_progress) {
          _this3.postSyncStatus({
            percent: 5,
            running: true,
            step: {
              readModelName: 'initDatabase',
              percent: _progress['percentage'] >= 0 && _progress['percentage'] <= 100 ? Number.parseInt(_progress['percentage'], 10) : 0
            }
          });
        };
        this._dbOptions.progress = progress;
        var loaded = false;
        try {
          loaded = yield this.localDb.initDB(this.dbOptions);
        } catch (e) {
          try {
            yield this.localDb.deleteDB();
            loaded = yield this.localDb.initDB(this.dbOptions);
          } catch (e) {}
        }
        this.postSyncStatus({
          percent: 10,
          running: true,
          step: {
            readModelName: 'initDatabase',
            percent: 100
          }
        });
        return loaded;
      });
      function initSD() {
        return _initSD.apply(this, arguments);
      }
      return initSD;
    }()
  }, {
    key: "startSync",
    value: function () {
      var _startSync = (0, _asyncToGenerator2.default)(function* () {
        var _this4 = this;
        if (this.localDb == null || this.serverConnection == null) {
          this.syncStop.post();
          return;
        }
        if (this.is_Syncing) {
          yield WaitFor_1.WaitFor.instance.waitFor(function () {
            return !_this4.is_Syncing;
          });
        }
        if (this.serverConnection && !this.serverConnection().isConnected) {
          yield this.serverConnection().connect();
          yield WaitFor_1.WaitFor.instance.waitFor(function () {
            return _this4.serverConnection != null && _this4.serverConnection().isConnected;
          });
        }
        if (!this.serverConnection || !this.serverConnection().isConnected) {
          this.syncStop.post();
          return;
        }
        this.is_Syncing = true;
        this.startTime = new Date();
        this.syncTimes = [];
        if (this.syncStopTimeout != null) {
          clearTimeout(this.syncStopTimeout);
        }
        this.syncStart.post();
        this.postSyncStatus({
          percent: 1,
          running: true,
          step: {
            percent: 0,
            readModelName: 'initDatabase'
          }
        });
        var loadI = setInterval(function () {
          _this4.postSyncStatus({
            percent: 1,
            running: true,
            step: {
              percent: 0,
              readModelName: 'initDatabase'
            }
          });
        }, 500);
        yield this.initSD();
        clearInterval(loadI);
        if (this.commandSync) yield this.commandSync.startScript();
        if (!this.commandSync) {
          throw new Error('no commands Sync on Syncdispatcher');
        }
        this.syncStart.post();
        yield WaitFor_1.WaitFor.instance.waitFor(function () {
          return _this4.areCommandsSynced();
        });
        if (!this.areCommandsSynced()) {
          this.is_Syncing = false;
          yield this.startSync();
          return;
        }
        this.postSyncStatus({
          percent: 11,
          running: true,
          step: Object.assign({}, this.currentSyncStatus.step)
        });
        var syncs = Array.from(this.syncs.values());
        syncs.sort(this.sortSync);
        this.currentSyncs = [].concat(syncs);
        var models = [];
        this.currentSyncs.forEach(function (a) {
          var _a$lastRun;
          models.push({
            readModel: a.readModelName,
            lastSyncDate: (_a$lastRun = a.lastRun) == null ? void 0 : _a$lastRun.getTime()
          });
        });
        yield this.startFreeSyncSlot();
        this.is_Syncing = false;
        this._syncedOnce = true;
      });
      function startSync() {
        return _startSync.apply(this, arguments);
      }
      return startSync;
    }()
  }, {
    key: "setCommandsSyncStatus",
    value: function setCommandsSyncStatus(p) {
      var percent = Math.round(p.percent / 10);
      this.postSyncStatus({
        percent: percent,
        running: true,
        step: {
          percent: p.percent,
          readModelName: 'syncCommands'
        }
      });
      if (p.percent === 100) this.commandsSynced = true;else this.commandsSynced = false;
    }
  }, {
    key: "areCommandsSynced",
    value: function areCommandsSynced() {
      return this.commandsSynced;
    }
  }, {
    key: "startFreeSyncSlot",
    value: function () {
      var _startFreeSyncSlot = (0, _asyncToGenerator2.default)(function* () {
        var _this5 = this;
        if (this.syncStopTimeout != null) {
          clearTimeout(this.syncStopTimeout);
        }
        if (this.syncsRunning.length < this.maxParallelSyncs) {
          var start = new Date();
          var s = this.currentSyncs.shift();
          if (s != null && this.syncsRunning.indexOf(s.readModelName) === -1) {
            this.syncsRunning.push(s.readModelName);
            try {
              var postSync = function postSync(sStatus) {
                _this5.postSyncStatus({
                  step: {
                    readModelName: s.readModelName,
                    percent: sStatus.percent
                  },
                  percent: _this5.currentSyncStatus.percent,
                  running: true
                });
              };
              if (s.syncEvent) {
                s.syncEvent.attach(postSync);
              }
              var result = yield s.startScript();
              if (s.syncEvent) {
                s.syncEvent.detach(postSync);
              }
              this.syncFinished(s.readModelName);
              var end = new Date();
              this.syncTimes.push(Object.assign({}, result, {
                start: start,
                end: end,
                readModel: s.readModelName,
                duration: (end.getTime() - start.getTime()) / 1000
              }));
            } catch (e) {
              this.syncFinished(s.readModelName);
            }
          }
          if (this.syncsRunning.length < this.maxParallelSyncs && this.currentSyncs.length > 0) {
            this.startFreeSyncSlot().catch(function (err) {
              return void 0;
            });
          }
          return true;
        }
        return false;
      });
      function startFreeSyncSlot() {
        return _startFreeSyncSlot.apply(this, arguments);
      }
      return startFreeSyncSlot;
    }()
  }, {
    key: "syncFinished",
    value: function syncFinished(readModelName) {
      var _this6 = this;
      var indexExecuting = this.syncsRunning.indexOf(readModelName);
      if (indexExecuting >= 0) {
        this.syncsRunning.splice(indexExecuting, 1);
      }
      var item = this.syncs.get(readModelName);
      if (item) {
        item.lastRun = new Date();
      }
      var index2 = this.currentSyncs.findIndex(function (v) {
        return v.readModelName === readModelName;
      });
      if (index2 >= 0) {
        this.currentSyncs.splice(index2, 1);
      }
      if (this.syncStopTimeout != null) {
        clearTimeout(this.syncStopTimeout);
      }
      if (this.currentSyncs.length === 0 && this.syncsRunning.length === 0) {
        this.syncStopTimeout = setTimeout(function () {
          if (!_this6.isSyncing && _this6.areCommandsSynced()) {
            _this6.commandsSynced = false;
            var end = new Date();
            var duration = (end.getTime() - _this6.startTime.getTime()) / 1000;
            _this6.syncTimes.push({
              total: 0,
              updated: 0,
              start: _this6.startTime,
              end: end,
              readModel: 'all',
              duration: duration
            });
            _this6.syncStop.post();
            _this6.postSyncStatus({
              percent: 100,
              running: false,
              step: {
                percent: 100,
                readModelName: ''
              }
            });
          }
        }, 100);
      } else {
        var synced = this.syncs.size - this.currentSyncs.length;
        var percent = Math.ceil(synced / this.syncs.size * 90);
        this.postSyncStatus({
          percent: percent + 10,
          running: true,
          step: {
            readModelName: readModelName,
            percent: 100
          }
        });
        var currentS = [];
        this.currentSyncs.forEach(function (s) {
          return currentS.push(s.readModelName);
        });
        this.startFreeSyncSlot().catch(function (err) {
          return void 0;
        });
      }
    }
  }]);
}();
exports.SyncDispatcher = SyncDispatcher;