var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var mime = _interopRequireWildcard(require("mime"));
var _react = _interopRequireDefault(require("../../../../upmesh-client/node_modules/react"));
var _Alert = require("../theme/components/Alert");
var _ContainedButton = require("../theme/components/button/ContainedButton");
var _FileDropHandler = require("../theme/components/file/FileDropHandler");
var _I18n = require("../theme/i18n/I18n");
var _ThemeManager = require("../theme/ThemeManager");
var _FilePicker = require("./FilePicker");
var _FilePicketComp = require("./FilePicketComp");
var _ImagePickerOptions = require("./ImagePickerOptions");
var _jsxRuntime = require("../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ImagePickerComp = exports.default = function (_ImagePickerI) {
  function ImagePickerComp(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, ImagePickerComp);
    _this = _callSuper(this, ImagePickerComp, [props, context]);
    _this.onGotFile = function (f) {
      var asyncNow = function () {
        var _ref = (0, _asyncToGenerator2.default)(function* () {
          var options = _this.state.options;
          if (options.multiple) {
            try {
              var allFiles = [];
              for (var i = 0; i < f.length; i += 1) {
                allFiles.push(_this.loadImage(f[i]));
              }
              var files = yield Promise.all(allFiles);
              _this.props.callBack(files);
            } catch (e) {
              _this.displayAlert(e.toString());
            }
          } else {
            if (f.length > 1) {}
            try {
              var file = yield _this.loadImage(f[0]);
              _this.props.callBack(file);
            } catch (e) {
              _this.displayAlert(e.toString());
            }
          }
        });
        return function asyncNow() {
          return _ref.apply(this, arguments);
        };
      }();
      asyncNow().catch(function (err) {
        return void 0;
      });
    };
    _this.state = {
      options: _this.props.options
    };
    return _this;
  }
  (0, _inherits2.default)(ImagePickerComp, _ImagePickerI);
  return (0, _createClass2.default)(ImagePickerComp, [{
    key: "execute",
    value: function execute(overrideOptions) {
      var _this2 = this;
      var options = this.props.options;
      var opt = Object.assign({}, this.defaultOptions, options, overrideOptions);
      this.setState({
        options: opt
      }, function () {
        _FilePicker.FilePicker.show(opt.filePicketCompId, options.multiple, ['images'], function (_response) {
          var files = _response;
          _this2.onGotFile(files);
        });
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this$props = this.props,
        options = _this$props.options,
        dragNdrop = _this$props.dragNdrop;
      if (dragNdrop) {
        this.dropKey = _FileDropHandler.FileDropHandler.addTarget({
          onGotFile: this.onGotFile,
          text: options.dropText,
          filter: ['images'],
          multiHandlerDialogText: options.dropMultiHandlerDialogText,
          multiFiles: options.multiple,
          defaultHandler: false
        });
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.dropKey != null) {
        _FileDropHandler.FileDropHandler.removeTarget(this.dropKey);
        this.dropKey = undefined;
      }
    }
  }, {
    key: "loadImage",
    value: function () {
      var _loadImage = (0, _asyncToGenerator2.default)(function* (file) {
        var _this3 = this;
        return new Promise(function (resolve, reject) {
          var mimeType = mime.getType(file.name != null ? file.name : file['fileName']);
          if (mimeType == null || !(mimeType === 'image/jpeg' || mimeType === 'image/png')) {
            reject(new Error(`unsupported image format: ${mimeType}`));
            return;
          }
          var response = new _ImagePickerOptions.ImagePickerResponse();
          response.mime = mimeType;
          response.webFile = file;
          response.filename = file.name;
          response.modificationDate = file.lastModified;
          var options = _this3.state.options;
          var reader = new FileReader();
          reader.onload = function (readerEvent) {
            if (readerEvent != null && readerEvent.target != null && readerEvent.target['result'] != null) {
              var image = new Image();
              image.onload = function (_imageEvent) {
                var w = image.width;
                var h = image.height;
                response.width = image.width;
                response.height = image.height;
                if (options.maxHeight == null && options.maxWidth == null && !options.includeBase64) {
                  resolve(response);
                }
                if (options.maxWidth != null && options.maxWidth > 0) {
                  if (w > options.maxWidth) {
                    h *= options.maxWidth / w;
                    w = options.maxWidth;
                  }
                }
                if (options.maxHeight != null && options.maxHeight > 0) {
                  if (h > options.maxHeight) {
                    w *= options.maxHeight / h;
                    h = options.maxHeight;
                  }
                }
                response.width = w;
                response.height = h;
                var canvas = document.createElement('canvas');
                canvas.width = w;
                canvas.height = h;
                var context = canvas.getContext('2d');
                if (context != null) {
                  context.drawImage(image, 0, 0, w, h);
                  canvas.toBlob(function (blob) {
                    var webFile = blob;
                    webFile['name'] = file.name;
                    webFile['lastModified'] = file.lastModified;
                    response.webFile = webFile;
                    if (options.includeBase64) {
                      response.data = canvas.toDataURL(mimeType, options.quality);
                    }
                    resolve(response);
                  }, mimeType, options.quality);
                } else {
                  reject(new Error('no context'));
                }
              };
              image.src = readerEvent.target['result'];
            }
          };
          reader.readAsDataURL(file);
        });
      });
      function loadImage(_x) {
        return _loadImage.apply(this, arguments);
      }
      return loadImage;
    }()
  }, {
    key: "displayAlert",
    value: function displayAlert(message) {
      var _Alert$instance, _Alert$instance2;
      (_Alert$instance = _Alert.Alert.instance) == null || _Alert$instance.open({
        content: message,
        buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          title: _I18n.I18n.m.getMessage('ok'),
          onPress: (_Alert$instance2 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance2.close,
          full: false,
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary
        }, "imagePickerAlert")],
        closeOnTouchOutside: false
      });
    }
  }, {
    key: "render",
    value: function render() {
      var options = this.state.options;
      return (0, _jsxRuntime.jsx)(_FilePicketComp.FilePicketComp, {
        id: options.filePicketCompId,
        multiple: options.multiple,
        gotFile: this.onGotFile,
        filter: ['images']
      });
    }
  }], [{
    key: "hasCamera",
    value: function () {
      var _hasCamera = (0, _asyncToGenerator2.default)(function* () {
        return false;
      });
      function hasCamera() {
        return _hasCamera.apply(this, arguments);
      }
      return hasCamera;
    }()
  }]);
}(_ImagePickerOptions.ImagePickerI);