var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SocialLogin = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _queryString = _interopRequireDefault(require("query-string"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _LoginWithGoogle = require("upmesh-auth-core/build/src/client/commands/guest/LoginWithGoogle");
var _LoginWithMicrosoft = require("upmesh-auth-core/build/src/client/commands/guest/LoginWithMicrosoft");
var _SignUpUserWithGoogle = require("upmesh-auth-core/build/src/client/commands/guest/SignUpUserWithGoogle");
var _SignUpUserWithMicrosoft = require("upmesh-auth-core/build/src/client/commands/guest/SignUpUserWithMicrosoft");
var _Alert = require("../../../theme/components/Alert");
var _ContainedButton = require("../../../theme/components/button/ContainedButton");
var _Htextline = require("../../../theme/components/Htextline");
var _MaterialText = require("../../../theme/components/text/MaterialText");
var _I18n = require("../../../theme/i18n/I18n");
var _LoadingEvents = require("../../../theme/routing/LoadingEvents");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _GoogleButton = require("./GoogleButton");
var _MicrosoftButton = require("./MicrosoftButton");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var SocialLogin = exports.SocialLogin = function (_PureComponent) {
  function SocialLogin(props) {
    var _this;
    (0, _classCallCheck2.default)(this, SocialLogin);
    _this = _callSuper(this, SocialLogin, [props]);
    _this.useRedirect = false;
    _this.errorGoogle = function (response) {
      _LoadingEvents.LoadingEvents.instance.stopLoading();
      if (response && response.error === 'idpiframe_initialization_failed') {
        _this.useRedirect = true;
      } else {
        var errorMessage = response && response.details ? response.details : '';
        _this.setState({
          errorMessage: errorMessage,
          error: true
        }, function () {
          _LoadingEvents.LoadingEvents.instance.stopLoading();
        });
      }
    };
    _this.msAuthHandler = function (err, data) {
      if (err != null) {
        var errorMessage = err.message ? err.message : err.toString();
        _Routing.Routing.instance.alert.post({
          text: errorMessage
        });
      } else {
        _LoadingEvents.LoadingEvents.instance.startLoading();
        var idToken = data.uniqueId;
        var accessToken = data.accessToken;
        _this.loginWithMicrosoftToken(accessToken, idToken).catch(function (err) {
          _LoadingEvents.LoadingEvents.instance.stopLoading();
        });
      }
    };
    _this.loginWithMicrosoftToken = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (microsoftAccessToken, microsoftIdToken) {
        try {
          var connect = new _LoginWithMicrosoft.LoginWithMicrosoft({
            microsoftAccessToken: microsoftAccessToken,
            microsoftIdToken: microsoftIdToken
          });
          yield connect.execute(_AuthClient.AuthClient.instance.commandStore);
          yield _AuthClient.AuthClient.instance.logIn(connect.result);
        } catch (e) {
          _LoadingEvents.LoadingEvents.instance.stopLoading();
          if (e.messageCode === 'loginErrorNoAccount') {
            _Routing.Routing.instance.alert.post({
              title: _I18n.I18n.m.getMessage('loginErrorNoAccountMicrosoftTitle'),
              text: `${_I18n.I18n.m.getMessage('loginErrorNoAccountMicrosoft')}`,
              buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                title: _I18n.I18n.m.getMessage('abort'),
                onPress: _this.gotoLogin,
                backgroundColor: "transparent",
                textColor: _ThemeManager.ThemeManager.style.brandPrimary
              }, "loginButton"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                title: _I18n.I18n.m.getMessage('loginErrorNoAccountRegister'),
                onPress: _this.registerWithMicrosoft(microsoftIdToken, microsoftAccessToken),
                backgroundColor: "transparent",
                textColor: _ThemeManager.ThemeManager.style.brandPrimary
              }, "registerButton")]
            });
          } else {
            _Routing.Routing.instance.alert.post({
              text: _I18n.I18n.m.getMessage(e.messageCode)
            });
          }
        }
        _LoadingEvents.LoadingEvents.instance.stopLoading();
      });
      return function (_x, _x2) {
        return _ref.apply(this, arguments);
      };
    }();
    _this.responseGoogle = function (response) {
      _this.setState({
        user: response
      }, function () {
        _this.signIn(response).catch(function (err) {
          return void 0;
        });
      });
    };
    _this.googleSignIn = function (e) {
      if (e) {
        e.preventDefault();
      }
      if (window.gapi) {
        var auth2 = window.gapi.auth2.getAuthInstance();
        var options = {
          ux_mode: _this.useRedirect ? 'redirect' : 'popup',
          scope: 'profile email'
        };
        auth2.signIn(options).then(_this.signIn, _this.errorGoogle);
      }
    };
    _this.gotoLogin = function () {
      var _Alert$instance;
      (_Alert$instance = _Alert.Alert.instance) == null || _Alert$instance.close(function () {
        setTimeout(function () {
          _Routing.Routing.instance.goTo('/login');
        }, 500);
      });
    };
    _this.hashLoginOnConnection = function () {
      _AuthClient.AuthClient.instance.serverConnection.onConnect.detach(_this.hashLoginOnConnection);
      _this.hashLogin();
    };
    _this.loginAfterRegisterWithGoogle = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (googleAccessToken, googleIdToken, tries) {
        try {
          var loginWithGoogle = new _LoginWithGoogle.LoginWithGoogle({
            googleAccessToken: googleAccessToken,
            googleIdToken: googleIdToken
          });
          yield loginWithGoogle.execute(_AuthClient.AuthClient.instance.commandStore);
          yield _AuthClient.AuthClient.instance.logIn(loginWithGoogle.result);
        } catch (e) {
          if (tries <= 3) {
            setTimeout(function () {
              _this.loginAfterRegisterWithGoogle(googleAccessToken, googleIdToken, tries + 1).catch(function (err) {
                return void 0;
              });
            }, 1000);
          } else {
            _Routing.Routing.instance.goTo('/login');
          }
        }
      });
      return function (_x3, _x4, _x5) {
        return _ref2.apply(this, arguments);
      };
    }();
    _this.loginAfterRegisterWithMicrosoft = function () {
      var _ref3 = (0, _asyncToGenerator2.default)(function* (microsoftAccessToken, microsoftIdToken, tries) {
        try {
          var loginWithGoogle = new _LoginWithMicrosoft.LoginWithMicrosoft({
            microsoftAccessToken: microsoftAccessToken,
            microsoftIdToken: microsoftIdToken
          });
          yield loginWithGoogle.execute(_AuthClient.AuthClient.instance.commandStore);
          yield _AuthClient.AuthClient.instance.logIn(loginWithGoogle.result);
        } catch (e) {
          if (tries <= 3) {
            setTimeout(function () {
              _this.loginAfterRegisterWithMicrosoft(microsoftAccessToken, microsoftIdToken, tries + 1).catch(function (err) {
                return void 0;
              });
            }, 1000);
          } else {
            _Routing.Routing.instance.goTo('/login');
          }
        }
      });
      return function (_x6, _x7, _x8) {
        return _ref3.apply(this, arguments);
      };
    }();
    _this.loginWithGoogleToken = function () {
      var _ref4 = (0, _asyncToGenerator2.default)(function* (googleIdToken, googleAccessToken) {
        try {
          var loginWithGoogle = new _LoginWithGoogle.LoginWithGoogle({
            googleAccessToken: googleAccessToken,
            googleIdToken: googleIdToken
          });
          yield loginWithGoogle.execute(_AuthClient.AuthClient.instance.commandStore);
          yield _AuthClient.AuthClient.instance.logIn(loginWithGoogle.result);
        } catch (e) {
          _LoadingEvents.LoadingEvents.instance.stopLoading();
          if (e.messageCode === 'loginErrorNoAccount') {
            _Routing.Routing.instance.alert.post({
              title: _I18n.I18n.m.getMessage('loginErrorNoAccountGoogleTitle'),
              text: `${_I18n.I18n.m.getMessage('loginErrorNoAccountGoogle')} ${_I18n.I18n.m.getMessage('loginErrorNoAccountGoogleSub')}`,
              buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                title: _I18n.I18n.m.getMessage('login'),
                onPress: _this.gotoLogin,
                backgroundColor: "transparent",
                textColor: _ThemeManager.ThemeManager.style.brandPrimary
              }, "loginButton"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                title: _I18n.I18n.m.getMessage('loginErrorNoAccountRegister'),
                onPress: _this.registerWithGoogle(googleIdToken, googleAccessToken),
                backgroundColor: "transparent",
                textColor: _ThemeManager.ThemeManager.style.brandPrimary
              }, "registerButton")]
            });
          } else {
            _Routing.Routing.instance.alert.post({
              text: _I18n.I18n.m.getMessage(e.messageCode)
            });
          }
        }
      });
      return function (_x9, _x10) {
        return _ref4.apply(this, arguments);
      };
    }();
    _this.registerWithGoogle = function (googleIdToken, googleAccessToken) {
      return function (_e) {
        var _Alert$instance2;
        (_Alert$instance2 = _Alert.Alert.instance) == null || _Alert$instance2.close(function () {
          var asyncNow = function () {
            var _ref5 = (0, _asyncToGenerator2.default)(function* () {
              try {
                var signUpWithGoogle = new _SignUpUserWithGoogle.SignUpUserWithGoogle({
                  googleIdToken: googleIdToken,
                  googleAccessToken: googleAccessToken,
                  locale: 'de'
                }, '', '');
                yield signUpWithGoogle.execute(_AuthClient.AuthClient.instance.commandStore);
                setTimeout(function () {
                  _this.loginAfterRegisterWithGoogle(googleAccessToken, googleIdToken, 0).catch(function (err) {
                    return void 0;
                  });
                }, 1000);
              } catch (e) {
                if (e.messageCode === 'SignUpErrorUserAlreadyExisting') {
                  setTimeout(function () {
                    _LoadingEvents.LoadingEvents.instance.stopLoading();
                    _Routing.Routing.instance.alert.post({
                      text: _I18n.I18n.m.getMessage('SignUpErrorGoogleUserAlreadyExisting'),
                      buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                        backgroundColor: "transparent",
                        textColor: _ThemeManager.ThemeManager.style.brandPrimary,
                        onPress: _this.closeAlert,
                        title: _I18n.I18n.m.getMessage('ok')
                      }, "ok"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                        onPress: _this.goToLogin,
                        title: _I18n.I18n.m.getMessage('login')
                      }, "login")]
                    });
                  }, 0);
                  return;
                }
                _Routing.Routing.instance.alert.post({
                  text: _I18n.I18n.m.getMessage(e.messageCode)
                });
              }
            });
            return function asyncNow() {
              return _ref5.apply(this, arguments);
            };
          }();
          asyncNow().catch(function (err) {
            return void 0;
          });
        });
      };
    };
    _this.registerWithMicrosoft = function (microsoftIdToken, microsoftAccessToken) {
      return function (_e) {
        var _Alert$instance3;
        (_Alert$instance3 = _Alert.Alert.instance) == null || _Alert$instance3.close(function () {
          var asyncNow = function () {
            var _ref6 = (0, _asyncToGenerator2.default)(function* () {
              try {
                var signUp = new _SignUpUserWithMicrosoft.SignUpUserWithMicrosoft({
                  microsoftIdToken: microsoftIdToken,
                  microsoftAccessToken: microsoftAccessToken,
                  locale: 'de'
                }, '', '');
                yield signUp.execute(_AuthClient.AuthClient.instance.commandStore);
                setTimeout(function () {
                  _this.loginAfterRegisterWithMicrosoft(microsoftAccessToken, microsoftIdToken, 0).catch(function (err) {
                    return void 0;
                  });
                }, 1000);
              } catch (e) {
                var message = e.messageCode ? _I18n.I18n.m.getMessage(e.messageCode) : e.message ? e.message : e.toString();
                _Routing.Routing.instance.alert.post({
                  text: message
                });
              }
            });
            return function asyncNow() {
              return _ref6.apply(this, arguments);
            };
          }();
          asyncNow().catch(function (err) {
            return void 0;
          });
        });
      };
    };
    _this.closeAlert = function () {
      var _Alert$instance4;
      (_Alert$instance4 = _Alert.Alert.instance) == null || _Alert$instance4.close();
    };
    _this.goToLogin = function (_e) {
      var _Alert$instance5;
      (_Alert$instance5 = _Alert.Alert.instance) == null || _Alert$instance5.close(function () {
        requestAnimationFrame(function () {
          _Routing.Routing.instance.goTo('/login');
        });
      });
    };
    _this.signIn = function () {
      var _ref7 = (0, _asyncToGenerator2.default)(function* (user) {
        var userData = user.getAuthResponse();
        if (userData != null) {
          _LoadingEvents.LoadingEvents.instance.startLoading();
          var idToken = userData['id_token'];
          var accessToken = userData['access_token'];
          _this.loginWithGoogleToken(idToken, accessToken).catch(function (err) {
            return void 0;
          });
        } else {}
      });
      return function (_x11) {
        return _ref7.apply(this, arguments);
      };
    }();
    _this.state = {
      tokenId: '',
      user: null,
      error: false,
      isLoading: false,
      errorMessage: ''
    };
    return _this;
  }
  (0, _inherits2.default)(SocialLogin, _PureComponent);
  return (0, _createClass2.default)(SocialLogin, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;
      if (!this.hashLogin()) {
        (function (d, s, id, cb) {
          var element = d.getElementsByTagName(s)[0];
          var fjs = element;
          if (fjs != null) {
            var js = element;
            js = d.createElement(s);
            js.id = id;
            js.src = '//apis.google.com/js/client:platform.js';
            if (fjs.parentNode != null) {
              fjs.parentNode.insertBefore(js, fjs);
            }
            js.onload = cb;
          }
        })(document, 'script', 'google-login', function () {
          _this2.initGoogle();
        });
      }
    }
  }, {
    key: "hashLogin",
    value: function hashLogin() {
      if (window.location.hash != null && window.location.hash.length > 0) {
        try {
          var qs = _queryString.default.parse(window.location.hash);
          if (qs != null && qs.id_token != null && typeof qs.id_token === 'string' && qs.id_token.length > 0) {
            if (_AuthClient.AuthClient.instance.serverConnection.isConnected) {
              _LoadingEvents.LoadingEvents.instance.startLoading();
              _Routing.Routing.instance.goTo('/login', true);
              this.loginWithGoogleToken(qs.id_token, qs.access_token == null || typeof qs.access_token !== 'string' ? '' : qs.access_token).catch(function (err) {
                return void 0;
              });
            } else {
              _AuthClient.AuthClient.instance.serverConnection.onConnect.attach(this.hashLoginOnConnection);
            }
            return true;
          }
        } catch (e) {}
      }
      return false;
    }
  }, {
    key: "initGoogle",
    value: function initGoogle() {
      var _this3 = this;
      window.gapi.load('auth2', function () {
        var params = {
          client_id: _AuthClient.AuthClient.webClientId,
          cookie_policy: 'single_host_origin',
          login_hint: '',
          hosted_domain: '',
          fetch_basic_profile: true,
          ux_mode: _this3.useRedirect ? 'redirect' : 'popup',
          redirect_uri: null,
          scope: 'profile email'
        };
        window.gapi.auth2.init(params).then(function (_res) {}, _this3.errorGoogle);
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this4 = this;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          alignItems: 'center',
          alignSelf: 'stretch',
          padding: 0
        },
        children: [this.state.error ? (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            alignSelf: 'stretch'
          },
          children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Body1,
            children: _I18n.I18n.m.getMessage('loginErrorNoGoogle')
          }), this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Body1,
            children: this.state.errorMessage
          }) : null]
        }) : null, (0, _jsxRuntime.jsx)(_GoogleButton.GoogleButton, {
          title: _I18n.I18n.m.getMessage('loginWithGoogle'),
          disabled: this.state.error,
          onPress: this.googleSignIn
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 8
          }
        }), (0, _jsxRuntime.jsx)(_MicrosoftButton.MicrosoftButton, {
          disabled: this.state.error,
          title: _I18n.I18n.m.getMessage('loginWithMicrosoft'),
          onAccess: function onAccess(data) {
            _this4.loginWithMicrosoftToken(data.accessToken, data.idToken).catch(function (err) {
              return void 0;
            });
          }
        }), (0, _jsxRuntime.jsx)(_Htextline.Htextline, {
          sitePadding: 0,
          children: _I18n.I18n.m.getMessage('or')
        })]
      });
    }
  }]);
}(_react.PureComponent);