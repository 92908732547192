var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WrapperUserContext = exports.LanguageAsyncCache = void 0;
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _UserSettingsEntity = require("upmesh-auth-core/build/src/client/query/entities/UserSettingsEntity");
var _userContext = _interopRequireDefault(require("../../../userContext"));
var _I18n = require("../../i18n/I18n");
var _LocaleDetector = require("../../i18n/LocaleDetector");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var LanguageAsyncCache = exports.LanguageAsyncCache = function () {
  function LanguageAsyncCache(key) {
    (0, _classCallCheck2.default)(this, LanguageAsyncCache);
    this.storeKey = key;
  }
  return (0, _createClass2.default)(LanguageAsyncCache, [{
    key: "save",
    value: function () {
      var _save = (0, _asyncToGenerator2.default)(function* (key, value) {
        _SimpleStorage.SimpleStorage.set(`${this.storeKey}_${key}`, value);
      });
      function save(_x, _x2) {
        return _save.apply(this, arguments);
      }
      return save;
    }()
  }, {
    key: "restore",
    value: function () {
      var _restore = (0, _asyncToGenerator2.default)(function* (key) {
        return _SimpleStorage.SimpleStorage.get(`${this.storeKey}_${key}`);
      });
      function restore(_x3) {
        return _restore.apply(this, arguments);
      }
      return restore;
    }()
  }]);
}();
var WrapperUserContext = exports.WrapperUserContext = function (_PureComponent) {
  function WrapperUserContext(props) {
    var _this;
    (0, _classCallCheck2.default)(this, WrapperUserContext);
    _this = _callSuper(this, WrapperUserContext, [props]);
    _this.unsubscribeFromNetworkConnectivityChanges = null;
    _this.attachKey = null;
    _this.attachSettingsKey = null;
    _this.onChangeLanguage = function (locale) {
      try {
        _SimpleStorage.SimpleStorage.set('locale', locale);
        _this.setState({
          locale: locale
        });
      } catch (e) {}
    };
    _this.updateUser = function (en) {
      var locale = _this.state.locale;
      en.entities.forEach(function (e) {
        var _CurrentUser$entity;
        if (e != null && e.entity != null && e.entity.id === ((_CurrentUser$entity = _CurrentUser.CurrentUser.entity) == null ? void 0 : _CurrentUser$entity.id)) {
          var user = e.entity;
          _this.setState({
            user: user
          }, function () {
            if (locale !== user.locale) {
              _I18n.I18n.m.changeLanguage(user.locale).catch(function (err) {
                return void 0;
              });
            }
          });
        }
      });
    };
    _this.updateSettings = function (en) {
      en.entities.forEach(function (e) {
        if (e != null && e.entity != null && e.entity.userId === _CurrentUser.CurrentUser.userId) {
          var settings = e.entity;
          _CurrentUser.CurrentUser.settings = e.entity;
          _I18n.I18n.m.changeDateLocale(settings.timeFormat === '24');
          _this.setState({
            settings: settings
          });
        }
      });
    };
    _this.onLogin = function (data) {
      if (_this.attachKey != null) _AuthClient.AuthClient.instance.eventDispatcher.detach('User', _this.attachKey);
      _this.attachKey = _AuthClient.AuthClient.instance.eventDispatcher.attach({
        readModelName: 'User',
        callback: _this.updateUser,
        entityId: data.userId
      });
      if (_this.attachSettingsKey != null) _AuthClient.AuthClient.instance.eventDispatcher.detach('UserSettings', _this.attachSettingsKey);
      _this.attachSettingsKey = _AuthClient.AuthClient.instance.eventDispatcher.attach({
        readModelName: 'UserSettings',
        callback: _this.updateSettings,
        entityId: data.userId
      });
      if (_CurrentUser.CurrentUser.entity != null && _CurrentUser.CurrentUser.entity.locale != null) {
        var map = new Map();
        map.set(_CurrentUser.CurrentUser.entity.id, {
          entity: _CurrentUser.CurrentUser.entity
        });
        var e = {
          entities: map,
          readModelName: 'User'
        };
        _this.updateUser(e);
      }
      _this.setState({
        settings: _CurrentUser.CurrentUser.settings
      });
    };
    _this.onLogout = function () {
      if (_this.attachKey != null) _AuthClient.AuthClient.instance.eventDispatcher.detach('User', _this.attachKey);
      if (_this.attachSettingsKey != null) _AuthClient.AuthClient.instance.eventDispatcher.detach('UserSettings', _this.attachSettingsKey);
      _this.setState({
        user: null
      });
    };
    _this.state = {
      locale: _I18n.I18n.m.currentLanguage,
      user: null,
      settings: new _UserSettingsEntity.UserSettingsEntity()
    };
    return _this;
  }
  (0, _inherits2.default)(WrapperUserContext, _PureComponent);
  return (0, _createClass2.default)(WrapperUserContext, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _AuthClient.AuthClient.onLogin(this.onLogin);
      _AuthClient.AuthClient.onLogout(this.onLogout);
      _I18n.I18n.m.changedLanguage.attach(this.onChangeLanguage);
      this.init();
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _I18n.I18n.m.changedLanguage.detach(this.onChangeLanguage);
      _AuthClient.AuthClient.onLoginDetach(this.onLogin);
      _AuthClient.AuthClient.onLogoutDetach(this.onLogout);
      if (this.attachKey != null) {
        _AuthClient.AuthClient.instance.eventDispatcher.detach('User', this.attachKey);
        this.attachKey = null;
      }
      if (this.attachSettingsKey != null) {
        _AuthClient.AuthClient.instance.eventDispatcher.detach('UserSettings', this.attachSettingsKey);
        this.attachSettingsKey = null;
      }
    }
  }, {
    key: "init",
    value: function init() {
      if (_CurrentUser.CurrentUser.entity != null && _CurrentUser.CurrentUser.entity.locale != null) {
        this.onLogin({
          userId: _CurrentUser.CurrentUser.entity.id
        });
        return;
      }
      var locale = _SimpleStorage.SimpleStorage.get('locale');
      if (locale != null) {
        _I18n.I18n.m.changeLanguage(locale).catch(function (e) {
          return void 0;
        });
      } else {
        _I18n.I18n.m.changeLanguage(_LocaleDetector.LocaleDetector.detect()).catch(function (e) {
          return void 0;
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this$state = this.state,
        locale = _this$state.locale,
        user = _this$state.user,
        settings = _this$state.settings;
      var children = this.props.children;
      return (0, _jsxRuntime.jsx)(_userContext.default.Provider, {
        value: {
          locale: locale,
          user: user,
          settings: settings
        },
        children: children
      });
    }
  }]);
}(_react.PureComponent);