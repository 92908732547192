import { getAddressInfoFromGoogleResponse, GooglePlacesInput, } from 'materialTheme/src/theme/components/addressCard/GooglePlacesInput';
import { Header } from 'materialTheme/src/theme/components/header/Header';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import querystring from 'query-string';
import React, { PureComponent } from 'react';
import { Image, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ChangeTicketPlanAffiliation } from 'upmesh-core/src/client/commands/tickets/ChangeTicketPlanAffiliation';
import { ChangeTicketPlanPosition } from 'upmesh-core/src/client/commands/tickets/ChangeTicketPlanPosition';
import { RemoveTicketPlanAffiliation } from 'upmesh-core/src/client/commands/tickets/RemoveTicketPlanAffiliation';
import { TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { PlanFilter } from 'upmesh-core/src/client/query/filter/PlanFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { OfflineDataDownloader } from '../../../repo/file/OfflineDataDownloader';
import { CustomMarkerComponent } from '../../plans/planzoom/CustomMarkerComponent';
import { PlanMarker } from '../../plans/planzoom/PlanMarker';
import { PlanZoomTileMapper } from '../../plans/PlanZoomTileMapper';
import { CurrentProject } from '../../project/CurrentProject';
import { PlanSelector } from '../PlanSelector';
const notFoundImage = require('../../../assets/img/no_blueprint.png');
export class ChangePositionOnPlan extends PureComponent {
    constructor(props) {
        super(props);
        this.apiUrl = '';
        this.plans = CurrentProject.instance.getCurrentPlans();
        this.goToPlanUpload = () => {
            Routing.instance.goTo(`/projects/${this.props.ticket.projectId}/plans`);
        };
        this.setCurrentCenter = (r) => {
            this.currentCenter = r;
            const region = { latitude: r.center.latlng.lat, longitude: r.center.latlng.lng };
            try {
                if (this.fetchTO != null)
                    clearTimeout(this.fetchTO);
                this.abortController.abort();
            }
            catch (e) {
                console.debug('cannot abort fetch');
            }
            this.abortController = new AbortController();
            const { signal } = this.abortController;
            this.fetchTO = setTimeout(() => {
                fetch(`${this.apiUrl}/geocode/json?&lat=${region.latitude}&long=${region.longitude}`, { signal })
                    .then((result) => {
                    if (result.status === 200) {
                        result
                            .json()
                            .then((json) => {
                            if (json == null || json.results == null)
                                return;
                            const { placesKey } = this.state;
                            const bestMatch = json.results.filter((addressItem) => addressItem.address_components.length >= 7)[0];
                            if (bestMatch != null) {
                                const newAddress = getAddressInfoFromGoogleResponse(bestMatch.formatted_address, bestMatch);
                                if (newAddress.addressInput != null)
                                    this.setState({ addressInput: newAddress.addressInput, placesKey: placesKey + 1 });
                            }
                        })
                            .catch((err) => console.debug(err));
                    }
                })
                    .catch((err) => {
                    console.debug('catched', err);
                });
            }, 200);
        };
        this.abort = (e) => {
            const { ticket } = this.props;
            if (ticket.id === 'new') {
                Routing.instance.closeDialog(true)(e);
            }
            else {
                Routing.instance.openDialog('ticket', { id: ticket.id }, true)(e);
            }
        };
        this.setMapRef = (r) => {
            this.zoomTile = r;
        };
        this.onGoogleAddressSelected = (newAddress) => {
            if (newAddress.addressInput != null) {
                this.setState({ addressInput: newAddress.addressInput }, () => {
                    if (newAddress.longitude != null && newAddress.latitude != null)
                        this.zoomTile?.zoomTo({ x: Number.parseFloat(newAddress.longitude), y: Number.parseFloat(newAddress.latitude) }, 17);
                });
            }
        };
        this.onCustomAddressTextSelected = (text) => {
            this.setState({ addressInput: text });
        };
        this.onLayout = (_e) => {
            const getNumColums = this.createNumColumns(this.props.width);
            this.setState({
                ...getNumColums,
            });
        };
        this.onPlanChange = (plans) => {
            this.plans = plans;
            this.forceUpdate();
        };
        this.noPlan = async (_e) => {
            const { ticket, createTicket } = this.props;
            if (ticket.planId != null && ticket.planId.length > 0) {
                LoadingEvents.instance.startLoading();
                if (ticket.id === 'new') {
                    ticket.planId = undefined;
                    ticket.planPositionX = undefined;
                    ticket.planPositionY = undefined;
                    createTicket(ticket);
                }
                else {
                    try {
                        await new Promise((resolve, reject) => {
                            UpmeshClient.eventDispatcher.attach({
                                once: true,
                                entityId: ticket.id,
                                readModelName: 'Ticket',
                                callback: () => {
                                    requestAnimationFrame(() => {
                                        resolve();
                                    });
                                },
                            });
                            const c = new RemoveTicketPlanAffiliation({}, ticket.id);
                            c.execute()
                                .catch((err) => {
                                reject(err);
                            })
                                .finally(() => {
                                LoadingEvents.instance.stopLoading();
                                Routing.instance.openDialog('ticket', { id: ticket.id }, true)(null);
                            });
                        });
                    }
                    catch (e) {
                        LoadingEvents.instance.stopLoading();
                        Routing.instance.alert.post({
                            title: I18n.m.getMessage('defaultErrorTitleOnSave'),
                            text: e['messageCode'] != null ? I18n.m.getMessage(e.messageCode) : e.toString(),
                        });
                        ErrorReporter.sendReport({
                            subject: `Cant remove plan position`,
                            data: e,
                            type: 'warn',
                        });
                    }
                }
            }
            else {
                Routing.instance.openDialog('ticket', { id: ticket.id }, true)(null);
            }
        };
        this.savePlanPosition = async () => {
            const { createTicket } = this.props;
            const { planId, selectedPlanVersion, selectedPlan, addressInput } = this.state;
            if (this.currentCenter != null &&
                planId != null &&
                (selectedPlanVersion != null || selectedPlan?.activePlanId === 'MAP')) {
                const { ticket } = this.props;
                LoadingEvents.instance.startLoading();
                const address = selectedPlan?.activePlanId === 'MAP' ? addressInput : '';
                try {
                    const center = this.currentCenter.center.point;
                    const position = selectedPlan?.activePlanId === 'MAP' ? center : selectedPlanVersion.getRealpointInCMFromPagepoint(center);
                    if (ticket.id === 'new') {
                        ticket.planId = planId;
                        ticket.address = address;
                        ticket.planPositionX = position.x;
                        ticket.planPositionY = position.y;
                        createTicket(ticket);
                    }
                    else {
                        await new Promise((resolve, reject) => {
                            UpmeshClient.eventDispatcher.attach({
                                once: true,
                                entityId: ticket.id,
                                readModelName: 'Ticket',
                                callback: () => {
                                    requestAnimationFrame(() => {
                                        resolve();
                                    });
                                },
                            });
                            const asyncNow = async () => {
                                try {
                                    if (planId !== ticket.planId) {
                                        const c = new ChangeTicketPlanAffiliation({ address, planId, planPositionX: position.x, planPositionY: position.y, planPositionZ: 0 }, ticket.id);
                                        await c.execute();
                                    }
                                    else {
                                        const c = new ChangeTicketPlanPosition({ address, planPositionX: position.x, planPositionY: position.y, planPositionZ: 0 }, ticket.id);
                                        await c.execute();
                                    }
                                }
                                catch (e) {
                                    reject(e);
                                }
                            };
                            asyncNow().catch((err) => console.error(err));
                        });
                        LoadingEvents.instance.stopLoading();
                        Routing.instance.openDialog('ticket', { id: ticket.id }, true)(null);
                    }
                }
                catch (e) {
                    LoadingEvents.instance.stopLoading();
                    Routing.instance.alert.post({
                        title: I18n.m.getMessage('defaultErrorTitleOnSave'),
                        text: e['messageCode'] != null ? I18n.m.getMessage(e.messageCode) : e.toString(),
                    });
                    ErrorReporter.sendReport({
                        subject: `Cant save plan position`,
                        data: e,
                        type: 'warn',
                    });
                }
            }
        };
        this.changePlan = (_e) => {
            this.setState({ planId: undefined });
        };
        this.setPlanId = async (planId) => {
            try {
                const { ticket } = this.props;
                const selectedPlan = await UpmeshClient.instance.modals.plan.getById(planId);
                if (selectedPlan.activePlanId === 'MAP') {
                    const initialPlanPosition = this.getInitialPlanPosition(ticket, selectedPlan);
                    this.setState({
                        planId,
                        selectedPlan,
                        initialPlanPosition,
                        selectedPlanVersion: undefined,
                        addressInput: ticket.address != null ? ticket.address : '',
                    });
                }
                else {
                    const selectedPlanVersion = await UpmeshClient.instance.modals.planVersion.getById(selectedPlan.activePlanId);
                    const initialPlanPosition = this.getInitialPlanPosition(ticket, selectedPlan, selectedPlanVersion);
                    this.setState({ planId, selectedPlan, selectedPlanVersion, initialPlanPosition });
                }
            }
            catch (e) {
                ErrorReporter.sendReport({
                    subject: `Cant get plan - ${planId}`,
                    data: e,
                    type: 'warn',
                });
            }
        };
        this.apiUrl = `${AuthClient.instance.url.replace('3011/auth/', '3012/')}/backend/googleapis`;
        this.state = {
            selectedPlan: undefined,
            selectedPlanVersion: undefined,
            planId: undefined,
            targetWidth: 256,
            numColumns: 1,
            placesKey: 0,
            addressInput: '',
        };
    }
    componentDidMount() {
        ResizeEvent.onResize.attach(this.onLayout);
        this.plans = CurrentProject.instance.getCurrentPlans();
        CurrentProject.plansChanged.attach(this.onPlanChange);
        this.onLayout();
        if (this.props.ticket.planId != null && this.state.planId == null) {
            this.setPlanId(this.props.ticket.planId).catch((err) => console.debug(err));
        }
    }
    componentWillUnmount() {
        ResizeEvent.onResize.detach(this.onLayout);
        CurrentProject.plansChanged.detach(this.onPlanChange);
    }
    render() {
        const { height, ticket, width } = this.props;
        const content = this.renderContent();
        const closeButton = this.state.planId == null || this.plans == null || this.plans.length === 0
            ? [<Icon toolTip={I18n.m.getMessage('close')} key="close2Button" icon="close" onPress={this.abort}/>]
            : [];
        return (<View style={{ width: '100%', height: '100%', justifyContent: 'center' }}>
        <View key={ticket.id} style={{
                width,
                height,
                overflow: 'hidden',
                paddingBottom: 0,
            }}>
          {content.title && (<Header title={content.title} rightButtons={closeButton} leftButtons={[]} withElevation={false}/>)}
          <View style={{ height, flexDirection: 'row', minHeight: '100%' }}>{content.view}</View>
        </View>
      </View>);
    }
    getInitialPlanPosition(ticket, selectedPlan, selectedPlanVersion) {
        if (selectedPlan?.activePlanId === 'MAP' || selectedPlanVersion != null) {
            let pointInPage = selectedPlanVersion == null
                ? { x: 0, y: 0 }
                : {
                    x: selectedPlanVersion.pageWidth / (selectedPlanVersion?.fileId.toLowerCase().endsWith('pdf') ? 2 : 20),
                    y: selectedPlanVersion.pageHeight / (selectedPlanVersion?.fileId.toLowerCase().endsWith('pdf') ? 2 : 20),
                };
            if (selectedPlan?.address != null) {
                pointInPage = { x: selectedPlan.address.longitude, y: selectedPlan.address.latitude };
            }
            let zoomLevel = selectedPlanVersion == null ? 16 : 2;
            if (ticket.planId === selectedPlan?.id && ticket.planPositionX != null && ticket.planPositionY != null) {
                pointInPage =
                    selectedPlanVersion == null
                        ? { x: ticket.planPositionX, y: ticket.planPositionY }
                        : selectedPlanVersion.getPointInPageFromRealpointInCM({
                            x: ticket.planPositionX,
                            y: ticket.planPositionY,
                        });
                zoomLevel = selectedPlanVersion == null ? 16 : 3;
                try {
                    if (RouterControl.instance.currentUrl.search != null) {
                        const query = { ...querystring.parse(RouterControl.instance.currentUrl.search) };
                        if (query != null && query['zTo'] != null) {
                            const s = query['zTo'].toString().split('x');
                            if (s.length >= 3) {
                                const z = Number.parseFloat(s[2]);
                                if (z > zoomLevel)
                                    zoomLevel = z;
                            }
                        }
                    }
                }
                catch (e) {
                    console.debug('cant get query search', e);
                }
            }
            return { center: { point: pointInPage, latlng: { lat: pointInPage.y, lng: pointInPage.x } }, zoom: zoomLevel };
        }
        return undefined;
    }
    renderAddressField() {
        const { selectedPlan, placesKey, addressInput } = this.state;
        if (selectedPlan != null && selectedPlan.activePlanId === 'MAP') {
            return (<View style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: 72,
                    padding: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    left: 0,
                    zIndex: 200,
                    elevation: 200,
                    backgroundColor: 'transparent',
                }} pointerEvents="box-none">
          <GooglePlacesInput key={`places_${placesKey.toString()}`} initialText={addressInput} onChange={this.onGoogleAddressSelected} onChangeText={this.onCustomAddressTextSelected} backendURL={this.apiUrl}/>
        </View>);
        }
        return null;
    }
    renderContent() {
        const { ticket, height, width } = this.props;
        const { planId } = this.state;
        if (planId == null && (this.plans == null || this.plans.length === 0)) {
            return {
                view: (<View style={{ width: '100%', height: '100%' }}>
            <View style={{ alignItems: 'center', alignContent: 'center' }}>
              <Image style={{ height: 200, width: 400, resizeMode: 'contain', marginTop: 16 }} resizeMode="contain" source={notFoundImage}/>
              <View style={{ marginTop: 16 }}>
                <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2}>
                  {I18n.m.getMessage('plansNotFound')}
                </MaterialText>
                <Ripple disabled={false} style={{
                        width: ThemeManager.style.getScreenRelativePixelSize(264),
                        height: ThemeManager.style.getScreenRelativePixelSize(48),
                    }} onPress={this.goToPlanUpload}>
                  <MaterialText centeredBox centeredText color={ThemeManager.style.black87} type={MaterialTextTypes.Subtitle2} strong>
                    {I18n.m.getMessage('planUploadDescription')}
                  </MaterialText>
                </Ripple>
              </View>
            </View>
          </View>),
                title: I18n.m.getMessage('ticketChangePlanSelectPlanTitle'),
            };
        }
        if (planId === undefined) {
            return {
                view: (<PlanSelector plans={this.plans} onSelect={(planId) => () => this.setPlanId(planId)} withAll={false} currentSelecedPlanId={ticket.planId ?? undefined} currentFilter={new PlanFilter()} onDialogClose={this.abort} allowTable dialogHeight={height} width={width}/>),
            };
        }
        const { selectedPlanVersion, selectedPlan, initialPlanPosition } = this.state;
        if (selectedPlan != null && (selectedPlanVersion != null || selectedPlan?.activePlanId === 'MAP')) {
            const { ticket } = this.props;
            const { width, height } = this.props;
            const cHeight = height - ThemeManager.style.headerHeight;
            const color = TicketStatusColor.getColorForStatus(ticket.ticketStatus);
            return {
                view: (<View style={{ width, height: cHeight, position: 'relative' }}>
            <PlanZoomTileMapper initialPlanPosition={initialPlanPosition} plan={selectedPlan} type={selectedPlanVersion == null ? 'map' : 'plan'} planVersion={selectedPlanVersion} zoomAble offlineData={selectedPlanVersion == null
                        ? undefined
                        : OfflineDataDownloader.isPlanVersionSynced(selectedPlanVersion.projectId, selectedPlanVersion.id)} ref={this.setMapRef} onRegionChange={this.setCurrentCenter} url={selectedPlanVersion == null
                        ? 'map'
                        : `${UpmeshClient.instance.url}/plan/tiles/${selectedPlanVersion.projectId}/planVersion/${selectedPlanVersion.id}`}/>
            {this.renderAddressField()}
            <View style={{
                        position: 'absolute',
                        left: width / 2 - 1,
                        height: cHeight,
                        width: 2,
                        backgroundColor: color,
                    }}/>
            <View style={{
                        width,
                        position: 'absolute',
                        top: cHeight / 2 - 1,
                        height: 2,
                        backgroundColor: color,
                    }}/>
            <CustomMarkerComponent style={{
                        position: 'absolute',
                        top: cHeight / 2 - 44,
                        left: width / 2 - 24,
                        width: 48,
                        height: 48,
                    }} size={48} id={ticket.id} index={0} marker={new PlanMarker(ticket.id, { x: 0, y: 0 }, color, ticket.ticketStatus)}/>
            <View style={{
                        position: 'absolute',
                        top: cHeight / 2 - 94,
                        left: width / 2 - 18,
                        width: 36,
                        height: 36,
                    }}>
              <Icon backgroundColor={ThemeManager.style.brandPrimary} color="#FFFFFF" icon="floor-plan" toolTip={I18n.m.getMessage('ticketChangePlanSelectPlanChangePlan')} onPress={this.changePlan}/>
            </View>

            <View style={{
                        position: 'absolute',
                        top: cHeight / 2 - 46,
                        left: width / 2 - 68,
                        width: 36,
                        height: 36,
                    }}>
              <Icon backgroundColor={ThemeManager.style.brandDanger} color="#FFFFFF" icon="close" toolTip={I18n.m.getMessage('ticketChangePlanSelectPlanAbort')} onPress={this.abort}/>
            </View>
            <View style={{
                        position: 'absolute',
                        top: cHeight / 2 - 46,
                        left: width / 2 + 32,
                        width: 36,
                        height: 36,
                    }}>
              <Icon backgroundColor={ThemeManager.style.brandSuccess} color="#FFFFFF" icon="check" toolTip={I18n.m.getMessage('ticketChangePlanSelectPlanSave')} onPress={() => {
                        this.savePlanPosition().catch((err) => console.error(err));
                    }}/>
            </View>
          </View>),
                title: selectedPlan.title,
            };
        }
        return {
            view: (<View style={{ width: '100%', height: '100%' }}>
          <Spinner />
        </View>),
            title: '',
        };
    }
    createNumColumns(forWidth) {
        let targetWidth = ThemeManager.style.getScreenRelativePixelSize(212);
        const width = forWidth - ThemeManager.style.contentPaddingValue;
        const numColumns = Math.max(1, Math.round(width / targetWidth));
        targetWidth = width / numColumns;
        return { targetWidth, numColumns };
    }
}
