"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WorkerInterface = void 0;
var CommandOfflineFirstStore_1 = require("cqrs-core/build/src/client/commands/CommandOfflineFirstStore");
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var WaitFor_1 = require("cqrs-shared/build/src/WaitFor");
var SimpleStorage_1 = require("odatarepos/build/src/db/SimpleStorage");
var ts_events_1 = require("ts-events");
var uuid = require("uuid");
var ClientDB_1 = require("../ClientDB");
var WorkerServerConnection_1 = require("./WorkerServerConnection");
var WorkerInterface = function () {
  function WorkerInterface() {
    var _this = this;
    (0, _classCallCheck2.default)(this, WorkerInterface);
    this.clientId = uuid.v1();
    this.serverConnectionsChanged = new ts_events_1.AsyncEvent();
    this.lastUserId = '';
    this.onMessage = new ts_events_1.AsyncEvent();
    this.init = false;
    this.initError = null;
    this.toClientMessage = function (msg) {
      _this.processMessageOnClient(msg).catch(function (err) {
        return void 0;
      });
      _this.onMessage.post({
        id: 'id',
        message: msg
      });
    };
    this.toWorkerMessage = function (msg) {
      _this.processMessageOnWorker(msg).catch(function (err) {
        return void 0;
      });
      _this.onMessage.post({
        id: 'id',
        message: msg
      });
    };
    this.processMessageOnWorker = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (msg) {
        try {
          var result;
          var s = msg.split('µ');
          if (s.length >= 2 && s[2] === 'getConnection') {
            _this.postToClient(`clientµallµconnectionµ${_this.sc.isConnected ? '1' : ''}`);
          } else if (s[1] === 'logout') {
            yield _this.onWorkerLogout();
            _this.postToClient(`clientµallµlogout`);
          } else if (s[1] === 'changeAuthToken') {
            var newToken = s[2];
            yield _this.sc.changeAuthToken(newToken);
            _this.postToClient(`authTokenµ${newToken}`);
          } else if (s[1] === 'startSync') {
            _this.startSyncInWorker();
          } else if (s[1] === 'connect') {
            var c = yield _this.sc.connect();
            _this.postToClient(`connectedµ${c}`);
          } else if (s[1] === 'freshDatabase') {
            var fresh = yield _this.freshDatabase();
            _this.postToClient(`freshDatabaseµ${fresh ? '1' : '0'}`);
          } else if (s[0] === 'logsOnClient') {
            var orgLog = function orgLog() {};
            console.log = function () {};
            var orgDebug = function orgDebug() {};
            console.debug = function () {};
            var orgInfo = function orgInfo() {};
            console.info = function () {};
            var orgWarn = function orgWarn() {};
            console.warn = function () {};
            var orgError = function orgError() {};
            console.error = function () {};
          } else if (s[1] === 'init') {
            if (_this.sc == null || s[3] === '1' || s[2] !== _this.sc.token) _this.init = false;
            if (!_this.init) {
              yield _this.createWorkerConnections(s[2]);
            } else {
              yield WaitFor_1.WaitFor.instance.waitFor(function () {
                return _this.init === true || _this.init === 'error';
              }, 66, 120000);
            }
            if (_this.initError != null) {
              _this.postToClient(`initedµerrorµ${JSON.stringify(_this.initError)}µ${s[2]}`);
            } else {
              _this.postToClient(`initedµ${_this.sc.token}`);
            }
            _this.postToClient(`clientµallµconnectionµ${_this.sc.isConnected ? '1' : ''}`);
          } else if (s.length >= 4 && s[2] === 'command') {
            var data = JSON.parse(s[3]);
            try {
              if (data['commandName'] === 'GetDataOverSocket') {
                var d = data.data;
                var m = ReadModels_1.ReadModels.get(d.readmodel);
                if (m == null && d.readmodel === 'CommandOfflineModel') {
                  var _this$cb$localDb;
                  var repo = (_this$cb$localDb = _this.cb.localDb) == null ? void 0 : _this$cb$localDb.getRepos(new CommandOfflineFirstStore_1.CommandOfflineModel());
                  if (repo != null) m = repo;
                }
                if (m == null) {
                  result = '';
                } else if (d.method === 'get') {
                  result = yield m.get(d.options);
                } else if (d.method === 'getById' && d.options != null && d.options.id != null) {
                  result = yield m.getById(d.options.id, d.options.select);
                } else if (d.method === 'getByIdAt' && d.options != null && d.options.id != null && d.options.date != null) {
                  result = yield m.getByIdAt(d.options.id, new Date(d.options.date));
                } else if (d.method === 'count') {
                  var _d$options;
                  result = yield m.count((_d$options = d.options) == null ? void 0 : _d$options.filter);
                } else if (d.method === 'distinct' && d.options != null && d.options.select != null && typeof d.options.select === 'string') {
                  var _d$options2;
                  result = yield m.distinct(d.options.select, (_d$options2 = d.options) == null ? void 0 : _d$options2.filter);
                }
              } else {
                var cData = Object.assign({}, data, {
                  token: _this.sc.token
                });
                if (_this.commandStore != null) result = yield _this.commandStore.save(cData);
              }
              _this.postToClient(`clientµ${s[1]}µ${data['id']}µ${result != null ? JSON.stringify(result) : ''}${s.length > 4 ? `µ${s[4]}` : ''}`);
            } catch (e) {
              var err = e.message == null ? Object.assign({}, e, {
                message: e.toString()
              }) : Object.assign({}, e, {
                message: e.message
              });
              _this.postToClient(`clientµ${s[1]}µ${data['id']}µ${JSON.stringify({
                error: err
              })}${s.length > 4 ? `µ${s[4]}` : ''}`);
            }
          }
        } catch (err) {}
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
    this.onResult = new ts_events_1.AsyncEvent();
    this.processMessageOnClient = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (msg) {
        var s = msg.split('µ');
        if (s[1] === 'all' && s[2] === 'readmodel') {
          var data = JSON.parse(s[3]);
          data.entities = data.entities != null ? new Map(data.entities) : new Map();
          ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst.remoteEventDispatcher.post(data);
        } else if (s[0].startsWith('storage')) {
          var v = s[0].substr(7);
          if (v.startsWith('Get')) {
            if (s[1] === 'allData') {
              var keys = yield SimpleStorage_1.SimpleStorage.getAllKeys();
              var value = {};
              for (var key of keys) {
                try {
                  value[key] = SimpleStorage_1.SimpleStorage.get(key);
                } catch (error) {}
              }
              _this.postToWorker(`storageGotµ${s[1]}µ${JSON.stringify(value)}`);
            } else {
              var _value = SimpleStorage_1.SimpleStorage.get(s[1]);
              _this.postToWorker(`storageGotµ${s[1]}µ${_value}`);
            }
          } else if (v.startsWith('Set')) {
            SimpleStorage_1.SimpleStorage.set(s[1], s[2]);
            _this.postToWorker(`storageSotµ${s[1]}`);
          } else if (v.startsWith('Remove')) {
            SimpleStorage_1.SimpleStorage.remove(s[1]);
          } else if (v.startsWith('Clear')) {
            SimpleStorage_1.SimpleStorage.clear();
          } else if (v.startsWith('AllKeys')) {
            var _value2 = yield SimpleStorage_1.SimpleStorage.getAllKeys();
            _this.postToWorker(`storageAKµ${JSON.stringify(_value2)}`);
          }
        } else if (s[0] === 'inited') {
          if (s[1].startsWith('error')) {
            throw JSON.parse(s[2]);
          } else {
            _this.onLogin(s[1]);
          }
        } else if (s[0] === 'log') {
          try {
            if (s[1] === 'log') {} else if (s[1] === 'info') {} else if (s[1] === 'debug') {} else if (s[1] === 'warn') {} else if (s[1] === 'error') {}
          } catch (err) {}
        } else if (s[1] === 'all' && s[2] === 'logout') {
          _this.onLogout();
        } else if (s[1] === 'all' && s[2] === 'syncStopped') {
          if (_this.syncDispatcher != null) _this.syncDispatcher().syncStop.post();
        } else if (s[1] === 'all' && s[2] === 'syncStarted') {
          if (_this.syncDispatcher != null) _this.syncDispatcher().syncStart.post();
          _this.sc.dispatchSyncCouldStart();
        } else if (s[1] === 'all' && s[2] === 'startSync') {
          _this.sc.dispatchSyncCouldStart();
        } else if (s[1] === 'all' && s[2] === 'syncStatus') {
          var status = JSON.parse(s[3]);
          if (_this.syncDispatcher != null) {
            _this.syncDispatcher().postSyncStatus(status);
          }
        } else if (s[1] === 'all' && s[2] === 'connection') {
          var connected = s[3] === '1';
          _this.currentConnection = connected;
        } else if (s[1] === _this.clientId || s[1] === 'all') {
          var _data = s[3].length > 0 ? JSON.parse(s[3]) : '';
          _this.sc.post(s[2], _data);
          if (s.length > 4) {
            _this.onResult.post({
              messageId: s[4],
              data: _data
            });
          }
        }
      });
      return function (_x2) {
        return _ref2.apply(this, arguments);
      };
    }();
    WorkerInterface.instance = this;
  }
  return (0, _createClass2.default)(WorkerInterface, [{
    key: "currentConnection",
    set: function set(v) {
      if (v !== this._currentConnection) {
        this._currentConnection = v;
        this.serverConnectionsChanged.post(v);
      }
    }
  }, {
    key: "serverConnected",
    value: function serverConnected() {
      if (this._currentConnection != null) return this._currentConnection;
      if (this.sc != null) return this.sc.isConnected;
      return false;
    }
  }, {
    key: "getClientSC",
    value: function getClientSC(token, syncDispatcher) {
      var _this2 = this;
      this.syncDispatcher = syncDispatcher;
      if (this.sc == null) {
        this.sc = new WorkerServerConnection_1.WorkerServerConnection(function (msg) {
          return _this2.postToWorker(msg);
        }, function (msg) {
          return _this2.postToWorkerWithAnswer(msg);
        }, this.onMessage, this.clientId, token);
      } else this.sc.changeAuthToken(token).catch(function (err) {
        return void 0;
      });
      this.postToClient('toLeaderµgetConnection');
      return this.sc;
    }
  }, {
    key: "getClientDB",
    value: function () {
      var _getClientDB = (0, _asyncToGenerator2.default)(function* (userId) {
        var _deleteDB = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        if (this.cbClient == null || this.lastUserId !== userId) {
          this.lastUserId = userId;
          this.cbClient = new ClientDB_1.ClientDB(undefined);
        }
        return this.cbClient;
      });
      function getClientDB(_x3) {
        return _getClientDB.apply(this, arguments);
      }
      return getClientDB;
    }()
  }, {
    key: "getFreshDatabase",
    value: function () {
      var _getFreshDatabase = (0, _asyncToGenerator2.default)(function* () {
        var _this3 = this;
        var i;
        var t = yield new Promise(function (resolve) {
          var a = function a(d) {
            if (d.message.startsWith('freshDatabase')) {
              if (i != null) clearInterval(i);
              var s = d.message.split('µ');
              _this3.onMessage.detach(d);
              resolve(s[1] === '1');
            }
          };
          _this3.onMessage.attach(a);
          var p = function p() {
            _this3.postToWorker(`toLeaderµfreshDatabase`);
          };
          i = setInterval(p, 3000);
          p();
        });
        return t;
      });
      function getFreshDatabase() {
        return _getFreshDatabase.apply(this, arguments);
      }
      return getFreshDatabase;
    }()
  }, {
    key: "initWorker",
    value: function () {
      var _initWorker = (0, _asyncToGenerator2.default)(function* (token) {
        var _this4 = this;
        var reset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var i;
        var t = yield new Promise(function (resolve) {
          _this4.onMessage.attach(function (d) {
            if (d.message.startsWith('inited')) {
              if (i != null) clearInterval(i);
              var s = d.message.split('µ');
              resolve(s[1]);
            }
          });
          var resetOnce = !!reset;
          var p = function p() {
            _this4.postToWorker(`toLeaderµinitµ${token}µ${resetOnce ? '1' : ''}`);
            resetOnce = false;
          };
          i = setInterval(p, reset ? 10000 : 5000);
          p();
        });
        return t;
      });
      function initWorker(_x4) {
        return _initWorker.apply(this, arguments);
      }
      return initWorker;
    }()
  }]);
}();
exports.WorkerInterface = WorkerInterface;