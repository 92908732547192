var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SettingsView = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _ChangeUserSettings = require("upmesh-auth-core/build/src/client/commands/user/ChangeUserSettings");
var _SetLocale = require("upmesh-auth-core/build/src/client/commands/user/SetLocale");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _UserSettingsEntity = require("upmesh-auth-core/build/src/client/query/entities/UserSettingsEntity");
var _SegmentedButton = require("../../theme/components/button/SegmentedButton");
var _Card = require("../../theme/components/Card");
var _FormInputPicker = require("../../theme/components/forminput/FormInputPicker");
var _Switch = require("../../theme/components/Switch");
var _MaterialText = require("../../theme/components/text/MaterialText");
var _I18n = require("../../theme/i18n/I18n");
var _LanguagePicker = require("../../theme/i18n/LanguagePicker");
var _Routing = require("../../theme/routing/Routing");
var _ThemeManager = require("../../theme/ThemeManager");
var _userContext = _interopRequireDefault(require("../../userContext"));
var _BackButton = _interopRequireDefault(require("../components/BackButton"));
var _PageView = require("../root/PageView");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var SettingsView = exports.SettingsView = function (_PureComponent) {
  function SettingsView(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, SettingsView);
    _this = _callSuper(this, SettingsView, [props, context]);
    _this.onChangeTimeFormat = function (e) {
      var timeFormat = e.index === 1 ? '12' : '24';
      var defaultErrorHandler = _this.props.defaultErrorHandler;
      var newSettings = new _UserSettingsEntity.UserSettingsEntity(Object.assign({}, _this.context.settings, {
        timeFormat: timeFormat
      }));
      var c = new _ChangeUserSettings.ChangeUserSettings(newSettings);
      c.execute(_AuthClient.AuthClient.instance.commandStore).catch(function (e) {
        if (defaultErrorHandler != null) defaultErrorHandler(e);
      });
    };
    _this.onChangeKW = function (e) {
      var defaultErrorHandler = _this.props.defaultErrorHandler;
      var newSettings = new _UserSettingsEntity.UserSettingsEntity(Object.assign({}, _this.context.settings, {
        showKW: e
      }));
      var c = new _ChangeUserSettings.ChangeUserSettings(newSettings);
      c.execute(_AuthClient.AuthClient.instance.commandStore).catch(function (e) {
        if (defaultErrorHandler != null) defaultErrorHandler(e);
      });
    };
    _this.onChangeMonday = function (e) {
      var defaultErrorHandler = _this.props.defaultErrorHandler;
      var newSettings = new _UserSettingsEntity.UserSettingsEntity(Object.assign({}, _this.context.settings, {
        startWithMonday: e
      }));
      var c = new _ChangeUserSettings.ChangeUserSettings(newSettings);
      c.execute(_AuthClient.AuthClient.instance.commandStore).catch(function (e) {
        if (defaultErrorHandler != null) defaultErrorHandler(e);
      });
    };
    _this.onChangeImageEditor = function (e) {
      var newSettings = new _UserSettingsEntity.UserSettingsEntity(Object.assign({}, _this.context.settings, {
        appSettings: Object.assign({}, _this.getCurrentSettings(), {
          openImageEditorAfterPhoto: e
        })
      }));
      var c = new _ChangeUserSettings.ChangeUserSettings(newSettings);
      c.execute(_AuthClient.AuthClient.instance.commandStore).catch(function (e) {});
    };
    _this.onChangeImageQuality = function (index) {
      var newSettings = new _UserSettingsEntity.UserSettingsEntity(Object.assign({}, _this.context.settings, {
        appSettings: Object.assign({}, _this.getCurrentSettings(), {
          imageUploadQuality: index
        })
      }));
      var c = new _ChangeUserSettings.ChangeUserSettings(newSettings);
      c.execute(_AuthClient.AuthClient.instance.commandStore).catch(function (e) {});
    };
    _this.onLangChanged = function (lang) {
      var _this$context;
      if (lang !== ((_this$context = _this.context) == null || (_this$context = _this$context.user) == null ? void 0 : _this$context.locale)) {
        var c = new _SetLocale.SetLocale({
          locale: lang,
          userId: _CurrentUser.CurrentUser.userId
        });
        c.execute(_AuthClient.AuthClient.instance.commandStore).catch(function (e) {
          _Routing.Routing.instance.alert.post({
            text: `${_I18n.I18n.m.getMessage('commandSetLocaleError')} ${e.toString()}`
          });
        });
      }
    };
    var cam = _SimpleStorage.SimpleStorage.get('cameraApp');
    var camIndex = cam != null && cam === 'native' ? 0 : 1;
    _this.state = {
      maxWidth: Math.min(512, _ThemeManager.ThemeManager.style.windowWidth),
      camIndex: camIndex
    };
    return _this;
  }
  (0, _inherits2.default)(SettingsView, _PureComponent);
  return (0, _createClass2.default)(SettingsView, [{
    key: "getTimeIndex",
    value: function getTimeIndex(userSettings) {
      if (userSettings.timeFormat === '24') return 0;
      return 1;
    }
  }, {
    key: "render",
    value: function render() {
      var _this2 = this;
      var additionalPersonalSettings = this.props.additionalPersonalSettings;
      var camIndex = this.state.camIndex;
      var timeIndex = this.getTimeIndex(this.context.settings);
      var selectedImageQuali = this.context.settings.appSettings != null && this.context.settings.appSettings['imageUploadQuality'] != null ? this.context.settings.appSettings['imageUploadQuality'] : 2;
      var editorValue = this.context.settings.appSettings == null || this.context.settings.appSettings['openImageEditorAfterPhoto'] === true;
      return (0, _jsxRuntime.jsx)(_PageView.PageView, {
        showAccountIcon: true,
        headerProps: {
          leftButtons: [(0, _jsxRuntime.jsx)(_BackButton.default, {}, "backButtonSettingsView")],
          rightButtons: [],
          title: _I18n.I18n.m.getMessage('accountSettings')
        },
        scrollable: true,
        style: {
          backgroundColor: _ThemeManager.ThemeManager.style.appBgColor
        },
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            maxWidth: 640,
            paddingTop: _ThemeManager.ThemeManager.style.contentPaddingValue,
            paddingBottom: _ThemeManager.ThemeManager.style.contentPaddingValue
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              marginLeft: 8 + _ThemeManager.ThemeManager.style.contentPaddingValue
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              children: _I18n.I18n.m.getMessage('settingsPersonal')
            })
          }), (0, _jsxRuntime.jsx)(_Card.Card, {
            children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
              style: {
                width: '100%',
                position: 'relative'
              },
              children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
                style: Object.assign({}, _ThemeManager.ThemeManager.style.contentPadding),
                children: [(0, _jsxRuntime.jsx)(_LanguagePicker.LanguagePicker, {
                  labelText: _I18n.I18n.m.getMessage('settingsLanguage'),
                  onChanged: this.onLangChanged,
                  changeOutside: true
                }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
                  style: {
                    width: '100%'
                  },
                  children: [(0, _jsxRuntime.jsx)(_FormInputPicker.FormInputPicker, {
                    labelText: _I18n.I18n.m.getMessage('settingsHourFormatLabel'),
                    selectedIndex: timeIndex,
                    list: [_I18n.I18n.m.getMessage('settingsHourFormat24'), _I18n.I18n.m.getMessage('settingsHourFormat12')],
                    onChange: this.onChangeTimeFormat
                  }, `timeformatSettings_${this.context.settings.timeFormat}`), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
                    style: {
                      width: '100%',
                      paddingVertical: 8,
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    },
                    children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                      centeredBox: true,
                      children: _I18n.I18n.m.getMessage('settingsWeekStartsWithMonday')
                    }), (0, _jsxRuntime.jsx)(_Switch.Switch, {
                      onPress: this.onChangeMonday,
                      value: this.context.settings.startWithMonday
                    }, `mondaySettings_${this.context.settings.startWithMonday}`)]
                  }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
                    style: {
                      width: '100%',
                      paddingVertical: 8,
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    },
                    children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                      centeredBox: true,
                      children: _I18n.I18n.m.getMessage('settingsShowKw')
                    }), (0, _jsxRuntime.jsx)(_Switch.Switch, {
                      value: this.context.settings.showKW,
                      onPress: this.onChangeKW
                    }, `kwSettings_${this.context.settings.showKW}`)]
                  })]
                })]
              }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                style: {
                  width: '100%',
                  paddingVertical: 0,
                  borderWidth: 0,
                  borderColor: _ThemeManager.ThemeManager.style.borderColor,
                  borderTopWidth: _ThemeManager.ThemeManager.style.borderWidth,
                  marginTop: 4
                },
                children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
                  style: Object.assign({}, _ThemeManager.ThemeManager.style.contentPadding),
                  children: [_reactNativeWeb.Platform.OS !== 'web' && (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                    style: {
                      width: '100%',
                      maxWidth: '100%',
                      height: 72,
                      overflow: 'hidden'
                    },
                    children: (0, _jsxRuntime.jsx)(_FormInputPicker.FormInputPicker, {
                      labelText: _I18n.I18n.m.getMessage('settingsCameraApp'),
                      selectedIndex: camIndex,
                      list: [_I18n.I18n.m.getMessage('settingsCameraAppNative'), _I18n.I18n.m.getMessage('settingsCameraAppVision')],
                      onChange: function onChange(e) {
                        _this2.setState({
                          camIndex: e.index
                        });
                        _SimpleStorage.SimpleStorage.set('cameraApp', e.index === 1 ? 'vision' : 'native');
                      }
                    }, `camSettings_${camIndex}`)
                  }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
                    style: {
                      width: '100%',
                      maxWidth: '100%',
                      paddingVertical: 8,
                      alignItems: 'center',
                      flexDirection: 'row'
                    },
                    children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                      style: {
                        flex: 1,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end'
                      },
                      children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                        fixedWidth: "100%",
                        centeredBox: true,
                        children: _I18n.I18n.m.getMessage('settingsImageEditor')
                      })
                    }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                      style: {
                        width: 56,
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end'
                      },
                      children: (0, _jsxRuntime.jsx)(_Switch.Switch, {
                        value: editorValue,
                        onPress: this.onChangeImageEditor
                      }, `imageEditorSettings_${editorValue}`)
                    })]
                  }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
                    style: {
                      width: '100%',
                      maxWidth: '100%',
                      paddingVertical: 8,
                      alignItems: 'center',
                      flexDirection: 'column'
                    },
                    children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                      style: {
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end'
                      },
                      children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                        fixedWidth: "100%",
                        centeredBox: true,
                        children: _I18n.I18n.m.getMessage('settingsImageUploadQuali')
                      })
                    }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                      style: {
                        width: '100%',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end'
                      },
                      children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                        style: {
                          width: '100%',
                          maxWidth: 512,
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end'
                        },
                        children: (0, _jsxRuntime.jsx)(_SegmentedButton.SegmentedButton, {
                          density: 0,
                          buttons: [{
                            title: _I18n.I18n.m.getMessage('settingsImageUploadQuali0')
                          }, {
                            title: _I18n.I18n.m.getMessage('settingsImageUploadQuali1')
                          }, {
                            title: _I18n.I18n.m.getMessage('settingsImageUploadQuali2')
                          }, {
                            title: _I18n.I18n.m.getMessage('settingsImageUploadQuali3')
                          }],
                          singleSelectSelected: selectedImageQuali,
                          onPress: this.onChangeImageQuality
                        })
                      })
                    })]
                  })]
                })
              }), additionalPersonalSettings != null ? additionalPersonalSettings : null]
            })
          }, `settings_${this.context.settings.lastModifiedAt}`), this.props.children]
        })
      });
    }
  }, {
    key: "getCurrentSettings",
    value: function getCurrentSettings() {
      if (this.context.settings.appSettings != null) {
        return this.context.settings.appSettings;
      }
      return {};
    }
  }]);
}(_react.PureComponent);
SettingsView.contextType = _userContext.default;